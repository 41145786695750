import { useEffect } from 'react'

import { ListWebPageV1Response } from '@singcolor/grpc-client/src/singcolor-proto/ts/users_v1_pb'
import { useGrpcClient } from 'src/modules/grpc/hooks'
import { SWRKey } from 'src/modules/swr/key'
import useSWRImmutable from 'swr/immutable'

const webLinkMasterKeys = [
  'fAQHome',
  'privacyPolicy',
  'userTerms',
  'corporation',
  'fundSettlementLaw',
  'aboutStar',
  'faqMembership',
  'faqCancelMembership',
  'faqGospel',
  'faqMembershipProratedFee',
  'faqMembershipPaymentInfo'
] as const
type WebLinkMaster = {
  [key in typeof webLinkMasterKeys[number]]: string
}

export const useWebLink = (): WebLinkMaster => {
  const { userClient } = useGrpcClient()
  const { data, error } = useSWRImmutable(
    SWRKey.listWebPage,
    () => userClient.listWebPage() as Promise<ListWebPageV1Response.AsObject>
  )

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  // デフォルト値の設定
  const webLinks: WebLinkMaster = {
    fAQHome: 'https://faq.colorsing.com/',
    privacyPolicy: 'https://faq.colorsing.com/7d9fb93b82fa4d29ad9b0106bd5e7f42',
    userTerms: 'https://faq.colorsing.com/779f3101217c473ab2994d23d90c391b',
    corporation: 'https://corp.colorsing.com/',
    fundSettlementLaw: 'https://faq.colorsing.com/4cdf233510a34761949713348e992ebe',
    aboutStar: 'https://faq.colorsing.com/346da5214b764e40b04f17de98191802',
    faqMembership: 'https://faq.colorsing.com/b64a6fe5ac1b4dca99047ce8c2b4ab12',
    faqCancelMembership: 'https://faq.colorsing.com/81308f7149ad4f5ba9c8cf92fa008a84',
    faqGospel: 'https://faq.colorsing.com/1ac2a1b22c9a4f2995ee68ba3a50115b',
    faqMembershipProratedFee: 'https://faq.colorsing.com/135c1bbbc67480f18c97d728100c1759',
    faqMembershipPaymentInfo: 'https://faq.colorsing.com/165c1bbbc67480019cc7c14d356add86'
  }

  const list = data?.webPagesList ?? []
  webLinkMasterKeys.forEach(key => {
    const webPage = list.find(webPage => webPage.keyname === key)
    if (webPage) {
      webLinks[key] = webPage.url
    }
  })

  return webLinks
}
