var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OpeUsersV1Client } from '../singcolor-proto/ts/Ope_user_v1ServiceClientPb';
import { BanUserV1Request, CreateLivePermissionTwitterAccountV1Request, GetUserBanHistoryV1Request, ListLivePermittedTwitterAccountV1Request, OpeGetMonthlyStatisticsV1Request, OpeGetStatisticsCurrentDiamondV1Request, GetUserDetailV1Request, GetUserFlagV1Request, UpdateUserFlagV1Request, MaskUserProfileV1Request, MaskLiveBackgroundV1Request, ListUserProfileScreeningsV1Request, ConfirmUserProfileScreeningsV1Request, RegisterBankTransferTokenV1Request, GetUserProfileScreeningV1Request, ListLeagueResetCompensationsV1Request, CreateLeagueResetCompensationV1Request, DeleteLeagueResetCompensationV1Request, ForceUnbanUserV1Request, DeleteUserDeviceV1Request, ListDevicesByUserIDRequest, OpeAddCoinRequest, OpeGetCoinBalanceRequest, PassLivePermissionFirstReviewV1Request, PassLivePermissionSecondReviewV1Request, SendSystemMessageV1Request, ListReservedLiverMembershipBenefitsV1Request, OpeRequestWithdrawV1Request, RevokeLivePermissionV1Request } from '../singcolor-proto/ts/ope_user_v1_pb';
import { SimplePaginationRequest } from '../singcolor-proto/ts/page_entity_pb';
import { MonthlyStatisticsPageKey } from '../singcolor-proto/ts/users_entity_pb';
var OpeUsersGrpcClient = /** @class */ (function () {
    function OpeUsersGrpcClient(metadataBuilder) {
        this.metadataBuilder = metadataBuilder;
        this.client = new OpeUsersV1Client(process.env.NEXT_PUBLIC_OPE_API_URL, null, null);
    }
    OpeUsersGrpcClient.prototype.callGrpc = function (invoke) {
        return __awaiter(this, void 0, void 0, function () {
            var metadata, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.metadataBuilder()];
                    case 1:
                        metadata = _a.sent();
                        return [4 /*yield*/, invoke({ metadata: metadata })];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.toObject()];
                }
            });
        });
    };
    OpeUsersGrpcClient.prototype.banUser = function (_a) {
        var userId = _a.userId, violationCategoryId = _a.violationCategoryId, violationTargetId = _a.violationTargetId, banLevel = _a.banLevel, reportId = _a.reportId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new BanUserV1Request();
                request.setUserId(userId);
                request.setViolationCategoryId(violationCategoryId);
                request.setViolationTargetId(violationTargetId);
                request.setBanLevel(banLevel);
                request.setReportId(reportId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.banUser(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.forceUnbanUser = function (_a) {
        var userId = _a.userId, banLevel = _a.banLevel;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ForceUnbanUserV1Request();
                request.setUserId(userId);
                request.setBanLevel(banLevel);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.forceUnbanUser(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.getUserBanHistory = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetUserBanHistoryV1Request();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getUserBanHistory(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.listLivePermittedTwitterAccount = function (_a) {
        var page = _a.page, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request, paginationRequest;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListLivePermittedTwitterAccountV1Request();
                paginationRequest = new SimplePaginationRequest();
                paginationRequest.setPage(page);
                paginationRequest.setLimit(limit);
                request.setPage(paginationRequest);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listLivePermittedTwitterAccountV1(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.createLivePermissionTwitterAccount = function (_a) {
        var twitterScreenName = _a.twitterScreenName;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new CreateLivePermissionTwitterAccountV1Request();
                request.setTwitterScreenName(twitterScreenName);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.createLivePermissionTwitterAccountV1(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.deleteLivePermissionTwitterAccount = function (_a) {
        var twitterScreenName = _a.twitterScreenName;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new CreateLivePermissionTwitterAccountV1Request();
                request.setTwitterScreenName(twitterScreenName);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.deleteLivePermissionTwitterAccountV1(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.getStatisticsCurrentDiamond = function (_a) {
        var liveID = _a.liveID;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new OpeGetStatisticsCurrentDiamondV1Request();
                request.setLiverId(liveID);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getStatisticsCurrentDiamond(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.getMonthlyStatistics = function (_a) {
        var liveID = _a.liveID, year = _a.year, month = _a.month;
        return __awaiter(this, void 0, void 0, function () {
            var request, pageKey;
            var _this = this;
            return __generator(this, function (_b) {
                request = new OpeGetMonthlyStatisticsV1Request();
                request.setLiverId(liveID);
                pageKey = new MonthlyStatisticsPageKey();
                pageKey.setYear(year);
                pageKey.setMonth(month);
                request.setPageKey(pageKey);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getMonthlyStatistics(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.getUserDetail = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new GetUserDetailV1Request();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getUserDetail(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.getUserFlag = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new GetUserFlagV1Request();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getUserFlag(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.deleteUserDevice = function (_a) {
        var userId = _a.userId, deviceId = _a.deviceId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new DeleteUserDeviceV1Request();
                request.setUserId(userId);
                request.setDeviceId(deviceId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.deleteUserDevice(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.updateUserFlag = function (userId, userFlag) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new UpdateUserFlagV1Request();
                request.setUserId(userId);
                request.setIsAntisocialForces(userFlag.isAntisocialForces);
                request.setIsCautionNeeded(userFlag.isCautionNeeded);
                request.setIsQa(userFlag.isQa);
                request.setIsPermittedToUseOrganization(userFlag.isPermittedToUseOrganization);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateUserFlag(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.passLivePermissionFirstReview = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new PassLivePermissionFirstReviewV1Request();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.passLivePermissionFirstReview(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.passLivePermissionSecondReview = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new PassLivePermissionSecondReviewV1Request();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.passLivePermissionSecondReview(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.maskUserProfile = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var userId, reportId, violationCategoryId, vaiolationTargetIdList, request;
            var _this = this;
            return __generator(this, function (_a) {
                userId = params.userId, reportId = params.reportId, violationCategoryId = params.violationCategoryId, vaiolationTargetIdList = params.vaiolationTargetIdList;
                request = new MaskUserProfileV1Request();
                request.setUserId(userId);
                if (reportId)
                    request.setReportId(reportId);
                request.setViolationCategoryId(violationCategoryId);
                request.setViolationTargetIdsList(vaiolationTargetIdList);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.maskUserProfile(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.maskLiveBackground = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var userId, request;
            var _this = this;
            return __generator(this, function (_a) {
                userId = params.userId;
                request = new MaskLiveBackgroundV1Request();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.maskLiveBackground(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.listUserProfileScreenings = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var request, limit, pageToken, hasScreeningTarget, updateAtFrom, updateAtTo;
            var _this = this;
            return __generator(this, function (_a) {
                request = new ListUserProfileScreeningsV1Request();
                limit = params.limit, pageToken = params.pageToken, hasScreeningTarget = params.hasScreeningTarget, updateAtFrom = params.updateAtFrom, updateAtTo = params.updateAtTo;
                request.setLimit(limit);
                if (hasScreeningTarget)
                    request.setHasScreeningTarget(hasScreeningTarget);
                if (updateAtFrom)
                    request.setUpdatedAtFrom(updateAtFrom);
                if (updateAtTo)
                    request.setUpdatedAtTo(updateAtTo);
                if (pageToken)
                    request.setPageToken(pageToken);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listUserProfileScreenings(request, metadata);
                    }).catch(function (item) {
                        console.log({ item: item });
                        return item;
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.getUserProfileScreeningByUserId = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var request, userId;
            var _this = this;
            return __generator(this, function (_a) {
                request = new GetUserProfileScreeningV1Request();
                userId = params.userId;
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getUserProfileScreening(request, metadata);
                    }).catch(function (item) {
                        console.log({ item: item });
                        return item;
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.confirmUserProfileScreenings = function (_a) {
        var userIdList = _a.userIdList;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ConfirmUserProfileScreeningsV1Request();
                request.setUserIdsList(userIdList);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.confirmUserProfileScreenings(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.registerBankTransferToken = function (code) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new RegisterBankTransferTokenV1Request();
                request.setCode(code);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.registerBankTransferToken(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.listLeagueResetCompensations = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListLeagueResetCompensationsV1Request();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listLeagueResetCompensations(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.createLeagueResetCompensation = function (leagueOverwriteResetAmount) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new CreateLeagueResetCompensationV1Request();
                request.setUserId(leagueOverwriteResetAmount.userId);
                request.setYear(leagueOverwriteResetAmount.year);
                request.setMonth(leagueOverwriteResetAmount.month);
                request.setTermOfMonth(leagueOverwriteResetAmount.termOfMonth);
                request.setAmount(leagueOverwriteResetAmount.amount);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.createLeagueResetCompensation(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.deleteLeagueResetCompensation = function (_a) {
        var userId = _a.userId, year = _a.year, month = _a.month, termOfMonth = _a.termOfMonth;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new DeleteLeagueResetCompensationV1Request();
                request.setUserId(userId);
                request.setYear(year);
                request.setMonth(month);
                request.setTermOfMonth(termOfMonth);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.deleteLeagueResetCompensation(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.listUserDevices = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListDevicesByUserIDRequest();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listDevicesByUserID(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.OpeAddCoin = function (_a) {
        var userId = _a.userId, amount = _a.amount, type = _a.type;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new OpeAddCoinRequest();
                request.setUserId(userId);
                request.setCoinAmount(amount);
                request.setType(type);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.opeAddCoin(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.OpeGetCoinBalance = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new OpeGetCoinBalanceRequest();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.opeGetCoinBalance(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.sendSystemMessage = function (_a) {
        var userIds = _a.userIds, message = _a.message, externalLink = _a.externalLink, openWebView = _a.openWebView;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new SendSystemMessageV1Request();
                request.setUserIdsList(userIds);
                request.setMessage(message);
                if (externalLink) {
                    request.setExternalLink(externalLink);
                    if (openWebView != undefined) {
                        request.setOpenWebView(openWebView);
                    }
                }
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.sendSystemMessage(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.listReservedLiverMembershipBenefits = function (_a) {
        var userId = _a.userId, monthsCount = _a.monthsCount;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListReservedLiverMembershipBenefitsV1Request();
                request.setLiverId(userId);
                request.setMonthsCount(monthsCount);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listReservedLiverMembershipBenefits(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.opeRequestWithdrawV1 = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new OpeRequestWithdrawV1Request().setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.opeRequestWithdrawV1(request, metadata);
                    })];
            });
        });
    };
    OpeUsersGrpcClient.prototype.revokeLivePermission = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new RevokeLivePermissionV1Request().setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.revokeLivePermissionV1(request, metadata);
                    })];
            });
        });
    };
    return OpeUsersGrpcClient;
}());
export { OpeUsersGrpcClient };
