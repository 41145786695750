"use strict";
// source: ope_user_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var users_entity_pb = require('./users_entity_pb.js');
goog.object.extend(proto, users_entity_pb);
goog.exportSymbol('proto.jp.singcolor.OpeUserDevice', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeUserDevice = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeUserDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeUserDevice.displayName = 'proto.jp.singcolor.OpeUserDevice';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeUserDevice.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeUserDevice.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeUserDevice} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeUserDevice.toObject = function (includeInstance, msg) {
        var f, obj = {
            deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            permitMultipleUser: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
            device: (f = msg.getDevice()) && users_entity_pb.Device.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeUserDevice}
 */
proto.jp.singcolor.OpeUserDevice.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeUserDevice;
    return proto.jp.singcolor.OpeUserDevice.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeUserDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeUserDevice}
 */
proto.jp.singcolor.OpeUserDevice.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setDeviceId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setPermitMultipleUser(value);
                break;
            case 4:
                var value = new users_entity_pb.Device;
                reader.readMessage(value, users_entity_pb.Device.deserializeBinaryFromReader);
                msg.setDevice(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeUserDevice.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeUserDevice.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeUserDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeUserDevice.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDeviceId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getPermitMultipleUser();
    if (f) {
        writer.writeBool(3, f);
    }
    f = message.getDevice();
    if (f != null) {
        writer.writeMessage(4, f, users_entity_pb.Device.serializeBinaryToWriter);
    }
};
/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeUserDevice.prototype.getDeviceId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeUserDevice} returns this
 */
proto.jp.singcolor.OpeUserDevice.prototype.setDeviceId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.jp.singcolor.OpeUserDevice.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeUserDevice} returns this
 */
proto.jp.singcolor.OpeUserDevice.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool permit_multiple_user = 3;
 * @return {boolean}
 */
proto.jp.singcolor.OpeUserDevice.prototype.getPermitMultipleUser = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.OpeUserDevice} returns this
 */
proto.jp.singcolor.OpeUserDevice.prototype.setPermitMultipleUser = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * optional Device device = 4;
 * @return {?proto.jp.singcolor.Device}
 */
proto.jp.singcolor.OpeUserDevice.prototype.getDevice = function () {
    return /** @type{?proto.jp.singcolor.Device} */ (jspb.Message.getWrapperField(this, users_entity_pb.Device, 4));
};
/**
 * @param {?proto.jp.singcolor.Device|undefined} value
 * @return {!proto.jp.singcolor.OpeUserDevice} returns this
*/
proto.jp.singcolor.OpeUserDevice.prototype.setDevice = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeUserDevice} returns this
 */
proto.jp.singcolor.OpeUserDevice.prototype.clearDevice = function () {
    return this.setDevice(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeUserDevice.prototype.hasDevice = function () {
    return jspb.Message.getField(this, 4) != null;
};
goog.object.extend(exports, proto.jp.singcolor);
