// source: lives_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var coins_entity_pb = require('./coins_entity_pb.js');
goog.object.extend(proto, coins_entity_pb);
var karaoke_entity_pb = require('./karaoke_entity_pb.js');
goog.object.extend(proto, karaoke_entity_pb);
var one_phrase_events_entity_pb = require('./one_phrase_events_entity_pb.js');
goog.object.extend(proto, one_phrase_events_entity_pb);
var users_entity_pb = require('./users_entity_pb.js');
goog.object.extend(proto, users_entity_pb);
goog.exportSymbol('proto.jp.singcolor.EmojiComment', null, global);
goog.exportSymbol('proto.jp.singcolor.FaveBonusTitle', null, global);
goog.exportSymbol('proto.jp.singcolor.Gift', null, global);
goog.exportSymbol('proto.jp.singcolor.Gift.BigGiftCoinAmount', null, global);
goog.exportSymbol('proto.jp.singcolor.GiftKind', null, global);
goog.exportSymbol('proto.jp.singcolor.GiftTab', null, global);
goog.exportSymbol('proto.jp.singcolor.GiftTabSection', null, global);
goog.exportSymbol('proto.jp.singcolor.GiftTabSectionType', null, global);
goog.exportSymbol('proto.jp.singcolor.GiftTabType', null, global);
goog.exportSymbol('proto.jp.singcolor.GiftType', null, global);
goog.exportSymbol('proto.jp.singcolor.HeartfulMention', null, global);
goog.exportSymbol('proto.jp.singcolor.HeartfulMentionButton', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFanInfoFaveBadgeTab', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFanInfoFaveBadgeUser', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFanInfoSongFaveTab', null, global);
goog.exportSymbol('proto.jp.singcolor.ListFanInfoSongFaveUser', null, global);
goog.exportSymbol('proto.jp.singcolor.ListeningReactionGiftButton', null, global);
goog.exportSymbol('proto.jp.singcolor.Live', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveBackground', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveBackgroundType', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveComment', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveCommentMention', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEvent', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEvent.EventCase', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventAnnounce', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventAnnounce.AnnounceType', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventBanListenerCommentByModerator', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventComment', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventDoBlockByModerator', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventEnter', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventFinish', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventFinishSing', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventFollow', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventGetFaveBadge', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventGetFaveBonus', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventGetFaveBonus.ValueCase', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventGetFaveBonusTitle', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventGetFavePoints', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventGetSongFave', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventGetSongRandomly', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventHeartfulMention', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventLargeGift', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventLeave', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventLike', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventLiveSettingsUpdated', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventMembershipUpdated', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventPickUpSongRandomly', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventRequestSing', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventSimplePFComment', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventSimplePFCommentType', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventSmallGift', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventStart', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventStartSing', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventSuperLike', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventSurgeRankingNo1Reward', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventSystem', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventTenuredModerator', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventTerminatedModerator', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventType', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveEventUnblockByModerator', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveFinishReason', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveHistory', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveLiverInfo', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveMode', null, global);
goog.exportSymbol('proto.jp.singcolor.LivePermission', null, global);
goog.exportSymbol('proto.jp.singcolor.LivePermission.FailedSecondReviewInfo', null, global);
goog.exportSymbol('proto.jp.singcolor.LivePermission.SecondReviewInfo', null, global);
goog.exportSymbol('proto.jp.singcolor.LivePermission.Status', null, global);
goog.exportSymbol('proto.jp.singcolor.LivePermission.TrialInfo', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveResultFavePoint', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveResultGiftCount', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveResultGiftListener', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveTimelineBanner', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveTimelineBanner.SpecificLiveTimelineBannerAdditionalInfoCase', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveTimelineInfo', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveTimelineItem', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveTimelineItem.BeginnerType', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveTimelineItem.SpecificLiveTimeInfoCase', null, global);
goog.exportSymbol('proto.jp.singcolor.LiveViewingSession', null, global);
goog.exportSymbol('proto.jp.singcolor.OnlineServer', null, global);
goog.exportSymbol('proto.jp.singcolor.PfFavePointsRankingTerm', null, global);
goog.exportSymbol('proto.jp.singcolor.PfFavePointsRankingUser', null, global);
goog.exportSymbol('proto.jp.singcolor.RankingEventStatus', null, global);
goog.exportSymbol('proto.jp.singcolor.ReactionGiftButton', null, global);
goog.exportSymbol('proto.jp.singcolor.ReqCapturePossibility', null, global);
goog.exportSymbol('proto.jp.singcolor.ShareTwitterDeleteSetting', null, global);
goog.exportSymbol('proto.jp.singcolor.SmallGiftType', null, global);
goog.exportSymbol('proto.jp.singcolor.SongYellBadge', null, global);
goog.exportSymbol('proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend', null, global);
goog.exportSymbol('proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent', null, global);
goog.exportSymbol('proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent', null, global);
goog.exportSymbol('proto.jp.singcolor.SpecificLiveTimelineInfoRecommend', null, global);
goog.exportSymbol('proto.jp.singcolor.TermOfMonth', null, global);
goog.exportSymbol('proto.jp.singcolor.UserLiveSetting', null, global);
goog.exportSymbol('proto.jp.singcolor.WorldFilter', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UserLiveSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UserLiveSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.UserLiveSetting.displayName = 'proto.jp.singcolor.UserLiveSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.displayName = 'proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveTimelineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.jp.singcolor.LiveTimelineItem.oneofGroups_);
};
goog.inherits(proto.jp.singcolor.LiveTimelineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveTimelineItem.displayName = 'proto.jp.singcolor.LiveTimelineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.displayName = 'proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SpecificLiveTimelineInfoRecommend = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SpecificLiveTimelineInfoRecommend, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.displayName = 'proto.jp.singcolor.SpecificLiveTimelineInfoRecommend';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveHistory.displayName = 'proto.jp.singcolor.LiveHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.Live = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.Live, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.Live.displayName = 'proto.jp.singcolor.Live';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OnlineServer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OnlineServer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.OnlineServer.displayName = 'proto.jp.singcolor.OnlineServer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveComment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.LiveComment.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.LiveComment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveComment.displayName = 'proto.jp.singcolor.LiveComment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveCommentMention = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveCommentMention, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveCommentMention.displayName = 'proto.jp.singcolor.LiveCommentMention';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveViewingSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveViewingSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveViewingSession.displayName = 'proto.jp.singcolor.LiveViewingSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.jp.singcolor.LiveEvent.oneofGroups_);
};
goog.inherits(proto.jp.singcolor.LiveEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEvent.displayName = 'proto.jp.singcolor.LiveEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventSystem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventSystem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventSystem.displayName = 'proto.jp.singcolor.LiveEventSystem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventStart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventStart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventStart.displayName = 'proto.jp.singcolor.LiveEventStart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventFinish = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventFinish, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventFinish.displayName = 'proto.jp.singcolor.LiveEventFinish';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventEnter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventEnter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventEnter.displayName = 'proto.jp.singcolor.LiveEventEnter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventLeave = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventLeave, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventLeave.displayName = 'proto.jp.singcolor.LiveEventLeave';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventComment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventComment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventComment.displayName = 'proto.jp.singcolor.LiveEventComment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventSmallGift = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventSmallGift, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventSmallGift.displayName = 'proto.jp.singcolor.LiveEventSmallGift';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventLargeGift = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventLargeGift, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventLargeGift.displayName = 'proto.jp.singcolor.LiveEventLargeGift';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventStartSing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.LiveEventStartSing.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.LiveEventStartSing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventStartSing.displayName = 'proto.jp.singcolor.LiveEventStartSing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListeningReactionGiftButton = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListeningReactionGiftButton, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.ListeningReactionGiftButton.displayName = 'proto.jp.singcolor.ListeningReactionGiftButton';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventFinishSing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventFinishSing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventFinishSing.displayName = 'proto.jp.singcolor.LiveEventFinishSing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventRequestSing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventRequestSing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventRequestSing.displayName = 'proto.jp.singcolor.LiveEventRequestSing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventGetFaveBonus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.jp.singcolor.LiveEventGetFaveBonus.oneofGroups_);
};
goog.inherits(proto.jp.singcolor.LiveEventGetFaveBonus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventGetFaveBonus.displayName = 'proto.jp.singcolor.LiveEventGetFaveBonus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventGetFaveBonusTitle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventGetFaveBonusTitle.displayName = 'proto.jp.singcolor.LiveEventGetFaveBonusTitle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventGetFavePoints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventGetFavePoints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventGetFavePoints.displayName = 'proto.jp.singcolor.LiveEventGetFavePoints';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventGetFaveBadge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventGetFaveBadge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventGetFaveBadge.displayName = 'proto.jp.singcolor.LiveEventGetFaveBadge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventGetSongFave = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventGetSongFave, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventGetSongFave.displayName = 'proto.jp.singcolor.LiveEventGetSongFave';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventFollow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventFollow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventFollow.displayName = 'proto.jp.singcolor.LiveEventFollow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventLike = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventLike, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventLike.displayName = 'proto.jp.singcolor.LiveEventLike';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventSuperLike = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventSuperLike, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventSuperLike.displayName = 'proto.jp.singcolor.LiveEventSuperLike';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventAnnounce = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.LiveEventAnnounce.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.LiveEventAnnounce, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventAnnounce.displayName = 'proto.jp.singcolor.LiveEventAnnounce';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventSurgeRankingNo1Reward = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventSurgeRankingNo1Reward, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.displayName = 'proto.jp.singcolor.LiveEventSurgeRankingNo1Reward';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventSimplePFComment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventSimplePFComment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventSimplePFComment.displayName = 'proto.jp.singcolor.LiveEventSimplePFComment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.displayName = 'proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.Gift = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.Gift.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.Gift, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.Gift.displayName = 'proto.jp.singcolor.Gift';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.Gift.BigGiftCoinAmount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.Gift.BigGiftCoinAmount.displayName = 'proto.jp.singcolor.Gift.BigGiftCoinAmount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GiftTab = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GiftTab.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GiftTab, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.GiftTab.displayName = 'proto.jp.singcolor.GiftTab';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GiftTabSection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.GiftTabSection.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.GiftTabSection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.GiftTabSection.displayName = 'proto.jp.singcolor.GiftTabSection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.EmojiComment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.EmojiComment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.EmojiComment.displayName = 'proto.jp.singcolor.EmojiComment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTab = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListFanInfoFaveBadgeTab, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.ListFanInfoFaveBadgeTab.displayName = 'proto.jp.singcolor.ListFanInfoFaveBadgeTab';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListFanInfoFaveBadgeUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.ListFanInfoFaveBadgeUser.displayName = 'proto.jp.singcolor.ListFanInfoFaveBadgeUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFanInfoSongFaveTab = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListFanInfoSongFaveTab, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.ListFanInfoSongFaveTab.displayName = 'proto.jp.singcolor.ListFanInfoSongFaveTab';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListFanInfoSongFaveUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListFanInfoSongFaveUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.ListFanInfoSongFaveUser.displayName = 'proto.jp.singcolor.ListFanInfoSongFaveUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PfFavePointsRankingTerm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PfFavePointsRankingTerm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.PfFavePointsRankingTerm.displayName = 'proto.jp.singcolor.PfFavePointsRankingTerm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PfFavePointsRankingUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PfFavePointsRankingUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.PfFavePointsRankingUser.displayName = 'proto.jp.singcolor.PfFavePointsRankingUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveResultFavePoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveResultFavePoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveResultFavePoint.displayName = 'proto.jp.singcolor.LiveResultFavePoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveResultGiftListener = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.LiveResultGiftListener.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.LiveResultGiftListener, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveResultGiftListener.displayName = 'proto.jp.singcolor.LiveResultGiftListener';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveResultGiftCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveResultGiftCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveResultGiftCount.displayName = 'proto.jp.singcolor.LiveResultGiftCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveLiverInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveLiverInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveLiverInfo.displayName = 'proto.jp.singcolor.LiveLiverInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveTimelineInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveTimelineInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveTimelineInfo.displayName = 'proto.jp.singcolor.LiveTimelineInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveTimelineBanner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.jp.singcolor.LiveTimelineBanner.oneofGroups_);
};
goog.inherits(proto.jp.singcolor.LiveTimelineBanner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveTimelineBanner.displayName = 'proto.jp.singcolor.LiveTimelineBanner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.displayName = 'proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.HeartfulMention = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.HeartfulMention.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.HeartfulMention, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.HeartfulMention.displayName = 'proto.jp.singcolor.HeartfulMention';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventHeartfulMention = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventHeartfulMention, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventHeartfulMention.displayName = 'proto.jp.singcolor.LiveEventHeartfulMention';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventLiveSettingsUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventLiveSettingsUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventLiveSettingsUpdated.displayName = 'proto.jp.singcolor.LiveEventLiveSettingsUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.displayName = 'proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventPickUpSongRandomly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventPickUpSongRandomly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventPickUpSongRandomly.displayName = 'proto.jp.singcolor.LiveEventPickUpSongRandomly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventMembershipUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventMembershipUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventMembershipUpdated.displayName = 'proto.jp.singcolor.LiveEventMembershipUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ReqCapturePossibility = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ReqCapturePossibility, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.ReqCapturePossibility.displayName = 'proto.jp.singcolor.ReqCapturePossibility';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventGetSongRandomly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventGetSongRandomly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventGetSongRandomly.displayName = 'proto.jp.singcolor.LiveEventGetSongRandomly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventBanListenerCommentByModerator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventBanListenerCommentByModerator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventBanListenerCommentByModerator.displayName = 'proto.jp.singcolor.LiveEventBanListenerCommentByModerator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.displayName = 'proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.displayName = 'proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.displayName = 'proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventDoBlockByModerator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventDoBlockByModerator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventDoBlockByModerator.displayName = 'proto.jp.singcolor.LiveEventDoBlockByModerator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventUnblockByModerator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventUnblockByModerator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventUnblockByModerator.displayName = 'proto.jp.singcolor.LiveEventUnblockByModerator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventTenuredModerator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventTenuredModerator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventTenuredModerator.displayName = 'proto.jp.singcolor.LiveEventTenuredModerator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveEventTerminatedModerator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveEventTerminatedModerator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveEventTerminatedModerator.displayName = 'proto.jp.singcolor.LiveEventTerminatedModerator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.HeartfulMentionButton = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.HeartfulMentionButton, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.HeartfulMentionButton.displayName = 'proto.jp.singcolor.HeartfulMentionButton';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ReactionGiftButton = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ReactionGiftButton, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.ReactionGiftButton.displayName = 'proto.jp.singcolor.ReactionGiftButton';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiveBackground = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiveBackground, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiveBackground.displayName = 'proto.jp.singcolor.LiveBackground';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LivePermission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LivePermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LivePermission.displayName = 'proto.jp.singcolor.LivePermission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LivePermission.TrialInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LivePermission.TrialInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LivePermission.TrialInfo.displayName = 'proto.jp.singcolor.LivePermission.TrialInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LivePermission.SecondReviewInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LivePermission.SecondReviewInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LivePermission.SecondReviewInfo.displayName = 'proto.jp.singcolor.LivePermission.SecondReviewInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LivePermission.FailedSecondReviewInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LivePermission.FailedSecondReviewInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.displayName = 'proto.jp.singcolor.LivePermission.FailedSecondReviewInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.UserLiveSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.UserLiveSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.UserLiveSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UserLiveSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    radioMode: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    shareTwitterAccount: (f = msg.getShareTwitterAccount()) && users_entity_pb.TwitterAccount.toObject(includeInstance, f),
    shareTwitterDeleteSetting: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    preEnterComment: jspb.Message.getFieldWithDefault(msg, 6, ""),
    postEnterComment: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UserLiveSetting}
 */
proto.jp.singcolor.UserLiveSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.UserLiveSetting;
  return proto.jp.singcolor.UserLiveSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UserLiveSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UserLiveSetting}
 */
proto.jp.singcolor.UserLiveSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRadioMode(value);
      break;
    case 2:
      var value = new users_entity_pb.TwitterAccount;
      reader.readMessage(value,users_entity_pb.TwitterAccount.deserializeBinaryFromReader);
      msg.setShareTwitterAccount(value);
      break;
    case 3:
      var value = /** @type {!proto.jp.singcolor.ShareTwitterDeleteSetting} */ (reader.readEnum());
      msg.setShareTwitterDeleteSetting(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreEnterComment(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostEnterComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UserLiveSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.UserLiveSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UserLiveSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UserLiveSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRadioMode();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getShareTwitterAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.TwitterAccount.serializeBinaryToWriter
    );
  }
  f = message.getShareTwitterDeleteSetting();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPreEnterComment();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPostEnterComment();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional bool radio_mode = 1;
 * @return {boolean}
 */
proto.jp.singcolor.UserLiveSetting.prototype.getRadioMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UserLiveSetting} returns this
 */
proto.jp.singcolor.UserLiveSetting.prototype.setRadioMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional TwitterAccount share_twitter_account = 2;
 * @return {?proto.jp.singcolor.TwitterAccount}
 */
proto.jp.singcolor.UserLiveSetting.prototype.getShareTwitterAccount = function() {
  return /** @type{?proto.jp.singcolor.TwitterAccount} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.TwitterAccount, 2));
};


/**
 * @param {?proto.jp.singcolor.TwitterAccount|undefined} value
 * @return {!proto.jp.singcolor.UserLiveSetting} returns this
*/
proto.jp.singcolor.UserLiveSetting.prototype.setShareTwitterAccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UserLiveSetting} returns this
 */
proto.jp.singcolor.UserLiveSetting.prototype.clearShareTwitterAccount = function() {
  return this.setShareTwitterAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UserLiveSetting.prototype.hasShareTwitterAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ShareTwitterDeleteSetting share_twitter_delete_setting = 3;
 * @return {!proto.jp.singcolor.ShareTwitterDeleteSetting}
 */
proto.jp.singcolor.UserLiveSetting.prototype.getShareTwitterDeleteSetting = function() {
  return /** @type {!proto.jp.singcolor.ShareTwitterDeleteSetting} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.jp.singcolor.ShareTwitterDeleteSetting} value
 * @return {!proto.jp.singcolor.UserLiveSetting} returns this
 */
proto.jp.singcolor.UserLiveSetting.prototype.setShareTwitterDeleteSetting = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.jp.singcolor.UserLiveSetting.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UserLiveSetting} returns this
 */
proto.jp.singcolor.UserLiveSetting.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 updated_at = 5;
 * @return {number}
 */
proto.jp.singcolor.UserLiveSetting.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UserLiveSetting} returns this
 */
proto.jp.singcolor.UserLiveSetting.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string pre_enter_comment = 6;
 * @return {string}
 */
proto.jp.singcolor.UserLiveSetting.prototype.getPreEnterComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UserLiveSetting} returns this
 */
proto.jp.singcolor.UserLiveSetting.prototype.setPreEnterComment = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string post_enter_comment = 7;
 * @return {string}
 */
proto.jp.singcolor.UserLiveSetting.prototype.getPostEnterComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UserLiveSetting} returns this
 */
proto.jp.singcolor.UserLiveSetting.prototype.setPostEnterComment = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accuracyLabel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    thumbnailImageUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    descriptionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend}
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend;
  return proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend}
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccuracyLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailImageUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addDescriptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccuracyLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getThumbnailImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string model_name = 1;
 * @return {string}
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend} returns this
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string accuracy_label = 2;
 * @return {string}
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.prototype.getAccuracyLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend} returns this
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.prototype.setAccuracyLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string thumbnail_image_url = 3;
 * @return {string}
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.prototype.getThumbnailImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend} returns this
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.prototype.setThumbnailImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string descriptions = 4;
 * @return {!Array<string>}
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.prototype.getDescriptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend} returns this
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.prototype.setDescriptionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend} returns this
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.prototype.addDescriptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend} returns this
 */
proto.jp.singcolor.SpecificLiveTimelineAdditionalInfoRecommend.prototype.clearDescriptionsList = function() {
  return this.setDescriptionsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.jp.singcolor.LiveTimelineItem.oneofGroups_ = [[6,10]];

/**
 * @enum {number}
 */
proto.jp.singcolor.LiveTimelineItem.SpecificLiveTimeInfoCase = {
  SPECIFIC_LIVE_TIME_INFO_NOT_SET: 0,
  ONE_PHRASE_EVENT: 6,
  RECOMMEND: 10
};

/**
 * @return {proto.jp.singcolor.LiveTimelineItem.SpecificLiveTimeInfoCase}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.getSpecificLiveTimeInfoCase = function() {
  return /** @type {proto.jp.singcolor.LiveTimelineItem.SpecificLiveTimeInfoCase} */(jspb.Message.computeOneofCase(this, proto.jp.singcolor.LiveTimelineItem.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveTimelineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveTimelineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveTimelineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    live: (f = msg.getLive()) && proto.jp.singcolor.Live.toObject(includeInstance, f),
    userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f),
    song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
    beginnerType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    liverbeginnerdaycount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    livePermissionSecondReviewRemainingDays: jspb.Message.getFieldWithDefault(msg, 8, 0),
    issurging: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isHatsuplus: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    onePhraseEvent: (f = msg.getOnePhraseEvent()) && proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.toObject(includeInstance, f),
    recommend: (f = msg.getRecommend()) && proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveTimelineItem}
 */
proto.jp.singcolor.LiveTimelineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveTimelineItem;
  return proto.jp.singcolor.LiveTimelineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveTimelineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveTimelineItem}
 */
proto.jp.singcolor.LiveTimelineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jp.singcolor.Live;
      reader.readMessage(value,proto.jp.singcolor.Live.deserializeBinaryFromReader);
      msg.setLive(value);
      break;
    case 2:
      var value = new users_entity_pb.UserItem;
      reader.readMessage(value,users_entity_pb.UserItem.deserializeBinaryFromReader);
      msg.setUserItem(value);
      break;
    case 3:
      var value = new karaoke_entity_pb.Song;
      reader.readMessage(value,karaoke_entity_pb.Song.deserializeBinaryFromReader);
      msg.setSong(value);
      break;
    case 7:
      var value = /** @type {!proto.jp.singcolor.LiveTimelineItem.BeginnerType} */ (reader.readEnum());
      msg.setBeginnerType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLiverbeginnerdaycount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLivePermissionSecondReviewRemainingDays(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIssurging(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHatsuplus(value);
      break;
    case 6:
      var value = new proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent;
      reader.readMessage(value,proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.deserializeBinaryFromReader);
      msg.setOnePhraseEvent(value);
      break;
    case 10:
      var value = new proto.jp.singcolor.SpecificLiveTimelineInfoRecommend;
      reader.readMessage(value,proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.deserializeBinaryFromReader);
      msg.setRecommend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveTimelineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveTimelineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveTimelineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLive();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.jp.singcolor.Live.serializeBinaryToWriter
    );
  }
  f = message.getUserItem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.UserItem.serializeBinaryToWriter
    );
  }
  f = message.getSong();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      karaoke_entity_pb.Song.serializeBinaryToWriter
    );
  }
  f = message.getBeginnerType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getLiverbeginnerdaycount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getLivePermissionSecondReviewRemainingDays();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getIssurging();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsHatsuplus();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getOnePhraseEvent();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.serializeBinaryToWriter
    );
  }
  f = message.getRecommend();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.jp.singcolor.LiveTimelineItem.BeginnerType = {
  NOTBEGINNER: 0,
  FIRSTLIVE: 1,
  NEWSEASON: 2,
  FIRSTMONTH: 3,
  LIVEPERMISSIONSECONDREVIEWTRIAL: 4,
  LIVEPERMISSIONSECONDREVIEW: 5
};

/**
 * optional Live live = 1;
 * @return {?proto.jp.singcolor.Live}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.getLive = function() {
  return /** @type{?proto.jp.singcolor.Live} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.Live, 1));
};


/**
 * @param {?proto.jp.singcolor.Live|undefined} value
 * @return {!proto.jp.singcolor.LiveTimelineItem} returns this
*/
proto.jp.singcolor.LiveTimelineItem.prototype.setLive = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveTimelineItem} returns this
 */
proto.jp.singcolor.LiveTimelineItem.prototype.clearLive = function() {
  return this.setLive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.hasLive = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserItem user_item = 2;
 * @return {?proto.jp.singcolor.UserItem}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.getUserItem = function() {
  return /** @type{?proto.jp.singcolor.UserItem} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 2));
};


/**
 * @param {?proto.jp.singcolor.UserItem|undefined} value
 * @return {!proto.jp.singcolor.LiveTimelineItem} returns this
*/
proto.jp.singcolor.LiveTimelineItem.prototype.setUserItem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveTimelineItem} returns this
 */
proto.jp.singcolor.LiveTimelineItem.prototype.clearUserItem = function() {
  return this.setUserItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.hasUserItem = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Song song = 3;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.getSong = function() {
  return /** @type{?proto.jp.singcolor.Song} */ (
    jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 3));
};


/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.LiveTimelineItem} returns this
*/
proto.jp.singcolor.LiveTimelineItem.prototype.setSong = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveTimelineItem} returns this
 */
proto.jp.singcolor.LiveTimelineItem.prototype.clearSong = function() {
  return this.setSong(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.hasSong = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional BeginnerType beginner_type = 7;
 * @return {!proto.jp.singcolor.LiveTimelineItem.BeginnerType}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.getBeginnerType = function() {
  return /** @type {!proto.jp.singcolor.LiveTimelineItem.BeginnerType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.jp.singcolor.LiveTimelineItem.BeginnerType} value
 * @return {!proto.jp.singcolor.LiveTimelineItem} returns this
 */
proto.jp.singcolor.LiveTimelineItem.prototype.setBeginnerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int64 liverBeginnerDayCount = 4;
 * @return {number}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.getLiverbeginnerdaycount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveTimelineItem} returns this
 */
proto.jp.singcolor.LiveTimelineItem.prototype.setLiverbeginnerdaycount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 live_permission_second_review_remaining_days = 8;
 * @return {number}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.getLivePermissionSecondReviewRemainingDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveTimelineItem} returns this
 */
proto.jp.singcolor.LiveTimelineItem.prototype.setLivePermissionSecondReviewRemainingDays = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool isSurging = 5;
 * @return {boolean}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.getIssurging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LiveTimelineItem} returns this
 */
proto.jp.singcolor.LiveTimelineItem.prototype.setIssurging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_hatsuplus = 9;
 * @return {boolean}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.getIsHatsuplus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LiveTimelineItem} returns this
 */
proto.jp.singcolor.LiveTimelineItem.prototype.setIsHatsuplus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional SpecificLiveTimelineInfoOnePhraseEvent one_phrase_event = 6;
 * @return {?proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.getOnePhraseEvent = function() {
  return /** @type{?proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent, 6));
};


/**
 * @param {?proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent|undefined} value
 * @return {!proto.jp.singcolor.LiveTimelineItem} returns this
*/
proto.jp.singcolor.LiveTimelineItem.prototype.setOnePhraseEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.jp.singcolor.LiveTimelineItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveTimelineItem} returns this
 */
proto.jp.singcolor.LiveTimelineItem.prototype.clearOnePhraseEvent = function() {
  return this.setOnePhraseEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.hasOnePhraseEvent = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SpecificLiveTimelineInfoRecommend recommend = 10;
 * @return {?proto.jp.singcolor.SpecificLiveTimelineInfoRecommend}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.getRecommend = function() {
  return /** @type{?proto.jp.singcolor.SpecificLiveTimelineInfoRecommend} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.SpecificLiveTimelineInfoRecommend, 10));
};


/**
 * @param {?proto.jp.singcolor.SpecificLiveTimelineInfoRecommend|undefined} value
 * @return {!proto.jp.singcolor.LiveTimelineItem} returns this
*/
proto.jp.singcolor.LiveTimelineItem.prototype.setRecommend = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.jp.singcolor.LiveTimelineItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveTimelineItem} returns this
 */
proto.jp.singcolor.LiveTimelineItem.prototype.clearRecommend = function() {
  return this.setRecommend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveTimelineItem.prototype.hasRecommend = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && one_phrase_events_entity_pb.OnePhraseEventItem.toObject(includeInstance, f),
    prize: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent}
 */
proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent;
  return proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent}
 */
proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new one_phrase_events_entity_pb.OnePhraseEventItem;
      reader.readMessage(value,one_phrase_events_entity_pb.OnePhraseEventItem.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 2:
      var value = /** @type {!proto.jp.singcolor.OnePhraseEventPrize} */ (reader.readEnum());
      msg.setPrize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      one_phrase_events_entity_pb.OnePhraseEventItem.serializeBinaryToWriter
    );
  }
  f = message.getPrize();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional OnePhraseEventItem event = 1;
 * @return {?proto.jp.singcolor.OnePhraseEventItem}
 */
proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.prototype.getEvent = function() {
  return /** @type{?proto.jp.singcolor.OnePhraseEventItem} */ (
    jspb.Message.getWrapperField(this, one_phrase_events_entity_pb.OnePhraseEventItem, 1));
};


/**
 * @param {?proto.jp.singcolor.OnePhraseEventItem|undefined} value
 * @return {!proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent} returns this
*/
proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent} returns this
 */
proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OnePhraseEventPrize prize = 2;
 * @return {!proto.jp.singcolor.OnePhraseEventPrize}
 */
proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.prototype.getPrize = function() {
  return /** @type {!proto.jp.singcolor.OnePhraseEventPrize} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.jp.singcolor.OnePhraseEventPrize} value
 * @return {!proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent} returns this
 */
proto.jp.singcolor.SpecificLiveTimelineInfoOnePhraseEvent.prototype.setPrize = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.SpecificLiveTimelineInfoRecommend} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.toObject = function(includeInstance, msg) {
  var f, obj = {
    recommendScore: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accuracyLabel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    labelColor: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SpecificLiveTimelineInfoRecommend}
 */
proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.SpecificLiveTimelineInfoRecommend;
  return proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SpecificLiveTimelineInfoRecommend} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SpecificLiveTimelineInfoRecommend}
 */
proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecommendScore(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccuracyLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabelColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SpecificLiveTimelineInfoRecommend} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecommendScore();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccuracyLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabelColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string recommend_score = 1;
 * @return {string}
 */
proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.prototype.getRecommendScore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SpecificLiveTimelineInfoRecommend} returns this
 */
proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.prototype.setRecommendScore = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string accuracy_label = 2;
 * @return {string}
 */
proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.prototype.getAccuracyLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SpecificLiveTimelineInfoRecommend} returns this
 */
proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.prototype.setAccuracyLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label_color = 3;
 * @return {string}
 */
proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.prototype.getLabelColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SpecificLiveTimelineInfoRecommend} returns this
 */
proto.jp.singcolor.SpecificLiveTimelineInfoRecommend.prototype.setLabelColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    live: (f = msg.getLive()) && proto.jp.singcolor.Live.toObject(includeInstance, f),
    hasliveresult: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveHistory}
 */
proto.jp.singcolor.LiveHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveHistory;
  return proto.jp.singcolor.LiveHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveHistory}
 */
proto.jp.singcolor.LiveHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jp.singcolor.Live;
      reader.readMessage(value,proto.jp.singcolor.Live.deserializeBinaryFromReader);
      msg.setLive(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasliveresult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLive();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.jp.singcolor.Live.serializeBinaryToWriter
    );
  }
  f = message.getHasliveresult();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Live live = 1;
 * @return {?proto.jp.singcolor.Live}
 */
proto.jp.singcolor.LiveHistory.prototype.getLive = function() {
  return /** @type{?proto.jp.singcolor.Live} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.Live, 1));
};


/**
 * @param {?proto.jp.singcolor.Live|undefined} value
 * @return {!proto.jp.singcolor.LiveHistory} returns this
*/
proto.jp.singcolor.LiveHistory.prototype.setLive = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveHistory} returns this
 */
proto.jp.singcolor.LiveHistory.prototype.clearLive = function() {
  return this.setLive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveHistory.prototype.hasLive = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool hasLiveResult = 2;
 * @return {boolean}
 */
proto.jp.singcolor.LiveHistory.prototype.getHasliveresult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LiveHistory} returns this
 */
proto.jp.singcolor.LiveHistory.prototype.setHasliveresult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.Live.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.Live.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.Live} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Live.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    thumbnailImageUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    backgroundImageUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    enteringComment: jspb.Message.getFieldWithDefault(msg, 7, ""),
    onAir: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    voiceOnly: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
    liked: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    createdAt: jspb.Message.getFieldWithDefault(msg, 12, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 13, 0),
    startedAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
    finishedAt: jspb.Message.getFieldWithDefault(msg, 15, 0),
    liveBackground: (f = msg.getLiveBackground()) && proto.jp.singcolor.LiveBackground.toObject(includeInstance, f),
    liveMode: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.Live}
 */
proto.jp.singcolor.Live.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.Live;
  return proto.jp.singcolor.Live.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.Live} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.Live}
 */
proto.jp.singcolor.Live.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailImageUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundImageUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnteringComment(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnAir(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVoiceOnly(value);
      break;
    case 10:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiked(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartedAt(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFinishedAt(value);
      break;
    case 16:
      var value = new proto.jp.singcolor.LiveBackground;
      reader.readMessage(value,proto.jp.singcolor.LiveBackground.deserializeBinaryFromReader);
      msg.setLiveBackground(value);
      break;
    case 17:
      var value = /** @type {!proto.jp.singcolor.LiveMode} */ (reader.readEnum());
      msg.setLiveMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.Live.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.Live.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.Live} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Live.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getThumbnailImageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBackgroundImageUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEnteringComment();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOnAir();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getVoiceOnly();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getLiked();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getStartedAt();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getFinishedAt();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getLiveBackground();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.jp.singcolor.LiveBackground.serializeBinaryToWriter
    );
  }
  f = message.getLiveMode();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
};


/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.Live.prototype.getLiveId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.setLiveId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.jp.singcolor.Live.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.jp.singcolor.Live.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string thumbnail_image_url = 5;
 * @return {string}
 */
proto.jp.singcolor.Live.prototype.getThumbnailImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.setThumbnailImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string background_image_url = 6;
 * @return {string}
 */
proto.jp.singcolor.Live.prototype.getBackgroundImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.setBackgroundImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string entering_comment = 7;
 * @return {string}
 */
proto.jp.singcolor.Live.prototype.getEnteringComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.setEnteringComment = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool on_air = 8;
 * @return {boolean}
 */
proto.jp.singcolor.Live.prototype.getOnAir = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.setOnAir = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool voice_only = 9;
 * @return {boolean}
 */
proto.jp.singcolor.Live.prototype.getVoiceOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.setVoiceOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional User user = 10;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.Live.prototype.getUser = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 10));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.Live} returns this
*/
proto.jp.singcolor.Live.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.Live.prototype.hasUser = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool liked = 11;
 * @return {boolean}
 */
proto.jp.singcolor.Live.prototype.getLiked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.setLiked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int64 created_at = 12;
 * @return {number}
 */
proto.jp.singcolor.Live.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 updated_at = 13;
 * @return {number}
 */
proto.jp.singcolor.Live.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 started_at = 14;
 * @return {number}
 */
proto.jp.singcolor.Live.prototype.getStartedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.setStartedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 finished_at = 15;
 * @return {number}
 */
proto.jp.singcolor.Live.prototype.getFinishedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.setFinishedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional LiveBackground live_background = 16;
 * @return {?proto.jp.singcolor.LiveBackground}
 */
proto.jp.singcolor.Live.prototype.getLiveBackground = function() {
  return /** @type{?proto.jp.singcolor.LiveBackground} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveBackground, 16));
};


/**
 * @param {?proto.jp.singcolor.LiveBackground|undefined} value
 * @return {!proto.jp.singcolor.Live} returns this
*/
proto.jp.singcolor.Live.prototype.setLiveBackground = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.clearLiveBackground = function() {
  return this.setLiveBackground(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.Live.prototype.hasLiveBackground = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional LiveMode live_mode = 17;
 * @return {!proto.jp.singcolor.LiveMode}
 */
proto.jp.singcolor.Live.prototype.getLiveMode = function() {
  return /** @type {!proto.jp.singcolor.LiveMode} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.jp.singcolor.LiveMode} value
 * @return {!proto.jp.singcolor.Live} returns this
 */
proto.jp.singcolor.Live.prototype.setLiveMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.OnlineServer.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.OnlineServer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.OnlineServer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OnlineServer.toObject = function(includeInstance, msg) {
  var f, obj = {
    host: jspb.Message.getFieldWithDefault(msg, 1, ""),
    port: jspb.Message.getFieldWithDefault(msg, 2, 0),
    channel: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OnlineServer}
 */
proto.jp.singcolor.OnlineServer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.OnlineServer;
  return proto.jp.singcolor.OnlineServer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OnlineServer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OnlineServer}
 */
proto.jp.singcolor.OnlineServer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPort(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OnlineServer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.OnlineServer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OnlineServer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OnlineServer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getChannel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string host = 1;
 * @return {string}
 */
proto.jp.singcolor.OnlineServer.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OnlineServer} returns this
 */
proto.jp.singcolor.OnlineServer.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 port = 2;
 * @return {number}
 */
proto.jp.singcolor.OnlineServer.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OnlineServer} returns this
 */
proto.jp.singcolor.OnlineServer.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string channel = 3;
 * @return {string}
 */
proto.jp.singcolor.OnlineServer.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OnlineServer} returns this
 */
proto.jp.singcolor.OnlineServer.prototype.setChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.LiveComment.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveComment.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveComment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveComment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveComment.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveCommentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    liveId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    body: jspb.Message.getFieldWithDefault(msg, 4, ""),
    user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
    liveCommentMentionsList: jspb.Message.toObjectList(msg.getLiveCommentMentionsList(),
    proto.jp.singcolor.LiveCommentMention.toObject, includeInstance),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    userMembershipInfo: (f = msg.getUserMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveComment}
 */
proto.jp.singcolor.LiveComment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveComment;
  return proto.jp.singcolor.LiveComment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveComment}
 */
proto.jp.singcolor.LiveComment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveCommentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 5:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 6:
      var value = new proto.jp.singcolor.LiveCommentMention;
      reader.readMessage(value,proto.jp.singcolor.LiveCommentMention.deserializeBinaryFromReader);
      msg.addLiveCommentMentions(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 9:
      var value = new users_entity_pb.ListenerMembershipInfo;
      reader.readMessage(value,users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
      msg.setUserMembershipInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveComment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveComment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveComment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveCommentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLiveId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getLiveCommentMentionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.jp.singcolor.LiveCommentMention.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUserMembershipInfo();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string live_comment_id = 1;
 * @return {string}
 */
proto.jp.singcolor.LiveComment.prototype.getLiveCommentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveComment} returns this
 */
proto.jp.singcolor.LiveComment.prototype.setLiveCommentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string live_id = 2;
 * @return {string}
 */
proto.jp.singcolor.LiveComment.prototype.getLiveId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveComment} returns this
 */
proto.jp.singcolor.LiveComment.prototype.setLiveId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.jp.singcolor.LiveComment.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveComment} returns this
 */
proto.jp.singcolor.LiveComment.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string body = 4;
 * @return {string}
 */
proto.jp.singcolor.LiveComment.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveComment} returns this
 */
proto.jp.singcolor.LiveComment.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional User user = 5;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveComment.prototype.getUser = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 5));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveComment} returns this
*/
proto.jp.singcolor.LiveComment.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveComment} returns this
 */
proto.jp.singcolor.LiveComment.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveComment.prototype.hasUser = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated LiveCommentMention live_comment_mentions = 6;
 * @return {!Array<!proto.jp.singcolor.LiveCommentMention>}
 */
proto.jp.singcolor.LiveComment.prototype.getLiveCommentMentionsList = function() {
  return /** @type{!Array<!proto.jp.singcolor.LiveCommentMention>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.LiveCommentMention, 6));
};


/**
 * @param {!Array<!proto.jp.singcolor.LiveCommentMention>} value
 * @return {!proto.jp.singcolor.LiveComment} returns this
*/
proto.jp.singcolor.LiveComment.prototype.setLiveCommentMentionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.jp.singcolor.LiveCommentMention=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiveCommentMention}
 */
proto.jp.singcolor.LiveComment.prototype.addLiveCommentMentions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.jp.singcolor.LiveCommentMention, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.LiveComment} returns this
 */
proto.jp.singcolor.LiveComment.prototype.clearLiveCommentMentionsList = function() {
  return this.setLiveCommentMentionsList([]);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.jp.singcolor.LiveComment.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveComment} returns this
 */
proto.jp.singcolor.LiveComment.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 updated_at = 8;
 * @return {number}
 */
proto.jp.singcolor.LiveComment.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveComment} returns this
 */
proto.jp.singcolor.LiveComment.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional ListenerMembershipInfo user_membership_info = 9;
 * @return {?proto.jp.singcolor.ListenerMembershipInfo}
 */
proto.jp.singcolor.LiveComment.prototype.getUserMembershipInfo = function() {
  return /** @type{?proto.jp.singcolor.ListenerMembershipInfo} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 9));
};


/**
 * @param {?proto.jp.singcolor.ListenerMembershipInfo|undefined} value
 * @return {!proto.jp.singcolor.LiveComment} returns this
*/
proto.jp.singcolor.LiveComment.prototype.setUserMembershipInfo = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveComment} returns this
 */
proto.jp.singcolor.LiveComment.prototype.clearUserMembershipInfo = function() {
  return this.setUserMembershipInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveComment.prototype.hasUserMembershipInfo = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveCommentMention.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveCommentMention.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveCommentMention} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveCommentMention.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveCommentMentionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    liveId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    liveCommentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    targetUserId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    userMembershipInfo: (f = msg.getUserMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveCommentMention}
 */
proto.jp.singcolor.LiveCommentMention.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveCommentMention;
  return proto.jp.singcolor.LiveCommentMention.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveCommentMention} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveCommentMention}
 */
proto.jp.singcolor.LiveCommentMention.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveCommentMentionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveCommentId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUserId(value);
      break;
    case 5:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = new users_entity_pb.ListenerMembershipInfo;
      reader.readMessage(value,users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
      msg.setUserMembershipInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveCommentMention.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveCommentMention.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveCommentMention} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveCommentMention.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveCommentMentionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLiveId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLiveCommentId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTargetUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getUserMembershipInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string live_comment_mention_id = 1;
 * @return {string}
 */
proto.jp.singcolor.LiveCommentMention.prototype.getLiveCommentMentionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveCommentMention} returns this
 */
proto.jp.singcolor.LiveCommentMention.prototype.setLiveCommentMentionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string live_id = 2;
 * @return {string}
 */
proto.jp.singcolor.LiveCommentMention.prototype.getLiveId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveCommentMention} returns this
 */
proto.jp.singcolor.LiveCommentMention.prototype.setLiveId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string live_comment_id = 3;
 * @return {string}
 */
proto.jp.singcolor.LiveCommentMention.prototype.getLiveCommentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveCommentMention} returns this
 */
proto.jp.singcolor.LiveCommentMention.prototype.setLiveCommentId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string target_user_id = 4;
 * @return {string}
 */
proto.jp.singcolor.LiveCommentMention.prototype.getTargetUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveCommentMention} returns this
 */
proto.jp.singcolor.LiveCommentMention.prototype.setTargetUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional User user = 5;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveCommentMention.prototype.getUser = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 5));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveCommentMention} returns this
*/
proto.jp.singcolor.LiveCommentMention.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveCommentMention} returns this
 */
proto.jp.singcolor.LiveCommentMention.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveCommentMention.prototype.hasUser = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.jp.singcolor.LiveCommentMention.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveCommentMention} returns this
 */
proto.jp.singcolor.LiveCommentMention.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 updated_at = 7;
 * @return {number}
 */
proto.jp.singcolor.LiveCommentMention.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveCommentMention} returns this
 */
proto.jp.singcolor.LiveCommentMention.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional ListenerMembershipInfo user_membership_info = 8;
 * @return {?proto.jp.singcolor.ListenerMembershipInfo}
 */
proto.jp.singcolor.LiveCommentMention.prototype.getUserMembershipInfo = function() {
  return /** @type{?proto.jp.singcolor.ListenerMembershipInfo} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 8));
};


/**
 * @param {?proto.jp.singcolor.ListenerMembershipInfo|undefined} value
 * @return {!proto.jp.singcolor.LiveCommentMention} returns this
*/
proto.jp.singcolor.LiveCommentMention.prototype.setUserMembershipInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveCommentMention} returns this
 */
proto.jp.singcolor.LiveCommentMention.prototype.clearUserMembershipInfo = function() {
  return this.setUserMembershipInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveCommentMention.prototype.hasUserMembershipInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveViewingSession.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveViewingSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveViewingSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveViewingSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
    termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
    fanType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    userMembershipInfo: (f = msg.getUserMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f),
    listenerSongFaveThumbnail: (f = msg.getListenerSongFaveThumbnail()) && users_entity_pb.ListenerSongFaveThumbnail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveViewingSession}
 */
proto.jp.singcolor.LiveViewingSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveViewingSession;
  return proto.jp.singcolor.LiveViewingSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveViewingSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveViewingSession}
 */
proto.jp.singcolor.LiveViewingSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartAt(value);
      break;
    case 3:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTermlyFavePoints(value);
      break;
    case 5:
      var value = /** @type {!proto.jp.singcolor.FanType} */ (reader.readEnum());
      msg.setFanType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = new users_entity_pb.ListenerMembershipInfo;
      reader.readMessage(value,users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
      msg.setUserMembershipInfo(value);
      break;
    case 9:
      var value = new users_entity_pb.ListenerSongFaveThumbnail;
      reader.readMessage(value,users_entity_pb.ListenerSongFaveThumbnail.deserializeBinaryFromReader);
      msg.setListenerSongFaveThumbnail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveViewingSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveViewingSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveViewingSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveViewingSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getTermlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getFanType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getUserMembershipInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter
    );
  }
  f = message.getListenerSongFaveThumbnail();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      users_entity_pb.ListenerSongFaveThumbnail.serializeBinaryToWriter
    );
  }
};


/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.jp.singcolor.LiveViewingSession.prototype.getLiveId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveViewingSession} returns this
 */
proto.jp.singcolor.LiveViewingSession.prototype.setLiveId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_at = 2;
 * @return {number}
 */
proto.jp.singcolor.LiveViewingSession.prototype.getStartAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveViewingSession} returns this
 */
proto.jp.singcolor.LiveViewingSession.prototype.setStartAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional User user = 3;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveViewingSession.prototype.getUser = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 3));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveViewingSession} returns this
*/
proto.jp.singcolor.LiveViewingSession.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveViewingSession} returns this
 */
proto.jp.singcolor.LiveViewingSession.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveViewingSession.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 termly_fave_points = 4;
 * @return {number}
 */
proto.jp.singcolor.LiveViewingSession.prototype.getTermlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveViewingSession} returns this
 */
proto.jp.singcolor.LiveViewingSession.prototype.setTermlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional FanType fan_type = 5;
 * @return {!proto.jp.singcolor.FanType}
 */
proto.jp.singcolor.LiveViewingSession.prototype.getFanType = function() {
  return /** @type {!proto.jp.singcolor.FanType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.jp.singcolor.FanType} value
 * @return {!proto.jp.singcolor.LiveViewingSession} returns this
 */
proto.jp.singcolor.LiveViewingSession.prototype.setFanType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.jp.singcolor.LiveViewingSession.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveViewingSession} returns this
 */
proto.jp.singcolor.LiveViewingSession.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 updated_at = 7;
 * @return {number}
 */
proto.jp.singcolor.LiveViewingSession.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveViewingSession} returns this
 */
proto.jp.singcolor.LiveViewingSession.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional ListenerMembershipInfo user_membership_info = 8;
 * @return {?proto.jp.singcolor.ListenerMembershipInfo}
 */
proto.jp.singcolor.LiveViewingSession.prototype.getUserMembershipInfo = function() {
  return /** @type{?proto.jp.singcolor.ListenerMembershipInfo} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 8));
};


/**
 * @param {?proto.jp.singcolor.ListenerMembershipInfo|undefined} value
 * @return {!proto.jp.singcolor.LiveViewingSession} returns this
*/
proto.jp.singcolor.LiveViewingSession.prototype.setUserMembershipInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveViewingSession} returns this
 */
proto.jp.singcolor.LiveViewingSession.prototype.clearUserMembershipInfo = function() {
  return this.setUserMembershipInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveViewingSession.prototype.hasUserMembershipInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ListenerSongFaveThumbnail listener_song_fave_thumbnail = 9;
 * @return {?proto.jp.singcolor.ListenerSongFaveThumbnail}
 */
proto.jp.singcolor.LiveViewingSession.prototype.getListenerSongFaveThumbnail = function() {
  return /** @type{?proto.jp.singcolor.ListenerSongFaveThumbnail} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.ListenerSongFaveThumbnail, 9));
};


/**
 * @param {?proto.jp.singcolor.ListenerSongFaveThumbnail|undefined} value
 * @return {!proto.jp.singcolor.LiveViewingSession} returns this
*/
proto.jp.singcolor.LiveViewingSession.prototype.setListenerSongFaveThumbnail = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveViewingSession} returns this
 */
proto.jp.singcolor.LiveViewingSession.prototype.clearListenerSongFaveThumbnail = function() {
  return this.setListenerSongFaveThumbnail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveViewingSession.prototype.hasListenerSongFaveThumbnail = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.jp.singcolor.LiveEvent.oneofGroups_ = [[5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,31,32,34,35,36,37,38,39,40,41,42,43]];

/**
 * @enum {number}
 */
proto.jp.singcolor.LiveEvent.EventCase = {
  EVENT_NOT_SET: 0,
  LIVE_EVENT_SYSTEM: 5,
  LIVE_EVENT_START: 6,
  LIVE_EVENT_FINISH: 7,
  LIVE_EVENT_ENTER: 8,
  LIVE_EVENT_LEAVE: 9,
  LIVE_EVENT_COMMENT: 10,
  LIVE_EVENT_SMALL_GIFT: 11,
  LIVE_EVENT_LARGE_GIFT: 12,
  LIVE_EVENT_START_SING: 13,
  LIVE_EVENT_FINISH_SING: 14,
  LIVE_EVENT_GET_FAVE_BONUS: 15,
  LIVE_EVENT_GET_FAVE_BONUS_TITLE: 16,
  LIVE_EVENT_GET_FAVE_POINTS: 17,
  LIVE_EVENT_GET_FAVE_BADGE: 18,
  LIVE_EVENT_GET_SONG_FAVE: 19,
  LIVE_EVENT_FOLLOW: 20,
  LIVE_EVENT_LIKE: 21,
  LIVE_EVENT_SUPER_LIKE: 22,
  LIVE_EVENT_ANNOUNCE: 23,
  LIVE_EVENT_SURGE_RANKING_NO1_REWARD: 24,
  LIVE_EVENT_REQUEST_SING: 25,
  LIVE_EVENT_SIMPLE_PF_COMMENT: 26,
  LIVE_EVENT_GET_FAVE_BADGE_AND_SONG_FAVE: 27,
  LIVE_EVENT_HEARTFUL_MENTION: 28,
  LIVE_SETTINGS_UPDATED: 29,
  LISTENER_REQ_CAPTURE_POSSIBILITY_UPDATED: 31,
  LIVE_EVENT_PICK_UP_SONG_RANDOMLY: 32,
  LIVE_EVENT_MEMBERSHIP_UPDATED: 34,
  LIVE_EVENT_GET_SONG_RANDOMLY: 35,
  LIVE_EVENT_BAN_LISTENER_COMMENT_BY_MODERATOR: 36,
  LIVE_EVENT_UNBAN_LISTENER_COMMENT_BY_MODERATOR: 37,
  LIVE_EVENT_BAN_LISTENER_REQ_CAPTURE_BY_MODERATOR: 38,
  LIVE_EVENT_UNBAN_LISTENER_REQ_CAPTURE_BY_MODERATOR: 39,
  LIVE_EVENT_DO_BLOCK_BY_MODERATOR: 40,
  LIVE_EVENT_UNBLOCK_BY_MODERATOR: 41,
  LIVE_EVENT_TENURED_MODERATOR: 42,
  LIVE_EVENT_TERMINATED_MODERATOR: 43
};

/**
 * @return {proto.jp.singcolor.LiveEvent.EventCase}
 */
proto.jp.singcolor.LiveEvent.prototype.getEventCase = function() {
  return /** @type {proto.jp.singcolor.LiveEvent.EventCase} */(jspb.Message.computeOneofCase(this, proto.jp.singcolor.LiveEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveEventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    liveEventType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    liveId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    receivedInClient: jspb.Message.getFieldWithDefault(msg, 30, 0),
    liveEventSystem: (f = msg.getLiveEventSystem()) && proto.jp.singcolor.LiveEventSystem.toObject(includeInstance, f),
    liveEventStart: (f = msg.getLiveEventStart()) && proto.jp.singcolor.LiveEventStart.toObject(includeInstance, f),
    liveEventFinish: (f = msg.getLiveEventFinish()) && proto.jp.singcolor.LiveEventFinish.toObject(includeInstance, f),
    liveEventEnter: (f = msg.getLiveEventEnter()) && proto.jp.singcolor.LiveEventEnter.toObject(includeInstance, f),
    liveEventLeave: (f = msg.getLiveEventLeave()) && proto.jp.singcolor.LiveEventLeave.toObject(includeInstance, f),
    liveEventComment: (f = msg.getLiveEventComment()) && proto.jp.singcolor.LiveEventComment.toObject(includeInstance, f),
    liveEventSmallGift: (f = msg.getLiveEventSmallGift()) && proto.jp.singcolor.LiveEventSmallGift.toObject(includeInstance, f),
    liveEventLargeGift: (f = msg.getLiveEventLargeGift()) && proto.jp.singcolor.LiveEventLargeGift.toObject(includeInstance, f),
    liveEventStartSing: (f = msg.getLiveEventStartSing()) && proto.jp.singcolor.LiveEventStartSing.toObject(includeInstance, f),
    liveEventFinishSing: (f = msg.getLiveEventFinishSing()) && proto.jp.singcolor.LiveEventFinishSing.toObject(includeInstance, f),
    liveEventGetFaveBonus: (f = msg.getLiveEventGetFaveBonus()) && proto.jp.singcolor.LiveEventGetFaveBonus.toObject(includeInstance, f),
    liveEventGetFaveBonusTitle: (f = msg.getLiveEventGetFaveBonusTitle()) && proto.jp.singcolor.LiveEventGetFaveBonusTitle.toObject(includeInstance, f),
    liveEventGetFavePoints: (f = msg.getLiveEventGetFavePoints()) && proto.jp.singcolor.LiveEventGetFavePoints.toObject(includeInstance, f),
    liveEventGetFaveBadge: (f = msg.getLiveEventGetFaveBadge()) && proto.jp.singcolor.LiveEventGetFaveBadge.toObject(includeInstance, f),
    liveEventGetSongFave: (f = msg.getLiveEventGetSongFave()) && proto.jp.singcolor.LiveEventGetSongFave.toObject(includeInstance, f),
    liveEventFollow: (f = msg.getLiveEventFollow()) && proto.jp.singcolor.LiveEventFollow.toObject(includeInstance, f),
    liveEventLike: (f = msg.getLiveEventLike()) && proto.jp.singcolor.LiveEventLike.toObject(includeInstance, f),
    liveEventSuperLike: (f = msg.getLiveEventSuperLike()) && proto.jp.singcolor.LiveEventSuperLike.toObject(includeInstance, f),
    liveEventAnnounce: (f = msg.getLiveEventAnnounce()) && proto.jp.singcolor.LiveEventAnnounce.toObject(includeInstance, f),
    liveEventSurgeRankingNo1Reward: (f = msg.getLiveEventSurgeRankingNo1Reward()) && proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.toObject(includeInstance, f),
    liveEventRequestSing: (f = msg.getLiveEventRequestSing()) && proto.jp.singcolor.LiveEventRequestSing.toObject(includeInstance, f),
    liveEventSimplePfComment: (f = msg.getLiveEventSimplePfComment()) && proto.jp.singcolor.LiveEventSimplePFComment.toObject(includeInstance, f),
    liveEventGetFaveBadgeAndSongFave: (f = msg.getLiveEventGetFaveBadgeAndSongFave()) && proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.toObject(includeInstance, f),
    liveEventHeartfulMention: (f = msg.getLiveEventHeartfulMention()) && proto.jp.singcolor.LiveEventHeartfulMention.toObject(includeInstance, f),
    liveSettingsUpdated: (f = msg.getLiveSettingsUpdated()) && proto.jp.singcolor.LiveEventLiveSettingsUpdated.toObject(includeInstance, f),
    listenerReqCapturePossibilityUpdated: (f = msg.getListenerReqCapturePossibilityUpdated()) && proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.toObject(includeInstance, f),
    liveEventPickUpSongRandomly: (f = msg.getLiveEventPickUpSongRandomly()) && proto.jp.singcolor.LiveEventPickUpSongRandomly.toObject(includeInstance, f),
    liveEventMembershipUpdated: (f = msg.getLiveEventMembershipUpdated()) && proto.jp.singcolor.LiveEventMembershipUpdated.toObject(includeInstance, f),
    liveEventGetSongRandomly: (f = msg.getLiveEventGetSongRandomly()) && proto.jp.singcolor.LiveEventGetSongRandomly.toObject(includeInstance, f),
    liveEventBanListenerCommentByModerator: (f = msg.getLiveEventBanListenerCommentByModerator()) && proto.jp.singcolor.LiveEventBanListenerCommentByModerator.toObject(includeInstance, f),
    liveEventUnbanListenerCommentByModerator: (f = msg.getLiveEventUnbanListenerCommentByModerator()) && proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.toObject(includeInstance, f),
    liveEventBanListenerReqCaptureByModerator: (f = msg.getLiveEventBanListenerReqCaptureByModerator()) && proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.toObject(includeInstance, f),
    liveEventUnbanListenerReqCaptureByModerator: (f = msg.getLiveEventUnbanListenerReqCaptureByModerator()) && proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.toObject(includeInstance, f),
    liveEventDoBlockByModerator: (f = msg.getLiveEventDoBlockByModerator()) && proto.jp.singcolor.LiveEventDoBlockByModerator.toObject(includeInstance, f),
    liveEventUnblockByModerator: (f = msg.getLiveEventUnblockByModerator()) && proto.jp.singcolor.LiveEventUnblockByModerator.toObject(includeInstance, f),
    liveEventTenuredModerator: (f = msg.getLiveEventTenuredModerator()) && proto.jp.singcolor.LiveEventTenuredModerator.toObject(includeInstance, f),
    liveEventTerminatedModerator: (f = msg.getLiveEventTerminatedModerator()) && proto.jp.singcolor.LiveEventTerminatedModerator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEvent}
 */
proto.jp.singcolor.LiveEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEvent;
  return proto.jp.singcolor.LiveEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEvent}
 */
proto.jp.singcolor.LiveEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveEventId(value);
      break;
    case 2:
      var value = /** @type {!proto.jp.singcolor.LiveEventType} */ (reader.readEnum());
      msg.setLiveEventType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReceivedInClient(value);
      break;
    case 5:
      var value = new proto.jp.singcolor.LiveEventSystem;
      reader.readMessage(value,proto.jp.singcolor.LiveEventSystem.deserializeBinaryFromReader);
      msg.setLiveEventSystem(value);
      break;
    case 6:
      var value = new proto.jp.singcolor.LiveEventStart;
      reader.readMessage(value,proto.jp.singcolor.LiveEventStart.deserializeBinaryFromReader);
      msg.setLiveEventStart(value);
      break;
    case 7:
      var value = new proto.jp.singcolor.LiveEventFinish;
      reader.readMessage(value,proto.jp.singcolor.LiveEventFinish.deserializeBinaryFromReader);
      msg.setLiveEventFinish(value);
      break;
    case 8:
      var value = new proto.jp.singcolor.LiveEventEnter;
      reader.readMessage(value,proto.jp.singcolor.LiveEventEnter.deserializeBinaryFromReader);
      msg.setLiveEventEnter(value);
      break;
    case 9:
      var value = new proto.jp.singcolor.LiveEventLeave;
      reader.readMessage(value,proto.jp.singcolor.LiveEventLeave.deserializeBinaryFromReader);
      msg.setLiveEventLeave(value);
      break;
    case 10:
      var value = new proto.jp.singcolor.LiveEventComment;
      reader.readMessage(value,proto.jp.singcolor.LiveEventComment.deserializeBinaryFromReader);
      msg.setLiveEventComment(value);
      break;
    case 11:
      var value = new proto.jp.singcolor.LiveEventSmallGift;
      reader.readMessage(value,proto.jp.singcolor.LiveEventSmallGift.deserializeBinaryFromReader);
      msg.setLiveEventSmallGift(value);
      break;
    case 12:
      var value = new proto.jp.singcolor.LiveEventLargeGift;
      reader.readMessage(value,proto.jp.singcolor.LiveEventLargeGift.deserializeBinaryFromReader);
      msg.setLiveEventLargeGift(value);
      break;
    case 13:
      var value = new proto.jp.singcolor.LiveEventStartSing;
      reader.readMessage(value,proto.jp.singcolor.LiveEventStartSing.deserializeBinaryFromReader);
      msg.setLiveEventStartSing(value);
      break;
    case 14:
      var value = new proto.jp.singcolor.LiveEventFinishSing;
      reader.readMessage(value,proto.jp.singcolor.LiveEventFinishSing.deserializeBinaryFromReader);
      msg.setLiveEventFinishSing(value);
      break;
    case 15:
      var value = new proto.jp.singcolor.LiveEventGetFaveBonus;
      reader.readMessage(value,proto.jp.singcolor.LiveEventGetFaveBonus.deserializeBinaryFromReader);
      msg.setLiveEventGetFaveBonus(value);
      break;
    case 16:
      var value = new proto.jp.singcolor.LiveEventGetFaveBonusTitle;
      reader.readMessage(value,proto.jp.singcolor.LiveEventGetFaveBonusTitle.deserializeBinaryFromReader);
      msg.setLiveEventGetFaveBonusTitle(value);
      break;
    case 17:
      var value = new proto.jp.singcolor.LiveEventGetFavePoints;
      reader.readMessage(value,proto.jp.singcolor.LiveEventGetFavePoints.deserializeBinaryFromReader);
      msg.setLiveEventGetFavePoints(value);
      break;
    case 18:
      var value = new proto.jp.singcolor.LiveEventGetFaveBadge;
      reader.readMessage(value,proto.jp.singcolor.LiveEventGetFaveBadge.deserializeBinaryFromReader);
      msg.setLiveEventGetFaveBadge(value);
      break;
    case 19:
      var value = new proto.jp.singcolor.LiveEventGetSongFave;
      reader.readMessage(value,proto.jp.singcolor.LiveEventGetSongFave.deserializeBinaryFromReader);
      msg.setLiveEventGetSongFave(value);
      break;
    case 20:
      var value = new proto.jp.singcolor.LiveEventFollow;
      reader.readMessage(value,proto.jp.singcolor.LiveEventFollow.deserializeBinaryFromReader);
      msg.setLiveEventFollow(value);
      break;
    case 21:
      var value = new proto.jp.singcolor.LiveEventLike;
      reader.readMessage(value,proto.jp.singcolor.LiveEventLike.deserializeBinaryFromReader);
      msg.setLiveEventLike(value);
      break;
    case 22:
      var value = new proto.jp.singcolor.LiveEventSuperLike;
      reader.readMessage(value,proto.jp.singcolor.LiveEventSuperLike.deserializeBinaryFromReader);
      msg.setLiveEventSuperLike(value);
      break;
    case 23:
      var value = new proto.jp.singcolor.LiveEventAnnounce;
      reader.readMessage(value,proto.jp.singcolor.LiveEventAnnounce.deserializeBinaryFromReader);
      msg.setLiveEventAnnounce(value);
      break;
    case 24:
      var value = new proto.jp.singcolor.LiveEventSurgeRankingNo1Reward;
      reader.readMessage(value,proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.deserializeBinaryFromReader);
      msg.setLiveEventSurgeRankingNo1Reward(value);
      break;
    case 25:
      var value = new proto.jp.singcolor.LiveEventRequestSing;
      reader.readMessage(value,proto.jp.singcolor.LiveEventRequestSing.deserializeBinaryFromReader);
      msg.setLiveEventRequestSing(value);
      break;
    case 26:
      var value = new proto.jp.singcolor.LiveEventSimplePFComment;
      reader.readMessage(value,proto.jp.singcolor.LiveEventSimplePFComment.deserializeBinaryFromReader);
      msg.setLiveEventSimplePfComment(value);
      break;
    case 27:
      var value = new proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave;
      reader.readMessage(value,proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.deserializeBinaryFromReader);
      msg.setLiveEventGetFaveBadgeAndSongFave(value);
      break;
    case 28:
      var value = new proto.jp.singcolor.LiveEventHeartfulMention;
      reader.readMessage(value,proto.jp.singcolor.LiveEventHeartfulMention.deserializeBinaryFromReader);
      msg.setLiveEventHeartfulMention(value);
      break;
    case 29:
      var value = new proto.jp.singcolor.LiveEventLiveSettingsUpdated;
      reader.readMessage(value,proto.jp.singcolor.LiveEventLiveSettingsUpdated.deserializeBinaryFromReader);
      msg.setLiveSettingsUpdated(value);
      break;
    case 31:
      var value = new proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated;
      reader.readMessage(value,proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.deserializeBinaryFromReader);
      msg.setListenerReqCapturePossibilityUpdated(value);
      break;
    case 32:
      var value = new proto.jp.singcolor.LiveEventPickUpSongRandomly;
      reader.readMessage(value,proto.jp.singcolor.LiveEventPickUpSongRandomly.deserializeBinaryFromReader);
      msg.setLiveEventPickUpSongRandomly(value);
      break;
    case 34:
      var value = new proto.jp.singcolor.LiveEventMembershipUpdated;
      reader.readMessage(value,proto.jp.singcolor.LiveEventMembershipUpdated.deserializeBinaryFromReader);
      msg.setLiveEventMembershipUpdated(value);
      break;
    case 35:
      var value = new proto.jp.singcolor.LiveEventGetSongRandomly;
      reader.readMessage(value,proto.jp.singcolor.LiveEventGetSongRandomly.deserializeBinaryFromReader);
      msg.setLiveEventGetSongRandomly(value);
      break;
    case 36:
      var value = new proto.jp.singcolor.LiveEventBanListenerCommentByModerator;
      reader.readMessage(value,proto.jp.singcolor.LiveEventBanListenerCommentByModerator.deserializeBinaryFromReader);
      msg.setLiveEventBanListenerCommentByModerator(value);
      break;
    case 37:
      var value = new proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator;
      reader.readMessage(value,proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.deserializeBinaryFromReader);
      msg.setLiveEventUnbanListenerCommentByModerator(value);
      break;
    case 38:
      var value = new proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator;
      reader.readMessage(value,proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.deserializeBinaryFromReader);
      msg.setLiveEventBanListenerReqCaptureByModerator(value);
      break;
    case 39:
      var value = new proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator;
      reader.readMessage(value,proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.deserializeBinaryFromReader);
      msg.setLiveEventUnbanListenerReqCaptureByModerator(value);
      break;
    case 40:
      var value = new proto.jp.singcolor.LiveEventDoBlockByModerator;
      reader.readMessage(value,proto.jp.singcolor.LiveEventDoBlockByModerator.deserializeBinaryFromReader);
      msg.setLiveEventDoBlockByModerator(value);
      break;
    case 41:
      var value = new proto.jp.singcolor.LiveEventUnblockByModerator;
      reader.readMessage(value,proto.jp.singcolor.LiveEventUnblockByModerator.deserializeBinaryFromReader);
      msg.setLiveEventUnblockByModerator(value);
      break;
    case 42:
      var value = new proto.jp.singcolor.LiveEventTenuredModerator;
      reader.readMessage(value,proto.jp.singcolor.LiveEventTenuredModerator.deserializeBinaryFromReader);
      msg.setLiveEventTenuredModerator(value);
      break;
    case 43:
      var value = new proto.jp.singcolor.LiveEventTerminatedModerator;
      reader.readMessage(value,proto.jp.singcolor.LiveEventTerminatedModerator.deserializeBinaryFromReader);
      msg.setLiveEventTerminatedModerator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLiveEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLiveId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getReceivedInClient();
  if (f !== 0) {
    writer.writeInt64(
      30,
      f
    );
  }
  f = message.getLiveEventSystem();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.jp.singcolor.LiveEventSystem.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventStart();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.jp.singcolor.LiveEventStart.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventFinish();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.jp.singcolor.LiveEventFinish.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventEnter();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.jp.singcolor.LiveEventEnter.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventLeave();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.jp.singcolor.LiveEventLeave.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventComment();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.jp.singcolor.LiveEventComment.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventSmallGift();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.jp.singcolor.LiveEventSmallGift.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventLargeGift();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.jp.singcolor.LiveEventLargeGift.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventStartSing();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.jp.singcolor.LiveEventStartSing.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventFinishSing();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.jp.singcolor.LiveEventFinishSing.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventGetFaveBonus();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.jp.singcolor.LiveEventGetFaveBonus.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventGetFaveBonusTitle();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.jp.singcolor.LiveEventGetFaveBonusTitle.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventGetFavePoints();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.jp.singcolor.LiveEventGetFavePoints.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventGetFaveBadge();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.jp.singcolor.LiveEventGetFaveBadge.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventGetSongFave();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.jp.singcolor.LiveEventGetSongFave.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventFollow();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.jp.singcolor.LiveEventFollow.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventLike();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.jp.singcolor.LiveEventLike.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventSuperLike();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.jp.singcolor.LiveEventSuperLike.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventAnnounce();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.jp.singcolor.LiveEventAnnounce.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventSurgeRankingNo1Reward();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventRequestSing();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.jp.singcolor.LiveEventRequestSing.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventSimplePfComment();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.jp.singcolor.LiveEventSimplePFComment.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventGetFaveBadgeAndSongFave();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventHeartfulMention();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.jp.singcolor.LiveEventHeartfulMention.serializeBinaryToWriter
    );
  }
  f = message.getLiveSettingsUpdated();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.jp.singcolor.LiveEventLiveSettingsUpdated.serializeBinaryToWriter
    );
  }
  f = message.getListenerReqCapturePossibilityUpdated();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventPickUpSongRandomly();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.jp.singcolor.LiveEventPickUpSongRandomly.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventMembershipUpdated();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.jp.singcolor.LiveEventMembershipUpdated.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventGetSongRandomly();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.jp.singcolor.LiveEventGetSongRandomly.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventBanListenerCommentByModerator();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.jp.singcolor.LiveEventBanListenerCommentByModerator.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventUnbanListenerCommentByModerator();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventBanListenerReqCaptureByModerator();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventUnbanListenerReqCaptureByModerator();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventDoBlockByModerator();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      proto.jp.singcolor.LiveEventDoBlockByModerator.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventUnblockByModerator();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      proto.jp.singcolor.LiveEventUnblockByModerator.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventTenuredModerator();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      proto.jp.singcolor.LiveEventTenuredModerator.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventTerminatedModerator();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      proto.jp.singcolor.LiveEventTerminatedModerator.serializeBinaryToWriter
    );
  }
};


/**
 * optional string live_event_id = 1;
 * @return {string}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.setLiveEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LiveEventType live_event_type = 2;
 * @return {!proto.jp.singcolor.LiveEventType}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventType = function() {
  return /** @type {!proto.jp.singcolor.LiveEventType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.jp.singcolor.LiveEventType} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.setLiveEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string live_id = 3;
 * @return {string}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.setLiveId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.jp.singcolor.LiveEvent.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 received_in_client = 30;
 * @return {number}
 */
proto.jp.singcolor.LiveEvent.prototype.getReceivedInClient = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.setReceivedInClient = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional LiveEventSystem live_event_system = 5;
 * @return {?proto.jp.singcolor.LiveEventSystem}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventSystem = function() {
  return /** @type{?proto.jp.singcolor.LiveEventSystem} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventSystem, 5));
};


/**
 * @param {?proto.jp.singcolor.LiveEventSystem|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventSystem = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventSystem = function() {
  return this.setLiveEventSystem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventSystem = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional LiveEventStart live_event_start = 6;
 * @return {?proto.jp.singcolor.LiveEventStart}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventStart = function() {
  return /** @type{?proto.jp.singcolor.LiveEventStart} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventStart, 6));
};


/**
 * @param {?proto.jp.singcolor.LiveEventStart|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventStart = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventStart = function() {
  return this.setLiveEventStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventStart = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional LiveEventFinish live_event_finish = 7;
 * @return {?proto.jp.singcolor.LiveEventFinish}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventFinish = function() {
  return /** @type{?proto.jp.singcolor.LiveEventFinish} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventFinish, 7));
};


/**
 * @param {?proto.jp.singcolor.LiveEventFinish|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventFinish = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventFinish = function() {
  return this.setLiveEventFinish(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventFinish = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional LiveEventEnter live_event_enter = 8;
 * @return {?proto.jp.singcolor.LiveEventEnter}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventEnter = function() {
  return /** @type{?proto.jp.singcolor.LiveEventEnter} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventEnter, 8));
};


/**
 * @param {?proto.jp.singcolor.LiveEventEnter|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventEnter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventEnter = function() {
  return this.setLiveEventEnter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventEnter = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional LiveEventLeave live_event_leave = 9;
 * @return {?proto.jp.singcolor.LiveEventLeave}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventLeave = function() {
  return /** @type{?proto.jp.singcolor.LiveEventLeave} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventLeave, 9));
};


/**
 * @param {?proto.jp.singcolor.LiveEventLeave|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventLeave = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventLeave = function() {
  return this.setLiveEventLeave(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventLeave = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional LiveEventComment live_event_comment = 10;
 * @return {?proto.jp.singcolor.LiveEventComment}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventComment = function() {
  return /** @type{?proto.jp.singcolor.LiveEventComment} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventComment, 10));
};


/**
 * @param {?proto.jp.singcolor.LiveEventComment|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventComment = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventComment = function() {
  return this.setLiveEventComment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventComment = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional LiveEventSmallGift live_event_small_gift = 11;
 * @return {?proto.jp.singcolor.LiveEventSmallGift}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventSmallGift = function() {
  return /** @type{?proto.jp.singcolor.LiveEventSmallGift} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventSmallGift, 11));
};


/**
 * @param {?proto.jp.singcolor.LiveEventSmallGift|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventSmallGift = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventSmallGift = function() {
  return this.setLiveEventSmallGift(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventSmallGift = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional LiveEventLargeGift live_event_large_gift = 12;
 * @return {?proto.jp.singcolor.LiveEventLargeGift}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventLargeGift = function() {
  return /** @type{?proto.jp.singcolor.LiveEventLargeGift} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventLargeGift, 12));
};


/**
 * @param {?proto.jp.singcolor.LiveEventLargeGift|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventLargeGift = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventLargeGift = function() {
  return this.setLiveEventLargeGift(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventLargeGift = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional LiveEventStartSing live_event_start_sing = 13;
 * @return {?proto.jp.singcolor.LiveEventStartSing}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventStartSing = function() {
  return /** @type{?proto.jp.singcolor.LiveEventStartSing} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventStartSing, 13));
};


/**
 * @param {?proto.jp.singcolor.LiveEventStartSing|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventStartSing = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventStartSing = function() {
  return this.setLiveEventStartSing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventStartSing = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional LiveEventFinishSing live_event_finish_sing = 14;
 * @return {?proto.jp.singcolor.LiveEventFinishSing}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventFinishSing = function() {
  return /** @type{?proto.jp.singcolor.LiveEventFinishSing} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventFinishSing, 14));
};


/**
 * @param {?proto.jp.singcolor.LiveEventFinishSing|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventFinishSing = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventFinishSing = function() {
  return this.setLiveEventFinishSing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventFinishSing = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional LiveEventGetFaveBonus live_event_get_fave_bonus = 15;
 * @return {?proto.jp.singcolor.LiveEventGetFaveBonus}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventGetFaveBonus = function() {
  return /** @type{?proto.jp.singcolor.LiveEventGetFaveBonus} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventGetFaveBonus, 15));
};


/**
 * @param {?proto.jp.singcolor.LiveEventGetFaveBonus|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventGetFaveBonus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventGetFaveBonus = function() {
  return this.setLiveEventGetFaveBonus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventGetFaveBonus = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional LiveEventGetFaveBonusTitle live_event_get_fave_bonus_title = 16;
 * @return {?proto.jp.singcolor.LiveEventGetFaveBonusTitle}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventGetFaveBonusTitle = function() {
  return /** @type{?proto.jp.singcolor.LiveEventGetFaveBonusTitle} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventGetFaveBonusTitle, 16));
};


/**
 * @param {?proto.jp.singcolor.LiveEventGetFaveBonusTitle|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventGetFaveBonusTitle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventGetFaveBonusTitle = function() {
  return this.setLiveEventGetFaveBonusTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventGetFaveBonusTitle = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional LiveEventGetFavePoints live_event_get_fave_points = 17;
 * @return {?proto.jp.singcolor.LiveEventGetFavePoints}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventGetFavePoints = function() {
  return /** @type{?proto.jp.singcolor.LiveEventGetFavePoints} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventGetFavePoints, 17));
};


/**
 * @param {?proto.jp.singcolor.LiveEventGetFavePoints|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventGetFavePoints = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventGetFavePoints = function() {
  return this.setLiveEventGetFavePoints(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventGetFavePoints = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional LiveEventGetFaveBadge live_event_get_fave_badge = 18;
 * @return {?proto.jp.singcolor.LiveEventGetFaveBadge}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventGetFaveBadge = function() {
  return /** @type{?proto.jp.singcolor.LiveEventGetFaveBadge} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventGetFaveBadge, 18));
};


/**
 * @param {?proto.jp.singcolor.LiveEventGetFaveBadge|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventGetFaveBadge = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventGetFaveBadge = function() {
  return this.setLiveEventGetFaveBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventGetFaveBadge = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional LiveEventGetSongFave live_event_get_song_fave = 19;
 * @return {?proto.jp.singcolor.LiveEventGetSongFave}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventGetSongFave = function() {
  return /** @type{?proto.jp.singcolor.LiveEventGetSongFave} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventGetSongFave, 19));
};


/**
 * @param {?proto.jp.singcolor.LiveEventGetSongFave|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventGetSongFave = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventGetSongFave = function() {
  return this.setLiveEventGetSongFave(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventGetSongFave = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional LiveEventFollow live_event_follow = 20;
 * @return {?proto.jp.singcolor.LiveEventFollow}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventFollow = function() {
  return /** @type{?proto.jp.singcolor.LiveEventFollow} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventFollow, 20));
};


/**
 * @param {?proto.jp.singcolor.LiveEventFollow|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventFollow = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventFollow = function() {
  return this.setLiveEventFollow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventFollow = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional LiveEventLike live_event_like = 21;
 * @return {?proto.jp.singcolor.LiveEventLike}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventLike = function() {
  return /** @type{?proto.jp.singcolor.LiveEventLike} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventLike, 21));
};


/**
 * @param {?proto.jp.singcolor.LiveEventLike|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventLike = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventLike = function() {
  return this.setLiveEventLike(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventLike = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional LiveEventSuperLike live_event_super_like = 22;
 * @return {?proto.jp.singcolor.LiveEventSuperLike}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventSuperLike = function() {
  return /** @type{?proto.jp.singcolor.LiveEventSuperLike} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventSuperLike, 22));
};


/**
 * @param {?proto.jp.singcolor.LiveEventSuperLike|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventSuperLike = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventSuperLike = function() {
  return this.setLiveEventSuperLike(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventSuperLike = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional LiveEventAnnounce live_event_announce = 23;
 * @return {?proto.jp.singcolor.LiveEventAnnounce}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventAnnounce = function() {
  return /** @type{?proto.jp.singcolor.LiveEventAnnounce} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventAnnounce, 23));
};


/**
 * @param {?proto.jp.singcolor.LiveEventAnnounce|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventAnnounce = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventAnnounce = function() {
  return this.setLiveEventAnnounce(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventAnnounce = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional LiveEventSurgeRankingNo1Reward live_event_surge_ranking_no1_reward = 24;
 * @return {?proto.jp.singcolor.LiveEventSurgeRankingNo1Reward}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventSurgeRankingNo1Reward = function() {
  return /** @type{?proto.jp.singcolor.LiveEventSurgeRankingNo1Reward} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventSurgeRankingNo1Reward, 24));
};


/**
 * @param {?proto.jp.singcolor.LiveEventSurgeRankingNo1Reward|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventSurgeRankingNo1Reward = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventSurgeRankingNo1Reward = function() {
  return this.setLiveEventSurgeRankingNo1Reward(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventSurgeRankingNo1Reward = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional LiveEventRequestSing live_event_request_sing = 25;
 * @return {?proto.jp.singcolor.LiveEventRequestSing}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventRequestSing = function() {
  return /** @type{?proto.jp.singcolor.LiveEventRequestSing} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventRequestSing, 25));
};


/**
 * @param {?proto.jp.singcolor.LiveEventRequestSing|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventRequestSing = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventRequestSing = function() {
  return this.setLiveEventRequestSing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventRequestSing = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional LiveEventSimplePFComment live_event_simple_pf_comment = 26;
 * @return {?proto.jp.singcolor.LiveEventSimplePFComment}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventSimplePfComment = function() {
  return /** @type{?proto.jp.singcolor.LiveEventSimplePFComment} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventSimplePFComment, 26));
};


/**
 * @param {?proto.jp.singcolor.LiveEventSimplePFComment|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventSimplePfComment = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventSimplePfComment = function() {
  return this.setLiveEventSimplePfComment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventSimplePfComment = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional LiveEventGetFaveBadgeAndSongFave live_event_get_fave_badge_and_song_fave = 27;
 * @return {?proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventGetFaveBadgeAndSongFave = function() {
  return /** @type{?proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave, 27));
};


/**
 * @param {?proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventGetFaveBadgeAndSongFave = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventGetFaveBadgeAndSongFave = function() {
  return this.setLiveEventGetFaveBadgeAndSongFave(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventGetFaveBadgeAndSongFave = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional LiveEventHeartfulMention live_event_heartful_mention = 28;
 * @return {?proto.jp.singcolor.LiveEventHeartfulMention}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventHeartfulMention = function() {
  return /** @type{?proto.jp.singcolor.LiveEventHeartfulMention} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventHeartfulMention, 28));
};


/**
 * @param {?proto.jp.singcolor.LiveEventHeartfulMention|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventHeartfulMention = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventHeartfulMention = function() {
  return this.setLiveEventHeartfulMention(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventHeartfulMention = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional LiveEventLiveSettingsUpdated live_settings_updated = 29;
 * @return {?proto.jp.singcolor.LiveEventLiveSettingsUpdated}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveSettingsUpdated = function() {
  return /** @type{?proto.jp.singcolor.LiveEventLiveSettingsUpdated} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventLiveSettingsUpdated, 29));
};


/**
 * @param {?proto.jp.singcolor.LiveEventLiveSettingsUpdated|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveSettingsUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveSettingsUpdated = function() {
  return this.setLiveSettingsUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveSettingsUpdated = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional LiveEventListenerReqCapturePossibilityUpdated listener_req_capture_possibility_updated = 31;
 * @return {?proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated}
 */
proto.jp.singcolor.LiveEvent.prototype.getListenerReqCapturePossibilityUpdated = function() {
  return /** @type{?proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated, 31));
};


/**
 * @param {?proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setListenerReqCapturePossibilityUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearListenerReqCapturePossibilityUpdated = function() {
  return this.setListenerReqCapturePossibilityUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasListenerReqCapturePossibilityUpdated = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional LiveEventPickUpSongRandomly live_event_pick_up_song_randomly = 32;
 * @return {?proto.jp.singcolor.LiveEventPickUpSongRandomly}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventPickUpSongRandomly = function() {
  return /** @type{?proto.jp.singcolor.LiveEventPickUpSongRandomly} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventPickUpSongRandomly, 32));
};


/**
 * @param {?proto.jp.singcolor.LiveEventPickUpSongRandomly|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventPickUpSongRandomly = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventPickUpSongRandomly = function() {
  return this.setLiveEventPickUpSongRandomly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventPickUpSongRandomly = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional LiveEventMembershipUpdated live_event_membership_updated = 34;
 * @return {?proto.jp.singcolor.LiveEventMembershipUpdated}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventMembershipUpdated = function() {
  return /** @type{?proto.jp.singcolor.LiveEventMembershipUpdated} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventMembershipUpdated, 34));
};


/**
 * @param {?proto.jp.singcolor.LiveEventMembershipUpdated|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventMembershipUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 34, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventMembershipUpdated = function() {
  return this.setLiveEventMembershipUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventMembershipUpdated = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional LiveEventGetSongRandomly live_event_get_song_randomly = 35;
 * @return {?proto.jp.singcolor.LiveEventGetSongRandomly}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventGetSongRandomly = function() {
  return /** @type{?proto.jp.singcolor.LiveEventGetSongRandomly} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventGetSongRandomly, 35));
};


/**
 * @param {?proto.jp.singcolor.LiveEventGetSongRandomly|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventGetSongRandomly = function(value) {
  return jspb.Message.setOneofWrapperField(this, 35, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventGetSongRandomly = function() {
  return this.setLiveEventGetSongRandomly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventGetSongRandomly = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional LiveEventBanListenerCommentByModerator live_event_ban_listener_comment_by_moderator = 36;
 * @return {?proto.jp.singcolor.LiveEventBanListenerCommentByModerator}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventBanListenerCommentByModerator = function() {
  return /** @type{?proto.jp.singcolor.LiveEventBanListenerCommentByModerator} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventBanListenerCommentByModerator, 36));
};


/**
 * @param {?proto.jp.singcolor.LiveEventBanListenerCommentByModerator|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventBanListenerCommentByModerator = function(value) {
  return jspb.Message.setOneofWrapperField(this, 36, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventBanListenerCommentByModerator = function() {
  return this.setLiveEventBanListenerCommentByModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventBanListenerCommentByModerator = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional LiveEventUnbanListenerCommentByModerator live_event_unban_listener_comment_by_moderator = 37;
 * @return {?proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventUnbanListenerCommentByModerator = function() {
  return /** @type{?proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator, 37));
};


/**
 * @param {?proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventUnbanListenerCommentByModerator = function(value) {
  return jspb.Message.setOneofWrapperField(this, 37, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventUnbanListenerCommentByModerator = function() {
  return this.setLiveEventUnbanListenerCommentByModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventUnbanListenerCommentByModerator = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional LiveEventBanListenerReqCaptureByModerator live_event_ban_listener_req_capture_by_moderator = 38;
 * @return {?proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventBanListenerReqCaptureByModerator = function() {
  return /** @type{?proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator, 38));
};


/**
 * @param {?proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventBanListenerReqCaptureByModerator = function(value) {
  return jspb.Message.setOneofWrapperField(this, 38, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventBanListenerReqCaptureByModerator = function() {
  return this.setLiveEventBanListenerReqCaptureByModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventBanListenerReqCaptureByModerator = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional LiveEventUnbanListenerReqCaptureByModerator live_event_unban_listener_req_capture_by_moderator = 39;
 * @return {?proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventUnbanListenerReqCaptureByModerator = function() {
  return /** @type{?proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator, 39));
};


/**
 * @param {?proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventUnbanListenerReqCaptureByModerator = function(value) {
  return jspb.Message.setOneofWrapperField(this, 39, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventUnbanListenerReqCaptureByModerator = function() {
  return this.setLiveEventUnbanListenerReqCaptureByModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventUnbanListenerReqCaptureByModerator = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional LiveEventDoBlockByModerator live_event_do_block_by_moderator = 40;
 * @return {?proto.jp.singcolor.LiveEventDoBlockByModerator}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventDoBlockByModerator = function() {
  return /** @type{?proto.jp.singcolor.LiveEventDoBlockByModerator} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventDoBlockByModerator, 40));
};


/**
 * @param {?proto.jp.singcolor.LiveEventDoBlockByModerator|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventDoBlockByModerator = function(value) {
  return jspb.Message.setOneofWrapperField(this, 40, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventDoBlockByModerator = function() {
  return this.setLiveEventDoBlockByModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventDoBlockByModerator = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional LiveEventUnblockByModerator live_event_unblock_by_moderator = 41;
 * @return {?proto.jp.singcolor.LiveEventUnblockByModerator}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventUnblockByModerator = function() {
  return /** @type{?proto.jp.singcolor.LiveEventUnblockByModerator} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventUnblockByModerator, 41));
};


/**
 * @param {?proto.jp.singcolor.LiveEventUnblockByModerator|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventUnblockByModerator = function(value) {
  return jspb.Message.setOneofWrapperField(this, 41, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventUnblockByModerator = function() {
  return this.setLiveEventUnblockByModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventUnblockByModerator = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional LiveEventTenuredModerator live_event_tenured_moderator = 42;
 * @return {?proto.jp.singcolor.LiveEventTenuredModerator}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventTenuredModerator = function() {
  return /** @type{?proto.jp.singcolor.LiveEventTenuredModerator} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventTenuredModerator, 42));
};


/**
 * @param {?proto.jp.singcolor.LiveEventTenuredModerator|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventTenuredModerator = function(value) {
  return jspb.Message.setOneofWrapperField(this, 42, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventTenuredModerator = function() {
  return this.setLiveEventTenuredModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventTenuredModerator = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional LiveEventTerminatedModerator live_event_terminated_moderator = 43;
 * @return {?proto.jp.singcolor.LiveEventTerminatedModerator}
 */
proto.jp.singcolor.LiveEvent.prototype.getLiveEventTerminatedModerator = function() {
  return /** @type{?proto.jp.singcolor.LiveEventTerminatedModerator} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventTerminatedModerator, 43));
};


/**
 * @param {?proto.jp.singcolor.LiveEventTerminatedModerator|undefined} value
 * @return {!proto.jp.singcolor.LiveEvent} returns this
*/
proto.jp.singcolor.LiveEvent.prototype.setLiveEventTerminatedModerator = function(value) {
  return jspb.Message.setOneofWrapperField(this, 43, proto.jp.singcolor.LiveEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEvent} returns this
 */
proto.jp.singcolor.LiveEvent.prototype.clearLiveEventTerminatedModerator = function() {
  return this.setLiveEventTerminatedModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEvent.prototype.hasLiveEventTerminatedModerator = function() {
  return jspb.Message.getField(this, 43) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventSystem.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventSystem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventSystem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventSystem.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventSystem}
 */
proto.jp.singcolor.LiveEventSystem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventSystem;
  return proto.jp.singcolor.LiveEventSystem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventSystem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventSystem}
 */
proto.jp.singcolor.LiveEventSystem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventSystem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventSystem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventSystem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventSystem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.jp.singcolor.LiveEventSystem.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventSystem} returns this
 */
proto.jp.singcolor.LiveEventSystem.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventStart.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventStart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventStart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventStart.toObject = function(includeInstance, msg) {
  var f, obj = {
    liver: (f = msg.getLiver()) && users_entity_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventStart}
 */
proto.jp.singcolor.LiveEventStart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventStart;
  return proto.jp.singcolor.LiveEventStart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventStart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventStart}
 */
proto.jp.singcolor.LiveEventStart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setLiver(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventStart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventStart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventStart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventStart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiver();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User liver = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventStart.prototype.getLiver = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventStart} returns this
*/
proto.jp.singcolor.LiveEventStart.prototype.setLiver = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventStart} returns this
 */
proto.jp.singcolor.LiveEventStart.prototype.clearLiver = function() {
  return this.setLiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventStart.prototype.hasLiver = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventFinish.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventFinish.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventFinish} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventFinish.toObject = function(includeInstance, msg) {
  var f, obj = {
    liver: (f = msg.getLiver()) && users_entity_pb.User.toObject(includeInstance, f),
    reason: jspb.Message.getFieldWithDefault(msg, 2, 0),
    liverId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventFinish}
 */
proto.jp.singcolor.LiveEventFinish.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventFinish;
  return proto.jp.singcolor.LiveEventFinish.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventFinish} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventFinish}
 */
proto.jp.singcolor.LiveEventFinish.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setLiver(value);
      break;
    case 2:
      var value = /** @type {!proto.jp.singcolor.LiveFinishReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiverId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventFinish.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventFinish.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventFinish} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventFinish.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiver();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLiverId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional User liver = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventFinish.prototype.getLiver = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventFinish} returns this
*/
proto.jp.singcolor.LiveEventFinish.prototype.setLiver = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventFinish} returns this
 */
proto.jp.singcolor.LiveEventFinish.prototype.clearLiver = function() {
  return this.setLiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventFinish.prototype.hasLiver = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LiveFinishReason reason = 2;
 * @return {!proto.jp.singcolor.LiveFinishReason}
 */
proto.jp.singcolor.LiveEventFinish.prototype.getReason = function() {
  return /** @type {!proto.jp.singcolor.LiveFinishReason} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.jp.singcolor.LiveFinishReason} value
 * @return {!proto.jp.singcolor.LiveEventFinish} returns this
 */
proto.jp.singcolor.LiveEventFinish.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string liver_id = 3;
 * @return {string}
 */
proto.jp.singcolor.LiveEventFinish.prototype.getLiverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventFinish} returns this
 */
proto.jp.singcolor.LiveEventFinish.prototype.setLiverId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventEnter.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventEnter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventEnter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventEnter.toObject = function(includeInstance, msg) {
  var f, obj = {
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
    isFirstLook: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    pfFavePoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
    relatedTown: (f = msg.getRelatedTown()) && users_entity_pb.Town.toObject(includeInstance, f),
    relatedSinger: (f = msg.getRelatedSinger()) && karaoke_entity_pb.Singer.toObject(includeInstance, f),
    isPresentable: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    heartfulMentionButton: (f = msg.getHeartfulMentionButton()) && proto.jp.singcolor.HeartfulMentionButton.toObject(includeInstance, f),
    isHatsunajimi: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventEnter}
 */
proto.jp.singcolor.LiveEventEnter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventEnter;
  return proto.jp.singcolor.LiveEventEnter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventEnter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventEnter}
 */
proto.jp.singcolor.LiveEventEnter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFirstLook(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPfFavePoints(value);
      break;
    case 5:
      var value = new users_entity_pb.Town;
      reader.readMessage(value,users_entity_pb.Town.deserializeBinaryFromReader);
      msg.setRelatedTown(value);
      break;
    case 6:
      var value = new karaoke_entity_pb.Singer;
      reader.readMessage(value,karaoke_entity_pb.Singer.deserializeBinaryFromReader);
      msg.setRelatedSinger(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPresentable(value);
      break;
    case 8:
      var value = new proto.jp.singcolor.HeartfulMentionButton;
      reader.readMessage(value,proto.jp.singcolor.HeartfulMentionButton.deserializeBinaryFromReader);
      msg.setHeartfulMentionButton(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHatsunajimi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventEnter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventEnter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventEnter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventEnter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getIsFirstLook();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPfFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRelatedTown();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      users_entity_pb.Town.serializeBinaryToWriter
    );
  }
  f = message.getRelatedSinger();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      karaoke_entity_pb.Singer.serializeBinaryToWriter
    );
  }
  f = message.getIsPresentable();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getHeartfulMentionButton();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.jp.singcolor.HeartfulMentionButton.serializeBinaryToWriter
    );
  }
  f = message.getIsHatsunajimi();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional User listener = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventEnter.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventEnter} returns this
*/
proto.jp.singcolor.LiveEventEnter.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventEnter} returns this
 */
proto.jp.singcolor.LiveEventEnter.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventEnter.prototype.hasListener = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_first_look = 2;
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventEnter.prototype.getIsFirstLook = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LiveEventEnter} returns this
 */
proto.jp.singcolor.LiveEventEnter.prototype.setIsFirstLook = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int64 pf_fave_points = 3;
 * @return {number}
 */
proto.jp.singcolor.LiveEventEnter.prototype.getPfFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventEnter} returns this
 */
proto.jp.singcolor.LiveEventEnter.prototype.setPfFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Town related_town = 5;
 * @return {?proto.jp.singcolor.Town}
 */
proto.jp.singcolor.LiveEventEnter.prototype.getRelatedTown = function() {
  return /** @type{?proto.jp.singcolor.Town} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.Town, 5));
};


/**
 * @param {?proto.jp.singcolor.Town|undefined} value
 * @return {!proto.jp.singcolor.LiveEventEnter} returns this
*/
proto.jp.singcolor.LiveEventEnter.prototype.setRelatedTown = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventEnter} returns this
 */
proto.jp.singcolor.LiveEventEnter.prototype.clearRelatedTown = function() {
  return this.setRelatedTown(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventEnter.prototype.hasRelatedTown = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Singer related_singer = 6;
 * @return {?proto.jp.singcolor.Singer}
 */
proto.jp.singcolor.LiveEventEnter.prototype.getRelatedSinger = function() {
  return /** @type{?proto.jp.singcolor.Singer} */ (
    jspb.Message.getWrapperField(this, karaoke_entity_pb.Singer, 6));
};


/**
 * @param {?proto.jp.singcolor.Singer|undefined} value
 * @return {!proto.jp.singcolor.LiveEventEnter} returns this
*/
proto.jp.singcolor.LiveEventEnter.prototype.setRelatedSinger = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventEnter} returns this
 */
proto.jp.singcolor.LiveEventEnter.prototype.clearRelatedSinger = function() {
  return this.setRelatedSinger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventEnter.prototype.hasRelatedSinger = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool is_presentable = 7;
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventEnter.prototype.getIsPresentable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LiveEventEnter} returns this
 */
proto.jp.singcolor.LiveEventEnter.prototype.setIsPresentable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional HeartfulMentionButton heartful_mention_button = 8;
 * @return {?proto.jp.singcolor.HeartfulMentionButton}
 */
proto.jp.singcolor.LiveEventEnter.prototype.getHeartfulMentionButton = function() {
  return /** @type{?proto.jp.singcolor.HeartfulMentionButton} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.HeartfulMentionButton, 8));
};


/**
 * @param {?proto.jp.singcolor.HeartfulMentionButton|undefined} value
 * @return {!proto.jp.singcolor.LiveEventEnter} returns this
*/
proto.jp.singcolor.LiveEventEnter.prototype.setHeartfulMentionButton = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventEnter} returns this
 */
proto.jp.singcolor.LiveEventEnter.prototype.clearHeartfulMentionButton = function() {
  return this.setHeartfulMentionButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventEnter.prototype.hasHeartfulMentionButton = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_hatsunajimi = 9;
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventEnter.prototype.getIsHatsunajimi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LiveEventEnter} returns this
 */
proto.jp.singcolor.LiveEventEnter.prototype.setIsHatsunajimi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventLeave.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventLeave.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventLeave} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventLeave.toObject = function(includeInstance, msg) {
  var f, obj = {
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
    reason: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventLeave}
 */
proto.jp.singcolor.LiveEventLeave.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventLeave;
  return proto.jp.singcolor.LiveEventLeave.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventLeave} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventLeave}
 */
proto.jp.singcolor.LiveEventLeave.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    case 2:
      var value = /** @type {!proto.jp.singcolor.LiveFinishReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventLeave.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventLeave.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventLeave} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventLeave.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional User listener = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventLeave.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventLeave} returns this
*/
proto.jp.singcolor.LiveEventLeave.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventLeave} returns this
 */
proto.jp.singcolor.LiveEventLeave.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventLeave.prototype.hasListener = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LiveFinishReason reason = 2;
 * @return {!proto.jp.singcolor.LiveFinishReason}
 */
proto.jp.singcolor.LiveEventLeave.prototype.getReason = function() {
  return /** @type {!proto.jp.singcolor.LiveFinishReason} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.jp.singcolor.LiveFinishReason} value
 * @return {!proto.jp.singcolor.LiveEventLeave} returns this
 */
proto.jp.singcolor.LiveEventLeave.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventComment.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventComment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventComment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventComment.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
    comment: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mentionUser: (f = msg.getMentionUser()) && users_entity_pb.User.toObject(includeInstance, f),
    termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
    monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 5, 0),
    fanType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    songYellBadge: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isBeginner: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    suppressUselessComment: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    userMembershipInfo: (f = msg.getUserMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventComment}
 */
proto.jp.singcolor.LiveEventComment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventComment;
  return proto.jp.singcolor.LiveEventComment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventComment}
 */
proto.jp.singcolor.LiveEventComment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 3:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setMentionUser(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTermlyFavePoints(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlyFavePoints(value);
      break;
    case 6:
      var value = /** @type {!proto.jp.singcolor.FanType} */ (reader.readEnum());
      msg.setFanType(value);
      break;
    case 7:
      var value = /** @type {!proto.jp.singcolor.SongYellBadge} */ (reader.readEnum());
      msg.setSongYellBadge(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBeginner(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuppressUselessComment(value);
      break;
    case 10:
      var value = new users_entity_pb.ListenerMembershipInfo;
      reader.readMessage(value,users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
      msg.setUserMembershipInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventComment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventComment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventComment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMentionUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getTermlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMonthlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getFanType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSongYellBadge();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getIsBeginner();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSuppressUselessComment();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getUserMembershipInfo();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventComment.prototype.getUser = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventComment} returns this
*/
proto.jp.singcolor.LiveEventComment.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventComment} returns this
 */
proto.jp.singcolor.LiveEventComment.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventComment.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string comment = 2;
 * @return {string}
 */
proto.jp.singcolor.LiveEventComment.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventComment} returns this
 */
proto.jp.singcolor.LiveEventComment.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional User mention_user = 3;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventComment.prototype.getMentionUser = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 3));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventComment} returns this
*/
proto.jp.singcolor.LiveEventComment.prototype.setMentionUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventComment} returns this
 */
proto.jp.singcolor.LiveEventComment.prototype.clearMentionUser = function() {
  return this.setMentionUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventComment.prototype.hasMentionUser = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 termly_fave_points = 4;
 * @return {number}
 */
proto.jp.singcolor.LiveEventComment.prototype.getTermlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventComment} returns this
 */
proto.jp.singcolor.LiveEventComment.prototype.setTermlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 monthly_fave_points = 5;
 * @return {number}
 */
proto.jp.singcolor.LiveEventComment.prototype.getMonthlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventComment} returns this
 */
proto.jp.singcolor.LiveEventComment.prototype.setMonthlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional FanType fan_type = 6;
 * @return {!proto.jp.singcolor.FanType}
 */
proto.jp.singcolor.LiveEventComment.prototype.getFanType = function() {
  return /** @type {!proto.jp.singcolor.FanType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.jp.singcolor.FanType} value
 * @return {!proto.jp.singcolor.LiveEventComment} returns this
 */
proto.jp.singcolor.LiveEventComment.prototype.setFanType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional SongYellBadge song_yell_badge = 7;
 * @return {!proto.jp.singcolor.SongYellBadge}
 */
proto.jp.singcolor.LiveEventComment.prototype.getSongYellBadge = function() {
  return /** @type {!proto.jp.singcolor.SongYellBadge} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.jp.singcolor.SongYellBadge} value
 * @return {!proto.jp.singcolor.LiveEventComment} returns this
 */
proto.jp.singcolor.LiveEventComment.prototype.setSongYellBadge = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool is_beginner = 8;
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventComment.prototype.getIsBeginner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LiveEventComment} returns this
 */
proto.jp.singcolor.LiveEventComment.prototype.setIsBeginner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool suppress_useless_comment = 9;
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventComment.prototype.getSuppressUselessComment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LiveEventComment} returns this
 */
proto.jp.singcolor.LiveEventComment.prototype.setSuppressUselessComment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional ListenerMembershipInfo user_membership_info = 10;
 * @return {?proto.jp.singcolor.ListenerMembershipInfo}
 */
proto.jp.singcolor.LiveEventComment.prototype.getUserMembershipInfo = function() {
  return /** @type{?proto.jp.singcolor.ListenerMembershipInfo} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 10));
};


/**
 * @param {?proto.jp.singcolor.ListenerMembershipInfo|undefined} value
 * @return {!proto.jp.singcolor.LiveEventComment} returns this
*/
proto.jp.singcolor.LiveEventComment.prototype.setUserMembershipInfo = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventComment} returns this
 */
proto.jp.singcolor.LiveEventComment.prototype.clearUserMembershipInfo = function() {
  return this.setUserMembershipInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventComment.prototype.hasUserMembershipInfo = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventSmallGift.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventSmallGift} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventSmallGift.toObject = function(includeInstance, msg) {
  var f, obj = {
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
    gift: (f = msg.getGift()) && proto.jp.singcolor.Gift.toObject(includeInstance, f),
    giftCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
    monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 5, 0),
    fanType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    songYellBadge: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isBeginner: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    smallGiftType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventSmallGift}
 */
proto.jp.singcolor.LiveEventSmallGift.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventSmallGift;
  return proto.jp.singcolor.LiveEventSmallGift.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventSmallGift} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventSmallGift}
 */
proto.jp.singcolor.LiveEventSmallGift.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.Gift;
      reader.readMessage(value,proto.jp.singcolor.Gift.deserializeBinaryFromReader);
      msg.setGift(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGiftCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTermlyFavePoints(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlyFavePoints(value);
      break;
    case 6:
      var value = /** @type {!proto.jp.singcolor.FanType} */ (reader.readEnum());
      msg.setFanType(value);
      break;
    case 7:
      var value = /** @type {!proto.jp.singcolor.SongYellBadge} */ (reader.readEnum());
      msg.setSongYellBadge(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBeginner(value);
      break;
    case 9:
      var value = /** @type {!proto.jp.singcolor.SmallGiftType} */ (reader.readEnum());
      msg.setSmallGiftType(value);
      break;
    case 10:
      var value = new users_entity_pb.ListenerMembershipInfo;
      reader.readMessage(value,users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
      msg.setListenerMembershipInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventSmallGift.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventSmallGift} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventSmallGift.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getGift();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.Gift.serializeBinaryToWriter
    );
  }
  f = message.getGiftCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTermlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMonthlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getFanType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSongYellBadge();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getIsBeginner();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSmallGiftType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getListenerMembershipInfo();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional User listener = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventSmallGift} returns this
*/
proto.jp.singcolor.LiveEventSmallGift.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventSmallGift} returns this
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.hasListener = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Gift gift = 2;
 * @return {?proto.jp.singcolor.Gift}
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.getGift = function() {
  return /** @type{?proto.jp.singcolor.Gift} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.Gift, 2));
};


/**
 * @param {?proto.jp.singcolor.Gift|undefined} value
 * @return {!proto.jp.singcolor.LiveEventSmallGift} returns this
*/
proto.jp.singcolor.LiveEventSmallGift.prototype.setGift = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventSmallGift} returns this
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.clearGift = function() {
  return this.setGift(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.hasGift = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 gift_count = 3;
 * @return {number}
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.getGiftCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventSmallGift} returns this
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.setGiftCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 termly_fave_points = 4;
 * @return {number}
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.getTermlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventSmallGift} returns this
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.setTermlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 monthly_fave_points = 5;
 * @return {number}
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.getMonthlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventSmallGift} returns this
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.setMonthlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional FanType fan_type = 6;
 * @return {!proto.jp.singcolor.FanType}
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.getFanType = function() {
  return /** @type {!proto.jp.singcolor.FanType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.jp.singcolor.FanType} value
 * @return {!proto.jp.singcolor.LiveEventSmallGift} returns this
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.setFanType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional SongYellBadge song_yell_badge = 7;
 * @return {!proto.jp.singcolor.SongYellBadge}
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.getSongYellBadge = function() {
  return /** @type {!proto.jp.singcolor.SongYellBadge} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.jp.singcolor.SongYellBadge} value
 * @return {!proto.jp.singcolor.LiveEventSmallGift} returns this
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.setSongYellBadge = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool is_beginner = 8;
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.getIsBeginner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LiveEventSmallGift} returns this
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.setIsBeginner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional SmallGiftType small_gift_type = 9;
 * @return {!proto.jp.singcolor.SmallGiftType}
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.getSmallGiftType = function() {
  return /** @type {!proto.jp.singcolor.SmallGiftType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.jp.singcolor.SmallGiftType} value
 * @return {!proto.jp.singcolor.LiveEventSmallGift} returns this
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.setSmallGiftType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional ListenerMembershipInfo listener_membership_info = 10;
 * @return {?proto.jp.singcolor.ListenerMembershipInfo}
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.getListenerMembershipInfo = function() {
  return /** @type{?proto.jp.singcolor.ListenerMembershipInfo} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 10));
};


/**
 * @param {?proto.jp.singcolor.ListenerMembershipInfo|undefined} value
 * @return {!proto.jp.singcolor.LiveEventSmallGift} returns this
*/
proto.jp.singcolor.LiveEventSmallGift.prototype.setListenerMembershipInfo = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventSmallGift} returns this
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.clearListenerMembershipInfo = function() {
  return this.setListenerMembershipInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventSmallGift.prototype.hasListenerMembershipInfo = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventLargeGift.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventLargeGift} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventLargeGift.toObject = function(includeInstance, msg) {
  var f, obj = {
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
    gift: (f = msg.getGift()) && proto.jp.singcolor.Gift.toObject(includeInstance, f),
    termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
    monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
    fanType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    songYellBadge: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isBeginner: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventLargeGift}
 */
proto.jp.singcolor.LiveEventLargeGift.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventLargeGift;
  return proto.jp.singcolor.LiveEventLargeGift.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventLargeGift} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventLargeGift}
 */
proto.jp.singcolor.LiveEventLargeGift.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.Gift;
      reader.readMessage(value,proto.jp.singcolor.Gift.deserializeBinaryFromReader);
      msg.setGift(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTermlyFavePoints(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlyFavePoints(value);
      break;
    case 5:
      var value = /** @type {!proto.jp.singcolor.FanType} */ (reader.readEnum());
      msg.setFanType(value);
      break;
    case 6:
      var value = /** @type {!proto.jp.singcolor.SongYellBadge} */ (reader.readEnum());
      msg.setSongYellBadge(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBeginner(value);
      break;
    case 8:
      var value = new users_entity_pb.ListenerMembershipInfo;
      reader.readMessage(value,users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
      msg.setListenerMembershipInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventLargeGift.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventLargeGift} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventLargeGift.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getGift();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.Gift.serializeBinaryToWriter
    );
  }
  f = message.getTermlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMonthlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getFanType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSongYellBadge();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getIsBeginner();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getListenerMembershipInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional User listener = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventLargeGift} returns this
*/
proto.jp.singcolor.LiveEventLargeGift.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventLargeGift} returns this
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.hasListener = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Gift gift = 2;
 * @return {?proto.jp.singcolor.Gift}
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.getGift = function() {
  return /** @type{?proto.jp.singcolor.Gift} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.Gift, 2));
};


/**
 * @param {?proto.jp.singcolor.Gift|undefined} value
 * @return {!proto.jp.singcolor.LiveEventLargeGift} returns this
*/
proto.jp.singcolor.LiveEventLargeGift.prototype.setGift = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventLargeGift} returns this
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.clearGift = function() {
  return this.setGift(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.hasGift = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 termly_fave_points = 3;
 * @return {number}
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.getTermlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventLargeGift} returns this
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.setTermlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 monthly_fave_points = 4;
 * @return {number}
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.getMonthlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventLargeGift} returns this
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.setMonthlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional FanType fan_type = 5;
 * @return {!proto.jp.singcolor.FanType}
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.getFanType = function() {
  return /** @type {!proto.jp.singcolor.FanType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.jp.singcolor.FanType} value
 * @return {!proto.jp.singcolor.LiveEventLargeGift} returns this
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.setFanType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional SongYellBadge song_yell_badge = 6;
 * @return {!proto.jp.singcolor.SongYellBadge}
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.getSongYellBadge = function() {
  return /** @type {!proto.jp.singcolor.SongYellBadge} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.jp.singcolor.SongYellBadge} value
 * @return {!proto.jp.singcolor.LiveEventLargeGift} returns this
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.setSongYellBadge = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool is_beginner = 7;
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.getIsBeginner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LiveEventLargeGift} returns this
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.setIsBeginner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional ListenerMembershipInfo listener_membership_info = 8;
 * @return {?proto.jp.singcolor.ListenerMembershipInfo}
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.getListenerMembershipInfo = function() {
  return /** @type{?proto.jp.singcolor.ListenerMembershipInfo} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 8));
};


/**
 * @param {?proto.jp.singcolor.ListenerMembershipInfo|undefined} value
 * @return {!proto.jp.singcolor.LiveEventLargeGift} returns this
*/
proto.jp.singcolor.LiveEventLargeGift.prototype.setListenerMembershipInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventLargeGift} returns this
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.clearListenerMembershipInfo = function() {
  return this.setListenerMembershipInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventLargeGift.prototype.hasListenerMembershipInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.LiveEventStartSing.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventStartSing.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventStartSing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventStartSing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventStartSing.toObject = function(includeInstance, msg) {
  var f, obj = {
    song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
    singStartTimeInClient: jspb.Message.getFieldWithDefault(msg, 2, 0),
    listeningReactionGiftButtonsList: jspb.Message.toObjectList(msg.getListeningReactionGiftButtonsList(),
    proto.jp.singcolor.ListeningReactionGiftButton.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventStartSing}
 */
proto.jp.singcolor.LiveEventStartSing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventStartSing;
  return proto.jp.singcolor.LiveEventStartSing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventStartSing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventStartSing}
 */
proto.jp.singcolor.LiveEventStartSing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new karaoke_entity_pb.Song;
      reader.readMessage(value,karaoke_entity_pb.Song.deserializeBinaryFromReader);
      msg.setSong(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSingStartTimeInClient(value);
      break;
    case 4:
      var value = new proto.jp.singcolor.ListeningReactionGiftButton;
      reader.readMessage(value,proto.jp.singcolor.ListeningReactionGiftButton.deserializeBinaryFromReader);
      msg.addListeningReactionGiftButtons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventStartSing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventStartSing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventStartSing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventStartSing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSong();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      karaoke_entity_pb.Song.serializeBinaryToWriter
    );
  }
  f = message.getSingStartTimeInClient();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getListeningReactionGiftButtonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.jp.singcolor.ListeningReactionGiftButton.serializeBinaryToWriter
    );
  }
};


/**
 * optional Song song = 1;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.LiveEventStartSing.prototype.getSong = function() {
  return /** @type{?proto.jp.singcolor.Song} */ (
    jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 1));
};


/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.LiveEventStartSing} returns this
*/
proto.jp.singcolor.LiveEventStartSing.prototype.setSong = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventStartSing} returns this
 */
proto.jp.singcolor.LiveEventStartSing.prototype.clearSong = function() {
  return this.setSong(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventStartSing.prototype.hasSong = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 sing_start_time_in_client = 2;
 * @return {number}
 */
proto.jp.singcolor.LiveEventStartSing.prototype.getSingStartTimeInClient = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventStartSing} returns this
 */
proto.jp.singcolor.LiveEventStartSing.prototype.setSingStartTimeInClient = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ListeningReactionGiftButton listening_reaction_gift_buttons = 4;
 * @return {!Array<!proto.jp.singcolor.ListeningReactionGiftButton>}
 */
proto.jp.singcolor.LiveEventStartSing.prototype.getListeningReactionGiftButtonsList = function() {
  return /** @type{!Array<!proto.jp.singcolor.ListeningReactionGiftButton>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.ListeningReactionGiftButton, 4));
};


/**
 * @param {!Array<!proto.jp.singcolor.ListeningReactionGiftButton>} value
 * @return {!proto.jp.singcolor.LiveEventStartSing} returns this
*/
proto.jp.singcolor.LiveEventStartSing.prototype.setListeningReactionGiftButtonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.jp.singcolor.ListeningReactionGiftButton=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListeningReactionGiftButton}
 */
proto.jp.singcolor.LiveEventStartSing.prototype.addListeningReactionGiftButtons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.jp.singcolor.ListeningReactionGiftButton, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.LiveEventStartSing} returns this
 */
proto.jp.singcolor.LiveEventStartSing.prototype.clearListeningReactionGiftButtonsList = function() {
  return this.setListeningReactionGiftButtonsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.ListeningReactionGiftButton.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.ListeningReactionGiftButton.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.ListeningReactionGiftButton} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListeningReactionGiftButton.toObject = function(includeInstance, msg) {
  var f, obj = {
    listenerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayTimingSeconds: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reactionGiftButton: (f = msg.getReactionGiftButton()) && proto.jp.singcolor.ReactionGiftButton.toObject(includeInstance, f),
    isGospelGift: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListeningReactionGiftButton}
 */
proto.jp.singcolor.ListeningReactionGiftButton.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.ListeningReactionGiftButton;
  return proto.jp.singcolor.ListeningReactionGiftButton.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListeningReactionGiftButton} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListeningReactionGiftButton}
 */
proto.jp.singcolor.ListeningReactionGiftButton.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setListenerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDisplayTimingSeconds(value);
      break;
    case 3:
      var value = new proto.jp.singcolor.ReactionGiftButton;
      reader.readMessage(value,proto.jp.singcolor.ReactionGiftButton.deserializeBinaryFromReader);
      msg.setReactionGiftButton(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsGospelGift(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListeningReactionGiftButton.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.ListeningReactionGiftButton.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListeningReactionGiftButton} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListeningReactionGiftButton.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListenerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayTimingSeconds();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getReactionGiftButton();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.jp.singcolor.ReactionGiftButton.serializeBinaryToWriter
    );
  }
  f = message.getIsGospelGift();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string listener_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListeningReactionGiftButton.prototype.getListenerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListeningReactionGiftButton} returns this
 */
proto.jp.singcolor.ListeningReactionGiftButton.prototype.setListenerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 display_timing_seconds = 2;
 * @return {number}
 */
proto.jp.singcolor.ListeningReactionGiftButton.prototype.getDisplayTimingSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListeningReactionGiftButton} returns this
 */
proto.jp.singcolor.ListeningReactionGiftButton.prototype.setDisplayTimingSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ReactionGiftButton reaction_gift_button = 3;
 * @return {?proto.jp.singcolor.ReactionGiftButton}
 */
proto.jp.singcolor.ListeningReactionGiftButton.prototype.getReactionGiftButton = function() {
  return /** @type{?proto.jp.singcolor.ReactionGiftButton} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.ReactionGiftButton, 3));
};


/**
 * @param {?proto.jp.singcolor.ReactionGiftButton|undefined} value
 * @return {!proto.jp.singcolor.ListeningReactionGiftButton} returns this
*/
proto.jp.singcolor.ListeningReactionGiftButton.prototype.setReactionGiftButton = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListeningReactionGiftButton} returns this
 */
proto.jp.singcolor.ListeningReactionGiftButton.prototype.clearReactionGiftButton = function() {
  return this.setReactionGiftButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListeningReactionGiftButton.prototype.hasReactionGiftButton = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_gospel_gift = 4;
 * @return {boolean}
 */
proto.jp.singcolor.ListeningReactionGiftButton.prototype.getIsGospelGift = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListeningReactionGiftButton} returns this
 */
proto.jp.singcolor.ListeningReactionGiftButton.prototype.setIsGospelGift = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventFinishSing.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventFinishSing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventFinishSing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventFinishSing.toObject = function(includeInstance, msg) {
  var f, obj = {
    song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
    reactionGiftButton: (f = msg.getReactionGiftButton()) && proto.jp.singcolor.ReactionGiftButton.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventFinishSing}
 */
proto.jp.singcolor.LiveEventFinishSing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventFinishSing;
  return proto.jp.singcolor.LiveEventFinishSing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventFinishSing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventFinishSing}
 */
proto.jp.singcolor.LiveEventFinishSing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new karaoke_entity_pb.Song;
      reader.readMessage(value,karaoke_entity_pb.Song.deserializeBinaryFromReader);
      msg.setSong(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.ReactionGiftButton;
      reader.readMessage(value,proto.jp.singcolor.ReactionGiftButton.deserializeBinaryFromReader);
      msg.setReactionGiftButton(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventFinishSing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventFinishSing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventFinishSing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventFinishSing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSong();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      karaoke_entity_pb.Song.serializeBinaryToWriter
    );
  }
  f = message.getReactionGiftButton();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.ReactionGiftButton.serializeBinaryToWriter
    );
  }
};


/**
 * optional Song song = 1;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.LiveEventFinishSing.prototype.getSong = function() {
  return /** @type{?proto.jp.singcolor.Song} */ (
    jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 1));
};


/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.LiveEventFinishSing} returns this
*/
proto.jp.singcolor.LiveEventFinishSing.prototype.setSong = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventFinishSing} returns this
 */
proto.jp.singcolor.LiveEventFinishSing.prototype.clearSong = function() {
  return this.setSong(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventFinishSing.prototype.hasSong = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ReactionGiftButton reaction_gift_button = 2;
 * @return {?proto.jp.singcolor.ReactionGiftButton}
 */
proto.jp.singcolor.LiveEventFinishSing.prototype.getReactionGiftButton = function() {
  return /** @type{?proto.jp.singcolor.ReactionGiftButton} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.ReactionGiftButton, 2));
};


/**
 * @param {?proto.jp.singcolor.ReactionGiftButton|undefined} value
 * @return {!proto.jp.singcolor.LiveEventFinishSing} returns this
*/
proto.jp.singcolor.LiveEventFinishSing.prototype.setReactionGiftButton = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventFinishSing} returns this
 */
proto.jp.singcolor.LiveEventFinishSing.prototype.clearReactionGiftButton = function() {
  return this.setReactionGiftButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventFinishSing.prototype.hasReactionGiftButton = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventRequestSing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventRequestSing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventRequestSing.toObject = function(includeInstance, msg) {
  var f, obj = {
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
    song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
    termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
    monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
    fanType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    songYellBadge: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isBeginner: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventRequestSing}
 */
proto.jp.singcolor.LiveEventRequestSing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventRequestSing;
  return proto.jp.singcolor.LiveEventRequestSing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventRequestSing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventRequestSing}
 */
proto.jp.singcolor.LiveEventRequestSing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    case 2:
      var value = new karaoke_entity_pb.Song;
      reader.readMessage(value,karaoke_entity_pb.Song.deserializeBinaryFromReader);
      msg.setSong(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTermlyFavePoints(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlyFavePoints(value);
      break;
    case 5:
      var value = /** @type {!proto.jp.singcolor.FanType} */ (reader.readEnum());
      msg.setFanType(value);
      break;
    case 6:
      var value = /** @type {!proto.jp.singcolor.SongYellBadge} */ (reader.readEnum());
      msg.setSongYellBadge(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBeginner(value);
      break;
    case 8:
      var value = new users_entity_pb.ListenerMembershipInfo;
      reader.readMessage(value,users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
      msg.setListenerMembershipInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventRequestSing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventRequestSing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventRequestSing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getSong();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      karaoke_entity_pb.Song.serializeBinaryToWriter
    );
  }
  f = message.getTermlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMonthlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getFanType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSongYellBadge();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getIsBeginner();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getListenerMembershipInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional User listener = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventRequestSing} returns this
*/
proto.jp.singcolor.LiveEventRequestSing.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventRequestSing} returns this
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.hasListener = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Song song = 2;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.getSong = function() {
  return /** @type{?proto.jp.singcolor.Song} */ (
    jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 2));
};


/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.LiveEventRequestSing} returns this
*/
proto.jp.singcolor.LiveEventRequestSing.prototype.setSong = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventRequestSing} returns this
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.clearSong = function() {
  return this.setSong(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.hasSong = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 termly_fave_points = 3;
 * @return {number}
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.getTermlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventRequestSing} returns this
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.setTermlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 monthly_fave_points = 4;
 * @return {number}
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.getMonthlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventRequestSing} returns this
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.setMonthlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional FanType fan_type = 5;
 * @return {!proto.jp.singcolor.FanType}
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.getFanType = function() {
  return /** @type {!proto.jp.singcolor.FanType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.jp.singcolor.FanType} value
 * @return {!proto.jp.singcolor.LiveEventRequestSing} returns this
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.setFanType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional SongYellBadge song_yell_badge = 6;
 * @return {!proto.jp.singcolor.SongYellBadge}
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.getSongYellBadge = function() {
  return /** @type {!proto.jp.singcolor.SongYellBadge} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.jp.singcolor.SongYellBadge} value
 * @return {!proto.jp.singcolor.LiveEventRequestSing} returns this
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.setSongYellBadge = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool is_beginner = 7;
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.getIsBeginner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LiveEventRequestSing} returns this
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.setIsBeginner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional ListenerMembershipInfo listener_membership_info = 8;
 * @return {?proto.jp.singcolor.ListenerMembershipInfo}
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.getListenerMembershipInfo = function() {
  return /** @type{?proto.jp.singcolor.ListenerMembershipInfo} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 8));
};


/**
 * @param {?proto.jp.singcolor.ListenerMembershipInfo|undefined} value
 * @return {!proto.jp.singcolor.LiveEventRequestSing} returns this
*/
proto.jp.singcolor.LiveEventRequestSing.prototype.setListenerMembershipInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventRequestSing} returns this
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.clearListenerMembershipInfo = function() {
  return this.setListenerMembershipInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventRequestSing.prototype.hasListenerMembershipInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.jp.singcolor.LiveEventGetFaveBonus.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.jp.singcolor.LiveEventGetFaveBonus.ValueCase = {
  VALUE_NOT_SET: 0,
  NUMBER_OF_SONG_FAVE: 2,
  FAVE_POINTS: 3
};

/**
 * @return {proto.jp.singcolor.LiveEventGetFaveBonus.ValueCase}
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.getValueCase = function() {
  return /** @type {proto.jp.singcolor.LiveEventGetFaveBonus.ValueCase} */(jspb.Message.computeOneofCase(this, proto.jp.singcolor.LiveEventGetFaveBonus.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventGetFaveBonus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventGetFaveBonus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventGetFaveBonus.toObject = function(includeInstance, msg) {
  var f, obj = {
    faveBonusRate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    numberOfSongFave: jspb.Message.getFieldWithDefault(msg, 2, 0),
    favePoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
    reactionGiftButton: (f = msg.getReactionGiftButton()) && proto.jp.singcolor.ReactionGiftButton.toObject(includeInstance, f),
    faveBonusTitle: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonus}
 */
proto.jp.singcolor.LiveEventGetFaveBonus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventGetFaveBonus;
  return proto.jp.singcolor.LiveEventGetFaveBonus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventGetFaveBonus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonus}
 */
proto.jp.singcolor.LiveEventGetFaveBonus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaveBonusRate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfSongFave(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFavePoints(value);
      break;
    case 4:
      var value = new proto.jp.singcolor.ReactionGiftButton;
      reader.readMessage(value,proto.jp.singcolor.ReactionGiftButton.deserializeBinaryFromReader);
      msg.setReactionGiftButton(value);
      break;
    case 5:
      var value = /** @type {!proto.jp.singcolor.FaveBonusTitle} */ (reader.readEnum());
      msg.setFaveBonusTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventGetFaveBonus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventGetFaveBonus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventGetFaveBonus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaveBonusRate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getReactionGiftButton();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.jp.singcolor.ReactionGiftButton.serializeBinaryToWriter
    );
  }
  f = message.getFaveBonusTitle();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string fave_bonus_rate = 1;
 * @return {string}
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.getFaveBonusRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonus} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.setFaveBonusRate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 number_of_song_fave = 2;
 * @return {number}
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.getNumberOfSongFave = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonus} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.setNumberOfSongFave = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.jp.singcolor.LiveEventGetFaveBonus.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonus} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.clearNumberOfSongFave = function() {
  return jspb.Message.setOneofField(this, 2, proto.jp.singcolor.LiveEventGetFaveBonus.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.hasNumberOfSongFave = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 fave_points = 3;
 * @return {number}
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.getFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonus} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.setFavePoints = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.jp.singcolor.LiveEventGetFaveBonus.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonus} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.clearFavePoints = function() {
  return jspb.Message.setOneofField(this, 3, proto.jp.singcolor.LiveEventGetFaveBonus.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.hasFavePoints = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ReactionGiftButton reaction_gift_button = 4;
 * @return {?proto.jp.singcolor.ReactionGiftButton}
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.getReactionGiftButton = function() {
  return /** @type{?proto.jp.singcolor.ReactionGiftButton} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.ReactionGiftButton, 4));
};


/**
 * @param {?proto.jp.singcolor.ReactionGiftButton|undefined} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonus} returns this
*/
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.setReactionGiftButton = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonus} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.clearReactionGiftButton = function() {
  return this.setReactionGiftButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.hasReactionGiftButton = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FaveBonusTitle fave_bonus_title = 5;
 * @return {!proto.jp.singcolor.FaveBonusTitle}
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.getFaveBonusTitle = function() {
  return /** @type {!proto.jp.singcolor.FaveBonusTitle} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.jp.singcolor.FaveBonusTitle} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonus} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBonus.prototype.setFaveBonusTitle = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventGetFaveBonusTitle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventGetFaveBonusTitle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.toObject = function(includeInstance, msg) {
  var f, obj = {
    liver: (f = msg.getLiver()) && users_entity_pb.User.toObject(includeInstance, f),
    faveBonusTitleRate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reactionGiftButton: (f = msg.getReactionGiftButton()) && proto.jp.singcolor.ReactionGiftButton.toObject(includeInstance, f),
    faveBonusTitle: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonusTitle}
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventGetFaveBonusTitle;
  return proto.jp.singcolor.LiveEventGetFaveBonusTitle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventGetFaveBonusTitle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonusTitle}
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setLiver(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaveBonusTitleRate(value);
      break;
    case 3:
      var value = new proto.jp.singcolor.ReactionGiftButton;
      reader.readMessage(value,proto.jp.singcolor.ReactionGiftButton.deserializeBinaryFromReader);
      msg.setReactionGiftButton(value);
      break;
    case 4:
      var value = /** @type {!proto.jp.singcolor.FaveBonusTitle} */ (reader.readEnum());
      msg.setFaveBonusTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventGetFaveBonusTitle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventGetFaveBonusTitle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiver();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getFaveBonusTitleRate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReactionGiftButton();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.jp.singcolor.ReactionGiftButton.serializeBinaryToWriter
    );
  }
  f = message.getFaveBonusTitle();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional User liver = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.prototype.getLiver = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonusTitle} returns this
*/
proto.jp.singcolor.LiveEventGetFaveBonusTitle.prototype.setLiver = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonusTitle} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.prototype.clearLiver = function() {
  return this.setLiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.prototype.hasLiver = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string fave_bonus_title_rate = 2;
 * @return {string}
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.prototype.getFaveBonusTitleRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonusTitle} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.prototype.setFaveBonusTitleRate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ReactionGiftButton reaction_gift_button = 3;
 * @return {?proto.jp.singcolor.ReactionGiftButton}
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.prototype.getReactionGiftButton = function() {
  return /** @type{?proto.jp.singcolor.ReactionGiftButton} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.ReactionGiftButton, 3));
};


/**
 * @param {?proto.jp.singcolor.ReactionGiftButton|undefined} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonusTitle} returns this
*/
proto.jp.singcolor.LiveEventGetFaveBonusTitle.prototype.setReactionGiftButton = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonusTitle} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.prototype.clearReactionGiftButton = function() {
  return this.setReactionGiftButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.prototype.hasReactionGiftButton = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FaveBonusTitle fave_bonus_title = 4;
 * @return {!proto.jp.singcolor.FaveBonusTitle}
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.prototype.getFaveBonusTitle = function() {
  return /** @type {!proto.jp.singcolor.FaveBonusTitle} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.jp.singcolor.FaveBonusTitle} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBonusTitle} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBonusTitle.prototype.setFaveBonusTitle = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventGetFavePoints.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventGetFavePoints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventGetFavePoints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventGetFavePoints.toObject = function(includeInstance, msg) {
  var f, obj = {
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
    favePoints: jspb.Message.getFieldWithDefault(msg, 2, 0),
    heartfulMentionButton: (f = msg.getHeartfulMentionButton()) && proto.jp.singcolor.HeartfulMentionButton.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventGetFavePoints}
 */
proto.jp.singcolor.LiveEventGetFavePoints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventGetFavePoints;
  return proto.jp.singcolor.LiveEventGetFavePoints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventGetFavePoints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventGetFavePoints}
 */
proto.jp.singcolor.LiveEventGetFavePoints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFavePoints(value);
      break;
    case 3:
      var value = new proto.jp.singcolor.HeartfulMentionButton;
      reader.readMessage(value,proto.jp.singcolor.HeartfulMentionButton.deserializeBinaryFromReader);
      msg.setHeartfulMentionButton(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventGetFavePoints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventGetFavePoints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventGetFavePoints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventGetFavePoints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getHeartfulMentionButton();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.jp.singcolor.HeartfulMentionButton.serializeBinaryToWriter
    );
  }
};


/**
 * optional User listener = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventGetFavePoints.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventGetFavePoints} returns this
*/
proto.jp.singcolor.LiveEventGetFavePoints.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventGetFavePoints} returns this
 */
proto.jp.singcolor.LiveEventGetFavePoints.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetFavePoints.prototype.hasListener = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 fave_points = 2;
 * @return {number}
 */
proto.jp.singcolor.LiveEventGetFavePoints.prototype.getFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventGetFavePoints} returns this
 */
proto.jp.singcolor.LiveEventGetFavePoints.prototype.setFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional HeartfulMentionButton heartful_mention_button = 3;
 * @return {?proto.jp.singcolor.HeartfulMentionButton}
 */
proto.jp.singcolor.LiveEventGetFavePoints.prototype.getHeartfulMentionButton = function() {
  return /** @type{?proto.jp.singcolor.HeartfulMentionButton} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.HeartfulMentionButton, 3));
};


/**
 * @param {?proto.jp.singcolor.HeartfulMentionButton|undefined} value
 * @return {!proto.jp.singcolor.LiveEventGetFavePoints} returns this
*/
proto.jp.singcolor.LiveEventGetFavePoints.prototype.setHeartfulMentionButton = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventGetFavePoints} returns this
 */
proto.jp.singcolor.LiveEventGetFavePoints.prototype.clearHeartfulMentionButton = function() {
  return this.setHeartfulMentionButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetFavePoints.prototype.hasHeartfulMentionButton = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventGetFaveBadge.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventGetFaveBadge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventGetFaveBadge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventGetFaveBadge.toObject = function(includeInstance, msg) {
  var f, obj = {
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
    termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isFirstFaveBadge: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadge}
 */
proto.jp.singcolor.LiveEventGetFaveBadge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventGetFaveBadge;
  return proto.jp.singcolor.LiveEventGetFaveBadge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventGetFaveBadge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadge}
 */
proto.jp.singcolor.LiveEventGetFaveBadge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTermlyFavePoints(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFirstFaveBadge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventGetFaveBadge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventGetFaveBadge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventGetFaveBadge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventGetFaveBadge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getTermlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getIsFirstFaveBadge();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional User listener = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventGetFaveBadge.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadge} returns this
*/
proto.jp.singcolor.LiveEventGetFaveBadge.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadge} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBadge.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetFaveBadge.prototype.hasListener = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 termly_fave_points = 2;
 * @return {number}
 */
proto.jp.singcolor.LiveEventGetFaveBadge.prototype.getTermlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadge} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBadge.prototype.setTermlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_first_fave_badge = 3;
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetFaveBadge.prototype.getIsFirstFaveBadge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadge} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBadge.prototype.setIsFirstFaveBadge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventGetSongFave.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventGetSongFave.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventGetSongFave} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventGetSongFave.toObject = function(includeInstance, msg) {
  var f, obj = {
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
    monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numberOfGettingSongFaveClass: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numberOfMonthsInRow: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventGetSongFave}
 */
proto.jp.singcolor.LiveEventGetSongFave.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventGetSongFave;
  return proto.jp.singcolor.LiveEventGetSongFave.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventGetSongFave} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventGetSongFave}
 */
proto.jp.singcolor.LiveEventGetSongFave.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlyFavePoints(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfGettingSongFaveClass(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfMonthsInRow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventGetSongFave.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventGetSongFave.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventGetSongFave} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventGetSongFave.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNumberOfGettingSongFaveClass();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getNumberOfMonthsInRow();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional User listener = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventGetSongFave.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventGetSongFave} returns this
*/
proto.jp.singcolor.LiveEventGetSongFave.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventGetSongFave} returns this
 */
proto.jp.singcolor.LiveEventGetSongFave.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetSongFave.prototype.hasListener = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 monthly_fave_points = 2;
 * @return {number}
 */
proto.jp.singcolor.LiveEventGetSongFave.prototype.getMonthlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventGetSongFave} returns this
 */
proto.jp.singcolor.LiveEventGetSongFave.prototype.setMonthlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 number_of_getting_song_fave_class = 3;
 * @return {number}
 */
proto.jp.singcolor.LiveEventGetSongFave.prototype.getNumberOfGettingSongFaveClass = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventGetSongFave} returns this
 */
proto.jp.singcolor.LiveEventGetSongFave.prototype.setNumberOfGettingSongFaveClass = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 number_of_months_in_row = 4;
 * @return {number}
 */
proto.jp.singcolor.LiveEventGetSongFave.prototype.getNumberOfMonthsInRow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventGetSongFave} returns this
 */
proto.jp.singcolor.LiveEventGetSongFave.prototype.setNumberOfMonthsInRow = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventFollow.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventFollow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventFollow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventFollow.toObject = function(includeInstance, msg) {
  var f, obj = {
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
    heartfulMentionButton: (f = msg.getHeartfulMentionButton()) && proto.jp.singcolor.HeartfulMentionButton.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventFollow}
 */
proto.jp.singcolor.LiveEventFollow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventFollow;
  return proto.jp.singcolor.LiveEventFollow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventFollow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventFollow}
 */
proto.jp.singcolor.LiveEventFollow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.HeartfulMentionButton;
      reader.readMessage(value,proto.jp.singcolor.HeartfulMentionButton.deserializeBinaryFromReader);
      msg.setHeartfulMentionButton(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventFollow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventFollow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventFollow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventFollow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getHeartfulMentionButton();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.HeartfulMentionButton.serializeBinaryToWriter
    );
  }
};


/**
 * optional User listener = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventFollow.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventFollow} returns this
*/
proto.jp.singcolor.LiveEventFollow.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventFollow} returns this
 */
proto.jp.singcolor.LiveEventFollow.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventFollow.prototype.hasListener = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional HeartfulMentionButton heartful_mention_button = 2;
 * @return {?proto.jp.singcolor.HeartfulMentionButton}
 */
proto.jp.singcolor.LiveEventFollow.prototype.getHeartfulMentionButton = function() {
  return /** @type{?proto.jp.singcolor.HeartfulMentionButton} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.HeartfulMentionButton, 2));
};


/**
 * @param {?proto.jp.singcolor.HeartfulMentionButton|undefined} value
 * @return {!proto.jp.singcolor.LiveEventFollow} returns this
*/
proto.jp.singcolor.LiveEventFollow.prototype.setHeartfulMentionButton = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventFollow} returns this
 */
proto.jp.singcolor.LiveEventFollow.prototype.clearHeartfulMentionButton = function() {
  return this.setHeartfulMentionButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventFollow.prototype.hasHeartfulMentionButton = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventLike.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventLike.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventLike} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventLike.toObject = function(includeInstance, msg) {
  var f, obj = {
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventLike}
 */
proto.jp.singcolor.LiveEventLike.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventLike;
  return proto.jp.singcolor.LiveEventLike.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventLike} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventLike}
 */
proto.jp.singcolor.LiveEventLike.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventLike.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventLike.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventLike} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventLike.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User listener = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventLike.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventLike} returns this
*/
proto.jp.singcolor.LiveEventLike.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventLike} returns this
 */
proto.jp.singcolor.LiveEventLike.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventLike.prototype.hasListener = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventSuperLike.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventSuperLike.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventSuperLike} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventSuperLike.toObject = function(includeInstance, msg) {
  var f, obj = {
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
    dailyCountToLiver: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventSuperLike}
 */
proto.jp.singcolor.LiveEventSuperLike.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventSuperLike;
  return proto.jp.singcolor.LiveEventSuperLike.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventSuperLike} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventSuperLike}
 */
proto.jp.singcolor.LiveEventSuperLike.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDailyCountToLiver(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventSuperLike.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventSuperLike.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventSuperLike} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventSuperLike.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getDailyCountToLiver();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional User listener = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventSuperLike.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventSuperLike} returns this
*/
proto.jp.singcolor.LiveEventSuperLike.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventSuperLike} returns this
 */
proto.jp.singcolor.LiveEventSuperLike.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventSuperLike.prototype.hasListener = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 daily_count_to_liver = 2;
 * @return {number}
 */
proto.jp.singcolor.LiveEventSuperLike.prototype.getDailyCountToLiver = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventSuperLike} returns this
 */
proto.jp.singcolor.LiveEventSuperLike.prototype.setDailyCountToLiver = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.LiveEventAnnounce.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventAnnounce.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventAnnounce.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventAnnounce} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventAnnounce.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    invisibleUserIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    visibleUserIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventAnnounce}
 */
proto.jp.singcolor.LiveEventAnnounce.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventAnnounce;
  return proto.jp.singcolor.LiveEventAnnounce.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventAnnounce} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventAnnounce}
 */
proto.jp.singcolor.LiveEventAnnounce.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {!proto.jp.singcolor.LiveEventAnnounce.AnnounceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addInvisibleUserIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addVisibleUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventAnnounce.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventAnnounce.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventAnnounce} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventAnnounce.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getInvisibleUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getVisibleUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.jp.singcolor.LiveEventAnnounce.AnnounceType = {
  ANNOUNCE_TYPE_NORMAL: 0,
  ANNOUNCE_TYPE_WARN: 1,
  ANNOUNCE_TYPE_ALERT: 2
};

/**
 * optional string message = 1;
 * @return {string}
 */
proto.jp.singcolor.LiveEventAnnounce.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventAnnounce} returns this
 */
proto.jp.singcolor.LiveEventAnnounce.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AnnounceType type = 2;
 * @return {!proto.jp.singcolor.LiveEventAnnounce.AnnounceType}
 */
proto.jp.singcolor.LiveEventAnnounce.prototype.getType = function() {
  return /** @type {!proto.jp.singcolor.LiveEventAnnounce.AnnounceType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.jp.singcolor.LiveEventAnnounce.AnnounceType} value
 * @return {!proto.jp.singcolor.LiveEventAnnounce} returns this
 */
proto.jp.singcolor.LiveEventAnnounce.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated string invisible_user_ids = 3;
 * @return {!Array<string>}
 */
proto.jp.singcolor.LiveEventAnnounce.prototype.getInvisibleUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.LiveEventAnnounce} returns this
 */
proto.jp.singcolor.LiveEventAnnounce.prototype.setInvisibleUserIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiveEventAnnounce} returns this
 */
proto.jp.singcolor.LiveEventAnnounce.prototype.addInvisibleUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.LiveEventAnnounce} returns this
 */
proto.jp.singcolor.LiveEventAnnounce.prototype.clearInvisibleUserIdsList = function() {
  return this.setInvisibleUserIdsList([]);
};


/**
 * repeated string visible_user_ids = 4;
 * @return {!Array<string>}
 */
proto.jp.singcolor.LiveEventAnnounce.prototype.getVisibleUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.LiveEventAnnounce} returns this
 */
proto.jp.singcolor.LiveEventAnnounce.prototype.setVisibleUserIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiveEventAnnounce} returns this
 */
proto.jp.singcolor.LiveEventAnnounce.prototype.addVisibleUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.LiveEventAnnounce} returns this
 */
proto.jp.singcolor.LiveEventAnnounce.prototype.clearVisibleUserIdsList = function() {
  return this.setVisibleUserIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventSurgeRankingNo1Reward} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.toObject = function(includeInstance, msg) {
  var f, obj = {
    liverMessage: jspb.Message.getFieldWithDefault(msg, 1, ""),
    listenerMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reactionGiftButton: (f = msg.getReactionGiftButton()) && proto.jp.singcolor.ReactionGiftButton.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventSurgeRankingNo1Reward}
 */
proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventSurgeRankingNo1Reward;
  return proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventSurgeRankingNo1Reward} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventSurgeRankingNo1Reward}
 */
proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiverMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setListenerMessage(value);
      break;
    case 3:
      var value = new proto.jp.singcolor.ReactionGiftButton;
      reader.readMessage(value,proto.jp.singcolor.ReactionGiftButton.deserializeBinaryFromReader);
      msg.setReactionGiftButton(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventSurgeRankingNo1Reward} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiverMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getListenerMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReactionGiftButton();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.jp.singcolor.ReactionGiftButton.serializeBinaryToWriter
    );
  }
};


/**
 * optional string liver_message = 1;
 * @return {string}
 */
proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.prototype.getLiverMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventSurgeRankingNo1Reward} returns this
 */
proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.prototype.setLiverMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string listener_message = 2;
 * @return {string}
 */
proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.prototype.getListenerMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventSurgeRankingNo1Reward} returns this
 */
proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.prototype.setListenerMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ReactionGiftButton reaction_gift_button = 3;
 * @return {?proto.jp.singcolor.ReactionGiftButton}
 */
proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.prototype.getReactionGiftButton = function() {
  return /** @type{?proto.jp.singcolor.ReactionGiftButton} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.ReactionGiftButton, 3));
};


/**
 * @param {?proto.jp.singcolor.ReactionGiftButton|undefined} value
 * @return {!proto.jp.singcolor.LiveEventSurgeRankingNo1Reward} returns this
*/
proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.prototype.setReactionGiftButton = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventSurgeRankingNo1Reward} returns this
 */
proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.prototype.clearReactionGiftButton = function() {
  return this.setReactionGiftButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventSurgeRankingNo1Reward.prototype.hasReactionGiftButton = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventSimplePFComment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventSimplePFComment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventSimplePFComment.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    borderWidth: jspb.Message.getFieldWithDefault(msg, 3, 0),
    textColor: jspb.Message.getFieldWithDefault(msg, 4, ""),
    backgroundColor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    borderColor: jspb.Message.getFieldWithDefault(msg, 6, ""),
    textAlpha: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    backgroundAlpha: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    borderAlpha: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    reactionGiftButton: (f = msg.getReactionGiftButton()) && proto.jp.singcolor.ReactionGiftButton.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventSimplePFComment}
 */
proto.jp.singcolor.LiveEventSimplePFComment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventSimplePFComment;
  return proto.jp.singcolor.LiveEventSimplePFComment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventSimplePFComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventSimplePFComment}
 */
proto.jp.singcolor.LiveEventSimplePFComment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.jp.singcolor.LiveEventSimplePFCommentType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBorderWidth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextColor(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundColor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorderColor(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTextAlpha(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBackgroundAlpha(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBorderAlpha(value);
      break;
    case 10:
      var value = new proto.jp.singcolor.ReactionGiftButton;
      reader.readMessage(value,proto.jp.singcolor.ReactionGiftButton.deserializeBinaryFromReader);
      msg.setReactionGiftButton(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventSimplePFComment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventSimplePFComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventSimplePFComment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBorderWidth();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTextColor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBackgroundColor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBorderColor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTextAlpha();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getBackgroundAlpha();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getBorderAlpha();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getReactionGiftButton();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.jp.singcolor.ReactionGiftButton.serializeBinaryToWriter
    );
  }
};


/**
 * optional LiveEventSimplePFCommentType type = 1;
 * @return {!proto.jp.singcolor.LiveEventSimplePFCommentType}
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.getType = function() {
  return /** @type {!proto.jp.singcolor.LiveEventSimplePFCommentType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.jp.singcolor.LiveEventSimplePFCommentType} value
 * @return {!proto.jp.singcolor.LiveEventSimplePFComment} returns this
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventSimplePFComment} returns this
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 border_width = 3;
 * @return {number}
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.getBorderWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventSimplePFComment} returns this
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.setBorderWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string text_color = 4;
 * @return {string}
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.getTextColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventSimplePFComment} returns this
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.setTextColor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string background_color = 5;
 * @return {string}
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.getBackgroundColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventSimplePFComment} returns this
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.setBackgroundColor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string border_color = 6;
 * @return {string}
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.getBorderColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventSimplePFComment} returns this
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.setBorderColor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional float text_alpha = 7;
 * @return {number}
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.getTextAlpha = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventSimplePFComment} returns this
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.setTextAlpha = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float background_alpha = 8;
 * @return {number}
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.getBackgroundAlpha = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventSimplePFComment} returns this
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.setBackgroundAlpha = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float border_alpha = 9;
 * @return {number}
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.getBorderAlpha = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventSimplePFComment} returns this
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.setBorderAlpha = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional ReactionGiftButton reaction_gift_button = 10;
 * @return {?proto.jp.singcolor.ReactionGiftButton}
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.getReactionGiftButton = function() {
  return /** @type{?proto.jp.singcolor.ReactionGiftButton} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.ReactionGiftButton, 10));
};


/**
 * @param {?proto.jp.singcolor.ReactionGiftButton|undefined} value
 * @return {!proto.jp.singcolor.LiveEventSimplePFComment} returns this
*/
proto.jp.singcolor.LiveEventSimplePFComment.prototype.setReactionGiftButton = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventSimplePFComment} returns this
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.clearReactionGiftButton = function() {
  return this.setReactionGiftButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventSimplePFComment.prototype.hasReactionGiftButton = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.toObject = function(includeInstance, msg) {
  var f, obj = {
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
    getfavebadgeevent: (f = msg.getGetfavebadgeevent()) && proto.jp.singcolor.LiveEventGetFaveBadge.toObject(includeInstance, f),
    getsongfaveevent: (f = msg.getGetsongfaveevent()) && proto.jp.singcolor.LiveEventGetSongFave.toObject(includeInstance, f),
    heartfulMentionButton: (f = msg.getHeartfulMentionButton()) && proto.jp.singcolor.HeartfulMentionButton.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave}
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave;
  return proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave}
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.LiveEventGetFaveBadge;
      reader.readMessage(value,proto.jp.singcolor.LiveEventGetFaveBadge.deserializeBinaryFromReader);
      msg.setGetfavebadgeevent(value);
      break;
    case 3:
      var value = new proto.jp.singcolor.LiveEventGetSongFave;
      reader.readMessage(value,proto.jp.singcolor.LiveEventGetSongFave.deserializeBinaryFromReader);
      msg.setGetsongfaveevent(value);
      break;
    case 4:
      var value = new proto.jp.singcolor.HeartfulMentionButton;
      reader.readMessage(value,proto.jp.singcolor.HeartfulMentionButton.deserializeBinaryFromReader);
      msg.setHeartfulMentionButton(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getGetfavebadgeevent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.LiveEventGetFaveBadge.serializeBinaryToWriter
    );
  }
  f = message.getGetsongfaveevent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.jp.singcolor.LiveEventGetSongFave.serializeBinaryToWriter
    );
  }
  f = message.getHeartfulMentionButton();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.jp.singcolor.HeartfulMentionButton.serializeBinaryToWriter
    );
  }
};


/**
 * optional User listener = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave} returns this
*/
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.hasListener = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LiveEventGetFaveBadge getFaveBadgeEvent = 2;
 * @return {?proto.jp.singcolor.LiveEventGetFaveBadge}
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.getGetfavebadgeevent = function() {
  return /** @type{?proto.jp.singcolor.LiveEventGetFaveBadge} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventGetFaveBadge, 2));
};


/**
 * @param {?proto.jp.singcolor.LiveEventGetFaveBadge|undefined} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave} returns this
*/
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.setGetfavebadgeevent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.clearGetfavebadgeevent = function() {
  return this.setGetfavebadgeevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.hasGetfavebadgeevent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LiveEventGetSongFave getSongFaveEvent = 3;
 * @return {?proto.jp.singcolor.LiveEventGetSongFave}
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.getGetsongfaveevent = function() {
  return /** @type{?proto.jp.singcolor.LiveEventGetSongFave} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveEventGetSongFave, 3));
};


/**
 * @param {?proto.jp.singcolor.LiveEventGetSongFave|undefined} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave} returns this
*/
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.setGetsongfaveevent = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.clearGetsongfaveevent = function() {
  return this.setGetsongfaveevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.hasGetsongfaveevent = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional HeartfulMentionButton heartful_mention_button = 4;
 * @return {?proto.jp.singcolor.HeartfulMentionButton}
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.getHeartfulMentionButton = function() {
  return /** @type{?proto.jp.singcolor.HeartfulMentionButton} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.HeartfulMentionButton, 4));
};


/**
 * @param {?proto.jp.singcolor.HeartfulMentionButton|undefined} value
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave} returns this
*/
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.setHeartfulMentionButton = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave} returns this
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.clearHeartfulMentionButton = function() {
  return this.setHeartfulMentionButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetFaveBadgeAndSongFave.prototype.hasHeartfulMentionButton = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.Gift.repeatedFields_ = [16,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.Gift.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.Gift.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.Gift} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Gift.toObject = function(includeInstance, msg) {
  var f, obj = {
    giftId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    giftKind: jspb.Message.getFieldWithDefault(msg, 18, 0),
    giftType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    kana: jspb.Message.getFieldWithDefault(msg, 15, ""),
    suggestionWordsList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    suggestionSubWordsList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    coinAmount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    coin: (f = msg.getCoin()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f),
    coinAmountBigGift: (f = msg.getCoinAmountBigGift()) && proto.jp.singcolor.Gift.BigGiftCoinAmount.toObject(includeInstance, f),
    chainable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    assetPath: jspb.Message.getFieldWithDefault(msg, 6, ""),
    thumbnailPath: jspb.Message.getFieldWithDefault(msg, 7, ""),
    assetHash: jspb.Message.getFieldWithDefault(msg, 10, ""),
    thumbnailHash: jspb.Message.getFieldWithDefault(msg, 11, ""),
    downloadPriority: jspb.Message.getFieldWithDefault(msg, 12, 0),
    appBundle: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.Gift}
 */
proto.jp.singcolor.Gift.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.Gift;
  return proto.jp.singcolor.Gift.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.Gift} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.Gift}
 */
proto.jp.singcolor.Gift.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGiftId(value);
      break;
    case 18:
      var value = /** @type {!proto.jp.singcolor.GiftKind} */ (reader.readEnum());
      msg.setGiftKind(value);
      break;
    case 2:
      var value = /** @type {!proto.jp.singcolor.GiftType} */ (reader.readEnum());
      msg.setGiftType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setKana(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addSuggestionWords(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addSuggestionSubWords(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCoinAmount(value);
      break;
    case 19:
      var value = new coins_entity_pb.CoinAmount;
      reader.readMessage(value,coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
      msg.setCoin(value);
      break;
    case 14:
      var value = new proto.jp.singcolor.Gift.BigGiftCoinAmount;
      reader.readMessage(value,proto.jp.singcolor.Gift.BigGiftCoinAmount.deserializeBinaryFromReader);
      msg.setCoinAmountBigGift(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChainable(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetPath(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailPath(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetHash(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailHash(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDownloadPriority(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAppBundle(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.Gift.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.Gift.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.Gift} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Gift.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGiftId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGiftKind();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getGiftType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKana();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getSuggestionWordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = message.getSuggestionSubWordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
  f = message.getCoinAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCoin();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      coins_entity_pb.CoinAmount.serializeBinaryToWriter
    );
  }
  f = message.getCoinAmountBigGift();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.jp.singcolor.Gift.BigGiftCoinAmount.serializeBinaryToWriter
    );
  }
  f = message.getChainable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAssetPath();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getThumbnailPath();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAssetHash();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getThumbnailHash();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDownloadPriority();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getAppBundle();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.Gift.BigGiftCoinAmount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.Gift.BigGiftCoinAmount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.toObject = function(includeInstance, msg) {
  var f, obj = {
    coinAmountBig: jspb.Message.getFieldWithDefault(msg, 1, 0),
    coinBig: (f = msg.getCoinBig()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f),
    coinAmountBigHeart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    coinBigHeart: (f = msg.getCoinBigHeart()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.Gift.BigGiftCoinAmount}
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.Gift.BigGiftCoinAmount;
  return proto.jp.singcolor.Gift.BigGiftCoinAmount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.Gift.BigGiftCoinAmount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.Gift.BigGiftCoinAmount}
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCoinAmountBig(value);
      break;
    case 3:
      var value = new coins_entity_pb.CoinAmount;
      reader.readMessage(value,coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
      msg.setCoinBig(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCoinAmountBigHeart(value);
      break;
    case 4:
      var value = new coins_entity_pb.CoinAmount;
      reader.readMessage(value,coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
      msg.setCoinBigHeart(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.Gift.BigGiftCoinAmount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.Gift.BigGiftCoinAmount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoinAmountBig();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCoinBig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      coins_entity_pb.CoinAmount.serializeBinaryToWriter
    );
  }
  f = message.getCoinAmountBigHeart();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCoinBigHeart();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      coins_entity_pb.CoinAmount.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 coin_amount_big = 1;
 * @return {number}
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.prototype.getCoinAmountBig = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Gift.BigGiftCoinAmount} returns this
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.prototype.setCoinAmountBig = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CoinAmount coin_big = 3;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.prototype.getCoinBig = function() {
  return /** @type{?proto.jp.singcolor.CoinAmount} */ (
    jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 3));
};


/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.Gift.BigGiftCoinAmount} returns this
*/
proto.jp.singcolor.Gift.BigGiftCoinAmount.prototype.setCoinBig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.Gift.BigGiftCoinAmount} returns this
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.prototype.clearCoinBig = function() {
  return this.setCoinBig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.prototype.hasCoinBig = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 coin_amount_big_heart = 2;
 * @return {number}
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.prototype.getCoinAmountBigHeart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Gift.BigGiftCoinAmount} returns this
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.prototype.setCoinAmountBigHeart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional CoinAmount coin_big_heart = 4;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.prototype.getCoinBigHeart = function() {
  return /** @type{?proto.jp.singcolor.CoinAmount} */ (
    jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 4));
};


/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.Gift.BigGiftCoinAmount} returns this
*/
proto.jp.singcolor.Gift.BigGiftCoinAmount.prototype.setCoinBigHeart = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.Gift.BigGiftCoinAmount} returns this
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.prototype.clearCoinBigHeart = function() {
  return this.setCoinBigHeart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.Gift.BigGiftCoinAmount.prototype.hasCoinBigHeart = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string gift_id = 1;
 * @return {string}
 */
proto.jp.singcolor.Gift.prototype.getGiftId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setGiftId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GiftKind gift_kind = 18;
 * @return {!proto.jp.singcolor.GiftKind}
 */
proto.jp.singcolor.Gift.prototype.getGiftKind = function() {
  return /** @type {!proto.jp.singcolor.GiftKind} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.jp.singcolor.GiftKind} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setGiftKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional GiftType gift_type = 2;
 * @return {!proto.jp.singcolor.GiftType}
 */
proto.jp.singcolor.Gift.prototype.getGiftType = function() {
  return /** @type {!proto.jp.singcolor.GiftType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.jp.singcolor.GiftType} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setGiftType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.jp.singcolor.Gift.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string kana = 15;
 * @return {string}
 */
proto.jp.singcolor.Gift.prototype.getKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setKana = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated string suggestion_words = 16;
 * @return {!Array<string>}
 */
proto.jp.singcolor.Gift.prototype.getSuggestionWordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setSuggestionWordsList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.addSuggestionWords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.clearSuggestionWordsList = function() {
  return this.setSuggestionWordsList([]);
};


/**
 * repeated string suggestion_sub_words = 17;
 * @return {!Array<string>}
 */
proto.jp.singcolor.Gift.prototype.getSuggestionSubWordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setSuggestionSubWordsList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.addSuggestionSubWords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.clearSuggestionSubWordsList = function() {
  return this.setSuggestionSubWordsList([]);
};


/**
 * optional int64 coin_amount = 4;
 * @return {number}
 */
proto.jp.singcolor.Gift.prototype.getCoinAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setCoinAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional CoinAmount coin = 19;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.Gift.prototype.getCoin = function() {
  return /** @type{?proto.jp.singcolor.CoinAmount} */ (
    jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 19));
};


/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.Gift} returns this
*/
proto.jp.singcolor.Gift.prototype.setCoin = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.clearCoin = function() {
  return this.setCoin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.Gift.prototype.hasCoin = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional BigGiftCoinAmount coin_amount_big_gift = 14;
 * @return {?proto.jp.singcolor.Gift.BigGiftCoinAmount}
 */
proto.jp.singcolor.Gift.prototype.getCoinAmountBigGift = function() {
  return /** @type{?proto.jp.singcolor.Gift.BigGiftCoinAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.Gift.BigGiftCoinAmount, 14));
};


/**
 * @param {?proto.jp.singcolor.Gift.BigGiftCoinAmount|undefined} value
 * @return {!proto.jp.singcolor.Gift} returns this
*/
proto.jp.singcolor.Gift.prototype.setCoinAmountBigGift = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.clearCoinAmountBigGift = function() {
  return this.setCoinAmountBigGift(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.Gift.prototype.hasCoinAmountBigGift = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional bool chainable = 5;
 * @return {boolean}
 */
proto.jp.singcolor.Gift.prototype.getChainable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setChainable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string asset_path = 6;
 * @return {string}
 */
proto.jp.singcolor.Gift.prototype.getAssetPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setAssetPath = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string thumbnail_path = 7;
 * @return {string}
 */
proto.jp.singcolor.Gift.prototype.getThumbnailPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setThumbnailPath = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string asset_hash = 10;
 * @return {string}
 */
proto.jp.singcolor.Gift.prototype.getAssetHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setAssetHash = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string thumbnail_hash = 11;
 * @return {string}
 */
proto.jp.singcolor.Gift.prototype.getThumbnailHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setThumbnailHash = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 download_priority = 12;
 * @return {number}
 */
proto.jp.singcolor.Gift.prototype.getDownloadPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setDownloadPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool app_bundle = 13;
 * @return {boolean}
 */
proto.jp.singcolor.Gift.prototype.getAppBundle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setAppBundle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.jp.singcolor.Gift.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.jp.singcolor.Gift.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Gift} returns this
 */
proto.jp.singcolor.Gift.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GiftTab.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.GiftTab.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.GiftTab.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.GiftTab} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GiftTab.toObject = function(includeInstance, msg) {
  var f, obj = {
    giftTabType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isdefault: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    normalorderindex: jspb.Message.getFieldWithDefault(msg, 5, 0),
    singingorderindex: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sectionsList: jspb.Message.toObjectList(msg.getSectionsList(),
    proto.jp.singcolor.GiftTabSection.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GiftTab}
 */
proto.jp.singcolor.GiftTab.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.GiftTab;
  return proto.jp.singcolor.GiftTab.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GiftTab} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GiftTab}
 */
proto.jp.singcolor.GiftTab.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.jp.singcolor.GiftTabType} */ (reader.readEnum());
      msg.setGiftTabType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdefault(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNormalorderindex(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSingingorderindex(value);
      break;
    case 3:
      var value = new proto.jp.singcolor.GiftTabSection;
      reader.readMessage(value,proto.jp.singcolor.GiftTabSection.deserializeBinaryFromReader);
      msg.addSections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GiftTab.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.GiftTab.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GiftTab} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GiftTab.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGiftTabType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsdefault();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getNormalorderindex();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getSingingorderindex();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getSectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.jp.singcolor.GiftTabSection.serializeBinaryToWriter
    );
  }
};


/**
 * optional GiftTabType gift_tab_type = 1;
 * @return {!proto.jp.singcolor.GiftTabType}
 */
proto.jp.singcolor.GiftTab.prototype.getGiftTabType = function() {
  return /** @type {!proto.jp.singcolor.GiftTabType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.jp.singcolor.GiftTabType} value
 * @return {!proto.jp.singcolor.GiftTab} returns this
 */
proto.jp.singcolor.GiftTab.prototype.setGiftTabType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.jp.singcolor.GiftTab.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GiftTab} returns this
 */
proto.jp.singcolor.GiftTab.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isDefault = 4;
 * @return {boolean}
 */
proto.jp.singcolor.GiftTab.prototype.getIsdefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.GiftTab} returns this
 */
proto.jp.singcolor.GiftTab.prototype.setIsdefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 normalOrderIndex = 5;
 * @return {number}
 */
proto.jp.singcolor.GiftTab.prototype.getNormalorderindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GiftTab} returns this
 */
proto.jp.singcolor.GiftTab.prototype.setNormalorderindex = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 singingOrderIndex = 6;
 * @return {number}
 */
proto.jp.singcolor.GiftTab.prototype.getSingingorderindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GiftTab} returns this
 */
proto.jp.singcolor.GiftTab.prototype.setSingingorderindex = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated GiftTabSection sections = 3;
 * @return {!Array<!proto.jp.singcolor.GiftTabSection>}
 */
proto.jp.singcolor.GiftTab.prototype.getSectionsList = function() {
  return /** @type{!Array<!proto.jp.singcolor.GiftTabSection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.GiftTabSection, 3));
};


/**
 * @param {!Array<!proto.jp.singcolor.GiftTabSection>} value
 * @return {!proto.jp.singcolor.GiftTab} returns this
*/
proto.jp.singcolor.GiftTab.prototype.setSectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.jp.singcolor.GiftTabSection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.GiftTabSection}
 */
proto.jp.singcolor.GiftTab.prototype.addSections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.jp.singcolor.GiftTabSection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GiftTab} returns this
 */
proto.jp.singcolor.GiftTab.prototype.clearSectionsList = function() {
  return this.setSectionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.GiftTabSection.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.GiftTabSection.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.GiftTabSection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.GiftTabSection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GiftTabSection.toObject = function(includeInstance, msg) {
  var f, obj = {
    giftTabSectionType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    giftsList: jspb.Message.toObjectList(msg.getGiftsList(),
    proto.jp.singcolor.Gift.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GiftTabSection}
 */
proto.jp.singcolor.GiftTabSection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.GiftTabSection;
  return proto.jp.singcolor.GiftTabSection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GiftTabSection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GiftTabSection}
 */
proto.jp.singcolor.GiftTabSection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.jp.singcolor.GiftTabSectionType} */ (reader.readEnum());
      msg.setGiftTabSectionType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.jp.singcolor.Gift;
      reader.readMessage(value,proto.jp.singcolor.Gift.deserializeBinaryFromReader);
      msg.addGifts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GiftTabSection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.GiftTabSection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GiftTabSection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GiftTabSection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGiftTabSectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGiftsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.jp.singcolor.Gift.serializeBinaryToWriter
    );
  }
};


/**
 * optional GiftTabSectionType gift_tab_section_type = 1;
 * @return {!proto.jp.singcolor.GiftTabSectionType}
 */
proto.jp.singcolor.GiftTabSection.prototype.getGiftTabSectionType = function() {
  return /** @type {!proto.jp.singcolor.GiftTabSectionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.jp.singcolor.GiftTabSectionType} value
 * @return {!proto.jp.singcolor.GiftTabSection} returns this
 */
proto.jp.singcolor.GiftTabSection.prototype.setGiftTabSectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.jp.singcolor.GiftTabSection.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GiftTabSection} returns this
 */
proto.jp.singcolor.GiftTabSection.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Gift gifts = 3;
 * @return {!Array<!proto.jp.singcolor.Gift>}
 */
proto.jp.singcolor.GiftTabSection.prototype.getGiftsList = function() {
  return /** @type{!Array<!proto.jp.singcolor.Gift>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.Gift, 3));
};


/**
 * @param {!Array<!proto.jp.singcolor.Gift>} value
 * @return {!proto.jp.singcolor.GiftTabSection} returns this
*/
proto.jp.singcolor.GiftTabSection.prototype.setGiftsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.jp.singcolor.Gift=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Gift}
 */
proto.jp.singcolor.GiftTabSection.prototype.addGifts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.jp.singcolor.Gift, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.GiftTabSection} returns this
 */
proto.jp.singcolor.GiftTabSection.prototype.clearGiftsList = function() {
  return this.setGiftsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.EmojiComment.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.EmojiComment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.EmojiComment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.EmojiComment.toObject = function(includeInstance, msg) {
  var f, obj = {
    emojiCommentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 2, ""),
    coinAmount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.EmojiComment}
 */
proto.jp.singcolor.EmojiComment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.EmojiComment;
  return proto.jp.singcolor.EmojiComment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.EmojiComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.EmojiComment}
 */
proto.jp.singcolor.EmojiComment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmojiCommentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCoinAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.EmojiComment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.EmojiComment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.EmojiComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.EmojiComment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmojiCommentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCoinAmount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string emoji_comment_id = 1;
 * @return {string}
 */
proto.jp.singcolor.EmojiComment.prototype.getEmojiCommentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.EmojiComment} returns this
 */
proto.jp.singcolor.EmojiComment.prototype.setEmojiCommentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string icon = 2;
 * @return {string}
 */
proto.jp.singcolor.EmojiComment.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.EmojiComment} returns this
 */
proto.jp.singcolor.EmojiComment.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 coin_amount = 3;
 * @return {number}
 */
proto.jp.singcolor.EmojiComment.prototype.getCoinAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.EmojiComment} returns this
 */
proto.jp.singcolor.EmojiComment.prototype.setCoinAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.jp.singcolor.EmojiComment.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.EmojiComment} returns this
 */
proto.jp.singcolor.EmojiComment.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 updated_at = 5;
 * @return {number}
 */
proto.jp.singcolor.EmojiComment.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.EmojiComment} returns this
 */
proto.jp.singcolor.EmojiComment.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTab.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.ListFanInfoFaveBadgeTab.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.ListFanInfoFaveBadgeTab} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTab.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    termOfMonth: jspb.Message.getFieldWithDefault(msg, 3, 0),
    label: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTab}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTab.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.ListFanInfoFaveBadgeTab;
  return proto.jp.singcolor.ListFanInfoFaveBadgeTab.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFanInfoFaveBadgeTab} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTab}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTab.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {!proto.jp.singcolor.TermOfMonth} */ (reader.readEnum());
      msg.setTermOfMonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTab.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.ListFanInfoFaveBadgeTab.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFanInfoFaveBadgeTab} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTab.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTermOfMonth();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTab.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTab} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTab.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTab.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTab} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTab.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional TermOfMonth term_of_month = 3;
 * @return {!proto.jp.singcolor.TermOfMonth}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTab.prototype.getTermOfMonth = function() {
  return /** @type {!proto.jp.singcolor.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.jp.singcolor.TermOfMonth} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTab} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTab.prototype.setTermOfMonth = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string label = 4;
 * @return {string}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTab.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeTab} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeTab.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.ListFanInfoFaveBadgeUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.ListFanInfoFaveBadgeUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f),
    userFollow: (f = msg.getUserFollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
    fanType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
    monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 5, 0),
    listenerSongFaveThumbnail: (f = msg.getListenerSongFaveThumbnail()) && users_entity_pb.ListenerSongFaveThumbnail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeUser}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.ListFanInfoFaveBadgeUser;
  return proto.jp.singcolor.ListFanInfoFaveBadgeUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFanInfoFaveBadgeUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeUser}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.UserItem;
      reader.readMessage(value,users_entity_pb.UserItem.deserializeBinaryFromReader);
      msg.setUserItem(value);
      break;
    case 2:
      var value = new users_entity_pb.UserFollow;
      reader.readMessage(value,users_entity_pb.UserFollow.deserializeBinaryFromReader);
      msg.setUserFollow(value);
      break;
    case 3:
      var value = /** @type {!proto.jp.singcolor.FanType} */ (reader.readEnum());
      msg.setFanType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTermlyFavePoints(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlyFavePoints(value);
      break;
    case 6:
      var value = new users_entity_pb.ListenerSongFaveThumbnail;
      reader.readMessage(value,users_entity_pb.ListenerSongFaveThumbnail.deserializeBinaryFromReader);
      msg.setListenerSongFaveThumbnail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.ListFanInfoFaveBadgeUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFanInfoFaveBadgeUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.UserItem.serializeBinaryToWriter
    );
  }
  f = message.getUserFollow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.UserFollow.serializeBinaryToWriter
    );
  }
  f = message.getFanType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTermlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMonthlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getListenerSongFaveThumbnail();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      users_entity_pb.ListenerSongFaveThumbnail.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserItem user_item = 1;
 * @return {?proto.jp.singcolor.UserItem}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.getUserItem = function() {
  return /** @type{?proto.jp.singcolor.UserItem} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 1));
};


/**
 * @param {?proto.jp.singcolor.UserItem|undefined} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeUser} returns this
*/
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.setUserItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeUser} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.clearUserItem = function() {
  return this.setUserItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.hasUserItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserFollow user_follow = 2;
 * @return {?proto.jp.singcolor.UserFollow}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.getUserFollow = function() {
  return /** @type{?proto.jp.singcolor.UserFollow} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 2));
};


/**
 * @param {?proto.jp.singcolor.UserFollow|undefined} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeUser} returns this
*/
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.setUserFollow = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeUser} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.clearUserFollow = function() {
  return this.setUserFollow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.hasUserFollow = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FanType fan_type = 3;
 * @return {!proto.jp.singcolor.FanType}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.getFanType = function() {
  return /** @type {!proto.jp.singcolor.FanType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.jp.singcolor.FanType} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeUser} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.setFanType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 termly_fave_points = 4;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.getTermlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeUser} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.setTermlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 monthly_fave_points = 5;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.getMonthlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeUser} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.setMonthlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional ListenerSongFaveThumbnail listener_song_fave_thumbnail = 6;
 * @return {?proto.jp.singcolor.ListenerSongFaveThumbnail}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.getListenerSongFaveThumbnail = function() {
  return /** @type{?proto.jp.singcolor.ListenerSongFaveThumbnail} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.ListenerSongFaveThumbnail, 6));
};


/**
 * @param {?proto.jp.singcolor.ListenerSongFaveThumbnail|undefined} value
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeUser} returns this
*/
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.setListenerSongFaveThumbnail = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListFanInfoFaveBadgeUser} returns this
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.clearListenerSongFaveThumbnail = function() {
  return this.setListenerSongFaveThumbnail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListFanInfoFaveBadgeUser.prototype.hasListenerSongFaveThumbnail = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.ListFanInfoSongFaveTab.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.ListFanInfoSongFaveTab.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.ListFanInfoSongFaveTab} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFanInfoSongFaveTab.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    label: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTab}
 */
proto.jp.singcolor.ListFanInfoSongFaveTab.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.ListFanInfoSongFaveTab;
  return proto.jp.singcolor.ListFanInfoSongFaveTab.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFanInfoSongFaveTab} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTab}
 */
proto.jp.singcolor.ListFanInfoSongFaveTab.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFanInfoSongFaveTab.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.ListFanInfoSongFaveTab.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFanInfoSongFaveTab} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFanInfoSongFaveTab.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoSongFaveTab.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTab} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTab.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoSongFaveTab.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTab} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTab.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.jp.singcolor.ListFanInfoSongFaveTab.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveTab} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveTab.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.ListFanInfoSongFaveUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.ListFanInfoSongFaveUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f),
    userFollow: (f = msg.getUserFollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
    fanType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
    monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 5, 0),
    listenerSongFaveThumbnail: (f = msg.getListenerSongFaveThumbnail()) && users_entity_pb.ListenerSongFaveThumbnail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveUser}
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.ListFanInfoSongFaveUser;
  return proto.jp.singcolor.ListFanInfoSongFaveUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListFanInfoSongFaveUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveUser}
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.UserItem;
      reader.readMessage(value,users_entity_pb.UserItem.deserializeBinaryFromReader);
      msg.setUserItem(value);
      break;
    case 2:
      var value = new users_entity_pb.UserFollow;
      reader.readMessage(value,users_entity_pb.UserFollow.deserializeBinaryFromReader);
      msg.setUserFollow(value);
      break;
    case 3:
      var value = /** @type {!proto.jp.singcolor.FanType} */ (reader.readEnum());
      msg.setFanType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTermlyFavePoints(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlyFavePoints(value);
      break;
    case 6:
      var value = new users_entity_pb.ListenerSongFaveThumbnail;
      reader.readMessage(value,users_entity_pb.ListenerSongFaveThumbnail.deserializeBinaryFromReader);
      msg.setListenerSongFaveThumbnail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.ListFanInfoSongFaveUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListFanInfoSongFaveUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.UserItem.serializeBinaryToWriter
    );
  }
  f = message.getUserFollow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.UserFollow.serializeBinaryToWriter
    );
  }
  f = message.getFanType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTermlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMonthlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getListenerSongFaveThumbnail();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      users_entity_pb.ListenerSongFaveThumbnail.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserItem user_item = 1;
 * @return {?proto.jp.singcolor.UserItem}
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.getUserItem = function() {
  return /** @type{?proto.jp.singcolor.UserItem} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 1));
};


/**
 * @param {?proto.jp.singcolor.UserItem|undefined} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveUser} returns this
*/
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.setUserItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveUser} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.clearUserItem = function() {
  return this.setUserItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.hasUserItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserFollow user_follow = 2;
 * @return {?proto.jp.singcolor.UserFollow}
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.getUserFollow = function() {
  return /** @type{?proto.jp.singcolor.UserFollow} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 2));
};


/**
 * @param {?proto.jp.singcolor.UserFollow|undefined} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveUser} returns this
*/
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.setUserFollow = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveUser} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.clearUserFollow = function() {
  return this.setUserFollow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.hasUserFollow = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FanType fan_type = 3;
 * @return {!proto.jp.singcolor.FanType}
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.getFanType = function() {
  return /** @type {!proto.jp.singcolor.FanType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.jp.singcolor.FanType} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveUser} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.setFanType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 termly_fave_points = 4;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.getTermlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveUser} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.setTermlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 monthly_fave_points = 5;
 * @return {number}
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.getMonthlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveUser} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.setMonthlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional ListenerSongFaveThumbnail listener_song_fave_thumbnail = 6;
 * @return {?proto.jp.singcolor.ListenerSongFaveThumbnail}
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.getListenerSongFaveThumbnail = function() {
  return /** @type{?proto.jp.singcolor.ListenerSongFaveThumbnail} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.ListenerSongFaveThumbnail, 6));
};


/**
 * @param {?proto.jp.singcolor.ListenerSongFaveThumbnail|undefined} value
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveUser} returns this
*/
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.setListenerSongFaveThumbnail = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListFanInfoSongFaveUser} returns this
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.clearListenerSongFaveThumbnail = function() {
  return this.setListenerSongFaveThumbnail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListFanInfoSongFaveUser.prototype.hasListenerSongFaveThumbnail = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.PfFavePointsRankingTerm.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.PfFavePointsRankingTerm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.PfFavePointsRankingTerm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PfFavePointsRankingTerm.toObject = function(includeInstance, msg) {
  var f, obj = {
    month: jspb.Message.getFieldWithDefault(msg, 1, 0),
    termOfMonth: jspb.Message.getFieldWithDefault(msg, 2, 0),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    day: jspb.Message.getFieldWithDefault(msg, 4, 0),
    periodTimestamp: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PfFavePointsRankingTerm}
 */
proto.jp.singcolor.PfFavePointsRankingTerm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.PfFavePointsRankingTerm;
  return proto.jp.singcolor.PfFavePointsRankingTerm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PfFavePointsRankingTerm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PfFavePointsRankingTerm}
 */
proto.jp.singcolor.PfFavePointsRankingTerm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonth(value);
      break;
    case 2:
      var value = /** @type {!proto.jp.singcolor.TermOfMonth} */ (reader.readEnum());
      msg.setTermOfMonth(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDay(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPeriodTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PfFavePointsRankingTerm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.PfFavePointsRankingTerm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PfFavePointsRankingTerm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PfFavePointsRankingTerm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTermOfMonth();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPeriodTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 month = 1;
 * @return {number}
 */
proto.jp.singcolor.PfFavePointsRankingTerm.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PfFavePointsRankingTerm} returns this
 */
proto.jp.singcolor.PfFavePointsRankingTerm.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional TermOfMonth term_of_month = 2;
 * @return {!proto.jp.singcolor.TermOfMonth}
 */
proto.jp.singcolor.PfFavePointsRankingTerm.prototype.getTermOfMonth = function() {
  return /** @type {!proto.jp.singcolor.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.jp.singcolor.TermOfMonth} value
 * @return {!proto.jp.singcolor.PfFavePointsRankingTerm} returns this
 */
proto.jp.singcolor.PfFavePointsRankingTerm.prototype.setTermOfMonth = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.jp.singcolor.PfFavePointsRankingTerm.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PfFavePointsRankingTerm} returns this
 */
proto.jp.singcolor.PfFavePointsRankingTerm.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 day = 4;
 * @return {number}
 */
proto.jp.singcolor.PfFavePointsRankingTerm.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PfFavePointsRankingTerm} returns this
 */
proto.jp.singcolor.PfFavePointsRankingTerm.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 period_timestamp = 5;
 * @return {number}
 */
proto.jp.singcolor.PfFavePointsRankingTerm.prototype.getPeriodTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PfFavePointsRankingTerm} returns this
 */
proto.jp.singcolor.PfFavePointsRankingTerm.prototype.setPeriodTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.PfFavePointsRankingUser.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.PfFavePointsRankingUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.PfFavePointsRankingUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PfFavePointsRankingUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f),
    userFollow: (f = msg.getUserFollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
    rankingOrder: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pfFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
    hidden: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PfFavePointsRankingUser}
 */
proto.jp.singcolor.PfFavePointsRankingUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.PfFavePointsRankingUser;
  return proto.jp.singcolor.PfFavePointsRankingUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PfFavePointsRankingUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PfFavePointsRankingUser}
 */
proto.jp.singcolor.PfFavePointsRankingUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.UserItem;
      reader.readMessage(value,users_entity_pb.UserItem.deserializeBinaryFromReader);
      msg.setUserItem(value);
      break;
    case 2:
      var value = new users_entity_pb.UserFollow;
      reader.readMessage(value,users_entity_pb.UserFollow.deserializeBinaryFromReader);
      msg.setUserFollow(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRankingOrder(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPfFavePoints(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHidden(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PfFavePointsRankingUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.PfFavePointsRankingUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PfFavePointsRankingUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PfFavePointsRankingUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.UserItem.serializeBinaryToWriter
    );
  }
  f = message.getUserFollow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.UserFollow.serializeBinaryToWriter
    );
  }
  f = message.getRankingOrder();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPfFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getHidden();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional UserItem user_item = 1;
 * @return {?proto.jp.singcolor.UserItem}
 */
proto.jp.singcolor.PfFavePointsRankingUser.prototype.getUserItem = function() {
  return /** @type{?proto.jp.singcolor.UserItem} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 1));
};


/**
 * @param {?proto.jp.singcolor.UserItem|undefined} value
 * @return {!proto.jp.singcolor.PfFavePointsRankingUser} returns this
*/
proto.jp.singcolor.PfFavePointsRankingUser.prototype.setUserItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PfFavePointsRankingUser} returns this
 */
proto.jp.singcolor.PfFavePointsRankingUser.prototype.clearUserItem = function() {
  return this.setUserItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PfFavePointsRankingUser.prototype.hasUserItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserFollow user_follow = 2;
 * @return {?proto.jp.singcolor.UserFollow}
 */
proto.jp.singcolor.PfFavePointsRankingUser.prototype.getUserFollow = function() {
  return /** @type{?proto.jp.singcolor.UserFollow} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 2));
};


/**
 * @param {?proto.jp.singcolor.UserFollow|undefined} value
 * @return {!proto.jp.singcolor.PfFavePointsRankingUser} returns this
*/
proto.jp.singcolor.PfFavePointsRankingUser.prototype.setUserFollow = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PfFavePointsRankingUser} returns this
 */
proto.jp.singcolor.PfFavePointsRankingUser.prototype.clearUserFollow = function() {
  return this.setUserFollow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PfFavePointsRankingUser.prototype.hasUserFollow = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 ranking_order = 3;
 * @return {number}
 */
proto.jp.singcolor.PfFavePointsRankingUser.prototype.getRankingOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PfFavePointsRankingUser} returns this
 */
proto.jp.singcolor.PfFavePointsRankingUser.prototype.setRankingOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 pf_fave_points = 4;
 * @return {number}
 */
proto.jp.singcolor.PfFavePointsRankingUser.prototype.getPfFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PfFavePointsRankingUser} returns this
 */
proto.jp.singcolor.PfFavePointsRankingUser.prototype.setPfFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool hidden = 5;
 * @return {boolean}
 */
proto.jp.singcolor.PfFavePointsRankingUser.prototype.getHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.PfFavePointsRankingUser} returns this
 */
proto.jp.singcolor.PfFavePointsRankingUser.prototype.setHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveResultFavePoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveResultFavePoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveResultFavePoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    month: jspb.Message.getFieldWithDefault(msg, 1, 0),
    monthlyReceivedFavePoints: jspb.Message.getFieldWithDefault(msg, 2, 0),
    monthlyReceivedFavePointsDiff: jspb.Message.getFieldWithDefault(msg, 3, 0),
    monthlyReceivedFavePointsNext: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numberOfMonthlySongFaves: jspb.Message.getFieldWithDefault(msg, 5, 0),
    numberOfMonthlySongFavesDiff: jspb.Message.getFieldWithDefault(msg, 6, 0),
    numberOfMonthlySongFavesNext: jspb.Message.getFieldWithDefault(msg, 7, 0),
    faveBonusRate: jspb.Message.getFieldWithDefault(msg, 8, ""),
    faveBonusRateDiff: jspb.Message.getFieldWithDefault(msg, 9, ""),
    faveBonusTitleRate: jspb.Message.getFieldWithDefault(msg, 10, ""),
    faveBonusTitle: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveResultFavePoint}
 */
proto.jp.singcolor.LiveResultFavePoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveResultFavePoint;
  return proto.jp.singcolor.LiveResultFavePoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveResultFavePoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveResultFavePoint}
 */
proto.jp.singcolor.LiveResultFavePoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlyReceivedFavePoints(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlyReceivedFavePointsDiff(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlyReceivedFavePointsNext(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfMonthlySongFaves(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfMonthlySongFavesDiff(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfMonthlySongFavesNext(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaveBonusRate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaveBonusRateDiff(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaveBonusTitleRate(value);
      break;
    case 11:
      var value = /** @type {!proto.jp.singcolor.FaveBonusTitle} */ (reader.readEnum());
      msg.setFaveBonusTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveResultFavePoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveResultFavePoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveResultFavePoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMonthlyReceivedFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMonthlyReceivedFavePointsDiff();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMonthlyReceivedFavePointsNext();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNumberOfMonthlySongFaves();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getNumberOfMonthlySongFavesDiff();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getNumberOfMonthlySongFavesNext();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getFaveBonusRate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFaveBonusRateDiff();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFaveBonusTitleRate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFaveBonusTitle();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional int64 month = 1;
 * @return {number}
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveResultFavePoint} returns this
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 monthly_received_fave_points = 2;
 * @return {number}
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.getMonthlyReceivedFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveResultFavePoint} returns this
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.setMonthlyReceivedFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 monthly_received_fave_points_diff = 3;
 * @return {number}
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.getMonthlyReceivedFavePointsDiff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveResultFavePoint} returns this
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.setMonthlyReceivedFavePointsDiff = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 monthly_received_fave_points_next = 4;
 * @return {number}
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.getMonthlyReceivedFavePointsNext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveResultFavePoint} returns this
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.setMonthlyReceivedFavePointsNext = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 number_of_monthly_song_faves = 5;
 * @return {number}
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.getNumberOfMonthlySongFaves = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveResultFavePoint} returns this
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.setNumberOfMonthlySongFaves = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 number_of_monthly_song_faves_diff = 6;
 * @return {number}
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.getNumberOfMonthlySongFavesDiff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveResultFavePoint} returns this
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.setNumberOfMonthlySongFavesDiff = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 number_of_monthly_song_faves_next = 7;
 * @return {number}
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.getNumberOfMonthlySongFavesNext = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveResultFavePoint} returns this
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.setNumberOfMonthlySongFavesNext = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string fave_bonus_rate = 8;
 * @return {string}
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.getFaveBonusRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveResultFavePoint} returns this
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.setFaveBonusRate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string fave_bonus_rate_diff = 9;
 * @return {string}
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.getFaveBonusRateDiff = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveResultFavePoint} returns this
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.setFaveBonusRateDiff = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string fave_bonus_title_rate = 10;
 * @return {string}
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.getFaveBonusTitleRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveResultFavePoint} returns this
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.setFaveBonusTitleRate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional FaveBonusTitle fave_bonus_title = 11;
 * @return {!proto.jp.singcolor.FaveBonusTitle}
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.getFaveBonusTitle = function() {
  return /** @type {!proto.jp.singcolor.FaveBonusTitle} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.jp.singcolor.FaveBonusTitle} value
 * @return {!proto.jp.singcolor.LiveResultFavePoint} returns this
 */
proto.jp.singcolor.LiveResultFavePoint.prototype.setFaveBonusTitle = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.LiveResultGiftListener.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveResultGiftListener.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveResultGiftListener.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveResultGiftListener} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveResultGiftListener.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
    termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 2, 0),
    monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fanType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    giftCountsList: jspb.Message.toObjectList(msg.getGiftCountsList(),
    proto.jp.singcolor.LiveResultGiftCount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveResultGiftListener}
 */
proto.jp.singcolor.LiveResultGiftListener.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveResultGiftListener;
  return proto.jp.singcolor.LiveResultGiftListener.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveResultGiftListener} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveResultGiftListener}
 */
proto.jp.singcolor.LiveResultGiftListener.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTermlyFavePoints(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlyFavePoints(value);
      break;
    case 4:
      var value = /** @type {!proto.jp.singcolor.FanType} */ (reader.readEnum());
      msg.setFanType(value);
      break;
    case 5:
      var value = new proto.jp.singcolor.LiveResultGiftCount;
      reader.readMessage(value,proto.jp.singcolor.LiveResultGiftCount.deserializeBinaryFromReader);
      msg.addGiftCounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveResultGiftListener.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveResultGiftListener.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveResultGiftListener} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveResultGiftListener.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getTermlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMonthlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getFanType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getGiftCountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.jp.singcolor.LiveResultGiftCount.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveResultGiftListener.prototype.getUser = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveResultGiftListener} returns this
*/
proto.jp.singcolor.LiveResultGiftListener.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveResultGiftListener} returns this
 */
proto.jp.singcolor.LiveResultGiftListener.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveResultGiftListener.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 termly_fave_points = 2;
 * @return {number}
 */
proto.jp.singcolor.LiveResultGiftListener.prototype.getTermlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveResultGiftListener} returns this
 */
proto.jp.singcolor.LiveResultGiftListener.prototype.setTermlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 monthly_fave_points = 3;
 * @return {number}
 */
proto.jp.singcolor.LiveResultGiftListener.prototype.getMonthlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveResultGiftListener} returns this
 */
proto.jp.singcolor.LiveResultGiftListener.prototype.setMonthlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional FanType fan_type = 4;
 * @return {!proto.jp.singcolor.FanType}
 */
proto.jp.singcolor.LiveResultGiftListener.prototype.getFanType = function() {
  return /** @type {!proto.jp.singcolor.FanType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.jp.singcolor.FanType} value
 * @return {!proto.jp.singcolor.LiveResultGiftListener} returns this
 */
proto.jp.singcolor.LiveResultGiftListener.prototype.setFanType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated LiveResultGiftCount gift_counts = 5;
 * @return {!Array<!proto.jp.singcolor.LiveResultGiftCount>}
 */
proto.jp.singcolor.LiveResultGiftListener.prototype.getGiftCountsList = function() {
  return /** @type{!Array<!proto.jp.singcolor.LiveResultGiftCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.LiveResultGiftCount, 5));
};


/**
 * @param {!Array<!proto.jp.singcolor.LiveResultGiftCount>} value
 * @return {!proto.jp.singcolor.LiveResultGiftListener} returns this
*/
proto.jp.singcolor.LiveResultGiftListener.prototype.setGiftCountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.jp.singcolor.LiveResultGiftCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LiveResultGiftCount}
 */
proto.jp.singcolor.LiveResultGiftListener.prototype.addGiftCounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.jp.singcolor.LiveResultGiftCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.LiveResultGiftListener} returns this
 */
proto.jp.singcolor.LiveResultGiftListener.prototype.clearGiftCountsList = function() {
  return this.setGiftCountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveResultGiftCount.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveResultGiftCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveResultGiftCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveResultGiftCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    gift: (f = msg.getGift()) && proto.jp.singcolor.Gift.toObject(includeInstance, f),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveResultGiftCount}
 */
proto.jp.singcolor.LiveResultGiftCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveResultGiftCount;
  return proto.jp.singcolor.LiveResultGiftCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveResultGiftCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveResultGiftCount}
 */
proto.jp.singcolor.LiveResultGiftCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jp.singcolor.Gift;
      reader.readMessage(value,proto.jp.singcolor.Gift.deserializeBinaryFromReader);
      msg.setGift(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveResultGiftCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveResultGiftCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveResultGiftCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveResultGiftCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGift();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.jp.singcolor.Gift.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional Gift gift = 1;
 * @return {?proto.jp.singcolor.Gift}
 */
proto.jp.singcolor.LiveResultGiftCount.prototype.getGift = function() {
  return /** @type{?proto.jp.singcolor.Gift} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.Gift, 1));
};


/**
 * @param {?proto.jp.singcolor.Gift|undefined} value
 * @return {!proto.jp.singcolor.LiveResultGiftCount} returns this
*/
proto.jp.singcolor.LiveResultGiftCount.prototype.setGift = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveResultGiftCount} returns this
 */
proto.jp.singcolor.LiveResultGiftCount.prototype.clearGift = function() {
  return this.setGift(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveResultGiftCount.prototype.hasGift = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.jp.singcolor.LiveResultGiftCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveResultGiftCount} returns this
 */
proto.jp.singcolor.LiveResultGiftCount.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveLiverInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveLiverInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveLiverInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveLiverInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f),
    userFollow: (f = msg.getUserFollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
    liveTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    superLikeInDayCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    surgingMark: jspb.Message.getFieldWithDefault(msg, 5, ""),
    meterRangeInformation: jspb.Message.getFieldWithDefault(msg, 6, ""),
    faveBonusRate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    currentFavePoints: jspb.Message.getFieldWithDefault(msg, 8, 0),
    nextFaveBonusRequiredFavePoints: jspb.Message.getFieldWithDefault(msg, 9, 0),
    currentSongFaveCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    nextFaveBonusRequiredSongFaveCount: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveLiverInfo}
 */
proto.jp.singcolor.LiveLiverInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveLiverInfo;
  return proto.jp.singcolor.LiveLiverInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveLiverInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveLiverInfo}
 */
proto.jp.singcolor.LiveLiverInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.UserItem;
      reader.readMessage(value,users_entity_pb.UserItem.deserializeBinaryFromReader);
      msg.setUserItem(value);
      break;
    case 2:
      var value = new users_entity_pb.UserFollow;
      reader.readMessage(value,users_entity_pb.UserFollow.deserializeBinaryFromReader);
      msg.setUserFollow(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveTitle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSuperLikeInDayCount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSurgingMark(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeterRangeInformation(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaveBonusRate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentFavePoints(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextFaveBonusRequiredFavePoints(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentSongFaveCount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextFaveBonusRequiredSongFaveCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveLiverInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveLiverInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveLiverInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveLiverInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.UserItem.serializeBinaryToWriter
    );
  }
  f = message.getUserFollow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.UserFollow.serializeBinaryToWriter
    );
  }
  f = message.getLiveTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSuperLikeInDayCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSurgingMark();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMeterRangeInformation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFaveBonusRate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCurrentFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getNextFaveBonusRequiredFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getCurrentSongFaveCount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getNextFaveBonusRequiredSongFaveCount();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
};


/**
 * optional UserItem user_item = 1;
 * @return {?proto.jp.singcolor.UserItem}
 */
proto.jp.singcolor.LiveLiverInfo.prototype.getUserItem = function() {
  return /** @type{?proto.jp.singcolor.UserItem} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 1));
};


/**
 * @param {?proto.jp.singcolor.UserItem|undefined} value
 * @return {!proto.jp.singcolor.LiveLiverInfo} returns this
*/
proto.jp.singcolor.LiveLiverInfo.prototype.setUserItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveLiverInfo} returns this
 */
proto.jp.singcolor.LiveLiverInfo.prototype.clearUserItem = function() {
  return this.setUserItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveLiverInfo.prototype.hasUserItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserFollow user_follow = 2;
 * @return {?proto.jp.singcolor.UserFollow}
 */
proto.jp.singcolor.LiveLiverInfo.prototype.getUserFollow = function() {
  return /** @type{?proto.jp.singcolor.UserFollow} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 2));
};


/**
 * @param {?proto.jp.singcolor.UserFollow|undefined} value
 * @return {!proto.jp.singcolor.LiveLiverInfo} returns this
*/
proto.jp.singcolor.LiveLiverInfo.prototype.setUserFollow = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveLiverInfo} returns this
 */
proto.jp.singcolor.LiveLiverInfo.prototype.clearUserFollow = function() {
  return this.setUserFollow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveLiverInfo.prototype.hasUserFollow = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string live_title = 3;
 * @return {string}
 */
proto.jp.singcolor.LiveLiverInfo.prototype.getLiveTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveLiverInfo} returns this
 */
proto.jp.singcolor.LiveLiverInfo.prototype.setLiveTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 super_like_in_day_count = 4;
 * @return {number}
 */
proto.jp.singcolor.LiveLiverInfo.prototype.getSuperLikeInDayCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveLiverInfo} returns this
 */
proto.jp.singcolor.LiveLiverInfo.prototype.setSuperLikeInDayCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string surging_mark = 5;
 * @return {string}
 */
proto.jp.singcolor.LiveLiverInfo.prototype.getSurgingMark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveLiverInfo} returns this
 */
proto.jp.singcolor.LiveLiverInfo.prototype.setSurgingMark = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string meter_range_information = 6;
 * @return {string}
 */
proto.jp.singcolor.LiveLiverInfo.prototype.getMeterRangeInformation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveLiverInfo} returns this
 */
proto.jp.singcolor.LiveLiverInfo.prototype.setMeterRangeInformation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string fave_bonus_rate = 7;
 * @return {string}
 */
proto.jp.singcolor.LiveLiverInfo.prototype.getFaveBonusRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveLiverInfo} returns this
 */
proto.jp.singcolor.LiveLiverInfo.prototype.setFaveBonusRate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 current_fave_points = 8;
 * @return {number}
 */
proto.jp.singcolor.LiveLiverInfo.prototype.getCurrentFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveLiverInfo} returns this
 */
proto.jp.singcolor.LiveLiverInfo.prototype.setCurrentFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 next_fave_bonus_required_fave_points = 9;
 * @return {number}
 */
proto.jp.singcolor.LiveLiverInfo.prototype.getNextFaveBonusRequiredFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveLiverInfo} returns this
 */
proto.jp.singcolor.LiveLiverInfo.prototype.setNextFaveBonusRequiredFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 current_song_fave_count = 10;
 * @return {number}
 */
proto.jp.singcolor.LiveLiverInfo.prototype.getCurrentSongFaveCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveLiverInfo} returns this
 */
proto.jp.singcolor.LiveLiverInfo.prototype.setCurrentSongFaveCount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 next_fave_bonus_required_song_fave_count = 11;
 * @return {number}
 */
proto.jp.singcolor.LiveLiverInfo.prototype.getNextFaveBonusRequiredSongFaveCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveLiverInfo} returns this
 */
proto.jp.singcolor.LiveLiverInfo.prototype.setNextFaveBonusRequiredSongFaveCount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveTimelineInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveTimelineInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveTimelineInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveTimelineInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    timelineId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timelineName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveTimelineInfo}
 */
proto.jp.singcolor.LiveTimelineInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveTimelineInfo;
  return proto.jp.singcolor.LiveTimelineInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveTimelineInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveTimelineInfo}
 */
proto.jp.singcolor.LiveTimelineInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimelineId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimelineName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveTimelineInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveTimelineInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveTimelineInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveTimelineInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimelineId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimelineName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string timeline_id = 1;
 * @return {string}
 */
proto.jp.singcolor.LiveTimelineInfo.prototype.getTimelineId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveTimelineInfo} returns this
 */
proto.jp.singcolor.LiveTimelineInfo.prototype.setTimelineId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string timeline_name = 2;
 * @return {string}
 */
proto.jp.singcolor.LiveTimelineInfo.prototype.getTimelineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveTimelineInfo} returns this
 */
proto.jp.singcolor.LiveTimelineInfo.prototype.setTimelineName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.jp.singcolor.LiveTimelineBanner.oneofGroups_ = [[8]];

/**
 * @enum {number}
 */
proto.jp.singcolor.LiveTimelineBanner.SpecificLiveTimelineBannerAdditionalInfoCase = {
  SPECIFIC_LIVE_TIMELINE_BANNER_ADDITIONAL_INFO_NOT_SET: 0,
  ONE_PHRASE_EVENT: 8
};

/**
 * @return {proto.jp.singcolor.LiveTimelineBanner.SpecificLiveTimelineBannerAdditionalInfoCase}
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.getSpecificLiveTimelineBannerAdditionalInfoCase = function() {
  return /** @type {proto.jp.singcolor.LiveTimelineBanner.SpecificLiveTimelineBannerAdditionalInfoCase} */(jspb.Message.computeOneofCase(this, proto.jp.singcolor.LiveTimelineBanner.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveTimelineBanner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveTimelineBanner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveTimelineBanner.toObject = function(includeInstance, msg) {
  var f, obj = {
    live: (f = msg.getLive()) && proto.jp.singcolor.Live.toObject(includeInstance, f),
    userItemWithFollow: (f = msg.getUserItemWithFollow()) && users_entity_pb.UserItemWithFollow.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rank: jspb.Message.getFieldWithDefault(msg, 4, 0),
    periodText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    backgroundColor: jspb.Message.getFieldWithDefault(msg, 6, ""),
    timelineName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    onePhraseEvent: (f = msg.getOnePhraseEvent()) && proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveTimelineBanner}
 */
proto.jp.singcolor.LiveTimelineBanner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveTimelineBanner;
  return proto.jp.singcolor.LiveTimelineBanner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveTimelineBanner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveTimelineBanner}
 */
proto.jp.singcolor.LiveTimelineBanner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jp.singcolor.Live;
      reader.readMessage(value,proto.jp.singcolor.Live.deserializeBinaryFromReader);
      msg.setLive(value);
      break;
    case 2:
      var value = new users_entity_pb.UserItemWithFollow;
      reader.readMessage(value,users_entity_pb.UserItemWithFollow.deserializeBinaryFromReader);
      msg.setUserItemWithFollow(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRank(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodText(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundColor(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimelineName(value);
      break;
    case 8:
      var value = new proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent;
      reader.readMessage(value,proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.deserializeBinaryFromReader);
      msg.setOnePhraseEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveTimelineBanner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveTimelineBanner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveTimelineBanner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLive();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.jp.singcolor.Live.serializeBinaryToWriter
    );
  }
  f = message.getUserItemWithFollow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.UserItemWithFollow.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPeriodText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBackgroundColor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTimelineName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOnePhraseEvent();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional Live live = 1;
 * @return {?proto.jp.singcolor.Live}
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.getLive = function() {
  return /** @type{?proto.jp.singcolor.Live} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.Live, 1));
};


/**
 * @param {?proto.jp.singcolor.Live|undefined} value
 * @return {!proto.jp.singcolor.LiveTimelineBanner} returns this
*/
proto.jp.singcolor.LiveTimelineBanner.prototype.setLive = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveTimelineBanner} returns this
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.clearLive = function() {
  return this.setLive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.hasLive = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserItemWithFollow user_item_with_follow = 2;
 * @return {?proto.jp.singcolor.UserItemWithFollow}
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.getUserItemWithFollow = function() {
  return /** @type{?proto.jp.singcolor.UserItemWithFollow} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.UserItemWithFollow, 2));
};


/**
 * @param {?proto.jp.singcolor.UserItemWithFollow|undefined} value
 * @return {!proto.jp.singcolor.LiveTimelineBanner} returns this
*/
proto.jp.singcolor.LiveTimelineBanner.prototype.setUserItemWithFollow = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveTimelineBanner} returns this
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.clearUserItemWithFollow = function() {
  return this.setUserItemWithFollow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.hasUserItemWithFollow = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveTimelineBanner} returns this
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 rank = 4;
 * @return {number}
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveTimelineBanner} returns this
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string period_text = 5;
 * @return {string}
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.getPeriodText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveTimelineBanner} returns this
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.setPeriodText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string background_color = 6;
 * @return {string}
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.getBackgroundColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveTimelineBanner} returns this
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.setBackgroundColor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string timeline_name = 7;
 * @return {string}
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.getTimelineName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveTimelineBanner} returns this
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.setTimelineName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent one_phrase_event = 8;
 * @return {?proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent}
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.getOnePhraseEvent = function() {
  return /** @type{?proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent, 8));
};


/**
 * @param {?proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent|undefined} value
 * @return {!proto.jp.singcolor.LiveTimelineBanner} returns this
*/
proto.jp.singcolor.LiveTimelineBanner.prototype.setOnePhraseEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.jp.singcolor.LiveTimelineBanner.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveTimelineBanner} returns this
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.clearOnePhraseEvent = function() {
  return this.setOnePhraseEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveTimelineBanner.prototype.hasOnePhraseEvent = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && one_phrase_events_entity_pb.OnePhraseEventItem.toObject(includeInstance, f),
    prize: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent}
 */
proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent;
  return proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent}
 */
proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new one_phrase_events_entity_pb.OnePhraseEventItem;
      reader.readMessage(value,one_phrase_events_entity_pb.OnePhraseEventItem.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 2:
      var value = /** @type {!proto.jp.singcolor.OnePhraseEventPrize} */ (reader.readEnum());
      msg.setPrize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      one_phrase_events_entity_pb.OnePhraseEventItem.serializeBinaryToWriter
    );
  }
  f = message.getPrize();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional OnePhraseEventItem event = 1;
 * @return {?proto.jp.singcolor.OnePhraseEventItem}
 */
proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.prototype.getEvent = function() {
  return /** @type{?proto.jp.singcolor.OnePhraseEventItem} */ (
    jspb.Message.getWrapperField(this, one_phrase_events_entity_pb.OnePhraseEventItem, 1));
};


/**
 * @param {?proto.jp.singcolor.OnePhraseEventItem|undefined} value
 * @return {!proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent} returns this
*/
proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent} returns this
 */
proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OnePhraseEventPrize prize = 2;
 * @return {!proto.jp.singcolor.OnePhraseEventPrize}
 */
proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.prototype.getPrize = function() {
  return /** @type {!proto.jp.singcolor.OnePhraseEventPrize} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.jp.singcolor.OnePhraseEventPrize} value
 * @return {!proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent} returns this
 */
proto.jp.singcolor.SpecificLiveTimelineBannerAdditionalInfoOnePhraseEvent.prototype.setPrize = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.HeartfulMention.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.HeartfulMention.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.HeartfulMention.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.HeartfulMention} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.HeartfulMention.toObject = function(includeInstance, msg) {
  var f, obj = {
    heartfulMentionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    liveId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    stampImageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    user: (f = msg.getUser()) && users_entity_pb.UserItemWithFavePoint.toObject(includeInstance, f),
    mentionUser: (f = msg.getMentionUser()) && users_entity_pb.UserItemWithFavePoint.toObject(includeInstance, f),
    mentionTopSendersList: jspb.Message.toObjectList(msg.getMentionTopSendersList(),
    users_entity_pb.UserItemWithFavePoint.toObject, includeInstance),
    mentionSenderIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    visibleDelaySeconds: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.HeartfulMention}
 */
proto.jp.singcolor.HeartfulMention.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.HeartfulMention;
  return proto.jp.singcolor.HeartfulMention.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.HeartfulMention} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.HeartfulMention}
 */
proto.jp.singcolor.HeartfulMention.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeartfulMentionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStampImageUrl(value);
      break;
    case 5:
      var value = new users_entity_pb.UserItemWithFavePoint;
      reader.readMessage(value,users_entity_pb.UserItemWithFavePoint.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 6:
      var value = new users_entity_pb.UserItemWithFavePoint;
      reader.readMessage(value,users_entity_pb.UserItemWithFavePoint.deserializeBinaryFromReader);
      msg.setMentionUser(value);
      break;
    case 7:
      var value = new users_entity_pb.UserItemWithFavePoint;
      reader.readMessage(value,users_entity_pb.UserItemWithFavePoint.deserializeBinaryFromReader);
      msg.addMentionTopSenders(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addMentionSenderIds(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVisibleDelaySeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.HeartfulMention.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.HeartfulMention.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.HeartfulMention} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.HeartfulMention.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeartfulMentionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLiveId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStampImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      users_entity_pb.UserItemWithFavePoint.serializeBinaryToWriter
    );
  }
  f = message.getMentionUser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      users_entity_pb.UserItemWithFavePoint.serializeBinaryToWriter
    );
  }
  f = message.getMentionTopSendersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      users_entity_pb.UserItemWithFavePoint.serializeBinaryToWriter
    );
  }
  f = message.getMentionSenderIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getVisibleDelaySeconds();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional string heartful_mention_id = 1;
 * @return {string}
 */
proto.jp.singcolor.HeartfulMention.prototype.getHeartfulMentionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.HeartfulMention} returns this
 */
proto.jp.singcolor.HeartfulMention.prototype.setHeartfulMentionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string live_id = 2;
 * @return {string}
 */
proto.jp.singcolor.HeartfulMention.prototype.getLiveId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.HeartfulMention} returns this
 */
proto.jp.singcolor.HeartfulMention.prototype.setLiveId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.jp.singcolor.HeartfulMention.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.HeartfulMention} returns this
 */
proto.jp.singcolor.HeartfulMention.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string stamp_image_url = 4;
 * @return {string}
 */
proto.jp.singcolor.HeartfulMention.prototype.getStampImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.HeartfulMention} returns this
 */
proto.jp.singcolor.HeartfulMention.prototype.setStampImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional UserItemWithFavePoint user = 5;
 * @return {?proto.jp.singcolor.UserItemWithFavePoint}
 */
proto.jp.singcolor.HeartfulMention.prototype.getUser = function() {
  return /** @type{?proto.jp.singcolor.UserItemWithFavePoint} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.UserItemWithFavePoint, 5));
};


/**
 * @param {?proto.jp.singcolor.UserItemWithFavePoint|undefined} value
 * @return {!proto.jp.singcolor.HeartfulMention} returns this
*/
proto.jp.singcolor.HeartfulMention.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.HeartfulMention} returns this
 */
proto.jp.singcolor.HeartfulMention.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.HeartfulMention.prototype.hasUser = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UserItemWithFavePoint mention_user = 6;
 * @return {?proto.jp.singcolor.UserItemWithFavePoint}
 */
proto.jp.singcolor.HeartfulMention.prototype.getMentionUser = function() {
  return /** @type{?proto.jp.singcolor.UserItemWithFavePoint} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.UserItemWithFavePoint, 6));
};


/**
 * @param {?proto.jp.singcolor.UserItemWithFavePoint|undefined} value
 * @return {!proto.jp.singcolor.HeartfulMention} returns this
*/
proto.jp.singcolor.HeartfulMention.prototype.setMentionUser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.HeartfulMention} returns this
 */
proto.jp.singcolor.HeartfulMention.prototype.clearMentionUser = function() {
  return this.setMentionUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.HeartfulMention.prototype.hasMentionUser = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated UserItemWithFavePoint Mention_top_senders = 7;
 * @return {!Array<!proto.jp.singcolor.UserItemWithFavePoint>}
 */
proto.jp.singcolor.HeartfulMention.prototype.getMentionTopSendersList = function() {
  return /** @type{!Array<!proto.jp.singcolor.UserItemWithFavePoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, users_entity_pb.UserItemWithFavePoint, 7));
};


/**
 * @param {!Array<!proto.jp.singcolor.UserItemWithFavePoint>} value
 * @return {!proto.jp.singcolor.HeartfulMention} returns this
*/
proto.jp.singcolor.HeartfulMention.prototype.setMentionTopSendersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.jp.singcolor.UserItemWithFavePoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.UserItemWithFavePoint}
 */
proto.jp.singcolor.HeartfulMention.prototype.addMentionTopSenders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.jp.singcolor.UserItemWithFavePoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.HeartfulMention} returns this
 */
proto.jp.singcolor.HeartfulMention.prototype.clearMentionTopSendersList = function() {
  return this.setMentionTopSendersList([]);
};


/**
 * repeated string Mention_sender_ids = 8;
 * @return {!Array<string>}
 */
proto.jp.singcolor.HeartfulMention.prototype.getMentionSenderIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.HeartfulMention} returns this
 */
proto.jp.singcolor.HeartfulMention.prototype.setMentionSenderIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.HeartfulMention} returns this
 */
proto.jp.singcolor.HeartfulMention.prototype.addMentionSenderIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.HeartfulMention} returns this
 */
proto.jp.singcolor.HeartfulMention.prototype.clearMentionSenderIdsList = function() {
  return this.setMentionSenderIdsList([]);
};


/**
 * optional int64 visible_delay_seconds = 9;
 * @return {number}
 */
proto.jp.singcolor.HeartfulMention.prototype.getVisibleDelaySeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.HeartfulMention} returns this
 */
proto.jp.singcolor.HeartfulMention.prototype.setVisibleDelaySeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventHeartfulMention.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventHeartfulMention.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventHeartfulMention} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventHeartfulMention.toObject = function(includeInstance, msg) {
  var f, obj = {
    heartfulMention: (f = msg.getHeartfulMention()) && proto.jp.singcolor.HeartfulMention.toObject(includeInstance, f),
    reactionGiftButton: (f = msg.getReactionGiftButton()) && proto.jp.singcolor.ReactionGiftButton.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventHeartfulMention}
 */
proto.jp.singcolor.LiveEventHeartfulMention.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventHeartfulMention;
  return proto.jp.singcolor.LiveEventHeartfulMention.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventHeartfulMention} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventHeartfulMention}
 */
proto.jp.singcolor.LiveEventHeartfulMention.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jp.singcolor.HeartfulMention;
      reader.readMessage(value,proto.jp.singcolor.HeartfulMention.deserializeBinaryFromReader);
      msg.setHeartfulMention(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.ReactionGiftButton;
      reader.readMessage(value,proto.jp.singcolor.ReactionGiftButton.deserializeBinaryFromReader);
      msg.setReactionGiftButton(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventHeartfulMention.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventHeartfulMention.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventHeartfulMention} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventHeartfulMention.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeartfulMention();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.jp.singcolor.HeartfulMention.serializeBinaryToWriter
    );
  }
  f = message.getReactionGiftButton();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.ReactionGiftButton.serializeBinaryToWriter
    );
  }
};


/**
 * optional HeartfulMention heartful_mention = 1;
 * @return {?proto.jp.singcolor.HeartfulMention}
 */
proto.jp.singcolor.LiveEventHeartfulMention.prototype.getHeartfulMention = function() {
  return /** @type{?proto.jp.singcolor.HeartfulMention} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.HeartfulMention, 1));
};


/**
 * @param {?proto.jp.singcolor.HeartfulMention|undefined} value
 * @return {!proto.jp.singcolor.LiveEventHeartfulMention} returns this
*/
proto.jp.singcolor.LiveEventHeartfulMention.prototype.setHeartfulMention = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventHeartfulMention} returns this
 */
proto.jp.singcolor.LiveEventHeartfulMention.prototype.clearHeartfulMention = function() {
  return this.setHeartfulMention(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventHeartfulMention.prototype.hasHeartfulMention = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ReactionGiftButton reaction_gift_button = 2;
 * @return {?proto.jp.singcolor.ReactionGiftButton}
 */
proto.jp.singcolor.LiveEventHeartfulMention.prototype.getReactionGiftButton = function() {
  return /** @type{?proto.jp.singcolor.ReactionGiftButton} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.ReactionGiftButton, 2));
};


/**
 * @param {?proto.jp.singcolor.ReactionGiftButton|undefined} value
 * @return {!proto.jp.singcolor.LiveEventHeartfulMention} returns this
*/
proto.jp.singcolor.LiveEventHeartfulMention.prototype.setReactionGiftButton = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventHeartfulMention} returns this
 */
proto.jp.singcolor.LiveEventHeartfulMention.prototype.clearReactionGiftButton = function() {
  return this.setReactionGiftButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventHeartfulMention.prototype.hasReactionGiftButton = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventLiveSettingsUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventLiveSettingsUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventLiveSettingsUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventLiveSettingsUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveBackground: (f = msg.getLiveBackground()) && proto.jp.singcolor.LiveBackground.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventLiveSettingsUpdated}
 */
proto.jp.singcolor.LiveEventLiveSettingsUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventLiveSettingsUpdated;
  return proto.jp.singcolor.LiveEventLiveSettingsUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventLiveSettingsUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventLiveSettingsUpdated}
 */
proto.jp.singcolor.LiveEventLiveSettingsUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jp.singcolor.LiveBackground;
      reader.readMessage(value,proto.jp.singcolor.LiveBackground.deserializeBinaryFromReader);
      msg.setLiveBackground(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventLiveSettingsUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventLiveSettingsUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventLiveSettingsUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventLiveSettingsUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveBackground();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.jp.singcolor.LiveBackground.serializeBinaryToWriter
    );
  }
};


/**
 * optional LiveBackground live_background = 1;
 * @return {?proto.jp.singcolor.LiveBackground}
 */
proto.jp.singcolor.LiveEventLiveSettingsUpdated.prototype.getLiveBackground = function() {
  return /** @type{?proto.jp.singcolor.LiveBackground} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiveBackground, 1));
};


/**
 * @param {?proto.jp.singcolor.LiveBackground|undefined} value
 * @return {!proto.jp.singcolor.LiveEventLiveSettingsUpdated} returns this
*/
proto.jp.singcolor.LiveEventLiveSettingsUpdated.prototype.setLiveBackground = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventLiveSettingsUpdated} returns this
 */
proto.jp.singcolor.LiveEventLiveSettingsUpdated.prototype.clearLiveBackground = function() {
  return this.setLiveBackground(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventLiveSettingsUpdated.prototype.hasLiveBackground = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    listenerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reqCapturePossibility: (f = msg.getReqCapturePossibility()) && proto.jp.singcolor.ReqCapturePossibility.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated}
 */
proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated;
  return proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated}
 */
proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setListenerId(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.ReqCapturePossibility;
      reader.readMessage(value,proto.jp.singcolor.ReqCapturePossibility.deserializeBinaryFromReader);
      msg.setReqCapturePossibility(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListenerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReqCapturePossibility();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.ReqCapturePossibility.serializeBinaryToWriter
    );
  }
};


/**
 * optional string listener_id = 1;
 * @return {string}
 */
proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.prototype.getListenerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated} returns this
 */
proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.prototype.setListenerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ReqCapturePossibility req_capture_possibility = 2;
 * @return {?proto.jp.singcolor.ReqCapturePossibility}
 */
proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.prototype.getReqCapturePossibility = function() {
  return /** @type{?proto.jp.singcolor.ReqCapturePossibility} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.ReqCapturePossibility, 2));
};


/**
 * @param {?proto.jp.singcolor.ReqCapturePossibility|undefined} value
 * @return {!proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated} returns this
*/
proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.prototype.setReqCapturePossibility = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated} returns this
 */
proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.prototype.clearReqCapturePossibility = function() {
  return this.setReqCapturePossibility(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventListenerReqCapturePossibilityUpdated.prototype.hasReqCapturePossibility = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventPickUpSongRandomly.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventPickUpSongRandomly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventPickUpSongRandomly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventPickUpSongRandomly.toObject = function(includeInstance, msg) {
  var f, obj = {
    song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
    pickUpNumber: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventPickUpSongRandomly}
 */
proto.jp.singcolor.LiveEventPickUpSongRandomly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventPickUpSongRandomly;
  return proto.jp.singcolor.LiveEventPickUpSongRandomly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventPickUpSongRandomly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventPickUpSongRandomly}
 */
proto.jp.singcolor.LiveEventPickUpSongRandomly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new karaoke_entity_pb.Song;
      reader.readMessage(value,karaoke_entity_pb.Song.deserializeBinaryFromReader);
      msg.setSong(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickUpNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventPickUpSongRandomly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventPickUpSongRandomly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventPickUpSongRandomly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventPickUpSongRandomly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSong();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      karaoke_entity_pb.Song.serializeBinaryToWriter
    );
  }
  f = message.getPickUpNumber();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional Song song = 1;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.LiveEventPickUpSongRandomly.prototype.getSong = function() {
  return /** @type{?proto.jp.singcolor.Song} */ (
    jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 1));
};


/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.LiveEventPickUpSongRandomly} returns this
*/
proto.jp.singcolor.LiveEventPickUpSongRandomly.prototype.setSong = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventPickUpSongRandomly} returns this
 */
proto.jp.singcolor.LiveEventPickUpSongRandomly.prototype.clearSong = function() {
  return this.setSong(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventPickUpSongRandomly.prototype.hasSong = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 pick_up_number = 2;
 * @return {number}
 */
proto.jp.singcolor.LiveEventPickUpSongRandomly.prototype.getPickUpNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LiveEventPickUpSongRandomly} returns this
 */
proto.jp.singcolor.LiveEventPickUpSongRandomly.prototype.setPickUpNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventMembershipUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventMembershipUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventMembershipUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
    listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f),
    messageForLiver: jspb.Message.getFieldWithDefault(msg, 3, ""),
    messageForListener: jspb.Message.getFieldWithDefault(msg, 4, ""),
    heartfulMentionButton: (f = msg.getHeartfulMentionButton()) && proto.jp.singcolor.HeartfulMentionButton.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventMembershipUpdated}
 */
proto.jp.singcolor.LiveEventMembershipUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventMembershipUpdated;
  return proto.jp.singcolor.LiveEventMembershipUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventMembershipUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventMembershipUpdated}
 */
proto.jp.singcolor.LiveEventMembershipUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    case 2:
      var value = new users_entity_pb.ListenerMembershipInfo;
      reader.readMessage(value,users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
      msg.setListenerMembershipInfo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageForLiver(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageForListener(value);
      break;
    case 5:
      var value = new proto.jp.singcolor.HeartfulMentionButton;
      reader.readMessage(value,proto.jp.singcolor.HeartfulMentionButton.deserializeBinaryFromReader);
      msg.setHeartfulMentionButton(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventMembershipUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventMembershipUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventMembershipUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getListenerMembershipInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter
    );
  }
  f = message.getMessageForLiver();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMessageForListener();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHeartfulMentionButton();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.jp.singcolor.HeartfulMentionButton.serializeBinaryToWriter
    );
  }
};


/**
 * optional User listener = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventMembershipUpdated} returns this
*/
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventMembershipUpdated} returns this
 */
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.hasListener = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ListenerMembershipInfo listener_membership_info = 2;
 * @return {?proto.jp.singcolor.ListenerMembershipInfo}
 */
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.getListenerMembershipInfo = function() {
  return /** @type{?proto.jp.singcolor.ListenerMembershipInfo} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 2));
};


/**
 * @param {?proto.jp.singcolor.ListenerMembershipInfo|undefined} value
 * @return {!proto.jp.singcolor.LiveEventMembershipUpdated} returns this
*/
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.setListenerMembershipInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventMembershipUpdated} returns this
 */
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.clearListenerMembershipInfo = function() {
  return this.setListenerMembershipInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.hasListenerMembershipInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string message_for_liver = 3;
 * @return {string}
 */
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.getMessageForLiver = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventMembershipUpdated} returns this
 */
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.setMessageForLiver = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string message_for_listener = 4;
 * @return {string}
 */
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.getMessageForListener = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventMembershipUpdated} returns this
 */
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.setMessageForListener = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional HeartfulMentionButton heartful_mention_button = 5;
 * @return {?proto.jp.singcolor.HeartfulMentionButton}
 */
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.getHeartfulMentionButton = function() {
  return /** @type{?proto.jp.singcolor.HeartfulMentionButton} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.HeartfulMentionButton, 5));
};


/**
 * @param {?proto.jp.singcolor.HeartfulMentionButton|undefined} value
 * @return {!proto.jp.singcolor.LiveEventMembershipUpdated} returns this
*/
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.setHeartfulMentionButton = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventMembershipUpdated} returns this
 */
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.clearHeartfulMentionButton = function() {
  return this.setHeartfulMentionButton(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventMembershipUpdated.prototype.hasHeartfulMentionButton = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.ReqCapturePossibility.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.ReqCapturePossibility.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.ReqCapturePossibility} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ReqCapturePossibility.toObject = function(includeInstance, msg) {
  var f, obj = {
    canRequestSing: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    cannotRequestSingReason: jspb.Message.getFieldWithDefault(msg, 2, ""),
    canCapture: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    cannotCaptureReason: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ReqCapturePossibility}
 */
proto.jp.singcolor.ReqCapturePossibility.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.ReqCapturePossibility;
  return proto.jp.singcolor.ReqCapturePossibility.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ReqCapturePossibility} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ReqCapturePossibility}
 */
proto.jp.singcolor.ReqCapturePossibility.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanRequestSing(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCannotRequestSingReason(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanCapture(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCannotCaptureReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ReqCapturePossibility.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.ReqCapturePossibility.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ReqCapturePossibility} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ReqCapturePossibility.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanRequestSing();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCannotRequestSingReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCanCapture();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCannotCaptureReason();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional bool can_request_sing = 1;
 * @return {boolean}
 */
proto.jp.singcolor.ReqCapturePossibility.prototype.getCanRequestSing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ReqCapturePossibility} returns this
 */
proto.jp.singcolor.ReqCapturePossibility.prototype.setCanRequestSing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string cannot_request_sing_reason = 2;
 * @return {string}
 */
proto.jp.singcolor.ReqCapturePossibility.prototype.getCannotRequestSingReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ReqCapturePossibility} returns this
 */
proto.jp.singcolor.ReqCapturePossibility.prototype.setCannotRequestSingReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool can_capture = 3;
 * @return {boolean}
 */
proto.jp.singcolor.ReqCapturePossibility.prototype.getCanCapture = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ReqCapturePossibility} returns this
 */
proto.jp.singcolor.ReqCapturePossibility.prototype.setCanCapture = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string cannot_capture_reason = 4;
 * @return {string}
 */
proto.jp.singcolor.ReqCapturePossibility.prototype.getCannotCaptureReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ReqCapturePossibility} returns this
 */
proto.jp.singcolor.ReqCapturePossibility.prototype.setCannotCaptureReason = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventGetSongRandomly.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventGetSongRandomly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventGetSongRandomly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventGetSongRandomly.toObject = function(includeInstance, msg) {
  var f, obj = {
    song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
    isReSelected: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventGetSongRandomly}
 */
proto.jp.singcolor.LiveEventGetSongRandomly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventGetSongRandomly;
  return proto.jp.singcolor.LiveEventGetSongRandomly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventGetSongRandomly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventGetSongRandomly}
 */
proto.jp.singcolor.LiveEventGetSongRandomly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new karaoke_entity_pb.Song;
      reader.readMessage(value,karaoke_entity_pb.Song.deserializeBinaryFromReader);
      msg.setSong(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReSelected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventGetSongRandomly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventGetSongRandomly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventGetSongRandomly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventGetSongRandomly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSong();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      karaoke_entity_pb.Song.serializeBinaryToWriter
    );
  }
  f = message.getIsReSelected();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Song song = 1;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.LiveEventGetSongRandomly.prototype.getSong = function() {
  return /** @type{?proto.jp.singcolor.Song} */ (
    jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 1));
};


/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.LiveEventGetSongRandomly} returns this
*/
proto.jp.singcolor.LiveEventGetSongRandomly.prototype.setSong = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventGetSongRandomly} returns this
 */
proto.jp.singcolor.LiveEventGetSongRandomly.prototype.clearSong = function() {
  return this.setSong(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetSongRandomly.prototype.hasSong = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_re_selected = 2;
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventGetSongRandomly.prototype.getIsReSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LiveEventGetSongRandomly} returns this
 */
proto.jp.singcolor.LiveEventGetSongRandomly.prototype.setIsReSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventBanListenerCommentByModerator.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventBanListenerCommentByModerator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventBanListenerCommentByModerator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventBanListenerCommentByModerator.toObject = function(includeInstance, msg) {
  var f, obj = {
    moderator: (f = msg.getModerator()) && users_entity_pb.User.toObject(includeInstance, f),
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventBanListenerCommentByModerator}
 */
proto.jp.singcolor.LiveEventBanListenerCommentByModerator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventBanListenerCommentByModerator;
  return proto.jp.singcolor.LiveEventBanListenerCommentByModerator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventBanListenerCommentByModerator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventBanListenerCommentByModerator}
 */
proto.jp.singcolor.LiveEventBanListenerCommentByModerator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setModerator(value);
      break;
    case 2:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventBanListenerCommentByModerator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventBanListenerCommentByModerator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventBanListenerCommentByModerator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventBanListenerCommentByModerator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModerator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User moderator = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventBanListenerCommentByModerator.prototype.getModerator = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventBanListenerCommentByModerator} returns this
*/
proto.jp.singcolor.LiveEventBanListenerCommentByModerator.prototype.setModerator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventBanListenerCommentByModerator} returns this
 */
proto.jp.singcolor.LiveEventBanListenerCommentByModerator.prototype.clearModerator = function() {
  return this.setModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventBanListenerCommentByModerator.prototype.hasModerator = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User listener = 2;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventBanListenerCommentByModerator.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 2));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventBanListenerCommentByModerator} returns this
*/
proto.jp.singcolor.LiveEventBanListenerCommentByModerator.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventBanListenerCommentByModerator} returns this
 */
proto.jp.singcolor.LiveEventBanListenerCommentByModerator.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventBanListenerCommentByModerator.prototype.hasListener = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.toObject = function(includeInstance, msg) {
  var f, obj = {
    moderator: (f = msg.getModerator()) && users_entity_pb.User.toObject(includeInstance, f),
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator}
 */
proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator;
  return proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator}
 */
proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setModerator(value);
      break;
    case 2:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModerator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User moderator = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.prototype.getModerator = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator} returns this
*/
proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.prototype.setModerator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator} returns this
 */
proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.prototype.clearModerator = function() {
  return this.setModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.prototype.hasModerator = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User listener = 2;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 2));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator} returns this
*/
proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator} returns this
 */
proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventUnbanListenerCommentByModerator.prototype.hasListener = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.toObject = function(includeInstance, msg) {
  var f, obj = {
    moderator: (f = msg.getModerator()) && users_entity_pb.User.toObject(includeInstance, f),
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator}
 */
proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator;
  return proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator}
 */
proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setModerator(value);
      break;
    case 2:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModerator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User moderator = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.prototype.getModerator = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator} returns this
*/
proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.prototype.setModerator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator} returns this
 */
proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.prototype.clearModerator = function() {
  return this.setModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.prototype.hasModerator = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User listener = 2;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 2));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator} returns this
*/
proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator} returns this
 */
proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventBanListenerReqCaptureByModerator.prototype.hasListener = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.toObject = function(includeInstance, msg) {
  var f, obj = {
    moderator: (f = msg.getModerator()) && users_entity_pb.User.toObject(includeInstance, f),
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator}
 */
proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator;
  return proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator}
 */
proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setModerator(value);
      break;
    case 2:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModerator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User moderator = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.prototype.getModerator = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator} returns this
*/
proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.prototype.setModerator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator} returns this
 */
proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.prototype.clearModerator = function() {
  return this.setModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.prototype.hasModerator = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User listener = 2;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 2));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator} returns this
*/
proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator} returns this
 */
proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventUnbanListenerReqCaptureByModerator.prototype.hasListener = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventDoBlockByModerator.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventDoBlockByModerator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventDoBlockByModerator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventDoBlockByModerator.toObject = function(includeInstance, msg) {
  var f, obj = {
    moderator: (f = msg.getModerator()) && users_entity_pb.User.toObject(includeInstance, f),
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventDoBlockByModerator}
 */
proto.jp.singcolor.LiveEventDoBlockByModerator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventDoBlockByModerator;
  return proto.jp.singcolor.LiveEventDoBlockByModerator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventDoBlockByModerator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventDoBlockByModerator}
 */
proto.jp.singcolor.LiveEventDoBlockByModerator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setModerator(value);
      break;
    case 2:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventDoBlockByModerator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventDoBlockByModerator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventDoBlockByModerator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventDoBlockByModerator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModerator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User moderator = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventDoBlockByModerator.prototype.getModerator = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventDoBlockByModerator} returns this
*/
proto.jp.singcolor.LiveEventDoBlockByModerator.prototype.setModerator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventDoBlockByModerator} returns this
 */
proto.jp.singcolor.LiveEventDoBlockByModerator.prototype.clearModerator = function() {
  return this.setModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventDoBlockByModerator.prototype.hasModerator = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User listener = 2;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventDoBlockByModerator.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 2));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventDoBlockByModerator} returns this
*/
proto.jp.singcolor.LiveEventDoBlockByModerator.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventDoBlockByModerator} returns this
 */
proto.jp.singcolor.LiveEventDoBlockByModerator.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventDoBlockByModerator.prototype.hasListener = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventUnblockByModerator.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventUnblockByModerator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventUnblockByModerator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventUnblockByModerator.toObject = function(includeInstance, msg) {
  var f, obj = {
    moderator: (f = msg.getModerator()) && users_entity_pb.User.toObject(includeInstance, f),
    listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventUnblockByModerator}
 */
proto.jp.singcolor.LiveEventUnblockByModerator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventUnblockByModerator;
  return proto.jp.singcolor.LiveEventUnblockByModerator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventUnblockByModerator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventUnblockByModerator}
 */
proto.jp.singcolor.LiveEventUnblockByModerator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setModerator(value);
      break;
    case 2:
      var value = new users_entity_pb.User;
      reader.readMessage(value,users_entity_pb.User.deserializeBinaryFromReader);
      msg.setListener(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventUnblockByModerator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventUnblockByModerator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventUnblockByModerator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventUnblockByModerator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModerator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getListener();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User moderator = 1;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventUnblockByModerator.prototype.getModerator = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventUnblockByModerator} returns this
*/
proto.jp.singcolor.LiveEventUnblockByModerator.prototype.setModerator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventUnblockByModerator} returns this
 */
proto.jp.singcolor.LiveEventUnblockByModerator.prototype.clearModerator = function() {
  return this.setModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventUnblockByModerator.prototype.hasModerator = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User listener = 2;
 * @return {?proto.jp.singcolor.User}
 */
proto.jp.singcolor.LiveEventUnblockByModerator.prototype.getListener = function() {
  return /** @type{?proto.jp.singcolor.User} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.User, 2));
};


/**
 * @param {?proto.jp.singcolor.User|undefined} value
 * @return {!proto.jp.singcolor.LiveEventUnblockByModerator} returns this
*/
proto.jp.singcolor.LiveEventUnblockByModerator.prototype.setListener = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventUnblockByModerator} returns this
 */
proto.jp.singcolor.LiveEventUnblockByModerator.prototype.clearListener = function() {
  return this.setListener(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventUnblockByModerator.prototype.hasListener = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventTenuredModerator.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventTenuredModerator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventTenuredModerator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventTenuredModerator.toObject = function(includeInstance, msg) {
  var f, obj = {
    liverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    moderator: (f = msg.getModerator()) && users_entity_pb.Moderator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventTenuredModerator}
 */
proto.jp.singcolor.LiveEventTenuredModerator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventTenuredModerator;
  return proto.jp.singcolor.LiveEventTenuredModerator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventTenuredModerator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventTenuredModerator}
 */
proto.jp.singcolor.LiveEventTenuredModerator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiverId(value);
      break;
    case 2:
      var value = new users_entity_pb.Moderator;
      reader.readMessage(value,users_entity_pb.Moderator.deserializeBinaryFromReader);
      msg.setModerator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventTenuredModerator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventTenuredModerator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventTenuredModerator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventTenuredModerator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiverId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModerator();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.Moderator.serializeBinaryToWriter
    );
  }
};


/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.LiveEventTenuredModerator.prototype.getLiverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventTenuredModerator} returns this
 */
proto.jp.singcolor.LiveEventTenuredModerator.prototype.setLiverId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Moderator moderator = 2;
 * @return {?proto.jp.singcolor.Moderator}
 */
proto.jp.singcolor.LiveEventTenuredModerator.prototype.getModerator = function() {
  return /** @type{?proto.jp.singcolor.Moderator} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.Moderator, 2));
};


/**
 * @param {?proto.jp.singcolor.Moderator|undefined} value
 * @return {!proto.jp.singcolor.LiveEventTenuredModerator} returns this
*/
proto.jp.singcolor.LiveEventTenuredModerator.prototype.setModerator = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventTenuredModerator} returns this
 */
proto.jp.singcolor.LiveEventTenuredModerator.prototype.clearModerator = function() {
  return this.setModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventTenuredModerator.prototype.hasModerator = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveEventTerminatedModerator.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveEventTerminatedModerator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveEventTerminatedModerator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventTerminatedModerator.toObject = function(includeInstance, msg) {
  var f, obj = {
    liverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    moderator: (f = msg.getModerator()) && users_entity_pb.Moderator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveEventTerminatedModerator}
 */
proto.jp.singcolor.LiveEventTerminatedModerator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveEventTerminatedModerator;
  return proto.jp.singcolor.LiveEventTerminatedModerator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveEventTerminatedModerator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveEventTerminatedModerator}
 */
proto.jp.singcolor.LiveEventTerminatedModerator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiverId(value);
      break;
    case 2:
      var value = new users_entity_pb.Moderator;
      reader.readMessage(value,users_entity_pb.Moderator.deserializeBinaryFromReader);
      msg.setModerator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveEventTerminatedModerator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveEventTerminatedModerator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveEventTerminatedModerator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveEventTerminatedModerator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiverId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModerator();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users_entity_pb.Moderator.serializeBinaryToWriter
    );
  }
};


/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.LiveEventTerminatedModerator.prototype.getLiverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveEventTerminatedModerator} returns this
 */
proto.jp.singcolor.LiveEventTerminatedModerator.prototype.setLiverId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Moderator moderator = 2;
 * @return {?proto.jp.singcolor.Moderator}
 */
proto.jp.singcolor.LiveEventTerminatedModerator.prototype.getModerator = function() {
  return /** @type{?proto.jp.singcolor.Moderator} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.Moderator, 2));
};


/**
 * @param {?proto.jp.singcolor.Moderator|undefined} value
 * @return {!proto.jp.singcolor.LiveEventTerminatedModerator} returns this
*/
proto.jp.singcolor.LiveEventTerminatedModerator.prototype.setModerator = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LiveEventTerminatedModerator} returns this
 */
proto.jp.singcolor.LiveEventTerminatedModerator.prototype.clearModerator = function() {
  return this.setModerator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LiveEventTerminatedModerator.prototype.hasModerator = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.HeartfulMentionButton.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.HeartfulMentionButton.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.HeartfulMentionButton} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.HeartfulMentionButton.toObject = function(includeInstance, msg) {
  var f, obj = {
    heartfulMentionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    buttonLabel: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.HeartfulMentionButton}
 */
proto.jp.singcolor.HeartfulMentionButton.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.HeartfulMentionButton;
  return proto.jp.singcolor.HeartfulMentionButton.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.HeartfulMentionButton} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.HeartfulMentionButton}
 */
proto.jp.singcolor.HeartfulMentionButton.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeartfulMentionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setButtonLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.HeartfulMentionButton.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.HeartfulMentionButton.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.HeartfulMentionButton} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.HeartfulMentionButton.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeartfulMentionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getButtonLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string heartful_mention_id = 1;
 * @return {string}
 */
proto.jp.singcolor.HeartfulMentionButton.prototype.getHeartfulMentionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.HeartfulMentionButton} returns this
 */
proto.jp.singcolor.HeartfulMentionButton.prototype.setHeartfulMentionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string button_label = 2;
 * @return {string}
 */
proto.jp.singcolor.HeartfulMentionButton.prototype.getButtonLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.HeartfulMentionButton} returns this
 */
proto.jp.singcolor.HeartfulMentionButton.prototype.setButtonLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.ReactionGiftButton.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.ReactionGiftButton.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.ReactionGiftButton} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ReactionGiftButton.toObject = function(includeInstance, msg) {
  var f, obj = {
    gift: (f = msg.getGift()) && proto.jp.singcolor.Gift.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ReactionGiftButton}
 */
proto.jp.singcolor.ReactionGiftButton.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.ReactionGiftButton;
  return proto.jp.singcolor.ReactionGiftButton.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ReactionGiftButton} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ReactionGiftButton}
 */
proto.jp.singcolor.ReactionGiftButton.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jp.singcolor.Gift;
      reader.readMessage(value,proto.jp.singcolor.Gift.deserializeBinaryFromReader);
      msg.setGift(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ReactionGiftButton.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.ReactionGiftButton.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ReactionGiftButton} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ReactionGiftButton.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGift();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.jp.singcolor.Gift.serializeBinaryToWriter
    );
  }
};


/**
 * optional Gift gift = 1;
 * @return {?proto.jp.singcolor.Gift}
 */
proto.jp.singcolor.ReactionGiftButton.prototype.getGift = function() {
  return /** @type{?proto.jp.singcolor.Gift} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.Gift, 1));
};


/**
 * @param {?proto.jp.singcolor.Gift|undefined} value
 * @return {!proto.jp.singcolor.ReactionGiftButton} returns this
*/
proto.jp.singcolor.ReactionGiftButton.prototype.setGift = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ReactionGiftButton} returns this
 */
proto.jp.singcolor.ReactionGiftButton.prototype.clearGift = function() {
  return this.setGift(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ReactionGiftButton.prototype.hasGift = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiveBackground.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiveBackground.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiveBackground} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveBackground.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveBackgroundType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    backgroundImageUrl: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiveBackground}
 */
proto.jp.singcolor.LiveBackground.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiveBackground;
  return proto.jp.singcolor.LiveBackground.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiveBackground} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiveBackground}
 */
proto.jp.singcolor.LiveBackground.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.jp.singcolor.LiveBackgroundType} */ (reader.readEnum());
      msg.setLiveBackgroundType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiveBackground.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiveBackground.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiveBackground} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiveBackground.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveBackgroundType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBackgroundImageUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional LiveBackgroundType live_background_type = 1;
 * @return {!proto.jp.singcolor.LiveBackgroundType}
 */
proto.jp.singcolor.LiveBackground.prototype.getLiveBackgroundType = function() {
  return /** @type {!proto.jp.singcolor.LiveBackgroundType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.jp.singcolor.LiveBackgroundType} value
 * @return {!proto.jp.singcolor.LiveBackground} returns this
 */
proto.jp.singcolor.LiveBackground.prototype.setLiveBackgroundType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string background_image_url = 7;
 * @return {string}
 */
proto.jp.singcolor.LiveBackground.prototype.getBackgroundImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiveBackground} returns this
 */
proto.jp.singcolor.LiveBackground.prototype.setBackgroundImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LivePermission.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LivePermission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LivePermission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LivePermission.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    permitted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    trialInfo: (f = msg.getTrialInfo()) && proto.jp.singcolor.LivePermission.TrialInfo.toObject(includeInstance, f),
    secondReviewInfo: (f = msg.getSecondReviewInfo()) && proto.jp.singcolor.LivePermission.SecondReviewInfo.toObject(includeInstance, f),
    failedSecondReviewInfo: (f = msg.getFailedSecondReviewInfo()) && proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LivePermission}
 */
proto.jp.singcolor.LivePermission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LivePermission;
  return proto.jp.singcolor.LivePermission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LivePermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LivePermission}
 */
proto.jp.singcolor.LivePermission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPermitted(value);
      break;
    case 5:
      var value = /** @type {!proto.jp.singcolor.LivePermission.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = new proto.jp.singcolor.LivePermission.TrialInfo;
      reader.readMessage(value,proto.jp.singcolor.LivePermission.TrialInfo.deserializeBinaryFromReader);
      msg.setTrialInfo(value);
      break;
    case 7:
      var value = new proto.jp.singcolor.LivePermission.SecondReviewInfo;
      reader.readMessage(value,proto.jp.singcolor.LivePermission.SecondReviewInfo.deserializeBinaryFromReader);
      msg.setSecondReviewInfo(value);
      break;
    case 8:
      var value = new proto.jp.singcolor.LivePermission.FailedSecondReviewInfo;
      reader.readMessage(value,proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.deserializeBinaryFromReader);
      msg.setFailedSecondReviewInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LivePermission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LivePermission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LivePermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LivePermission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPermitted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTrialInfo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.jp.singcolor.LivePermission.TrialInfo.serializeBinaryToWriter
    );
  }
  f = message.getSecondReviewInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.jp.singcolor.LivePermission.SecondReviewInfo.serializeBinaryToWriter
    );
  }
  f = message.getFailedSecondReviewInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.jp.singcolor.LivePermission.Status = {
  NOT_REVIEWED: 0,
  PASSED_FIRST_REVIEW: 1,
  SECOND_REVIEW_TRIAL: 2,
  SECOND_REVIEW: 3,
  PASSED_SECOND_REVIEW: 4,
  FAILED_SECOND_REVIEW: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LivePermission.TrialInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LivePermission.TrialInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LivePermission.TrialInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LivePermission.TrialInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    trialDayCount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LivePermission.TrialInfo}
 */
proto.jp.singcolor.LivePermission.TrialInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LivePermission.TrialInfo;
  return proto.jp.singcolor.LivePermission.TrialInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LivePermission.TrialInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LivePermission.TrialInfo}
 */
proto.jp.singcolor.LivePermission.TrialInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTrialDayCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LivePermission.TrialInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LivePermission.TrialInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LivePermission.TrialInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LivePermission.TrialInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrialDayCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 trial_day_count = 1;
 * @return {number}
 */
proto.jp.singcolor.LivePermission.TrialInfo.prototype.getTrialDayCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LivePermission.TrialInfo} returns this
 */
proto.jp.singcolor.LivePermission.TrialInfo.prototype.setTrialDayCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LivePermission.SecondReviewInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LivePermission.SecondReviewInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LivePermission.SecondReviewInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LivePermission.SecondReviewInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    endYear: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endMonth: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endTermOfMonth: jspb.Message.getFieldWithDefault(msg, 3, 0),
    remainingDays: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LivePermission.SecondReviewInfo}
 */
proto.jp.singcolor.LivePermission.SecondReviewInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LivePermission.SecondReviewInfo;
  return proto.jp.singcolor.LivePermission.SecondReviewInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LivePermission.SecondReviewInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LivePermission.SecondReviewInfo}
 */
proto.jp.singcolor.LivePermission.SecondReviewInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndMonth(value);
      break;
    case 3:
      var value = /** @type {!proto.jp.singcolor.TermOfMonth} */ (reader.readEnum());
      msg.setEndTermOfMonth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRemainingDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LivePermission.SecondReviewInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LivePermission.SecondReviewInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LivePermission.SecondReviewInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LivePermission.SecondReviewInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEndYear();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndMonth();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndTermOfMonth();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRemainingDays();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 end_year = 1;
 * @return {number}
 */
proto.jp.singcolor.LivePermission.SecondReviewInfo.prototype.getEndYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LivePermission.SecondReviewInfo} returns this
 */
proto.jp.singcolor.LivePermission.SecondReviewInfo.prototype.setEndYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_month = 2;
 * @return {number}
 */
proto.jp.singcolor.LivePermission.SecondReviewInfo.prototype.getEndMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LivePermission.SecondReviewInfo} returns this
 */
proto.jp.singcolor.LivePermission.SecondReviewInfo.prototype.setEndMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional TermOfMonth end_term_of_month = 3;
 * @return {!proto.jp.singcolor.TermOfMonth}
 */
proto.jp.singcolor.LivePermission.SecondReviewInfo.prototype.getEndTermOfMonth = function() {
  return /** @type {!proto.jp.singcolor.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.jp.singcolor.TermOfMonth} value
 * @return {!proto.jp.singcolor.LivePermission.SecondReviewInfo} returns this
 */
proto.jp.singcolor.LivePermission.SecondReviewInfo.prototype.setEndTermOfMonth = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 remaining_days = 4;
 * @return {number}
 */
proto.jp.singcolor.LivePermission.SecondReviewInfo.prototype.getRemainingDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LivePermission.SecondReviewInfo} returns this
 */
proto.jp.singcolor.LivePermission.SecondReviewInfo.prototype.setRemainingDays = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LivePermission.FailedSecondReviewInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    nextReviewStartYear: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nextReviewStartMonth: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextReviewStartTermOfMonth: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LivePermission.FailedSecondReviewInfo}
 */
proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LivePermission.FailedSecondReviewInfo;
  return proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LivePermission.FailedSecondReviewInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LivePermission.FailedSecondReviewInfo}
 */
proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextReviewStartYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextReviewStartMonth(value);
      break;
    case 3:
      var value = /** @type {!proto.jp.singcolor.TermOfMonth} */ (reader.readEnum());
      msg.setNextReviewStartTermOfMonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LivePermission.FailedSecondReviewInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNextReviewStartYear();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNextReviewStartMonth();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNextReviewStartTermOfMonth();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 next_review_start_year = 1;
 * @return {number}
 */
proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.prototype.getNextReviewStartYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LivePermission.FailedSecondReviewInfo} returns this
 */
proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.prototype.setNextReviewStartYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 next_review_start_month = 2;
 * @return {number}
 */
proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.prototype.getNextReviewStartMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LivePermission.FailedSecondReviewInfo} returns this
 */
proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.prototype.setNextReviewStartMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional TermOfMonth next_review_start_term_of_month = 3;
 * @return {!proto.jp.singcolor.TermOfMonth}
 */
proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.prototype.getNextReviewStartTermOfMonth = function() {
  return /** @type {!proto.jp.singcolor.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.jp.singcolor.TermOfMonth} value
 * @return {!proto.jp.singcolor.LivePermission.FailedSecondReviewInfo} returns this
 */
proto.jp.singcolor.LivePermission.FailedSecondReviewInfo.prototype.setNextReviewStartTermOfMonth = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.LivePermission.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LivePermission} returns this
 */
proto.jp.singcolor.LivePermission.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool permitted = 4;
 * @return {boolean}
 */
proto.jp.singcolor.LivePermission.prototype.getPermitted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LivePermission} returns this
 */
proto.jp.singcolor.LivePermission.prototype.setPermitted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional Status status = 5;
 * @return {!proto.jp.singcolor.LivePermission.Status}
 */
proto.jp.singcolor.LivePermission.prototype.getStatus = function() {
  return /** @type {!proto.jp.singcolor.LivePermission.Status} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.jp.singcolor.LivePermission.Status} value
 * @return {!proto.jp.singcolor.LivePermission} returns this
 */
proto.jp.singcolor.LivePermission.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional TrialInfo trial_info = 6;
 * @return {?proto.jp.singcolor.LivePermission.TrialInfo}
 */
proto.jp.singcolor.LivePermission.prototype.getTrialInfo = function() {
  return /** @type{?proto.jp.singcolor.LivePermission.TrialInfo} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LivePermission.TrialInfo, 6));
};


/**
 * @param {?proto.jp.singcolor.LivePermission.TrialInfo|undefined} value
 * @return {!proto.jp.singcolor.LivePermission} returns this
*/
proto.jp.singcolor.LivePermission.prototype.setTrialInfo = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LivePermission} returns this
 */
proto.jp.singcolor.LivePermission.prototype.clearTrialInfo = function() {
  return this.setTrialInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LivePermission.prototype.hasTrialInfo = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SecondReviewInfo second_review_info = 7;
 * @return {?proto.jp.singcolor.LivePermission.SecondReviewInfo}
 */
proto.jp.singcolor.LivePermission.prototype.getSecondReviewInfo = function() {
  return /** @type{?proto.jp.singcolor.LivePermission.SecondReviewInfo} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LivePermission.SecondReviewInfo, 7));
};


/**
 * @param {?proto.jp.singcolor.LivePermission.SecondReviewInfo|undefined} value
 * @return {!proto.jp.singcolor.LivePermission} returns this
*/
proto.jp.singcolor.LivePermission.prototype.setSecondReviewInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LivePermission} returns this
 */
proto.jp.singcolor.LivePermission.prototype.clearSecondReviewInfo = function() {
  return this.setSecondReviewInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LivePermission.prototype.hasSecondReviewInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional FailedSecondReviewInfo failed_second_review_info = 8;
 * @return {?proto.jp.singcolor.LivePermission.FailedSecondReviewInfo}
 */
proto.jp.singcolor.LivePermission.prototype.getFailedSecondReviewInfo = function() {
  return /** @type{?proto.jp.singcolor.LivePermission.FailedSecondReviewInfo} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LivePermission.FailedSecondReviewInfo, 8));
};


/**
 * @param {?proto.jp.singcolor.LivePermission.FailedSecondReviewInfo|undefined} value
 * @return {!proto.jp.singcolor.LivePermission} returns this
*/
proto.jp.singcolor.LivePermission.prototype.setFailedSecondReviewInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.LivePermission} returns this
 */
proto.jp.singcolor.LivePermission.prototype.clearFailedSecondReviewInfo = function() {
  return this.setFailedSecondReviewInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.LivePermission.prototype.hasFailedSecondReviewInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * @enum {number}
 */
proto.jp.singcolor.ShareTwitterDeleteSetting = {
  SHARE_TWITER_DELETE_SETTING_UNKNOWN: 0,
  SHARE_TWITER_DELETE_SETTING_DISABLED: 1,
  SHARE_TWITER_DELETE_SETTING_ENABLED_1_DAY: 2,
  SHARE_TWITER_DELETE_SETTING_ENABLED_ONE_TIME: 3
};

/**
 * @enum {number}
 */
proto.jp.singcolor.LiveFinishReason = {
  LIVE_FINISH_REASON_UNKNOWN: 0,
  LIVE_FINISH_REASON_NORMAL: 1,
  LIVE_FINISH_REASON_BANNED: 2,
  LIVE_FINISH_REASON_OPE: 3,
  LIVE_FINISH_REASON_BLOCKED: 4,
  LIVE_FINISH_REASON_BLOCKING: 5,
  LIVE_FINISH_REASON_MAINTENANCE: 6,
  LIVE_FINISH_REASON_NOT_AVAILABLE_TIME: 7,
  LIVE_FINISH_REASON_BLOCKED_BY_MODERATOR: 8
};

/**
 * @enum {number}
 */
proto.jp.singcolor.LiveEventType = {
  LIVE_EVENT_TYPE_UNKNOWN: 0,
  LIVE_EVENT_TYPE_SYSTEM: 1,
  LIVE_EVENT_TYPE_START: 2,
  LIVE_EVENT_TYPE_FINISH: 3,
  LIVE_EVENT_TYPE_ENTER: 4,
  LIVE_EVENT_TYPE_LEAVE: 5,
  LIVE_EVENT_TYPE_COMMENT: 6,
  LIVE_EVENT_TYPE_SMALL_GIFT: 7,
  LIVE_EVENT_TYPE_LARGE_GIFT: 8,
  LIVE_EVENT_TYPE_START_SING: 9,
  LIVE_EVENT_TYPE_FINISH_SING: 10,
  LIVE_EVENT_TYPE_GET_FAVE_BONUS: 11,
  LIVE_EVENT_TYPE_GET_FAVE_BONUS_TITLE: 12,
  LIVE_EVENT_TYPE_GET_FAVE_POINTS: 13,
  LIVE_EVENT_TYPE_GET_FAVE_BADGE: 14,
  LIVE_EVENT_TYPE_GET_SONG_FAVE: 15,
  LIVE_EVENT_TYPE_FOLLOW: 16,
  LIVE_EVENT_TYPE_LIKE: 17,
  LIVE_EVENT_TYPE_SUPER_LIKE: 18,
  LIVE_EVENT_TYPE_ANNOUNCE: 19,
  LIVE_EVENT_TYPE_SURGE_RANKING_NO1_REWARD: 20,
  LIVE_EVENT_TYPE_REQUEST_SING: 21,
  LIVE_EVENT_TYPE_SIMPLE_PF_COMMENT: 22,
  LIVE_EVENT_TYPE_GET_FAVE_BADGE_AND_SONG_FAVE: 23,
  LIVE_EVENT_TYPE_HEARTFUL_MENTION: 24,
  LIVE_EVENT_TYPE_LIVE_SETTINGS_UPDATED: 25,
  LIVE_EVENT_TYPE_REQ_CAPTURE_POSSIBILITY_UPDATED: 26,
  LIVE_EVENT_TYPE_PICK_UP_SONG_RANDOMLY: 27,
  LIVE_EVENT_TYPE_MEMBERSHIP_UPDATED: 29,
  LIVE_EVENT_TYPE_GET_SONG_RANDOMLY: 30,
  LIVE_EVENT_TYPE_BAN_LISTENER_COMMENT_BY_MODERATOR: 31,
  LIVE_EVENT_TYPE_UNBAN_LISTENER_COMMENT_BY_MODERATOR: 32,
  LIVE_EVENT_TYPE_BAN_LISTENER_REQ_CAPTURE_BY_MODERATOR: 33,
  LIVE_EVENT_TYPE_UNBAN_LISTENER_REQ_CAPTURE_BY_MODERATOR: 34,
  LIVE_EVENT_TYPE_DO_BLOCK_BY_MODERATOR: 35,
  LIVE_EVENT_TYPE_UNBLOCK_BY_MODERATOR: 36,
  LIVE_EVENT_TYPE_TENURED_MODERATOR: 37,
  LIVE_EVENT_TYPE_TERMINATE_MODERATOR: 38
};

/**
 * @enum {number}
 */
proto.jp.singcolor.FaveBonusTitle = {
  FAVE_BONUS_TITLE_NONE: 0,
  FAVE_BONUS_TITLE_EXPERT: 1,
  FAVE_BONUS_TITLE_MASTER: 2,
  FAVE_BONUS_TITLE_SUPERMAN: 3,
  FAVE_BONUS_TITLE_DEMON: 4,
  FAVE_BONUS_TITLE_DEMON_LORD: 5,
  FAVE_BONUS_TITLE_GREAT_DEMON_LORD: 6,
  FAVE_BONUS_TITLE_DEMON_GOD: 7,
  FAVE_BONUS_TITLE_GREAT_DEMON_GOD: 8
};

/**
 * @enum {number}
 */
proto.jp.singcolor.LiveEventSimplePFCommentType = {
  LIVE_EVENT_SIMPLE_PF_COMMENT_TYPE_UNKNOWN: 0,
  LIVE_EVENT_SIMPLE_PF_COMMENT_TYPE_LIVE_TIMELINE_BANNER_PICK_UP: 1
};

/**
 * @enum {number}
 */
proto.jp.singcolor.GiftKind = {
  GIFT_KIND_NORMAL: 0,
  GIFT_KIND_REACTION: 1,
  GIFT_KIND_GOSPEL_REACTION: 2
};

/**
 * @enum {number}
 */
proto.jp.singcolor.GiftType = {
  GIFT_TYPE_UNKNOWN: 0,
  GIFT_TYPE_SMALL: 1,
  GIFT_TYPE_MIDDLE: 2,
  GIFT_TYPE_LARGE: 3
};

/**
 * @enum {number}
 */
proto.jp.singcolor.SmallGiftType = {
  SMALL_GIFT_TYPE_UNKNOWN: 0,
  SMALL_GIFT_TYPE_DEFAULT: 1,
  SMALL_GIFT_TYPE_BIG: 2,
  SMALL_GIFT_TYPE_BIG_HEART: 3
};

/**
 * @enum {number}
 */
proto.jp.singcolor.GiftTabType = {
  GIFT_TAB_TYPE_UNKNOWN: 0,
  GIFT_TAB_TYPE_HOME: 1,
  GIFT_TAB_TYPE_KANA: 2,
  GIFT_TAB_TYPE_PRICE: 3,
  GIFT_TAB_TYPE_CATEGORY: 4,
  GIFT_TAB_TYPE_NEW: 5
};

/**
 * @enum {number}
 */
proto.jp.singcolor.GiftTabSectionType = {
  GIFT_TAB_SECTION_TYPE_UNKNOWN: 0,
  GIFT_TAB_SECTION_TYPE_ALL: 1,
  GIFT_TAB_SECTION_TYPE_RECOMMEND: 2,
  GIFT_TAB_SECTION_TYPE_HISTORY: 3,
  GIFT_TAB_SECTION_TYPE_USE_FREQUENTLY_S: 4,
  GIFT_TAB_SECTION_TYPE_POPULAR_S: 5,
  GIFT_TAB_SECTION_TYPE_USE_FREQUENTLY_ML: 6,
  GIFT_TAB_SECTION_TYPE_POPULAR_ML: 7,
  GIFT_TAB_SECTION_TYPE_KANA_S: 8,
  GIFT_TAB_SECTION_TYPE_KANA_M: 9,
  GIFT_TAB_SECTION_TYPE_KANA_L: 10,
  GIFT_TAB_SECTION_TYPE_PRICE: 11
};

/**
 * @enum {number}
 */
proto.jp.singcolor.SongYellBadge = {
  SONG_YELL_BADGE_UNKNOWN: 0,
  SONG_YELL_BADGE_LV1: 1,
  SONG_YELL_BADGE_LV2: 2,
  SONG_YELL_BADGE_LV3: 3,
  SONG_YELL_BADGE_LV4: 4,
  SONG_YELL_BADGE_LV5: 5
};

/**
 * @enum {number}
 */
proto.jp.singcolor.TermOfMonth = {
  TERM_OF_MONTH_UNKNOWN: 0,
  TERM_OF_MONTH_FIRST: 1,
  TERM_OF_MONTH_SECOND: 2,
  TERM_OF_MONTH_THIRD: 3
};

/**
 * @enum {number}
 */
proto.jp.singcolor.WorldFilter = {
  WORLD_FILTER_ALL: 0,
  WORLD_FILTER_RADIO_MODE: 1,
  WORLD_FILTER_V_TUVER: 2,
  WORLD_FILTER_RADIO_AND_V: 3,
  WORLD_FILTER_CAMERA_MODE: 4
};

/**
 * @enum {number}
 */
proto.jp.singcolor.RankingEventStatus = {
  RANKING_EVENT_STATUS_NONE: 0,
  RANKING_EVENT_STATUS_ONGOING: 1,
  RANKING_EVENT_STATUS_CALCULATING: 2,
  RANKING_EVENT_STATUS_CONFIRMED: 3
};

/**
 * @enum {number}
 */
proto.jp.singcolor.LiveBackgroundType = {
  LIVE_BACKGROUND_TYPE_NONE: 0,
  LIVE_BACKGROUND_TYPE_SIMPLE: 1,
  LIVE_BACKGROUND_TYPE_CUSTOM: 2
};

/**
 * @enum {number}
 */
proto.jp.singcolor.LiveMode = {
  LIVE_MODE_TYPE_NONE: 0,
  LIVE_MODE_TYPE_DEFAULT_VIDEO: 1,
  LIVE_MODE_TYPE_RADIO: 2,
  LIVE_MODE_TYPE_VTUBER: 3
};

goog.object.extend(exports, proto.jp.singcolor);
