"use strict";
// source: diamonds_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var coins_entity_pb = require('./coins_entity_pb.js');
goog.object.extend(proto, coins_entity_pb);
var diamonds_entity_pb = require('./diamonds_entity_pb.js');
goog.object.extend(proto, diamonds_entity_pb);
var page_entity_pb = require('./page_entity_pb.js');
goog.object.extend(proto, page_entity_pb);
var users_entity_pb = require('./users_entity_pb.js');
goog.object.extend(proto, users_entity_pb);
goog.exportSymbol('proto.jp.singcolor.CreateBankAccountV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateBankAccountV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteBankAccountV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteBankAccountV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ExchangeDiamondStatusV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ExchangeDiamondStatusV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount', null, global);
goog.exportSymbol('proto.jp.singcolor.ExchangeDiamondToCoinV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ExchangeDiamondToCoinV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetBankV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetBankV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetEstimatedExchangeRateV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetEstimatedExchangeRateV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetWithdrawDiamondStatusV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetWithdrawDiamondStatusV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListBankAccountsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListBankAccountsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListBankBranchesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListBankBranchesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListBanksV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListBanksV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListMajorBanksV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListMajorBanksV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListStatementsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListStatementsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.StatementsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.StatementsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.StatementsV1Response.Statement', null, global);
goog.exportSymbol('proto.jp.singcolor.WithdrawDiamondV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.WithdrawDiamondV1Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ExchangeDiamondStatusV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ExchangeDiamondStatusV1Request.displayName = 'proto.jp.singcolor.ExchangeDiamondStatusV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ExchangeDiamondStatusV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ExchangeDiamondStatusV1Response.displayName = 'proto.jp.singcolor.ExchangeDiamondStatusV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.displayName = 'proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetEstimatedExchangeRateV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetEstimatedExchangeRateV1Request.displayName = 'proto.jp.singcolor.GetEstimatedExchangeRateV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetEstimatedExchangeRateV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetEstimatedExchangeRateV1Response.displayName = 'proto.jp.singcolor.GetEstimatedExchangeRateV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetWithdrawDiamondStatusV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetWithdrawDiamondStatusV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetWithdrawDiamondStatusV1Request.displayName = 'proto.jp.singcolor.GetWithdrawDiamondStatusV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetWithdrawDiamondStatusV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetWithdrawDiamondStatusV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetWithdrawDiamondStatusV1Response.displayName = 'proto.jp.singcolor.GetWithdrawDiamondStatusV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.WithdrawDiamondV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.WithdrawDiamondV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.WithdrawDiamondV1Request.displayName = 'proto.jp.singcolor.WithdrawDiamondV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.WithdrawDiamondV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.WithdrawDiamondV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.WithdrawDiamondV1Response.displayName = 'proto.jp.singcolor.WithdrawDiamondV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request.displayName = 'proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response.displayName = 'proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request.displayName = 'proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.displayName = 'proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ExchangeDiamondToCoinV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ExchangeDiamondToCoinV1Request.displayName = 'proto.jp.singcolor.ExchangeDiamondToCoinV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ExchangeDiamondToCoinV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ExchangeDiamondToCoinV1Response.displayName = 'proto.jp.singcolor.ExchangeDiamondToCoinV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.StatementsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.StatementsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.StatementsV1Request.displayName = 'proto.jp.singcolor.StatementsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.StatementsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.StatementsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.StatementsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.StatementsV1Response.displayName = 'proto.jp.singcolor.StatementsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.StatementsV1Response.Statement = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.StatementsV1Response.Statement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.StatementsV1Response.Statement.displayName = 'proto.jp.singcolor.StatementsV1Response.Statement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListMajorBanksV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListMajorBanksV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListMajorBanksV1Request.displayName = 'proto.jp.singcolor.ListMajorBanksV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListMajorBanksV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListMajorBanksV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListMajorBanksV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListMajorBanksV1Response.displayName = 'proto.jp.singcolor.ListMajorBanksV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetBankV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetBankV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetBankV1Request.displayName = 'proto.jp.singcolor.GetBankV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetBankV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetBankV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetBankV1Response.displayName = 'proto.jp.singcolor.GetBankV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListBanksV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListBanksV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListBanksV1Request.displayName = 'proto.jp.singcolor.ListBanksV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListBanksV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListBanksV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListBanksV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListBanksV1Response.displayName = 'proto.jp.singcolor.ListBanksV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListBankBranchesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListBankBranchesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListBankBranchesV1Request.displayName = 'proto.jp.singcolor.ListBankBranchesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListBankBranchesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListBankBranchesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListBankBranchesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListBankBranchesV1Response.displayName = 'proto.jp.singcolor.ListBankBranchesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateBankAccountV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateBankAccountV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateBankAccountV1Request.displayName = 'proto.jp.singcolor.CreateBankAccountV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateBankAccountV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateBankAccountV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateBankAccountV1Response.displayName = 'proto.jp.singcolor.CreateBankAccountV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListBankAccountsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListBankAccountsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListBankAccountsV1Request.displayName = 'proto.jp.singcolor.ListBankAccountsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListBankAccountsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListBankAccountsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListBankAccountsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListBankAccountsV1Response.displayName = 'proto.jp.singcolor.ListBankAccountsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteBankAccountV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteBankAccountV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteBankAccountV1Request.displayName = 'proto.jp.singcolor.DeleteBankAccountV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteBankAccountV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteBankAccountV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteBankAccountV1Response.displayName = 'proto.jp.singcolor.DeleteBankAccountV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListStatementsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListStatementsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListStatementsV1Request.displayName = 'proto.jp.singcolor.ListStatementsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListStatementsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListStatementsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListStatementsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListStatementsV1Response.displayName = 'proto.jp.singcolor.ListStatementsV1Response';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ExchangeDiamondStatusV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ExchangeDiamondStatusV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ExchangeDiamondStatusV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ExchangeDiamondStatusV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Request}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ExchangeDiamondStatusV1Request;
    return proto.jp.singcolor.ExchangeDiamondStatusV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ExchangeDiamondStatusV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Request}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ExchangeDiamondStatusV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ExchangeDiamondStatusV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ExchangeDiamondStatusV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ExchangeDiamondStatusV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ExchangeDiamondStatusV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            diamondBalance: (f = msg.getDiamondBalance()) && diamonds_entity_pb.DiamondBalance.toObject(includeInstance, f),
            coinBalance: (f = msg.getCoinBalance()) && coins_entity_pb.CoinBalance.toObject(includeInstance, f),
            normalDiamondExchangeToCoinPlanAndAmount: (f = msg.getNormalDiamondExchangeToCoinPlanAndAmount()) && proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.toObject(includeInstance, f),
            organizationDiamondExchangeToCoinPlanAndAmount: (f = msg.getOrganizationDiamondExchangeToCoinPlanAndAmount()) && proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.toObject(includeInstance, f),
            totalExchangeableDiamond: (f = msg.getTotalExchangeableDiamond()) && diamonds_entity_pb.DiamondAmount.toObject(includeInstance, f),
            bankAccountCreditCheckStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
            isAgreedTaxWithholding: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ExchangeDiamondStatusV1Response;
    return proto.jp.singcolor.ExchangeDiamondStatusV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ExchangeDiamondStatusV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.DiamondBalance;
                reader.readMessage(value, diamonds_entity_pb.DiamondBalance.deserializeBinaryFromReader);
                msg.setDiamondBalance(value);
                break;
            case 2:
                var value = new coins_entity_pb.CoinBalance;
                reader.readMessage(value, coins_entity_pb.CoinBalance.deserializeBinaryFromReader);
                msg.setCoinBalance(value);
                break;
            case 3:
                var value = new proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount;
                reader.readMessage(value, proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.deserializeBinaryFromReader);
                msg.setNormalDiamondExchangeToCoinPlanAndAmount(value);
                break;
            case 4:
                var value = new proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount;
                reader.readMessage(value, proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.deserializeBinaryFromReader);
                msg.setOrganizationDiamondExchangeToCoinPlanAndAmount(value);
                break;
            case 5:
                var value = new diamonds_entity_pb.DiamondAmount;
                reader.readMessage(value, diamonds_entity_pb.DiamondAmount.deserializeBinaryFromReader);
                msg.setTotalExchangeableDiamond(value);
                break;
            case 6:
                var value = /** @type {!proto.jp.singcolor.BankAccountCreditCheckStatus} */ (reader.readEnum());
                msg.setBankAccountCreditCheckStatus(value);
                break;
            case 7:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsAgreedTaxWithholding(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ExchangeDiamondStatusV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ExchangeDiamondStatusV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDiamondBalance();
    if (f != null) {
        writer.writeMessage(1, f, diamonds_entity_pb.DiamondBalance.serializeBinaryToWriter);
    }
    f = message.getCoinBalance();
    if (f != null) {
        writer.writeMessage(2, f, coins_entity_pb.CoinBalance.serializeBinaryToWriter);
    }
    f = message.getNormalDiamondExchangeToCoinPlanAndAmount();
    if (f != null) {
        writer.writeMessage(3, f, proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.serializeBinaryToWriter);
    }
    f = message.getOrganizationDiamondExchangeToCoinPlanAndAmount();
    if (f != null) {
        writer.writeMessage(4, f, proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.serializeBinaryToWriter);
    }
    f = message.getTotalExchangeableDiamond();
    if (f != null) {
        writer.writeMessage(5, f, diamonds_entity_pb.DiamondAmount.serializeBinaryToWriter);
    }
    f = message.getBankAccountCreditCheckStatus();
    if (f !== 0.0) {
        writer.writeEnum(6, f);
    }
    f = message.getIsAgreedTaxWithholding();
    if (f) {
        writer.writeBool(7, f);
    }
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.repeatedFields_ = [2];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.toObject = function (includeInstance, msg) {
        var f, obj = {
            exchangeableDiamond: (f = msg.getExchangeableDiamond()) && diamonds_entity_pb.DiamondAmount.toObject(includeInstance, f),
            diamondExchangeToCoinPlansList: jspb.Message.toObjectList(msg.getDiamondExchangeToCoinPlansList(), diamonds_entity_pb.DiamondExchangeToCoinPlan.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount;
    return proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.DiamondAmount;
                reader.readMessage(value, diamonds_entity_pb.DiamondAmount.deserializeBinaryFromReader);
                msg.setExchangeableDiamond(value);
                break;
            case 2:
                var value = new diamonds_entity_pb.DiamondExchangeToCoinPlan;
                reader.readMessage(value, diamonds_entity_pb.DiamondExchangeToCoinPlan.deserializeBinaryFromReader);
                msg.addDiamondExchangeToCoinPlans(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getExchangeableDiamond();
    if (f != null) {
        writer.writeMessage(1, f, diamonds_entity_pb.DiamondAmount.serializeBinaryToWriter);
    }
    f = message.getDiamondExchangeToCoinPlansList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, diamonds_entity_pb.DiamondExchangeToCoinPlan.serializeBinaryToWriter);
    }
};
/**
 * optional DiamondAmount exchangeable_diamond = 1;
 * @return {?proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.prototype.getExchangeableDiamond = function () {
    return /** @type{?proto.jp.singcolor.DiamondAmount} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondAmount, 1));
};
/**
 * @param {?proto.jp.singcolor.DiamondAmount|undefined} value
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount} returns this
*/
proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.prototype.setExchangeableDiamond = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount} returns this
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.prototype.clearExchangeableDiamond = function () {
    return this.setExchangeableDiamond(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.prototype.hasExchangeableDiamond = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * repeated DiamondExchangeToCoinPlan diamond_exchange_to_coin_plans = 2;
 * @return {!Array<!proto.jp.singcolor.DiamondExchangeToCoinPlan>}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.prototype.getDiamondExchangeToCoinPlansList = function () {
    return /** @type{!Array<!proto.jp.singcolor.DiamondExchangeToCoinPlan>} */ (jspb.Message.getRepeatedWrapperField(this, diamonds_entity_pb.DiamondExchangeToCoinPlan, 2));
};
/**
 * @param {!Array<!proto.jp.singcolor.DiamondExchangeToCoinPlan>} value
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount} returns this
*/
proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.prototype.setDiamondExchangeToCoinPlansList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.jp.singcolor.DiamondExchangeToCoinPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.DiamondExchangeToCoinPlan}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.prototype.addDiamondExchangeToCoinPlans = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jp.singcolor.DiamondExchangeToCoinPlan, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount} returns this
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount.prototype.clearDiamondExchangeToCoinPlansList = function () {
    return this.setDiamondExchangeToCoinPlansList([]);
};
/**
 * optional DiamondBalance diamond_balance = 1;
 * @return {?proto.jp.singcolor.DiamondBalance}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.getDiamondBalance = function () {
    return /** @type{?proto.jp.singcolor.DiamondBalance} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondBalance, 1));
};
/**
 * @param {?proto.jp.singcolor.DiamondBalance|undefined} value
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response} returns this
*/
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.setDiamondBalance = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response} returns this
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.clearDiamondBalance = function () {
    return this.setDiamondBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.hasDiamondBalance = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional CoinBalance coin_balance = 2;
 * @return {?proto.jp.singcolor.CoinBalance}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.getCoinBalance = function () {
    return /** @type{?proto.jp.singcolor.CoinBalance} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinBalance, 2));
};
/**
 * @param {?proto.jp.singcolor.CoinBalance|undefined} value
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response} returns this
*/
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.setCoinBalance = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response} returns this
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.clearCoinBalance = function () {
    return this.setCoinBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.hasCoinBalance = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional DiamondExchangeToCoinPlanAndAmount normal_diamond_exchange_to_coin_plan_and_amount = 3;
 * @return {?proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.getNormalDiamondExchangeToCoinPlanAndAmount = function () {
    return /** @type{?proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount, 3));
};
/**
 * @param {?proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount|undefined} value
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response} returns this
*/
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.setNormalDiamondExchangeToCoinPlanAndAmount = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response} returns this
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.clearNormalDiamondExchangeToCoinPlanAndAmount = function () {
    return this.setNormalDiamondExchangeToCoinPlanAndAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.hasNormalDiamondExchangeToCoinPlanAndAmount = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional DiamondExchangeToCoinPlanAndAmount organization_diamond_exchange_to_coin_plan_and_amount = 4;
 * @return {?proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.getOrganizationDiamondExchangeToCoinPlanAndAmount = function () {
    return /** @type{?proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount, 4));
};
/**
 * @param {?proto.jp.singcolor.ExchangeDiamondStatusV1Response.DiamondExchangeToCoinPlanAndAmount|undefined} value
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response} returns this
*/
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.setOrganizationDiamondExchangeToCoinPlanAndAmount = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response} returns this
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.clearOrganizationDiamondExchangeToCoinPlanAndAmount = function () {
    return this.setOrganizationDiamondExchangeToCoinPlanAndAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.hasOrganizationDiamondExchangeToCoinPlanAndAmount = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional DiamondAmount total_exchangeable_diamond = 5;
 * @return {?proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.getTotalExchangeableDiamond = function () {
    return /** @type{?proto.jp.singcolor.DiamondAmount} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondAmount, 5));
};
/**
 * @param {?proto.jp.singcolor.DiamondAmount|undefined} value
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response} returns this
*/
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.setTotalExchangeableDiamond = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response} returns this
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.clearTotalExchangeableDiamond = function () {
    return this.setTotalExchangeableDiamond(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.hasTotalExchangeableDiamond = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * optional BankAccountCreditCheckStatus bank_account_credit_check_status = 6;
 * @return {!proto.jp.singcolor.BankAccountCreditCheckStatus}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.getBankAccountCreditCheckStatus = function () {
    return /** @type {!proto.jp.singcolor.BankAccountCreditCheckStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {!proto.jp.singcolor.BankAccountCreditCheckStatus} value
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response} returns this
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.setBankAccountCreditCheckStatus = function (value) {
    return jspb.Message.setProto3EnumField(this, 6, value);
};
/**
 * optional bool is_agreed_tax_withholding = 7;
 * @return {boolean}
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.getIsAgreedTaxWithholding = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ExchangeDiamondStatusV1Response} returns this
 */
proto.jp.singcolor.ExchangeDiamondStatusV1Response.prototype.setIsAgreedTaxWithholding = function (value) {
    return jspb.Message.setProto3BooleanField(this, 7, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetEstimatedExchangeRateV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetEstimatedExchangeRateV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetEstimatedExchangeRateV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetEstimatedExchangeRateV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            diamondAmount: jspb.Message.getFieldWithDefault(msg, 1, 0),
            withdrawableDiamondType: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetEstimatedExchangeRateV1Request}
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetEstimatedExchangeRateV1Request;
    return proto.jp.singcolor.GetEstimatedExchangeRateV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetEstimatedExchangeRateV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetEstimatedExchangeRateV1Request}
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDiamondAmount(value);
                break;
            case 2:
                var value = /** @type {!proto.jp.singcolor.WithdrawableDiamondType} */ (reader.readEnum());
                msg.setWithdrawableDiamondType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetEstimatedExchangeRateV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetEstimatedExchangeRateV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDiamondAmount();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getWithdrawableDiamondType();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
};
/**
 * optional int64 diamond_amount = 1;
 * @return {number}
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Request.prototype.getDiamondAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetEstimatedExchangeRateV1Request} returns this
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Request.prototype.setDiamondAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional WithdrawableDiamondType withdrawable_diamond_type = 2;
 * @return {!proto.jp.singcolor.WithdrawableDiamondType}
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Request.prototype.getWithdrawableDiamondType = function () {
    return /** @type {!proto.jp.singcolor.WithdrawableDiamondType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.jp.singcolor.WithdrawableDiamondType} value
 * @return {!proto.jp.singcolor.GetEstimatedExchangeRateV1Request} returns this
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Request.prototype.setWithdrawableDiamondType = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetEstimatedExchangeRateV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetEstimatedExchangeRateV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetEstimatedExchangeRateV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetEstimatedExchangeRateV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            exchangeRate: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetEstimatedExchangeRateV1Response}
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetEstimatedExchangeRateV1Response;
    return proto.jp.singcolor.GetEstimatedExchangeRateV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetEstimatedExchangeRateV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetEstimatedExchangeRateV1Response}
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setExchangeRate(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetEstimatedExchangeRateV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetEstimatedExchangeRateV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getExchangeRate();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string exchange_rate = 1;
 * @return {string}
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Response.prototype.getExchangeRate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetEstimatedExchangeRateV1Response} returns this
 */
proto.jp.singcolor.GetEstimatedExchangeRateV1Response.prototype.setExchangeRate = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetWithdrawDiamondStatusV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetWithdrawDiamondStatusV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetWithdrawDiamondStatusV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetWithdrawDiamondStatusV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetWithdrawDiamondStatusV1Request}
 */
proto.jp.singcolor.GetWithdrawDiamondStatusV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetWithdrawDiamondStatusV1Request;
    return proto.jp.singcolor.GetWithdrawDiamondStatusV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetWithdrawDiamondStatusV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetWithdrawDiamondStatusV1Request}
 */
proto.jp.singcolor.GetWithdrawDiamondStatusV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetWithdrawDiamondStatusV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetWithdrawDiamondStatusV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetWithdrawDiamondStatusV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetWithdrawDiamondStatusV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetWithdrawDiamondStatusV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetWithdrawDiamondStatusV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetWithdrawDiamondStatusV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetWithdrawDiamondStatusV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            hasWithdrewDiamondsThisWeekMapMap: (f = msg.getHasWithdrewDiamondsThisWeekMapMap()) ? f.toObject(includeInstance, undefined) : []
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetWithdrawDiamondStatusV1Response}
 */
proto.jp.singcolor.GetWithdrawDiamondStatusV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetWithdrawDiamondStatusV1Response;
    return proto.jp.singcolor.GetWithdrawDiamondStatusV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetWithdrawDiamondStatusV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetWithdrawDiamondStatusV1Response}
 */
proto.jp.singcolor.GetWithdrawDiamondStatusV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = msg.getHasWithdrewDiamondsThisWeekMapMap();
                reader.readMessage(value, function (message, reader) {
                    jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readBool, null, 0, false);
                });
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetWithdrawDiamondStatusV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetWithdrawDiamondStatusV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetWithdrawDiamondStatusV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetWithdrawDiamondStatusV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getHasWithdrewDiamondsThisWeekMapMap(true);
    if (f && f.getLength() > 0) {
        f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeBool);
    }
};
/**
 * map<int32, bool> has_withdrew_diamonds_this_week_map = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,boolean>}
 */
proto.jp.singcolor.GetWithdrawDiamondStatusV1Response.prototype.getHasWithdrewDiamondsThisWeekMapMap = function (opt_noLazyCreate) {
    return /** @type {!jspb.Map<number,boolean>} */ (jspb.Message.getMapField(this, 1, opt_noLazyCreate, null));
};
/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.jp.singcolor.GetWithdrawDiamondStatusV1Response} returns this
 */
proto.jp.singcolor.GetWithdrawDiamondStatusV1Response.prototype.clearHasWithdrewDiamondsThisWeekMapMap = function () {
    this.getHasWithdrewDiamondsThisWeekMapMap().clear();
    return this;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.WithdrawDiamondV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.WithdrawDiamondV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.WithdrawDiamondV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.WithdrawDiamondV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            diamondAmount: jspb.Message.getFieldWithDefault(msg, 1, 0),
            bankAccountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            isAgreedTaxWithholding: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
            withdrawableDiamondType: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.WithdrawDiamondV1Request}
 */
proto.jp.singcolor.WithdrawDiamondV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.WithdrawDiamondV1Request;
    return proto.jp.singcolor.WithdrawDiamondV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.WithdrawDiamondV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.WithdrawDiamondV1Request}
 */
proto.jp.singcolor.WithdrawDiamondV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDiamondAmount(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setBankAccountId(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsAgreedTaxWithholding(value);
                break;
            case 4:
                var value = /** @type {!proto.jp.singcolor.WithdrawableDiamondType} */ (reader.readEnum());
                msg.setWithdrawableDiamondType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.WithdrawDiamondV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.WithdrawDiamondV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.WithdrawDiamondV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.WithdrawDiamondV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDiamondAmount();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getBankAccountId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getIsAgreedTaxWithholding();
    if (f) {
        writer.writeBool(3, f);
    }
    f = message.getWithdrawableDiamondType();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
};
/**
 * optional int64 diamond_amount = 1;
 * @return {number}
 */
proto.jp.singcolor.WithdrawDiamondV1Request.prototype.getDiamondAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.WithdrawDiamondV1Request} returns this
 */
proto.jp.singcolor.WithdrawDiamondV1Request.prototype.setDiamondAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional string bank_account_id = 2;
 * @return {string}
 */
proto.jp.singcolor.WithdrawDiamondV1Request.prototype.getBankAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.WithdrawDiamondV1Request} returns this
 */
proto.jp.singcolor.WithdrawDiamondV1Request.prototype.setBankAccountId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool is_agreed_tax_withholding = 3;
 * @return {boolean}
 */
proto.jp.singcolor.WithdrawDiamondV1Request.prototype.getIsAgreedTaxWithholding = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.WithdrawDiamondV1Request} returns this
 */
proto.jp.singcolor.WithdrawDiamondV1Request.prototype.setIsAgreedTaxWithholding = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * optional WithdrawableDiamondType withdrawable_diamond_type = 4;
 * @return {!proto.jp.singcolor.WithdrawableDiamondType}
 */
proto.jp.singcolor.WithdrawDiamondV1Request.prototype.getWithdrawableDiamondType = function () {
    return /** @type {!proto.jp.singcolor.WithdrawableDiamondType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.jp.singcolor.WithdrawableDiamondType} value
 * @return {!proto.jp.singcolor.WithdrawDiamondV1Request} returns this
 */
proto.jp.singcolor.WithdrawDiamondV1Request.prototype.setWithdrawableDiamondType = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.WithdrawDiamondV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.WithdrawDiamondV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.WithdrawDiamondV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.WithdrawDiamondV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            withdrawDiamondRequest: (f = msg.getWithdrawDiamondRequest()) && diamonds_entity_pb.WithdrawDiamondRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.WithdrawDiamondV1Response}
 */
proto.jp.singcolor.WithdrawDiamondV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.WithdrawDiamondV1Response;
    return proto.jp.singcolor.WithdrawDiamondV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.WithdrawDiamondV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.WithdrawDiamondV1Response}
 */
proto.jp.singcolor.WithdrawDiamondV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.WithdrawDiamondRequest;
                reader.readMessage(value, diamonds_entity_pb.WithdrawDiamondRequest.deserializeBinaryFromReader);
                msg.setWithdrawDiamondRequest(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.WithdrawDiamondV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.WithdrawDiamondV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.WithdrawDiamondV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.WithdrawDiamondV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getWithdrawDiamondRequest();
    if (f != null) {
        writer.writeMessage(1, f, diamonds_entity_pb.WithdrawDiamondRequest.serializeBinaryToWriter);
    }
};
/**
 * optional WithdrawDiamondRequest withdraw_diamond_request = 1;
 * @return {?proto.jp.singcolor.WithdrawDiamondRequest}
 */
proto.jp.singcolor.WithdrawDiamondV1Response.prototype.getWithdrawDiamondRequest = function () {
    return /** @type{?proto.jp.singcolor.WithdrawDiamondRequest} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.WithdrawDiamondRequest, 1));
};
/**
 * @param {?proto.jp.singcolor.WithdrawDiamondRequest|undefined} value
 * @return {!proto.jp.singcolor.WithdrawDiamondV1Response} returns this
*/
proto.jp.singcolor.WithdrawDiamondV1Response.prototype.setWithdrawDiamondRequest = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.WithdrawDiamondV1Response} returns this
 */
proto.jp.singcolor.WithdrawDiamondV1Response.prototype.clearWithdrawDiamondRequest = function () {
    return this.setWithdrawDiamondRequest(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.WithdrawDiamondV1Response.prototype.hasWithdrawDiamondRequest = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            withdrawalDiamondTransactionId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request}
 */
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request;
    return proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request}
 */
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setWithdrawalDiamondTransactionId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getWithdrawalDiamondTransactionId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string withdrawal_diamond_transaction_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request.prototype.getWithdrawalDiamondTransactionId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request} returns this
 */
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Request.prototype.setWithdrawalDiamondTransactionId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            withdrawalDiamondLedger: (f = msg.getWithdrawalDiamondLedger()) && diamonds_entity_pb.WithdrawalDiamondLedger.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response}
 */
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response;
    return proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response}
 */
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.WithdrawalDiamondLedger;
                reader.readMessage(value, diamonds_entity_pb.WithdrawalDiamondLedger.deserializeBinaryFromReader);
                msg.setWithdrawalDiamondLedger(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getWithdrawalDiamondLedger();
    if (f != null) {
        writer.writeMessage(1, f, diamonds_entity_pb.WithdrawalDiamondLedger.serializeBinaryToWriter);
    }
};
/**
 * optional WithdrawalDiamondLedger withdrawal_diamond_ledger = 1;
 * @return {?proto.jp.singcolor.WithdrawalDiamondLedger}
 */
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response.prototype.getWithdrawalDiamondLedger = function () {
    return /** @type{?proto.jp.singcolor.WithdrawalDiamondLedger} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.WithdrawalDiamondLedger, 1));
};
/**
 * @param {?proto.jp.singcolor.WithdrawalDiamondLedger|undefined} value
 * @return {!proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response} returns this
*/
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response.prototype.setWithdrawalDiamondLedger = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response} returns this
 */
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response.prototype.clearWithdrawalDiamondLedger = function () {
    return this.setWithdrawalDiamondLedger(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetWithdrawalDiamondLedgerV1Response.prototype.hasWithdrawalDiamondLedger = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            targetYear: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request}
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request;
    return proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request}
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTargetYear(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTargetYear();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
};
/**
 * optional int64 target_year = 1;
 * @return {number}
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request.prototype.getTargetYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request} returns this
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Request.prototype.setTargetYear = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            withdrawalDiamondLedgersList: jspb.Message.toObjectList(msg.getWithdrawalDiamondLedgersList(), diamonds_entity_pb.WithdrawalDiamondLedger.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response}
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response;
    return proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response}
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.WithdrawalDiamondLedger;
                reader.readMessage(value, diamonds_entity_pb.WithdrawalDiamondLedger.deserializeBinaryFromReader);
                msg.addWithdrawalDiamondLedgers(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getWithdrawalDiamondLedgersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, diamonds_entity_pb.WithdrawalDiamondLedger.serializeBinaryToWriter);
    }
};
/**
 * repeated WithdrawalDiamondLedger withdrawal_diamond_ledgers = 1;
 * @return {!Array<!proto.jp.singcolor.WithdrawalDiamondLedger>}
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.prototype.getWithdrawalDiamondLedgersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.WithdrawalDiamondLedger>} */ (jspb.Message.getRepeatedWrapperField(this, diamonds_entity_pb.WithdrawalDiamondLedger, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.WithdrawalDiamondLedger>} value
 * @return {!proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response} returns this
*/
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.prototype.setWithdrawalDiamondLedgersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.WithdrawalDiamondLedger=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedger}
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.prototype.addWithdrawalDiamondLedgers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.WithdrawalDiamondLedger, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response} returns this
 */
proto.jp.singcolor.ListWithdrawalDiamondLedgersV1Response.prototype.clearWithdrawalDiamondLedgersList = function () {
    return this.setWithdrawalDiamondLedgersList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ExchangeDiamondToCoinV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ExchangeDiamondToCoinV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ExchangeDiamondToCoinV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ExchangeDiamondToCoinV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            planId: jspb.Message.getFieldWithDefault(msg, 1, 0),
            diamondType: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ExchangeDiamondToCoinV1Request}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ExchangeDiamondToCoinV1Request;
    return proto.jp.singcolor.ExchangeDiamondToCoinV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ExchangeDiamondToCoinV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ExchangeDiamondToCoinV1Request}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPlanId(value);
                break;
            case 2:
                var value = /** @type {!proto.jp.singcolor.ExchangeableToCoinDiamondType} */ (reader.readEnum());
                msg.setDiamondType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ExchangeDiamondToCoinV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ExchangeDiamondToCoinV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPlanId();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getDiamondType();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
};
/**
 * optional int64 plan_id = 1;
 * @return {number}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Request.prototype.getPlanId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ExchangeDiamondToCoinV1Request} returns this
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Request.prototype.setPlanId = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional ExchangeableToCoinDiamondType diamond_type = 2;
 * @return {!proto.jp.singcolor.ExchangeableToCoinDiamondType}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Request.prototype.getDiamondType = function () {
    return /** @type {!proto.jp.singcolor.ExchangeableToCoinDiamondType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.jp.singcolor.ExchangeableToCoinDiamondType} value
 * @return {!proto.jp.singcolor.ExchangeDiamondToCoinV1Request} returns this
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Request.prototype.setDiamondType = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ExchangeDiamondToCoinV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ExchangeDiamondToCoinV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ExchangeDiamondToCoinV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            exchangedCoin: (f = msg.getExchangedCoin()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f),
            diamondBalance: (f = msg.getDiamondBalance()) && diamonds_entity_pb.DiamondBalance.toObject(includeInstance, f),
            exchangeableDiamond: (f = msg.getExchangeableDiamond()) && diamonds_entity_pb.DiamondAmount.toObject(includeInstance, f),
            coinBalance: (f = msg.getCoinBalance()) && coins_entity_pb.CoinBalance.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ExchangeDiamondToCoinV1Response}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ExchangeDiamondToCoinV1Response;
    return proto.jp.singcolor.ExchangeDiamondToCoinV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ExchangeDiamondToCoinV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ExchangeDiamondToCoinV1Response}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new coins_entity_pb.CoinAmount;
                reader.readMessage(value, coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
                msg.setExchangedCoin(value);
                break;
            case 2:
                var value = new diamonds_entity_pb.DiamondBalance;
                reader.readMessage(value, diamonds_entity_pb.DiamondBalance.deserializeBinaryFromReader);
                msg.setDiamondBalance(value);
                break;
            case 3:
                var value = new diamonds_entity_pb.DiamondAmount;
                reader.readMessage(value, diamonds_entity_pb.DiamondAmount.deserializeBinaryFromReader);
                msg.setExchangeableDiamond(value);
                break;
            case 4:
                var value = new coins_entity_pb.CoinBalance;
                reader.readMessage(value, coins_entity_pb.CoinBalance.deserializeBinaryFromReader);
                msg.setCoinBalance(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ExchangeDiamondToCoinV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ExchangeDiamondToCoinV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getExchangedCoin();
    if (f != null) {
        writer.writeMessage(1, f, coins_entity_pb.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getDiamondBalance();
    if (f != null) {
        writer.writeMessage(2, f, diamonds_entity_pb.DiamondBalance.serializeBinaryToWriter);
    }
    f = message.getExchangeableDiamond();
    if (f != null) {
        writer.writeMessage(3, f, diamonds_entity_pb.DiamondAmount.serializeBinaryToWriter);
    }
    f = message.getCoinBalance();
    if (f != null) {
        writer.writeMessage(4, f, coins_entity_pb.CoinBalance.serializeBinaryToWriter);
    }
};
/**
 * optional CoinAmount exchanged_coin = 1;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.getExchangedCoin = function () {
    return /** @type{?proto.jp.singcolor.CoinAmount} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 1));
};
/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.ExchangeDiamondToCoinV1Response} returns this
*/
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.setExchangedCoin = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ExchangeDiamondToCoinV1Response} returns this
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.clearExchangedCoin = function () {
    return this.setExchangedCoin(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.hasExchangedCoin = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional DiamondBalance diamond_balance = 2;
 * @return {?proto.jp.singcolor.DiamondBalance}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.getDiamondBalance = function () {
    return /** @type{?proto.jp.singcolor.DiamondBalance} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondBalance, 2));
};
/**
 * @param {?proto.jp.singcolor.DiamondBalance|undefined} value
 * @return {!proto.jp.singcolor.ExchangeDiamondToCoinV1Response} returns this
*/
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.setDiamondBalance = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ExchangeDiamondToCoinV1Response} returns this
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.clearDiamondBalance = function () {
    return this.setDiamondBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.hasDiamondBalance = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional DiamondAmount exchangeable_diamond = 3;
 * @return {?proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.getExchangeableDiamond = function () {
    return /** @type{?proto.jp.singcolor.DiamondAmount} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondAmount, 3));
};
/**
 * @param {?proto.jp.singcolor.DiamondAmount|undefined} value
 * @return {!proto.jp.singcolor.ExchangeDiamondToCoinV1Response} returns this
*/
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.setExchangeableDiamond = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ExchangeDiamondToCoinV1Response} returns this
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.clearExchangeableDiamond = function () {
    return this.setExchangeableDiamond(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.hasExchangeableDiamond = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional CoinBalance coin_balance = 4;
 * @return {?proto.jp.singcolor.CoinBalance}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.getCoinBalance = function () {
    return /** @type{?proto.jp.singcolor.CoinBalance} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinBalance, 4));
};
/**
 * @param {?proto.jp.singcolor.CoinBalance|undefined} value
 * @return {!proto.jp.singcolor.ExchangeDiamondToCoinV1Response} returns this
*/
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.setCoinBalance = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ExchangeDiamondToCoinV1Response} returns this
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.clearCoinBalance = function () {
    return this.setCoinBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ExchangeDiamondToCoinV1Response.prototype.hasCoinBalance = function () {
    return jspb.Message.getField(this, 4) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.StatementsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.StatementsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.StatementsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.StatementsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: (f = msg.getPage()) && page_entity_pb.CursorPaginationRequest.toObject(includeInstance, f),
            targetYear: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.StatementsV1Request}
 */
proto.jp.singcolor.StatementsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.StatementsV1Request;
    return proto.jp.singcolor.StatementsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.StatementsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.StatementsV1Request}
 */
proto.jp.singcolor.StatementsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new page_entity_pb.CursorPaginationRequest;
                reader.readMessage(value, page_entity_pb.CursorPaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTargetYear(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.StatementsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.StatementsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.StatementsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.StatementsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(1, f, page_entity_pb.CursorPaginationRequest.serializeBinaryToWriter);
    }
    f = message.getTargetYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};
/**
 * optional CursorPaginationRequest page = 1;
 * @return {?proto.jp.singcolor.CursorPaginationRequest}
 */
proto.jp.singcolor.StatementsV1Request.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.CursorPaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.CursorPaginationRequest, 1));
};
/**
 * @param {?proto.jp.singcolor.CursorPaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.StatementsV1Request} returns this
*/
proto.jp.singcolor.StatementsV1Request.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.StatementsV1Request} returns this
 */
proto.jp.singcolor.StatementsV1Request.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.StatementsV1Request.prototype.hasPage = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional int64 target_year = 2;
 * @return {number}
 */
proto.jp.singcolor.StatementsV1Request.prototype.getTargetYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.StatementsV1Request} returns this
 */
proto.jp.singcolor.StatementsV1Request.prototype.setTargetYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.StatementsV1Response.repeatedFields_ = [2];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.StatementsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.StatementsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.StatementsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.StatementsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: (f = msg.getPage()) && page_entity_pb.CursorPaginationResult.toObject(includeInstance, f),
            statementsList: jspb.Message.toObjectList(msg.getStatementsList(), proto.jp.singcolor.StatementsV1Response.Statement.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.StatementsV1Response}
 */
proto.jp.singcolor.StatementsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.StatementsV1Response;
    return proto.jp.singcolor.StatementsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.StatementsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.StatementsV1Response}
 */
proto.jp.singcolor.StatementsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new page_entity_pb.CursorPaginationResult;
                reader.readMessage(value, page_entity_pb.CursorPaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            case 2:
                var value = new proto.jp.singcolor.StatementsV1Response.Statement;
                reader.readMessage(value, proto.jp.singcolor.StatementsV1Response.Statement.deserializeBinaryFromReader);
                msg.addStatements(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.StatementsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.StatementsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.StatementsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.StatementsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(1, f, page_entity_pb.CursorPaginationResult.serializeBinaryToWriter);
    }
    f = message.getStatementsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, proto.jp.singcolor.StatementsV1Response.Statement.serializeBinaryToWriter);
    }
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.StatementsV1Response.Statement.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.StatementsV1Response.Statement.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.StatementsV1Response.Statement} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.StatementsV1Response.Statement.toObject = function (includeInstance, msg) {
        var f, obj = {
            date: jspb.Message.getFieldWithDefault(msg, 1, 0),
            inOutDiamondAmount: (f = msg.getInOutDiamondAmount()) && diamonds_entity_pb.DiamondAmount.toObject(includeInstance, f),
            diamondType: jspb.Message.getFieldWithDefault(msg, 3, 0),
            reason: jspb.Message.getFieldWithDefault(msg, 4, 0),
            note: jspb.Message.getFieldWithDefault(msg, 5, ""),
            acquiredDiamondDetail: (f = msg.getAcquiredDiamondDetail()) && users_entity_pb.AcquiredDiamondDetail.toObject(includeInstance, f),
            termLabel: jspb.Message.getFieldWithDefault(msg, 7, ""),
            exchangeRate: jspb.Message.getFieldWithDefault(msg, 8, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.StatementsV1Response.Statement}
 */
proto.jp.singcolor.StatementsV1Response.Statement.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.StatementsV1Response.Statement;
    return proto.jp.singcolor.StatementsV1Response.Statement.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.StatementsV1Response.Statement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.StatementsV1Response.Statement}
 */
proto.jp.singcolor.StatementsV1Response.Statement.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDate(value);
                break;
            case 2:
                var value = new diamonds_entity_pb.DiamondAmount;
                reader.readMessage(value, diamonds_entity_pb.DiamondAmount.deserializeBinaryFromReader);
                msg.setInOutDiamondAmount(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.DiamondType} */ (reader.readEnum());
                msg.setDiamondType(value);
                break;
            case 4:
                var value = /** @type {!proto.jp.singcolor.DiamondInOutReason} */ (reader.readEnum());
                msg.setReason(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setNote(value);
                break;
            case 6:
                var value = new users_entity_pb.AcquiredDiamondDetail;
                reader.readMessage(value, users_entity_pb.AcquiredDiamondDetail.deserializeBinaryFromReader);
                msg.setAcquiredDiamondDetail(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setTermLabel(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setExchangeRate(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.StatementsV1Response.Statement.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.StatementsV1Response.Statement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.StatementsV1Response.Statement.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDate();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getInOutDiamondAmount();
    if (f != null) {
        writer.writeMessage(2, f, diamonds_entity_pb.DiamondAmount.serializeBinaryToWriter);
    }
    f = message.getDiamondType();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getReason();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getNote();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getAcquiredDiamondDetail();
    if (f != null) {
        writer.writeMessage(6, f, users_entity_pb.AcquiredDiamondDetail.serializeBinaryToWriter);
    }
    f = message.getTermLabel();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
    f = message.getExchangeRate();
    if (f.length > 0) {
        writer.writeString(8, f);
    }
};
/**
 * optional int64 date = 1;
 * @return {number}
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.getDate = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.StatementsV1Response.Statement} returns this
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.setDate = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional DiamondAmount in_out_diamond_amount = 2;
 * @return {?proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.getInOutDiamondAmount = function () {
    return /** @type{?proto.jp.singcolor.DiamondAmount} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondAmount, 2));
};
/**
 * @param {?proto.jp.singcolor.DiamondAmount|undefined} value
 * @return {!proto.jp.singcolor.StatementsV1Response.Statement} returns this
*/
proto.jp.singcolor.StatementsV1Response.Statement.prototype.setInOutDiamondAmount = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.StatementsV1Response.Statement} returns this
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.clearInOutDiamondAmount = function () {
    return this.setInOutDiamondAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.hasInOutDiamondAmount = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional DiamondType diamond_type = 3;
 * @return {!proto.jp.singcolor.DiamondType}
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.getDiamondType = function () {
    return /** @type {!proto.jp.singcolor.DiamondType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.DiamondType} value
 * @return {!proto.jp.singcolor.StatementsV1Response.Statement} returns this
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.setDiamondType = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional DiamondInOutReason reason = 4;
 * @return {!proto.jp.singcolor.DiamondInOutReason}
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.getReason = function () {
    return /** @type {!proto.jp.singcolor.DiamondInOutReason} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.jp.singcolor.DiamondInOutReason} value
 * @return {!proto.jp.singcolor.StatementsV1Response.Statement} returns this
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.setReason = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional string note = 5;
 * @return {string}
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.getNote = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StatementsV1Response.Statement} returns this
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.setNote = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional AcquiredDiamondDetail acquired_diamond_detail = 6;
 * @return {?proto.jp.singcolor.AcquiredDiamondDetail}
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.getAcquiredDiamondDetail = function () {
    return /** @type{?proto.jp.singcolor.AcquiredDiamondDetail} */ (jspb.Message.getWrapperField(this, users_entity_pb.AcquiredDiamondDetail, 6));
};
/**
 * @param {?proto.jp.singcolor.AcquiredDiamondDetail|undefined} value
 * @return {!proto.jp.singcolor.StatementsV1Response.Statement} returns this
*/
proto.jp.singcolor.StatementsV1Response.Statement.prototype.setAcquiredDiamondDetail = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.StatementsV1Response.Statement} returns this
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.clearAcquiredDiamondDetail = function () {
    return this.setAcquiredDiamondDetail(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.hasAcquiredDiamondDetail = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * optional string term_label = 7;
 * @return {string}
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.getTermLabel = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StatementsV1Response.Statement} returns this
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.setTermLabel = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional string exchange_rate = 8;
 * @return {string}
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.getExchangeRate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.StatementsV1Response.Statement} returns this
 */
proto.jp.singcolor.StatementsV1Response.Statement.prototype.setExchangeRate = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};
/**
 * optional CursorPaginationResult page = 1;
 * @return {?proto.jp.singcolor.CursorPaginationResult}
 */
proto.jp.singcolor.StatementsV1Response.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.CursorPaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.CursorPaginationResult, 1));
};
/**
 * @param {?proto.jp.singcolor.CursorPaginationResult|undefined} value
 * @return {!proto.jp.singcolor.StatementsV1Response} returns this
*/
proto.jp.singcolor.StatementsV1Response.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.StatementsV1Response} returns this
 */
proto.jp.singcolor.StatementsV1Response.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.StatementsV1Response.prototype.hasPage = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * repeated Statement statements = 2;
 * @return {!Array<!proto.jp.singcolor.StatementsV1Response.Statement>}
 */
proto.jp.singcolor.StatementsV1Response.prototype.getStatementsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.StatementsV1Response.Statement>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.StatementsV1Response.Statement, 2));
};
/**
 * @param {!Array<!proto.jp.singcolor.StatementsV1Response.Statement>} value
 * @return {!proto.jp.singcolor.StatementsV1Response} returns this
*/
proto.jp.singcolor.StatementsV1Response.prototype.setStatementsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.jp.singcolor.StatementsV1Response.Statement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.StatementsV1Response.Statement}
 */
proto.jp.singcolor.StatementsV1Response.prototype.addStatements = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jp.singcolor.StatementsV1Response.Statement, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.StatementsV1Response} returns this
 */
proto.jp.singcolor.StatementsV1Response.prototype.clearStatementsList = function () {
    return this.setStatementsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListMajorBanksV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListMajorBanksV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListMajorBanksV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListMajorBanksV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListMajorBanksV1Request}
 */
proto.jp.singcolor.ListMajorBanksV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListMajorBanksV1Request;
    return proto.jp.singcolor.ListMajorBanksV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListMajorBanksV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListMajorBanksV1Request}
 */
proto.jp.singcolor.ListMajorBanksV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListMajorBanksV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListMajorBanksV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListMajorBanksV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListMajorBanksV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListMajorBanksV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListMajorBanksV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListMajorBanksV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListMajorBanksV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListMajorBanksV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            banksList: jspb.Message.toObjectList(msg.getBanksList(), diamonds_entity_pb.Bank.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListMajorBanksV1Response}
 */
proto.jp.singcolor.ListMajorBanksV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListMajorBanksV1Response;
    return proto.jp.singcolor.ListMajorBanksV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListMajorBanksV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListMajorBanksV1Response}
 */
proto.jp.singcolor.ListMajorBanksV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.Bank;
                reader.readMessage(value, diamonds_entity_pb.Bank.deserializeBinaryFromReader);
                msg.addBanks(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListMajorBanksV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListMajorBanksV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListMajorBanksV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListMajorBanksV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBanksList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, diamonds_entity_pb.Bank.serializeBinaryToWriter);
    }
};
/**
 * repeated Bank banks = 1;
 * @return {!Array<!proto.jp.singcolor.Bank>}
 */
proto.jp.singcolor.ListMajorBanksV1Response.prototype.getBanksList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Bank>} */ (jspb.Message.getRepeatedWrapperField(this, diamonds_entity_pb.Bank, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Bank>} value
 * @return {!proto.jp.singcolor.ListMajorBanksV1Response} returns this
*/
proto.jp.singcolor.ListMajorBanksV1Response.prototype.setBanksList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Bank=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Bank}
 */
proto.jp.singcolor.ListMajorBanksV1Response.prototype.addBanks = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Bank, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListMajorBanksV1Response} returns this
 */
proto.jp.singcolor.ListMajorBanksV1Response.prototype.clearBanksList = function () {
    return this.setBanksList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetBankV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetBankV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetBankV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetBankV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            bankCode: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetBankV1Request}
 */
proto.jp.singcolor.GetBankV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetBankV1Request;
    return proto.jp.singcolor.GetBankV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetBankV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetBankV1Request}
 */
proto.jp.singcolor.GetBankV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBankCode(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetBankV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetBankV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetBankV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetBankV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBankCode();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string bank_code = 1;
 * @return {string}
 */
proto.jp.singcolor.GetBankV1Request.prototype.getBankCode = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetBankV1Request} returns this
 */
proto.jp.singcolor.GetBankV1Request.prototype.setBankCode = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetBankV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetBankV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetBankV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetBankV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            bank: (f = msg.getBank()) && diamonds_entity_pb.Bank.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetBankV1Response}
 */
proto.jp.singcolor.GetBankV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetBankV1Response;
    return proto.jp.singcolor.GetBankV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetBankV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetBankV1Response}
 */
proto.jp.singcolor.GetBankV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.Bank;
                reader.readMessage(value, diamonds_entity_pb.Bank.deserializeBinaryFromReader);
                msg.setBank(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetBankV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetBankV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetBankV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetBankV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBank();
    if (f != null) {
        writer.writeMessage(1, f, diamonds_entity_pb.Bank.serializeBinaryToWriter);
    }
};
/**
 * optional Bank bank = 1;
 * @return {?proto.jp.singcolor.Bank}
 */
proto.jp.singcolor.GetBankV1Response.prototype.getBank = function () {
    return /** @type{?proto.jp.singcolor.Bank} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.Bank, 1));
};
/**
 * @param {?proto.jp.singcolor.Bank|undefined} value
 * @return {!proto.jp.singcolor.GetBankV1Response} returns this
*/
proto.jp.singcolor.GetBankV1Response.prototype.setBank = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetBankV1Response} returns this
 */
proto.jp.singcolor.GetBankV1Response.prototype.clearBank = function () {
    return this.setBank(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetBankV1Response.prototype.hasBank = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListBanksV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListBanksV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListBanksV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListBanksV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            initialKana: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListBanksV1Request}
 */
proto.jp.singcolor.ListBanksV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListBanksV1Request;
    return proto.jp.singcolor.ListBanksV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListBanksV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListBanksV1Request}
 */
proto.jp.singcolor.ListBanksV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setInitialKana(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListBanksV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListBanksV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListBanksV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListBanksV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getInitialKana();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string initial_kana = 1;
 * @return {string}
 */
proto.jp.singcolor.ListBanksV1Request.prototype.getInitialKana = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListBanksV1Request} returns this
 */
proto.jp.singcolor.ListBanksV1Request.prototype.setInitialKana = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListBanksV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListBanksV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListBanksV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListBanksV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListBanksV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            banksList: jspb.Message.toObjectList(msg.getBanksList(), diamonds_entity_pb.Bank.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListBanksV1Response}
 */
proto.jp.singcolor.ListBanksV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListBanksV1Response;
    return proto.jp.singcolor.ListBanksV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListBanksV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListBanksV1Response}
 */
proto.jp.singcolor.ListBanksV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.Bank;
                reader.readMessage(value, diamonds_entity_pb.Bank.deserializeBinaryFromReader);
                msg.addBanks(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListBanksV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListBanksV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListBanksV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListBanksV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBanksList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, diamonds_entity_pb.Bank.serializeBinaryToWriter);
    }
};
/**
 * repeated Bank banks = 1;
 * @return {!Array<!proto.jp.singcolor.Bank>}
 */
proto.jp.singcolor.ListBanksV1Response.prototype.getBanksList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Bank>} */ (jspb.Message.getRepeatedWrapperField(this, diamonds_entity_pb.Bank, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Bank>} value
 * @return {!proto.jp.singcolor.ListBanksV1Response} returns this
*/
proto.jp.singcolor.ListBanksV1Response.prototype.setBanksList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Bank=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Bank}
 */
proto.jp.singcolor.ListBanksV1Response.prototype.addBanks = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Bank, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListBanksV1Response} returns this
 */
proto.jp.singcolor.ListBanksV1Response.prototype.clearBanksList = function () {
    return this.setBanksList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListBankBranchesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListBankBranchesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListBankBranchesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListBankBranchesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            bankCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
            initialKana: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListBankBranchesV1Request}
 */
proto.jp.singcolor.ListBankBranchesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListBankBranchesV1Request;
    return proto.jp.singcolor.ListBankBranchesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListBankBranchesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListBankBranchesV1Request}
 */
proto.jp.singcolor.ListBankBranchesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBankCode(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setInitialKana(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListBankBranchesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListBankBranchesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListBankBranchesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListBankBranchesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBankCode();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getInitialKana();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string bank_code = 1;
 * @return {string}
 */
proto.jp.singcolor.ListBankBranchesV1Request.prototype.getBankCode = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListBankBranchesV1Request} returns this
 */
proto.jp.singcolor.ListBankBranchesV1Request.prototype.setBankCode = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string initial_kana = 2;
 * @return {string}
 */
proto.jp.singcolor.ListBankBranchesV1Request.prototype.getInitialKana = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListBankBranchesV1Request} returns this
 */
proto.jp.singcolor.ListBankBranchesV1Request.prototype.setInitialKana = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListBankBranchesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListBankBranchesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListBankBranchesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListBankBranchesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListBankBranchesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            bankBranchesList: jspb.Message.toObjectList(msg.getBankBranchesList(), diamonds_entity_pb.BankBranch.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListBankBranchesV1Response}
 */
proto.jp.singcolor.ListBankBranchesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListBankBranchesV1Response;
    return proto.jp.singcolor.ListBankBranchesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListBankBranchesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListBankBranchesV1Response}
 */
proto.jp.singcolor.ListBankBranchesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.BankBranch;
                reader.readMessage(value, diamonds_entity_pb.BankBranch.deserializeBinaryFromReader);
                msg.addBankBranches(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListBankBranchesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListBankBranchesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListBankBranchesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListBankBranchesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBankBranchesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, diamonds_entity_pb.BankBranch.serializeBinaryToWriter);
    }
};
/**
 * repeated BankBranch bank_branches = 1;
 * @return {!Array<!proto.jp.singcolor.BankBranch>}
 */
proto.jp.singcolor.ListBankBranchesV1Response.prototype.getBankBranchesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.BankBranch>} */ (jspb.Message.getRepeatedWrapperField(this, diamonds_entity_pb.BankBranch, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.BankBranch>} value
 * @return {!proto.jp.singcolor.ListBankBranchesV1Response} returns this
*/
proto.jp.singcolor.ListBankBranchesV1Response.prototype.setBankBranchesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.BankBranch=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.BankBranch}
 */
proto.jp.singcolor.ListBankBranchesV1Response.prototype.addBankBranches = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.BankBranch, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListBankBranchesV1Response} returns this
 */
proto.jp.singcolor.ListBankBranchesV1Response.prototype.clearBankBranchesList = function () {
    return this.setBankBranchesList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateBankAccountV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateBankAccountV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateBankAccountV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateBankAccountV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            bankCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
            branchCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
            accountType: jspb.Message.getFieldWithDefault(msg, 3, 0),
            accountNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
            accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
            postalCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
            address: jspb.Message.getFieldWithDefault(msg, 7, ""),
            name: jspb.Message.getFieldWithDefault(msg, 8, ""),
            kanaName: jspb.Message.getFieldWithDefault(msg, 9, ""),
            birthday: jspb.Message.getFieldWithDefault(msg, 10, ""),
            companyName: jspb.Message.getFieldWithDefault(msg, 11, ""),
            companyAddress: jspb.Message.getFieldWithDefault(msg, 12, ""),
            companyPostalCode: jspb.Message.getFieldWithDefault(msg, 13, ""),
            companyRepresentativeName: jspb.Message.getFieldWithDefault(msg, 14, ""),
            invoiceNumber: jspb.Message.getFieldWithDefault(msg, 15, ""),
            invoiceRegistrationName: jspb.Message.getFieldWithDefault(msg, 16, ""),
            invoiceRegistrationDate: jspb.Message.getFieldWithDefault(msg, 17, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request}
 */
proto.jp.singcolor.CreateBankAccountV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateBankAccountV1Request;
    return proto.jp.singcolor.CreateBankAccountV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateBankAccountV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request}
 */
proto.jp.singcolor.CreateBankAccountV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBankCode(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setBranchCode(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.BankAccountType} */ (reader.readEnum());
                msg.setAccountType(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountNumber(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccountName(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostalCode(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setAddress(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 9:
                var value = /** @type {string} */ (reader.readString());
                msg.setKanaName(value);
                break;
            case 10:
                var value = /** @type {string} */ (reader.readString());
                msg.setBirthday(value);
                break;
            case 11:
                var value = /** @type {string} */ (reader.readString());
                msg.setCompanyName(value);
                break;
            case 12:
                var value = /** @type {string} */ (reader.readString());
                msg.setCompanyAddress(value);
                break;
            case 13:
                var value = /** @type {string} */ (reader.readString());
                msg.setCompanyPostalCode(value);
                break;
            case 14:
                var value = /** @type {string} */ (reader.readString());
                msg.setCompanyRepresentativeName(value);
                break;
            case 15:
                var value = /** @type {string} */ (reader.readString());
                msg.setInvoiceNumber(value);
                break;
            case 16:
                var value = /** @type {string} */ (reader.readString());
                msg.setInvoiceRegistrationName(value);
                break;
            case 17:
                var value = /** @type {string} */ (reader.readString());
                msg.setInvoiceRegistrationDate(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateBankAccountV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateBankAccountV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateBankAccountV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBankCode();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getBranchCode();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getAccountType();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getAccountNumber();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getAccountName();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getPostalCode();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getAddress();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(8, f);
    }
    f = message.getKanaName();
    if (f.length > 0) {
        writer.writeString(9, f);
    }
    f = message.getBirthday();
    if (f.length > 0) {
        writer.writeString(10, f);
    }
    f = message.getCompanyName();
    if (f.length > 0) {
        writer.writeString(11, f);
    }
    f = message.getCompanyAddress();
    if (f.length > 0) {
        writer.writeString(12, f);
    }
    f = message.getCompanyPostalCode();
    if (f.length > 0) {
        writer.writeString(13, f);
    }
    f = message.getCompanyRepresentativeName();
    if (f.length > 0) {
        writer.writeString(14, f);
    }
    f = message.getInvoiceNumber();
    if (f.length > 0) {
        writer.writeString(15, f);
    }
    f = message.getInvoiceRegistrationName();
    if (f.length > 0) {
        writer.writeString(16, f);
    }
    f = message.getInvoiceRegistrationDate();
    if (f.length > 0) {
        writer.writeString(17, f);
    }
};
/**
 * optional string bank_code = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getBankCode = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setBankCode = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string branch_code = 2;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getBranchCode = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setBranchCode = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional BankAccountType account_type = 3;
 * @return {!proto.jp.singcolor.BankAccountType}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getAccountType = function () {
    return /** @type {!proto.jp.singcolor.BankAccountType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.BankAccountType} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setAccountType = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional string account_number = 4;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getAccountNumber = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setAccountNumber = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getAccountName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setAccountName = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string postal_code = 6;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getPostalCode = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setPostalCode = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string address = 7;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getAddress = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setAddress = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional string name = 8;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};
/**
 * optional string kana_name = 9;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getKanaName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setKanaName = function (value) {
    return jspb.Message.setProto3StringField(this, 9, value);
};
/**
 * optional string birthday = 10;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getBirthday = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setBirthday = function (value) {
    return jspb.Message.setProto3StringField(this, 10, value);
};
/**
 * optional string company_name = 11;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getCompanyName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setCompanyName = function (value) {
    return jspb.Message.setProto3StringField(this, 11, value);
};
/**
 * optional string company_address = 12;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getCompanyAddress = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setCompanyAddress = function (value) {
    return jspb.Message.setProto3StringField(this, 12, value);
};
/**
 * optional string company_postal_code = 13;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getCompanyPostalCode = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setCompanyPostalCode = function (value) {
    return jspb.Message.setProto3StringField(this, 13, value);
};
/**
 * optional string company_representative_name = 14;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getCompanyRepresentativeName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setCompanyRepresentativeName = function (value) {
    return jspb.Message.setProto3StringField(this, 14, value);
};
/**
 * optional string invoice_number = 15;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getInvoiceNumber = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setInvoiceNumber = function (value) {
    return jspb.Message.setProto3StringField(this, 15, value);
};
/**
 * optional string invoice_registration_name = 16;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getInvoiceRegistrationName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setInvoiceRegistrationName = function (value) {
    return jspb.Message.setProto3StringField(this, 16, value);
};
/**
 * optional string invoice_registration_date = 17;
 * @return {string}
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.getInvoiceRegistrationDate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Request} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Request.prototype.setInvoiceRegistrationDate = function (value) {
    return jspb.Message.setProto3StringField(this, 17, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateBankAccountV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateBankAccountV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateBankAccountV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateBankAccountV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            bankAccount: (f = msg.getBankAccount()) && diamonds_entity_pb.BankAccount.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateBankAccountV1Response}
 */
proto.jp.singcolor.CreateBankAccountV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateBankAccountV1Response;
    return proto.jp.singcolor.CreateBankAccountV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateBankAccountV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateBankAccountV1Response}
 */
proto.jp.singcolor.CreateBankAccountV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.BankAccount;
                reader.readMessage(value, diamonds_entity_pb.BankAccount.deserializeBinaryFromReader);
                msg.setBankAccount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateBankAccountV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateBankAccountV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateBankAccountV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateBankAccountV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBankAccount();
    if (f != null) {
        writer.writeMessage(1, f, diamonds_entity_pb.BankAccount.serializeBinaryToWriter);
    }
};
/**
 * optional BankAccount bank_account = 1;
 * @return {?proto.jp.singcolor.BankAccount}
 */
proto.jp.singcolor.CreateBankAccountV1Response.prototype.getBankAccount = function () {
    return /** @type{?proto.jp.singcolor.BankAccount} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.BankAccount, 1));
};
/**
 * @param {?proto.jp.singcolor.BankAccount|undefined} value
 * @return {!proto.jp.singcolor.CreateBankAccountV1Response} returns this
*/
proto.jp.singcolor.CreateBankAccountV1Response.prototype.setBankAccount = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CreateBankAccountV1Response} returns this
 */
proto.jp.singcolor.CreateBankAccountV1Response.prototype.clearBankAccount = function () {
    return this.setBankAccount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CreateBankAccountV1Response.prototype.hasBankAccount = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListBankAccountsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListBankAccountsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListBankAccountsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListBankAccountsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListBankAccountsV1Request}
 */
proto.jp.singcolor.ListBankAccountsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListBankAccountsV1Request;
    return proto.jp.singcolor.ListBankAccountsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListBankAccountsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListBankAccountsV1Request}
 */
proto.jp.singcolor.ListBankAccountsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListBankAccountsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListBankAccountsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListBankAccountsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListBankAccountsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListBankAccountsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListBankAccountsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListBankAccountsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListBankAccountsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListBankAccountsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            bankAccountsList: jspb.Message.toObjectList(msg.getBankAccountsList(), diamonds_entity_pb.BankAccount.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListBankAccountsV1Response}
 */
proto.jp.singcolor.ListBankAccountsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListBankAccountsV1Response;
    return proto.jp.singcolor.ListBankAccountsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListBankAccountsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListBankAccountsV1Response}
 */
proto.jp.singcolor.ListBankAccountsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.BankAccount;
                reader.readMessage(value, diamonds_entity_pb.BankAccount.deserializeBinaryFromReader);
                msg.addBankAccounts(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListBankAccountsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListBankAccountsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListBankAccountsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListBankAccountsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBankAccountsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, diamonds_entity_pb.BankAccount.serializeBinaryToWriter);
    }
};
/**
 * repeated BankAccount bank_accounts = 1;
 * @return {!Array<!proto.jp.singcolor.BankAccount>}
 */
proto.jp.singcolor.ListBankAccountsV1Response.prototype.getBankAccountsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.BankAccount>} */ (jspb.Message.getRepeatedWrapperField(this, diamonds_entity_pb.BankAccount, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.BankAccount>} value
 * @return {!proto.jp.singcolor.ListBankAccountsV1Response} returns this
*/
proto.jp.singcolor.ListBankAccountsV1Response.prototype.setBankAccountsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.BankAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.BankAccount}
 */
proto.jp.singcolor.ListBankAccountsV1Response.prototype.addBankAccounts = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.BankAccount, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListBankAccountsV1Response} returns this
 */
proto.jp.singcolor.ListBankAccountsV1Response.prototype.clearBankAccountsList = function () {
    return this.setBankAccountsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteBankAccountV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteBankAccountV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteBankAccountV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteBankAccountV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            bankAccountId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteBankAccountV1Request}
 */
proto.jp.singcolor.DeleteBankAccountV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteBankAccountV1Request;
    return proto.jp.singcolor.DeleteBankAccountV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteBankAccountV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteBankAccountV1Request}
 */
proto.jp.singcolor.DeleteBankAccountV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBankAccountId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteBankAccountV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteBankAccountV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteBankAccountV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteBankAccountV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBankAccountId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string bank_account_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteBankAccountV1Request.prototype.getBankAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteBankAccountV1Request} returns this
 */
proto.jp.singcolor.DeleteBankAccountV1Request.prototype.setBankAccountId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteBankAccountV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteBankAccountV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteBankAccountV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteBankAccountV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteBankAccountV1Response}
 */
proto.jp.singcolor.DeleteBankAccountV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteBankAccountV1Response;
    return proto.jp.singcolor.DeleteBankAccountV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteBankAccountV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteBankAccountV1Response}
 */
proto.jp.singcolor.DeleteBankAccountV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteBankAccountV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteBankAccountV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteBankAccountV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteBankAccountV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListStatementsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListStatementsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListStatementsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListStatementsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: (f = msg.getPage()) && page_entity_pb.CursorPaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListStatementsV1Request}
 */
proto.jp.singcolor.ListStatementsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListStatementsV1Request;
    return proto.jp.singcolor.ListStatementsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListStatementsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListStatementsV1Request}
 */
proto.jp.singcolor.ListStatementsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new page_entity_pb.CursorPaginationRequest;
                reader.readMessage(value, page_entity_pb.CursorPaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListStatementsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListStatementsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListStatementsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListStatementsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(1, f, page_entity_pb.CursorPaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional CursorPaginationRequest page = 1;
 * @return {?proto.jp.singcolor.CursorPaginationRequest}
 */
proto.jp.singcolor.ListStatementsV1Request.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.CursorPaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.CursorPaginationRequest, 1));
};
/**
 * @param {?proto.jp.singcolor.CursorPaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.ListStatementsV1Request} returns this
*/
proto.jp.singcolor.ListStatementsV1Request.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListStatementsV1Request} returns this
 */
proto.jp.singcolor.ListStatementsV1Request.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListStatementsV1Request.prototype.hasPage = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListStatementsV1Response.repeatedFields_ = [2];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListStatementsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListStatementsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListStatementsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListStatementsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: (f = msg.getPage()) && page_entity_pb.CursorPaginationResult.toObject(includeInstance, f),
            statementsList: jspb.Message.toObjectList(msg.getStatementsList(), diamonds_entity_pb.Statement.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListStatementsV1Response}
 */
proto.jp.singcolor.ListStatementsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListStatementsV1Response;
    return proto.jp.singcolor.ListStatementsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListStatementsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListStatementsV1Response}
 */
proto.jp.singcolor.ListStatementsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new page_entity_pb.CursorPaginationResult;
                reader.readMessage(value, page_entity_pb.CursorPaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            case 2:
                var value = new diamonds_entity_pb.Statement;
                reader.readMessage(value, diamonds_entity_pb.Statement.deserializeBinaryFromReader);
                msg.addStatements(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListStatementsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListStatementsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListStatementsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListStatementsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(1, f, page_entity_pb.CursorPaginationResult.serializeBinaryToWriter);
    }
    f = message.getStatementsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, diamonds_entity_pb.Statement.serializeBinaryToWriter);
    }
};
/**
 * optional CursorPaginationResult page = 1;
 * @return {?proto.jp.singcolor.CursorPaginationResult}
 */
proto.jp.singcolor.ListStatementsV1Response.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.CursorPaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.CursorPaginationResult, 1));
};
/**
 * @param {?proto.jp.singcolor.CursorPaginationResult|undefined} value
 * @return {!proto.jp.singcolor.ListStatementsV1Response} returns this
*/
proto.jp.singcolor.ListStatementsV1Response.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListStatementsV1Response} returns this
 */
proto.jp.singcolor.ListStatementsV1Response.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListStatementsV1Response.prototype.hasPage = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * repeated Statement statements = 2;
 * @return {!Array<!proto.jp.singcolor.Statement>}
 */
proto.jp.singcolor.ListStatementsV1Response.prototype.getStatementsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Statement>} */ (jspb.Message.getRepeatedWrapperField(this, diamonds_entity_pb.Statement, 2));
};
/**
 * @param {!Array<!proto.jp.singcolor.Statement>} value
 * @return {!proto.jp.singcolor.ListStatementsV1Response} returns this
*/
proto.jp.singcolor.ListStatementsV1Response.prototype.setStatementsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.jp.singcolor.Statement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Statement}
 */
proto.jp.singcolor.ListStatementsV1Response.prototype.addStatements = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jp.singcolor.Statement, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListStatementsV1Response} returns this
 */
proto.jp.singcolor.ListStatementsV1Response.prototype.clearStatementsList = function () {
    return this.setStatementsList([]);
};
goog.object.extend(exports, proto.jp.singcolor);
