"use strict";
// source: ope_diamond.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var diamonds_entity_pb = require('./diamonds_entity_pb.js');
goog.object.extend(proto, diamonds_entity_pb);
var page_entity_pb = require('./page_entity_pb.js');
goog.object.extend(proto, page_entity_pb);
goog.exportSymbol('proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.CancelBankTransferRequestRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.CancelBankTransferRequestResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.ListBankTransferRequestsRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListBankTransferRequestsResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.ListRiskeyesArticleLinksRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListRiskeyesArticleLinksResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink', null, global);
goog.exportSymbol('proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.ListUserBankAccountsRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListUserBankAccountsResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeAddDiamondRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeAddDiamondResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeListStatementsRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeListStatementsResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeSubtractDiamondRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeSubtractDiamondResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeWithdrawalDiamondLedger', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.UserBankAccountCreditCheckStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeWithdrawalDiamondLedger = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeWithdrawalDiamondLedger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeWithdrawalDiamondLedger.displayName = 'proto.jp.singcolor.OpeWithdrawalDiamondLedger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest.displayName = 'proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.displayName = 'proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.displayName = 'proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse.displayName = 'proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListBankTransferRequestsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListBankTransferRequestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListBankTransferRequestsRequest.displayName = 'proto.jp.singcolor.ListBankTransferRequestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListBankTransferRequestsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListBankTransferRequestsResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListBankTransferRequestsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListBankTransferRequestsResponse.displayName = 'proto.jp.singcolor.ListBankTransferRequestsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CancelBankTransferRequestRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CancelBankTransferRequestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CancelBankTransferRequestRequest.displayName = 'proto.jp.singcolor.CancelBankTransferRequestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CancelBankTransferRequestResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CancelBankTransferRequestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CancelBankTransferRequestResponse.displayName = 'proto.jp.singcolor.CancelBankTransferRequestResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UserBankAccountCreditCheckStatus = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UserBankAccountCreditCheckStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UserBankAccountCreditCheckStatus.displayName = 'proto.jp.singcolor.UserBankAccountCreditCheckStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest.displayName = 'proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse.displayName = 'proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.displayName = 'proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.displayName = 'proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest.displayName = 'proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse.displayName = 'proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListUserBankAccountsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListUserBankAccountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListUserBankAccountsRequest.displayName = 'proto.jp.singcolor.ListUserBankAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListUserBankAccountsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListUserBankAccountsResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListUserBankAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListUserBankAccountsResponse.displayName = 'proto.jp.singcolor.ListUserBankAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListRiskeyesArticleLinksRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListRiskeyesArticleLinksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListRiskeyesArticleLinksRequest.displayName = 'proto.jp.singcolor.ListRiskeyesArticleLinksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListRiskeyesArticleLinksResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListRiskeyesArticleLinksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListRiskeyesArticleLinksResponse.displayName = 'proto.jp.singcolor.ListRiskeyesArticleLinksResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.displayName = 'proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeAddDiamondRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeAddDiamondRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeAddDiamondRequest.displayName = 'proto.jp.singcolor.OpeAddDiamondRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeAddDiamondResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeAddDiamondResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeAddDiamondResponse.displayName = 'proto.jp.singcolor.OpeAddDiamondResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeSubtractDiamondRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeSubtractDiamondRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeSubtractDiamondRequest.displayName = 'proto.jp.singcolor.OpeSubtractDiamondRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeSubtractDiamondResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeSubtractDiamondResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeSubtractDiamondResponse.displayName = 'proto.jp.singcolor.OpeSubtractDiamondResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeListStatementsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeListStatementsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeListStatementsRequest.displayName = 'proto.jp.singcolor.OpeListStatementsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeListStatementsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.OpeListStatementsResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.OpeListStatementsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeListStatementsResponse.displayName = 'proto.jp.singcolor.OpeListStatementsResponse';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeWithdrawalDiamondLedger.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeWithdrawalDiamondLedger.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeWithdrawalDiamondLedger} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeWithdrawalDiamondLedger.toObject = function (includeInstance, msg) {
        var f, obj = {
            withdrawalDiamondTransactionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            transferAmount: jspb.Message.getFieldWithDefault(msg, 3, 0),
            acceptedAt: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeWithdrawalDiamondLedger}
 */
proto.jp.singcolor.OpeWithdrawalDiamondLedger.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeWithdrawalDiamondLedger;
    return proto.jp.singcolor.OpeWithdrawalDiamondLedger.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeWithdrawalDiamondLedger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeWithdrawalDiamondLedger}
 */
proto.jp.singcolor.OpeWithdrawalDiamondLedger.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setWithdrawalDiamondTransactionId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTransferAmount(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAcceptedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeWithdrawalDiamondLedger.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeWithdrawalDiamondLedger.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeWithdrawalDiamondLedger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeWithdrawalDiamondLedger.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getWithdrawalDiamondTransactionId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getTransferAmount();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getAcceptedAt();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
};
/**
 * optional string withdrawal_diamond_transaction_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeWithdrawalDiamondLedger.prototype.getWithdrawalDiamondTransactionId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeWithdrawalDiamondLedger} returns this
 */
proto.jp.singcolor.OpeWithdrawalDiamondLedger.prototype.setWithdrawalDiamondTransactionId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.jp.singcolor.OpeWithdrawalDiamondLedger.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeWithdrawalDiamondLedger} returns this
 */
proto.jp.singcolor.OpeWithdrawalDiamondLedger.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 transfer_amount = 3;
 * @return {number}
 */
proto.jp.singcolor.OpeWithdrawalDiamondLedger.prototype.getTransferAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OpeWithdrawalDiamondLedger} returns this
 */
proto.jp.singcolor.OpeWithdrawalDiamondLedger.prototype.setTransferAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 accepted_at = 4;
 * @return {number}
 */
proto.jp.singcolor.OpeWithdrawalDiamondLedger.prototype.getAcceptedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OpeWithdrawalDiamondLedger} returns this
 */
proto.jp.singcolor.OpeWithdrawalDiamondLedger.prototype.setAcceptedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest}
 */
proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest;
    return proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest}
 */
proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            withdrawalDiamondLedgersList: jspb.Message.toObjectList(msg.getWithdrawalDiamondLedgersList(), proto.jp.singcolor.OpeWithdrawalDiamondLedger.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse}
 */
proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse;
    return proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse}
 */
proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.OpeWithdrawalDiamondLedger;
                reader.readMessage(value, proto.jp.singcolor.OpeWithdrawalDiamondLedger.deserializeBinaryFromReader);
                msg.addWithdrawalDiamondLedgers(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getWithdrawalDiamondLedgersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.OpeWithdrawalDiamondLedger.serializeBinaryToWriter);
    }
};
/**
 * repeated OpeWithdrawalDiamondLedger withdrawal_diamond_ledgers = 1;
 * @return {!Array<!proto.jp.singcolor.OpeWithdrawalDiamondLedger>}
 */
proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.prototype.getWithdrawalDiamondLedgersList = function () {
    return /** @type{!Array<!proto.jp.singcolor.OpeWithdrawalDiamondLedger>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.OpeWithdrawalDiamondLedger, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.OpeWithdrawalDiamondLedger>} value
 * @return {!proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse} returns this
*/
proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.prototype.setWithdrawalDiamondLedgersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.OpeWithdrawalDiamondLedger=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.OpeWithdrawalDiamondLedger}
 */
proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.prototype.addWithdrawalDiamondLedgers = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.OpeWithdrawalDiamondLedger, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse} returns this
 */
proto.jp.singcolor.ListAcceptedWithdrawalDiamondLedgersResponse.prototype.clearWithdrawalDiamondLedgersList = function () {
    return this.setWithdrawalDiamondLedgersList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            withdrawalDiamondTransactionIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest}
 */
proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest;
    return proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest}
 */
proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.addWithdrawalDiamondTransactionIds(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getWithdrawalDiamondTransactionIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }
};
/**
 * repeated string withdrawal_diamond_transaction_ids = 1;
 * @return {!Array<string>}
 */
proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.prototype.getWithdrawalDiamondTransactionIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest} returns this
 */
proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.prototype.setWithdrawalDiamondTransactionIdsList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest} returns this
 */
proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.prototype.addWithdrawalDiamondTransactionIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest} returns this
 */
proto.jp.singcolor.ApproveWithdrawalDiamondLedgersRequest.prototype.clearWithdrawalDiamondTransactionIdsList = function () {
    return this.setWithdrawalDiamondTransactionIdsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse}
 */
proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse;
    return proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse}
 */
proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ApproveWithdrawalDiamondLedgersResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListBankTransferRequestsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListBankTransferRequestsRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListBankTransferRequestsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListBankTransferRequestsRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListBankTransferRequestsRequest}
 */
proto.jp.singcolor.ListBankTransferRequestsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListBankTransferRequestsRequest;
    return proto.jp.singcolor.ListBankTransferRequestsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListBankTransferRequestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListBankTransferRequestsRequest}
 */
proto.jp.singcolor.ListBankTransferRequestsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new page_entity_pb.SimplePaginationRequest;
                reader.readMessage(value, page_entity_pb.SimplePaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListBankTransferRequestsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListBankTransferRequestsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListBankTransferRequestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListBankTransferRequestsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(1, f, page_entity_pb.SimplePaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional SimplePaginationRequest page = 1;
 * @return {?proto.jp.singcolor.SimplePaginationRequest}
 */
proto.jp.singcolor.ListBankTransferRequestsRequest.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.SimplePaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationRequest, 1));
};
/**
 * @param {?proto.jp.singcolor.SimplePaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.ListBankTransferRequestsRequest} returns this
*/
proto.jp.singcolor.ListBankTransferRequestsRequest.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListBankTransferRequestsRequest} returns this
 */
proto.jp.singcolor.ListBankTransferRequestsRequest.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListBankTransferRequestsRequest.prototype.hasPage = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListBankTransferRequestsResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListBankTransferRequestsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListBankTransferRequestsResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListBankTransferRequestsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListBankTransferRequestsResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            bankTransferRequestsList: jspb.Message.toObjectList(msg.getBankTransferRequestsList(), diamonds_entity_pb.BankTransferRequest.toObject, includeInstance),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationResult.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListBankTransferRequestsResponse}
 */
proto.jp.singcolor.ListBankTransferRequestsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListBankTransferRequestsResponse;
    return proto.jp.singcolor.ListBankTransferRequestsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListBankTransferRequestsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListBankTransferRequestsResponse}
 */
proto.jp.singcolor.ListBankTransferRequestsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.BankTransferRequest;
                reader.readMessage(value, diamonds_entity_pb.BankTransferRequest.deserializeBinaryFromReader);
                msg.addBankTransferRequests(value);
                break;
            case 2:
                var value = new page_entity_pb.SimplePaginationResult;
                reader.readMessage(value, page_entity_pb.SimplePaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListBankTransferRequestsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListBankTransferRequestsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListBankTransferRequestsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListBankTransferRequestsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBankTransferRequestsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, diamonds_entity_pb.BankTransferRequest.serializeBinaryToWriter);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.SimplePaginationResult.serializeBinaryToWriter);
    }
};
/**
 * repeated BankTransferRequest bank_transfer_requests = 1;
 * @return {!Array<!proto.jp.singcolor.BankTransferRequest>}
 */
proto.jp.singcolor.ListBankTransferRequestsResponse.prototype.getBankTransferRequestsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.BankTransferRequest>} */ (jspb.Message.getRepeatedWrapperField(this, diamonds_entity_pb.BankTransferRequest, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.BankTransferRequest>} value
 * @return {!proto.jp.singcolor.ListBankTransferRequestsResponse} returns this
*/
proto.jp.singcolor.ListBankTransferRequestsResponse.prototype.setBankTransferRequestsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.BankTransferRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.BankTransferRequest}
 */
proto.jp.singcolor.ListBankTransferRequestsResponse.prototype.addBankTransferRequests = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.BankTransferRequest, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListBankTransferRequestsResponse} returns this
 */
proto.jp.singcolor.ListBankTransferRequestsResponse.prototype.clearBankTransferRequestsList = function () {
    return this.setBankTransferRequestsList([]);
};
/**
 * optional SimplePaginationResult page = 2;
 * @return {?proto.jp.singcolor.SimplePaginationResult}
 */
proto.jp.singcolor.ListBankTransferRequestsResponse.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.SimplePaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationResult, 2));
};
/**
 * @param {?proto.jp.singcolor.SimplePaginationResult|undefined} value
 * @return {!proto.jp.singcolor.ListBankTransferRequestsResponse} returns this
*/
proto.jp.singcolor.ListBankTransferRequestsResponse.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListBankTransferRequestsResponse} returns this
 */
proto.jp.singcolor.ListBankTransferRequestsResponse.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListBankTransferRequestsResponse.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CancelBankTransferRequestRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CancelBankTransferRequestRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CancelBankTransferRequestRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CancelBankTransferRequestRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            bankTransferRequestId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CancelBankTransferRequestRequest}
 */
proto.jp.singcolor.CancelBankTransferRequestRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CancelBankTransferRequestRequest;
    return proto.jp.singcolor.CancelBankTransferRequestRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CancelBankTransferRequestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CancelBankTransferRequestRequest}
 */
proto.jp.singcolor.CancelBankTransferRequestRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setBankTransferRequestId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CancelBankTransferRequestRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CancelBankTransferRequestRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CancelBankTransferRequestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CancelBankTransferRequestRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBankTransferRequestId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string bank_transfer_request_id = 1;
 * @return {string}
 */
proto.jp.singcolor.CancelBankTransferRequestRequest.prototype.getBankTransferRequestId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CancelBankTransferRequestRequest} returns this
 */
proto.jp.singcolor.CancelBankTransferRequestRequest.prototype.setBankTransferRequestId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CancelBankTransferRequestResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CancelBankTransferRequestResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CancelBankTransferRequestResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CancelBankTransferRequestResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CancelBankTransferRequestResponse}
 */
proto.jp.singcolor.CancelBankTransferRequestResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CancelBankTransferRequestResponse;
    return proto.jp.singcolor.CancelBankTransferRequestResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CancelBankTransferRequestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CancelBankTransferRequestResponse}
 */
proto.jp.singcolor.CancelBankTransferRequestResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CancelBankTransferRequestResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CancelBankTransferRequestResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CancelBankTransferRequestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CancelBankTransferRequestResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UserBankAccountCreditCheckStatus.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UserBankAccountCreditCheckStatus.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UserBankAccountCreditCheckStatus} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UserBankAccountCreditCheckStatus.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            creditCheckStatus: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UserBankAccountCreditCheckStatus}
 */
proto.jp.singcolor.UserBankAccountCreditCheckStatus.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UserBankAccountCreditCheckStatus;
    return proto.jp.singcolor.UserBankAccountCreditCheckStatus.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UserBankAccountCreditCheckStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UserBankAccountCreditCheckStatus}
 */
proto.jp.singcolor.UserBankAccountCreditCheckStatus.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.BankAccountCreditCheckStatus} */ (reader.readEnum());
                msg.setCreditCheckStatus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UserBankAccountCreditCheckStatus.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UserBankAccountCreditCheckStatus.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UserBankAccountCreditCheckStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UserBankAccountCreditCheckStatus.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getCreditCheckStatus();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UserBankAccountCreditCheckStatus.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UserBankAccountCreditCheckStatus} returns this
 */
proto.jp.singcolor.UserBankAccountCreditCheckStatus.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional BankAccountCreditCheckStatus credit_check_status = 3;
 * @return {!proto.jp.singcolor.BankAccountCreditCheckStatus}
 */
proto.jp.singcolor.UserBankAccountCreditCheckStatus.prototype.getCreditCheckStatus = function () {
    return /** @type {!proto.jp.singcolor.BankAccountCreditCheckStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.BankAccountCreditCheckStatus} value
 * @return {!proto.jp.singcolor.UserBankAccountCreditCheckStatus} returns this
 */
proto.jp.singcolor.UserBankAccountCreditCheckStatus.prototype.setCreditCheckStatus = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest}
 */
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest;
    return proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest}
 */
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest} returns this
 */
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userBankAccountCreditCheckStatus: (f = msg.getUserBankAccountCreditCheckStatus()) && proto.jp.singcolor.UserBankAccountCreditCheckStatus.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse}
 */
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse;
    return proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse}
 */
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.UserBankAccountCreditCheckStatus;
                reader.readMessage(value, proto.jp.singcolor.UserBankAccountCreditCheckStatus.deserializeBinaryFromReader);
                msg.setUserBankAccountCreditCheckStatus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserBankAccountCreditCheckStatus();
    if (f != null) {
        writer.writeMessage(1, f, proto.jp.singcolor.UserBankAccountCreditCheckStatus.serializeBinaryToWriter);
    }
};
/**
 * optional UserBankAccountCreditCheckStatus user_bank_account_credit_check_status = 1;
 * @return {?proto.jp.singcolor.UserBankAccountCreditCheckStatus}
 */
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse.prototype.getUserBankAccountCreditCheckStatus = function () {
    return /** @type{?proto.jp.singcolor.UserBankAccountCreditCheckStatus} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.UserBankAccountCreditCheckStatus, 1));
};
/**
 * @param {?proto.jp.singcolor.UserBankAccountCreditCheckStatus|undefined} value
 * @return {!proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse} returns this
*/
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse.prototype.setUserBankAccountCreditCheckStatus = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse} returns this
 */
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse.prototype.clearUserBankAccountCreditCheckStatus = function () {
    return this.setUserBankAccountCreditCheckStatus(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserBankAccountCreditCheckStatusResponse.prototype.hasUserBankAccountCreditCheckStatus = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            creditCheckStatus: jspb.Message.getFieldWithDefault(msg, 1, 0),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest}
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest;
    return proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest}
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.jp.singcolor.BankAccountCreditCheckStatus} */ (reader.readEnum());
                msg.setCreditCheckStatus(value);
                break;
            case 2:
                var value = new page_entity_pb.SimplePaginationRequest;
                reader.readMessage(value, page_entity_pb.SimplePaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCreditCheckStatus();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.SimplePaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional BankAccountCreditCheckStatus credit_check_status = 1;
 * @return {!proto.jp.singcolor.BankAccountCreditCheckStatus}
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.prototype.getCreditCheckStatus = function () {
    return /** @type {!proto.jp.singcolor.BankAccountCreditCheckStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.jp.singcolor.BankAccountCreditCheckStatus} value
 * @return {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest} returns this
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.prototype.setCreditCheckStatus = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional SimplePaginationRequest page = 2;
 * @return {?proto.jp.singcolor.SimplePaginationRequest}
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.SimplePaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationRequest, 2));
};
/**
 * @param {?proto.jp.singcolor.SimplePaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest} returns this
*/
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest} returns this
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesRequest.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userBankAccountCreditCheckStatusesList: jspb.Message.toObjectList(msg.getUserBankAccountCreditCheckStatusesList(), proto.jp.singcolor.UserBankAccountCreditCheckStatus.toObject, includeInstance),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationResult.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse}
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse;
    return proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse}
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.UserBankAccountCreditCheckStatus;
                reader.readMessage(value, proto.jp.singcolor.UserBankAccountCreditCheckStatus.deserializeBinaryFromReader);
                msg.addUserBankAccountCreditCheckStatuses(value);
                break;
            case 2:
                var value = new page_entity_pb.SimplePaginationResult;
                reader.readMessage(value, page_entity_pb.SimplePaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserBankAccountCreditCheckStatusesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.UserBankAccountCreditCheckStatus.serializeBinaryToWriter);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.SimplePaginationResult.serializeBinaryToWriter);
    }
};
/**
 * repeated UserBankAccountCreditCheckStatus user_bank_account_credit_check_statuses = 1;
 * @return {!Array<!proto.jp.singcolor.UserBankAccountCreditCheckStatus>}
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.prototype.getUserBankAccountCreditCheckStatusesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.UserBankAccountCreditCheckStatus>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.UserBankAccountCreditCheckStatus, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.UserBankAccountCreditCheckStatus>} value
 * @return {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse} returns this
*/
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.prototype.setUserBankAccountCreditCheckStatusesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.UserBankAccountCreditCheckStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.UserBankAccountCreditCheckStatus}
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.prototype.addUserBankAccountCreditCheckStatuses = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.UserBankAccountCreditCheckStatus, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse} returns this
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.prototype.clearUserBankAccountCreditCheckStatusesList = function () {
    return this.setUserBankAccountCreditCheckStatusesList([]);
};
/**
 * optional SimplePaginationResult page = 2;
 * @return {?proto.jp.singcolor.SimplePaginationResult}
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.SimplePaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationResult, 2));
};
/**
 * @param {?proto.jp.singcolor.SimplePaginationResult|undefined} value
 * @return {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse} returns this
*/
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse} returns this
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListUserBankAccountCreditCheckStatusesResponse.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            creditCheckStatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest}
 */
proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest;
    return proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest}
 */
proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {!proto.jp.singcolor.BankAccountCreditCheckStatus} */ (reader.readEnum());
                msg.setCreditCheckStatus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getCreditCheckStatus();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest} returns this
 */
proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional BankAccountCreditCheckStatus credit_check_status = 2;
 * @return {!proto.jp.singcolor.BankAccountCreditCheckStatus}
 */
proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest.prototype.getCreditCheckStatus = function () {
    return /** @type {!proto.jp.singcolor.BankAccountCreditCheckStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.jp.singcolor.BankAccountCreditCheckStatus} value
 * @return {!proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest} returns this
 */
proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusRequest.prototype.setCreditCheckStatus = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse}
 */
proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse;
    return proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse}
 */
proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserBankAccountCreditCheckStatusResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListUserBankAccountsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListUserBankAccountsRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListUserBankAccountsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListUserBankAccountsRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListUserBankAccountsRequest}
 */
proto.jp.singcolor.ListUserBankAccountsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListUserBankAccountsRequest;
    return proto.jp.singcolor.ListUserBankAccountsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListUserBankAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListUserBankAccountsRequest}
 */
proto.jp.singcolor.ListUserBankAccountsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListUserBankAccountsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListUserBankAccountsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListUserBankAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListUserBankAccountsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListUserBankAccountsRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListUserBankAccountsRequest} returns this
 */
proto.jp.singcolor.ListUserBankAccountsRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListUserBankAccountsResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListUserBankAccountsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListUserBankAccountsResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListUserBankAccountsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListUserBankAccountsResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            bankAccountsList: jspb.Message.toObjectList(msg.getBankAccountsList(), diamonds_entity_pb.BankAccount.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListUserBankAccountsResponse}
 */
proto.jp.singcolor.ListUserBankAccountsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListUserBankAccountsResponse;
    return proto.jp.singcolor.ListUserBankAccountsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListUserBankAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListUserBankAccountsResponse}
 */
proto.jp.singcolor.ListUserBankAccountsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.BankAccount;
                reader.readMessage(value, diamonds_entity_pb.BankAccount.deserializeBinaryFromReader);
                msg.addBankAccounts(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListUserBankAccountsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListUserBankAccountsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListUserBankAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListUserBankAccountsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBankAccountsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, diamonds_entity_pb.BankAccount.serializeBinaryToWriter);
    }
};
/**
 * repeated BankAccount bank_accounts = 1;
 * @return {!Array<!proto.jp.singcolor.BankAccount>}
 */
proto.jp.singcolor.ListUserBankAccountsResponse.prototype.getBankAccountsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.BankAccount>} */ (jspb.Message.getRepeatedWrapperField(this, diamonds_entity_pb.BankAccount, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.BankAccount>} value
 * @return {!proto.jp.singcolor.ListUserBankAccountsResponse} returns this
*/
proto.jp.singcolor.ListUserBankAccountsResponse.prototype.setBankAccountsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.BankAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.BankAccount}
 */
proto.jp.singcolor.ListUserBankAccountsResponse.prototype.addBankAccounts = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.BankAccount, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListUserBankAccountsResponse} returns this
 */
proto.jp.singcolor.ListUserBankAccountsResponse.prototype.clearBankAccountsList = function () {
    return this.setBankAccountsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListRiskeyesArticleLinksRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListRiskeyesArticleLinksRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListRiskeyesArticleLinksRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListRiskeyesArticleLinksRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListRiskeyesArticleLinksRequest}
 */
proto.jp.singcolor.ListRiskeyesArticleLinksRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListRiskeyesArticleLinksRequest;
    return proto.jp.singcolor.ListRiskeyesArticleLinksRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListRiskeyesArticleLinksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListRiskeyesArticleLinksRequest}
 */
proto.jp.singcolor.ListRiskeyesArticleLinksRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListRiskeyesArticleLinksRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListRiskeyesArticleLinksRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListRiskeyesArticleLinksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListRiskeyesArticleLinksRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListRiskeyesArticleLinksRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListRiskeyesArticleLinksRequest} returns this
 */
proto.jp.singcolor.ListRiskeyesArticleLinksRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListRiskeyesArticleLinksResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListRiskeyesArticleLinksResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListRiskeyesArticleLinksResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            articleLinksList: jspb.Message.toObjectList(msg.getArticleLinksList(), proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse}
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListRiskeyesArticleLinksResponse;
    return proto.jp.singcolor.ListRiskeyesArticleLinksResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse}
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink;
                reader.readMessage(value, proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.deserializeBinaryFromReader);
                msg.addArticleLinks(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListRiskeyesArticleLinksResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getArticleLinksList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.serializeBinaryToWriter);
    }
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.toObject = function (includeInstance, msg) {
        var f, obj = {
            userBankAccountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            keyword: jspb.Message.getFieldWithDefault(msg, 2, ""),
            articleLink: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink}
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink;
    return proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink}
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserBankAccountId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setKeyword(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setArticleLink(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserBankAccountId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getKeyword();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getArticleLink();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional string user_bank_account_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.prototype.getUserBankAccountId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink} returns this
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.prototype.setUserBankAccountId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string keyword = 2;
 * @return {string}
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.prototype.getKeyword = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink} returns this
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.prototype.setKeyword = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string article_link = 3;
 * @return {string}
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.prototype.getArticleLink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink} returns this
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink.prototype.setArticleLink = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * repeated ArticleLink article_links = 1;
 * @return {!Array<!proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink>}
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.prototype.getArticleLinksList = function () {
    return /** @type{!Array<!proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink>} value
 * @return {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse} returns this
*/
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.prototype.setArticleLinksList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink}
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.prototype.addArticleLinks = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.ListRiskeyesArticleLinksResponse.ArticleLink, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListRiskeyesArticleLinksResponse} returns this
 */
proto.jp.singcolor.ListRiskeyesArticleLinksResponse.prototype.clearArticleLinksList = function () {
    return this.setArticleLinksList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeAddDiamondRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeAddDiamondRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeAddDiamondRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeAddDiamondRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            amount: jspb.Message.getFieldWithDefault(msg, 2, 0),
            diamondType: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeAddDiamondRequest}
 */
proto.jp.singcolor.OpeAddDiamondRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeAddDiamondRequest;
    return proto.jp.singcolor.OpeAddDiamondRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeAddDiamondRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeAddDiamondRequest}
 */
proto.jp.singcolor.OpeAddDiamondRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAmount(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.DiamondType} */ (reader.readEnum());
                msg.setDiamondType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeAddDiamondRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeAddDiamondRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeAddDiamondRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeAddDiamondRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getAmount();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getDiamondType();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeAddDiamondRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeAddDiamondRequest} returns this
 */
proto.jp.singcolor.OpeAddDiamondRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 amount = 2;
 * @return {number}
 */
proto.jp.singcolor.OpeAddDiamondRequest.prototype.getAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OpeAddDiamondRequest} returns this
 */
proto.jp.singcolor.OpeAddDiamondRequest.prototype.setAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional DiamondType diamond_type = 3;
 * @return {!proto.jp.singcolor.DiamondType}
 */
proto.jp.singcolor.OpeAddDiamondRequest.prototype.getDiamondType = function () {
    return /** @type {!proto.jp.singcolor.DiamondType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.DiamondType} value
 * @return {!proto.jp.singcolor.OpeAddDiamondRequest} returns this
 */
proto.jp.singcolor.OpeAddDiamondRequest.prototype.setDiamondType = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeAddDiamondResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeAddDiamondResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeAddDiamondResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeAddDiamondResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            diamondBalance: (f = msg.getDiamondBalance()) && diamonds_entity_pb.DiamondBalance.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeAddDiamondResponse}
 */
proto.jp.singcolor.OpeAddDiamondResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeAddDiamondResponse;
    return proto.jp.singcolor.OpeAddDiamondResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeAddDiamondResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeAddDiamondResponse}
 */
proto.jp.singcolor.OpeAddDiamondResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = new diamonds_entity_pb.DiamondBalance;
                reader.readMessage(value, diamonds_entity_pb.DiamondBalance.deserializeBinaryFromReader);
                msg.setDiamondBalance(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeAddDiamondResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeAddDiamondResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeAddDiamondResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeAddDiamondResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getDiamondBalance();
    if (f != null) {
        writer.writeMessage(2, f, diamonds_entity_pb.DiamondBalance.serializeBinaryToWriter);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeAddDiamondResponse.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeAddDiamondResponse} returns this
 */
proto.jp.singcolor.OpeAddDiamondResponse.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional DiamondBalance diamond_balance = 2;
 * @return {?proto.jp.singcolor.DiamondBalance}
 */
proto.jp.singcolor.OpeAddDiamondResponse.prototype.getDiamondBalance = function () {
    return /** @type{?proto.jp.singcolor.DiamondBalance} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondBalance, 2));
};
/**
 * @param {?proto.jp.singcolor.DiamondBalance|undefined} value
 * @return {!proto.jp.singcolor.OpeAddDiamondResponse} returns this
*/
proto.jp.singcolor.OpeAddDiamondResponse.prototype.setDiamondBalance = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeAddDiamondResponse} returns this
 */
proto.jp.singcolor.OpeAddDiamondResponse.prototype.clearDiamondBalance = function () {
    return this.setDiamondBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeAddDiamondResponse.prototype.hasDiamondBalance = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeSubtractDiamondRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeSubtractDiamondRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeSubtractDiamondRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeSubtractDiamondRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            amount: jspb.Message.getFieldWithDefault(msg, 2, 0),
            diamondType: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeSubtractDiamondRequest}
 */
proto.jp.singcolor.OpeSubtractDiamondRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeSubtractDiamondRequest;
    return proto.jp.singcolor.OpeSubtractDiamondRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeSubtractDiamondRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeSubtractDiamondRequest}
 */
proto.jp.singcolor.OpeSubtractDiamondRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAmount(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.DiamondType} */ (reader.readEnum());
                msg.setDiamondType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeSubtractDiamondRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeSubtractDiamondRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeSubtractDiamondRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeSubtractDiamondRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getAmount();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getDiamondType();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeSubtractDiamondRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeSubtractDiamondRequest} returns this
 */
proto.jp.singcolor.OpeSubtractDiamondRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 amount = 2;
 * @return {number}
 */
proto.jp.singcolor.OpeSubtractDiamondRequest.prototype.getAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OpeSubtractDiamondRequest} returns this
 */
proto.jp.singcolor.OpeSubtractDiamondRequest.prototype.setAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional DiamondType diamond_type = 3;
 * @return {!proto.jp.singcolor.DiamondType}
 */
proto.jp.singcolor.OpeSubtractDiamondRequest.prototype.getDiamondType = function () {
    return /** @type {!proto.jp.singcolor.DiamondType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.DiamondType} value
 * @return {!proto.jp.singcolor.OpeSubtractDiamondRequest} returns this
 */
proto.jp.singcolor.OpeSubtractDiamondRequest.prototype.setDiamondType = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeSubtractDiamondResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeSubtractDiamondResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeSubtractDiamondResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeSubtractDiamondResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            diamondBalance: (f = msg.getDiamondBalance()) && diamonds_entity_pb.DiamondBalance.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeSubtractDiamondResponse}
 */
proto.jp.singcolor.OpeSubtractDiamondResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeSubtractDiamondResponse;
    return proto.jp.singcolor.OpeSubtractDiamondResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeSubtractDiamondResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeSubtractDiamondResponse}
 */
proto.jp.singcolor.OpeSubtractDiamondResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = new diamonds_entity_pb.DiamondBalance;
                reader.readMessage(value, diamonds_entity_pb.DiamondBalance.deserializeBinaryFromReader);
                msg.setDiamondBalance(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeSubtractDiamondResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeSubtractDiamondResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeSubtractDiamondResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeSubtractDiamondResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getDiamondBalance();
    if (f != null) {
        writer.writeMessage(2, f, diamonds_entity_pb.DiamondBalance.serializeBinaryToWriter);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeSubtractDiamondResponse.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeSubtractDiamondResponse} returns this
 */
proto.jp.singcolor.OpeSubtractDiamondResponse.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional DiamondBalance diamond_balance = 2;
 * @return {?proto.jp.singcolor.DiamondBalance}
 */
proto.jp.singcolor.OpeSubtractDiamondResponse.prototype.getDiamondBalance = function () {
    return /** @type{?proto.jp.singcolor.DiamondBalance} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondBalance, 2));
};
/**
 * @param {?proto.jp.singcolor.DiamondBalance|undefined} value
 * @return {!proto.jp.singcolor.OpeSubtractDiamondResponse} returns this
*/
proto.jp.singcolor.OpeSubtractDiamondResponse.prototype.setDiamondBalance = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeSubtractDiamondResponse} returns this
 */
proto.jp.singcolor.OpeSubtractDiamondResponse.prototype.clearDiamondBalance = function () {
    return this.setDiamondBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeSubtractDiamondResponse.prototype.hasDiamondBalance = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeListStatementsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeListStatementsRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeListStatementsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeListStatementsRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            page: (f = msg.getPage()) && page_entity_pb.CursorPaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeListStatementsRequest}
 */
proto.jp.singcolor.OpeListStatementsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeListStatementsRequest;
    return proto.jp.singcolor.OpeListStatementsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeListStatementsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeListStatementsRequest}
 */
proto.jp.singcolor.OpeListStatementsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = new page_entity_pb.CursorPaginationRequest;
                reader.readMessage(value, page_entity_pb.CursorPaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeListStatementsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeListStatementsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeListStatementsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeListStatementsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.CursorPaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeListStatementsRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeListStatementsRequest} returns this
 */
proto.jp.singcolor.OpeListStatementsRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional CursorPaginationRequest page = 2;
 * @return {?proto.jp.singcolor.CursorPaginationRequest}
 */
proto.jp.singcolor.OpeListStatementsRequest.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.CursorPaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.CursorPaginationRequest, 2));
};
/**
 * @param {?proto.jp.singcolor.CursorPaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.OpeListStatementsRequest} returns this
*/
proto.jp.singcolor.OpeListStatementsRequest.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeListStatementsRequest} returns this
 */
proto.jp.singcolor.OpeListStatementsRequest.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeListStatementsRequest.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.OpeListStatementsResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeListStatementsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeListStatementsResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeListStatementsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeListStatementsResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            statementsList: jspb.Message.toObjectList(msg.getStatementsList(), diamonds_entity_pb.Statement.toObject, includeInstance),
            page: (f = msg.getPage()) && page_entity_pb.CursorPaginationResult.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeListStatementsResponse}
 */
proto.jp.singcolor.OpeListStatementsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeListStatementsResponse;
    return proto.jp.singcolor.OpeListStatementsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeListStatementsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeListStatementsResponse}
 */
proto.jp.singcolor.OpeListStatementsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.Statement;
                reader.readMessage(value, diamonds_entity_pb.Statement.deserializeBinaryFromReader);
                msg.addStatements(value);
                break;
            case 2:
                var value = new page_entity_pb.CursorPaginationResult;
                reader.readMessage(value, page_entity_pb.CursorPaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeListStatementsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeListStatementsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeListStatementsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeListStatementsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getStatementsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, diamonds_entity_pb.Statement.serializeBinaryToWriter);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.CursorPaginationResult.serializeBinaryToWriter);
    }
};
/**
 * repeated Statement statements = 1;
 * @return {!Array<!proto.jp.singcolor.Statement>}
 */
proto.jp.singcolor.OpeListStatementsResponse.prototype.getStatementsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.Statement>} */ (jspb.Message.getRepeatedWrapperField(this, diamonds_entity_pb.Statement, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.Statement>} value
 * @return {!proto.jp.singcolor.OpeListStatementsResponse} returns this
*/
proto.jp.singcolor.OpeListStatementsResponse.prototype.setStatementsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.Statement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Statement}
 */
proto.jp.singcolor.OpeListStatementsResponse.prototype.addStatements = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.Statement, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.OpeListStatementsResponse} returns this
 */
proto.jp.singcolor.OpeListStatementsResponse.prototype.clearStatementsList = function () {
    return this.setStatementsList([]);
};
/**
 * optional CursorPaginationResult page = 2;
 * @return {?proto.jp.singcolor.CursorPaginationResult}
 */
proto.jp.singcolor.OpeListStatementsResponse.prototype.getPage = function () {
    return /** @type{?proto.jp.singcolor.CursorPaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.CursorPaginationResult, 2));
};
/**
 * @param {?proto.jp.singcolor.CursorPaginationResult|undefined} value
 * @return {!proto.jp.singcolor.OpeListStatementsResponse} returns this
*/
proto.jp.singcolor.OpeListStatementsResponse.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeListStatementsResponse} returns this
 */
proto.jp.singcolor.OpeListStatementsResponse.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeListStatementsResponse.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
goog.object.extend(exports, proto.jp.singcolor);
