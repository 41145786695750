export const SWRKey = {
  debugListUsers: '/debug/listUsers',
  exchangeDiamondStatus: `/diamond-status`,
  getStatisticsCurrentDiamond: (userID: string, organizationID: string) =>
    `/statistics-current-diamond?userID=${userID}&organizationID=${organizationID}`,
  getMonthlyStatistics: (userID: string, organizationID: string, year: number, month: number) =>
    `/monthly-statistics?userID=${userID}&organizationID=${organizationID}&year=${year}&month=${month}`,
  diamondStatements: {
    create: (pageToken: string | null | undefined, limit: number, targetYear?: number) =>
      `/diamondStatements?pageToken=${pageToken ?? ''}&limit=${limit}&targetYear=${targetYear}`,
    decode: (key: string) => {
      const url = new URL(`https://example.com${key}`)
      return {
        pageToken: url.searchParams.get('pageToken') || undefined,
        limit: Number.parseInt(url.searchParams.get('limit')!)
      }
    }
  },
  listJoinableMembershipLivers: {
    create: (page: number, limit: number) => `/listJoinableMembershipLivers?page=${page}&limit=${limit}`,
    decode: (key: string) => {
      const url = new URL(`https://example.com${key}`)
      return {
        page: Number.parseInt(url.searchParams.get('page')!),
        limit: Number.parseInt(url.searchParams.get('limit')!)
      }
    }
  },
  getMeProfile: () => '/me/profile',
  getMe: () => '/me',
  getUser: (userID: string) => `/users/${userID}`,
  getMeRequestedTime: () => '/debug/me/requestedTime',
  getOrganizationStaff: (userID: string) => `/organizationStaffs?userID=${userID}`,
  getOrganizationLiver: (userID: string) => `/organizationLivers?userID=${userID}`,
  listWebPage: '/list-web-page',
  listBankAccounts: () => '/list-bank-accounts',
  listBankAccountDetails: () => '/list-bank-account-details',
  listMembershipPlansForJoin: (liverId: string) => `/listMembershipPlansForJoin?liverId=${liverId}`,
  listMembershipPlansForChangeQuantity: (liverId: string) => `/listMembershipPlansForChangeQuantity?liverId=${liverId}`,
  listMembershipPlansForIncreaseQuantity: (liverId: string) =>
    `/listMembershipPlansForIncreaseQuantity?liverId=${liverId}`,
  listActiveListenerMembershipPlans: '/listActiveListenerMembershipPlans',
  listMembershipListeners: {
    create: (limit: number, pageToken: string | null | undefined) =>
      `/listMembershipListeners?limit=${limit}&pageToken=${pageToken ?? ''}`,
    decode: (key: string) => {
      const url = new URL(`https://example.com${key}`)
      return {
        limit: Number.parseInt(url.searchParams.get('limit')!),
        pageToken: url.searchParams.get('pageToken') || undefined
      }
    }
  },
  listWillJoinMembershipListeners: {
    create: (limit: number, pageToken: string | null | undefined) =>
      `/listWillJoinMembershipListeners?limit=${limit}&pageToken=${pageToken ?? ''}`,
    decode: (key: string) => {
      const url = new URL(`https://example.com${key}`)
      return {
        limit: Number.parseInt(url.searchParams.get('limit')!),
        pageToken: url.searchParams.get('pageToken') || undefined
      }
    }
  },

  listMajorBanks: () => '/listMajorBanks',
  listBanks: (initialKana: string) => `/listBanks${initialKana}`,
  listAvaliableFeatures: () => `/listAvaliableFeatures`,
  listBankBranches: (bankCode: string, initialKana?: string) =>
    `/listBankBranches?bankCode=${bankCode}&initialKana=${initialKana}`,

  getPurchasableWebCoinPlans: '/get-purchasable-web-coin-plans',
  getCoinBalance: '/get-coin-balance',

  listWithdrawalDiamondLedgers: (targetYear?: number) =>
    targetYear ? `listWithdrawalDiamondLedgers?targetYear=${targetYear}` : `listWithdrawalDiamondLedgers`,
  getWithdrawalDiamondLedgers: (withdrawalDiamondTransactionId: string) =>
    `getWithdrawalDiamondLedgers?withdrawalDiamondTransactionId=${withdrawalDiamondTransactionId}`,
  getWithdrawalDiamondStatus: () => 'getWithdrawalDiamondStatus',
  getWithdrawStatus: () => 'getWithdrawStatus',
  getBan: () => 'getBan',
  checkAbilityToPayTsunaguByDiamondsAndPearls: (price: number) => `/tsunagu/check_ability_to_pay?price=${price}`,
  getTransactionInfoRequest: (paymentId: string) => `/tsunagu/payments?payment_id=${paymentId}`,
  executePayment: (paymentId: string) => `/tsunagu_execute_payment?payment_id=${paymentId}`,
  getPearlBalanceSummary: () => 'getPearlBalanceSummary',
  getStripeCustomerPortalUrl: () => '/getStripeCustomerPortalUrl'
}
