// source: karaoke_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.jp.singcolor.BlockString', null, global);
goog.exportSymbol('proto.jp.singcolor.ChangeFlagType', null, global);
goog.exportSymbol('proto.jp.singcolor.Fme', null, global);
goog.exportSymbol('proto.jp.singcolor.LiverAndListenerFavoriteSongCategory', null, global);
goog.exportSymbol('proto.jp.singcolor.LiverAndListenerFavoriteSongsSort', null, global);
goog.exportSymbol('proto.jp.singcolor.RubiBlock', null, global);
goog.exportSymbol('proto.jp.singcolor.Singer', null, global);
goog.exportSymbol('proto.jp.singcolor.Song', null, global);
goog.exportSymbol('proto.jp.singcolor.SongWithCategory', null, global);
goog.exportSymbol('proto.jp.singcolor.Timing', null, global);
goog.exportSymbol('proto.jp.singcolor.TimingType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.Singer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.Singer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.Singer.displayName = 'proto.jp.singcolor.Singer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.Song = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.Song, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.Song.displayName = 'proto.jp.singcolor.Song';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.Fme = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.Fme.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.Fme, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.Fme.displayName = 'proto.jp.singcolor.Fme';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BlockString = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.BlockString.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.BlockString, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.BlockString.displayName = 'proto.jp.singcolor.BlockString';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RubiBlock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RubiBlock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.RubiBlock.displayName = 'proto.jp.singcolor.RubiBlock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.Timing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.Timing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.Timing.displayName = 'proto.jp.singcolor.Timing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SongWithCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SongWithCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.SongWithCategory.displayName = 'proto.jp.singcolor.SongWithCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LiverAndListenerFavoriteSongCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LiverAndListenerFavoriteSongCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.displayName = 'proto.jp.singcolor.LiverAndListenerFavoriteSongCategory';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.Singer.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.Singer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.Singer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Singer.toObject = function(includeInstance, msg) {
  var f, obj = {
    singerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    kana: jspb.Message.getFieldWithDefault(msg, 4, ""),
    favorited: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    hasCanSingSong: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.Singer}
 */
proto.jp.singcolor.Singer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.Singer;
  return proto.jp.singcolor.Singer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.Singer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.Singer}
 */
proto.jp.singcolor.Singer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKana(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorited(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasCanSingSong(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.Singer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.Singer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.Singer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Singer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSingerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKana();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFavorited();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHasCanSingSong();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string singer_id = 1;
 * @return {string}
 */
proto.jp.singcolor.Singer.prototype.getSingerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Singer} returns this
 */
proto.jp.singcolor.Singer.prototype.setSingerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.jp.singcolor.Singer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Singer} returns this
 */
proto.jp.singcolor.Singer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string kana = 4;
 * @return {string}
 */
proto.jp.singcolor.Singer.prototype.getKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Singer} returns this
 */
proto.jp.singcolor.Singer.prototype.setKana = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool favorited = 5;
 * @return {boolean}
 */
proto.jp.singcolor.Singer.prototype.getFavorited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.Singer} returns this
 */
proto.jp.singcolor.Singer.prototype.setFavorited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool has_can_sing_song = 8;
 * @return {boolean}
 */
proto.jp.singcolor.Singer.prototype.getHasCanSingSong = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.Singer} returns this
 */
proto.jp.singcolor.Singer.prototype.setHasCanSingSong = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.jp.singcolor.Singer.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Singer} returns this
 */
proto.jp.singcolor.Singer.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 updated_at = 7;
 * @return {number}
 */
proto.jp.singcolor.Singer.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Singer} returns this
 */
proto.jp.singcolor.Singer.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.Song.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.Song.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.Song} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Song.toObject = function(includeInstance, msg) {
  var f, obj = {
    songId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    kana: jspb.Message.getFieldWithDefault(msg, 4, ""),
    intro: jspb.Message.getFieldWithDefault(msg, 5, ""),
    bpm: jspb.Message.getFieldWithDefault(msg, 7, 0),
    playerTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    musicUrl: jspb.Message.getFieldWithDefault(msg, 9, ""),
    guidMusicUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    favorited: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    favoritedAsLiver: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    favoritedAsListener: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    singer: (f = msg.getSinger()) && proto.jp.singcolor.Singer.toObject(includeInstance, f),
    fme: (f = msg.getFme()) && proto.jp.singcolor.Fme.toObject(includeInstance, f),
    originalKey: jspb.Message.getFieldWithDefault(msg, 16, 0),
    latestSungAt: jspb.Message.getFieldWithDefault(msg, 17, 0),
    userSongStateOwnerId: jspb.Message.getFieldWithDefault(msg, 18, ""),
    duration: jspb.Message.getFieldWithDefault(msg, 19, 0),
    composer: jspb.Message.getFieldWithDefault(msg, 20, ""),
    canSing: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    availableAt: jspb.Message.getFieldWithDefault(msg, 27, 0),
    isRequestSong: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    requestedToAddNewSong: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    requestedUsersCount: jspb.Message.getFieldWithDefault(msg, 26, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.Song}
 */
proto.jp.singcolor.Song.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.Song;
  return proto.jp.singcolor.Song.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.Song} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.Song}
 */
proto.jp.singcolor.Song.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSongId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKana(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntro(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBpm(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlayerTime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMusicUrl(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setGuidMusicUrl(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorited(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavoritedAsLiver(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavoritedAsListener(value);
      break;
    case 12:
      var value = new proto.jp.singcolor.Singer;
      reader.readMessage(value,proto.jp.singcolor.Singer.deserializeBinaryFromReader);
      msg.setSinger(value);
      break;
    case 13:
      var value = new proto.jp.singcolor.Fme;
      reader.readMessage(value,proto.jp.singcolor.Fme.deserializeBinaryFromReader);
      msg.setFme(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOriginalKey(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestSungAt(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserSongStateOwnerId(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setComposer(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanSing(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAvailableAt(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequestSong(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequestedToAddNewSong(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestedUsersCount(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.Song.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.Song.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.Song} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Song.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSongId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKana();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIntro();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBpm();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPlayerTime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getMusicUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getGuidMusicUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFavorited();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getFavoritedAsLiver();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getFavoritedAsListener();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getSinger();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.jp.singcolor.Singer.serializeBinaryToWriter
    );
  }
  f = message.getFme();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.jp.singcolor.Fme.serializeBinaryToWriter
    );
  }
  f = message.getOriginalKey();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getLatestSungAt();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getUserSongStateOwnerId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getComposer();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getCanSing();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getAvailableAt();
  if (f !== 0) {
    writer.writeInt64(
      27,
      f
    );
  }
  f = message.getIsRequestSong();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getRequestedToAddNewSong();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getRequestedUsersCount();
  if (f !== 0) {
    writer.writeInt64(
      26,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
};


/**
 * optional string song_id = 1;
 * @return {string}
 */
proto.jp.singcolor.Song.prototype.getSongId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setSongId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.jp.singcolor.Song.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string kana = 4;
 * @return {string}
 */
proto.jp.singcolor.Song.prototype.getKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setKana = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string intro = 5;
 * @return {string}
 */
proto.jp.singcolor.Song.prototype.getIntro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setIntro = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 bpm = 7;
 * @return {number}
 */
proto.jp.singcolor.Song.prototype.getBpm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setBpm = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 player_time = 8;
 * @return {number}
 */
proto.jp.singcolor.Song.prototype.getPlayerTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setPlayerTime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string music_url = 9;
 * @return {string}
 */
proto.jp.singcolor.Song.prototype.getMusicUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setMusicUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string guid_music_url = 10;
 * @return {string}
 */
proto.jp.singcolor.Song.prototype.getGuidMusicUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setGuidMusicUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool favorited = 11;
 * @return {boolean}
 */
proto.jp.singcolor.Song.prototype.getFavorited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setFavorited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool favorited_as_liver = 21;
 * @return {boolean}
 */
proto.jp.singcolor.Song.prototype.getFavoritedAsLiver = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setFavoritedAsLiver = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool favorited_as_listener = 22;
 * @return {boolean}
 */
proto.jp.singcolor.Song.prototype.getFavoritedAsListener = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setFavoritedAsListener = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional Singer singer = 12;
 * @return {?proto.jp.singcolor.Singer}
 */
proto.jp.singcolor.Song.prototype.getSinger = function() {
  return /** @type{?proto.jp.singcolor.Singer} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.Singer, 12));
};


/**
 * @param {?proto.jp.singcolor.Singer|undefined} value
 * @return {!proto.jp.singcolor.Song} returns this
*/
proto.jp.singcolor.Song.prototype.setSinger = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.clearSinger = function() {
  return this.setSinger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.Song.prototype.hasSinger = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Fme fme = 13;
 * @return {?proto.jp.singcolor.Fme}
 */
proto.jp.singcolor.Song.prototype.getFme = function() {
  return /** @type{?proto.jp.singcolor.Fme} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.Fme, 13));
};


/**
 * @param {?proto.jp.singcolor.Fme|undefined} value
 * @return {!proto.jp.singcolor.Song} returns this
*/
proto.jp.singcolor.Song.prototype.setFme = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.clearFme = function() {
  return this.setFme(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.Song.prototype.hasFme = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int64 original_key = 16;
 * @return {number}
 */
proto.jp.singcolor.Song.prototype.getOriginalKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setOriginalKey = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 latest_sung_at = 17;
 * @return {number}
 */
proto.jp.singcolor.Song.prototype.getLatestSungAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setLatestSungAt = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string user_song_state_owner_id = 18;
 * @return {string}
 */
proto.jp.singcolor.Song.prototype.getUserSongStateOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setUserSongStateOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional int64 duration = 19;
 * @return {number}
 */
proto.jp.singcolor.Song.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string composer = 20;
 * @return {string}
 */
proto.jp.singcolor.Song.prototype.getComposer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setComposer = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool can_sing = 23;
 * @return {boolean}
 */
proto.jp.singcolor.Song.prototype.getCanSing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setCanSing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional int64 available_at = 27;
 * @return {number}
 */
proto.jp.singcolor.Song.prototype.getAvailableAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setAvailableAt = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional bool is_request_song = 24;
 * @return {boolean}
 */
proto.jp.singcolor.Song.prototype.getIsRequestSong = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setIsRequestSong = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional bool requested_to_add_new_song = 25;
 * @return {boolean}
 */
proto.jp.singcolor.Song.prototype.getRequestedToAddNewSong = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setRequestedToAddNewSong = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional int64 requested_users_count = 26;
 * @return {number}
 */
proto.jp.singcolor.Song.prototype.getRequestedUsersCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setRequestedUsersCount = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional int64 created_at = 14;
 * @return {number}
 */
proto.jp.singcolor.Song.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 updated_at = 15;
 * @return {number}
 */
proto.jp.singcolor.Song.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Song} returns this
 */
proto.jp.singcolor.Song.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.Fme.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.Fme.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.Fme.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.Fme} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Fme.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockStringsList: jspb.Message.toObjectList(msg.getBlockStringsList(),
    proto.jp.singcolor.BlockString.toObject, includeInstance),
    colorPalletsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    timingsList: jspb.Message.toObjectList(msg.getTimingsList(),
    proto.jp.singcolor.Timing.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.Fme}
 */
proto.jp.singcolor.Fme.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.Fme;
  return proto.jp.singcolor.Fme.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.Fme} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.Fme}
 */
proto.jp.singcolor.Fme.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jp.singcolor.BlockString;
      reader.readMessage(value,proto.jp.singcolor.BlockString.deserializeBinaryFromReader);
      msg.addBlockStrings(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addColorPallets(value);
      break;
    case 3:
      var value = new proto.jp.singcolor.Timing;
      reader.readMessage(value,proto.jp.singcolor.Timing.deserializeBinaryFromReader);
      msg.addTimings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.Fme.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.Fme.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.Fme} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Fme.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockStringsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.jp.singcolor.BlockString.serializeBinaryToWriter
    );
  }
  f = message.getColorPalletsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getTimingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.jp.singcolor.Timing.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BlockString block_strings = 1;
 * @return {!Array<!proto.jp.singcolor.BlockString>}
 */
proto.jp.singcolor.Fme.prototype.getBlockStringsList = function() {
  return /** @type{!Array<!proto.jp.singcolor.BlockString>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.BlockString, 1));
};


/**
 * @param {!Array<!proto.jp.singcolor.BlockString>} value
 * @return {!proto.jp.singcolor.Fme} returns this
*/
proto.jp.singcolor.Fme.prototype.setBlockStringsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.jp.singcolor.BlockString=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.BlockString}
 */
proto.jp.singcolor.Fme.prototype.addBlockStrings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.BlockString, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.Fme} returns this
 */
proto.jp.singcolor.Fme.prototype.clearBlockStringsList = function() {
  return this.setBlockStringsList([]);
};


/**
 * repeated string color_pallets = 2;
 * @return {!Array<string>}
 */
proto.jp.singcolor.Fme.prototype.getColorPalletsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.Fme} returns this
 */
proto.jp.singcolor.Fme.prototype.setColorPalletsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Fme} returns this
 */
proto.jp.singcolor.Fme.prototype.addColorPallets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.Fme} returns this
 */
proto.jp.singcolor.Fme.prototype.clearColorPalletsList = function() {
  return this.setColorPalletsList([]);
};


/**
 * repeated Timing timings = 3;
 * @return {!Array<!proto.jp.singcolor.Timing>}
 */
proto.jp.singcolor.Fme.prototype.getTimingsList = function() {
  return /** @type{!Array<!proto.jp.singcolor.Timing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.Timing, 3));
};


/**
 * @param {!Array<!proto.jp.singcolor.Timing>} value
 * @return {!proto.jp.singcolor.Fme} returns this
*/
proto.jp.singcolor.Fme.prototype.setTimingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.jp.singcolor.Timing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.Timing}
 */
proto.jp.singcolor.Fme.prototype.addTimings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.jp.singcolor.Timing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.Fme} returns this
 */
proto.jp.singcolor.Fme.prototype.clearTimingsList = function() {
  return this.setTimingsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.BlockString.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.BlockString.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.BlockString.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.BlockString} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BlockString.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeFlag: jspb.Message.getFieldWithDefault(msg, 1, 0),
    x: jspb.Message.getFieldWithDefault(msg, 2, 0),
    y: jspb.Message.getFieldWithDefault(msg, 3, 0),
    beforeTextColor: jspb.Message.getFieldWithDefault(msg, 4, 0),
    afterTextColor: jspb.Message.getFieldWithDefault(msg, 5, 0),
    beforeBorderColor: jspb.Message.getFieldWithDefault(msg, 6, 0),
    afterBorderColor: jspb.Message.getFieldWithDefault(msg, 7, 0),
    text: jspb.Message.getFieldWithDefault(msg, 8, ""),
    width: jspb.Message.getFieldWithDefault(msg, 9, 0),
    rubiBlocksList: jspb.Message.toObjectList(msg.getRubiBlocksList(),
    proto.jp.singcolor.RubiBlock.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BlockString}
 */
proto.jp.singcolor.BlockString.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.BlockString;
  return proto.jp.singcolor.BlockString.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BlockString} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BlockString}
 */
proto.jp.singcolor.BlockString.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.jp.singcolor.ChangeFlagType} */ (reader.readEnum());
      msg.setChangeFlag(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setX(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setY(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBeforeTextColor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAfterTextColor(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBeforeBorderColor(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAfterBorderColor(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWidth(value);
      break;
    case 10:
      var value = new proto.jp.singcolor.RubiBlock;
      reader.readMessage(value,proto.jp.singcolor.RubiBlock.deserializeBinaryFromReader);
      msg.addRubiBlocks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BlockString.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.BlockString.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BlockString} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BlockString.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeFlag();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getX();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getY();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getBeforeTextColor();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAfterTextColor();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getBeforeBorderColor();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getAfterBorderColor();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getRubiBlocksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.jp.singcolor.RubiBlock.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChangeFlagType change_flag = 1;
 * @return {!proto.jp.singcolor.ChangeFlagType}
 */
proto.jp.singcolor.BlockString.prototype.getChangeFlag = function() {
  return /** @type {!proto.jp.singcolor.ChangeFlagType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.jp.singcolor.ChangeFlagType} value
 * @return {!proto.jp.singcolor.BlockString} returns this
 */
proto.jp.singcolor.BlockString.prototype.setChangeFlag = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 x = 2;
 * @return {number}
 */
proto.jp.singcolor.BlockString.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.BlockString} returns this
 */
proto.jp.singcolor.BlockString.prototype.setX = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 y = 3;
 * @return {number}
 */
proto.jp.singcolor.BlockString.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.BlockString} returns this
 */
proto.jp.singcolor.BlockString.prototype.setY = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 before_text_color = 4;
 * @return {number}
 */
proto.jp.singcolor.BlockString.prototype.getBeforeTextColor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.BlockString} returns this
 */
proto.jp.singcolor.BlockString.prototype.setBeforeTextColor = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 after_text_color = 5;
 * @return {number}
 */
proto.jp.singcolor.BlockString.prototype.getAfterTextColor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.BlockString} returns this
 */
proto.jp.singcolor.BlockString.prototype.setAfterTextColor = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 before_border_color = 6;
 * @return {number}
 */
proto.jp.singcolor.BlockString.prototype.getBeforeBorderColor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.BlockString} returns this
 */
proto.jp.singcolor.BlockString.prototype.setBeforeBorderColor = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 after_border_color = 7;
 * @return {number}
 */
proto.jp.singcolor.BlockString.prototype.getAfterBorderColor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.BlockString} returns this
 */
proto.jp.singcolor.BlockString.prototype.setAfterBorderColor = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string text = 8;
 * @return {string}
 */
proto.jp.singcolor.BlockString.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BlockString} returns this
 */
proto.jp.singcolor.BlockString.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 width = 9;
 * @return {number}
 */
proto.jp.singcolor.BlockString.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.BlockString} returns this
 */
proto.jp.singcolor.BlockString.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated RubiBlock rubi_blocks = 10;
 * @return {!Array<!proto.jp.singcolor.RubiBlock>}
 */
proto.jp.singcolor.BlockString.prototype.getRubiBlocksList = function() {
  return /** @type{!Array<!proto.jp.singcolor.RubiBlock>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.RubiBlock, 10));
};


/**
 * @param {!Array<!proto.jp.singcolor.RubiBlock>} value
 * @return {!proto.jp.singcolor.BlockString} returns this
*/
proto.jp.singcolor.BlockString.prototype.setRubiBlocksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.jp.singcolor.RubiBlock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.RubiBlock}
 */
proto.jp.singcolor.BlockString.prototype.addRubiBlocks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.jp.singcolor.RubiBlock, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.BlockString} returns this
 */
proto.jp.singcolor.BlockString.prototype.clearRubiBlocksList = function() {
  return this.setRubiBlocksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.RubiBlock.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.RubiBlock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.RubiBlock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RubiBlock.toObject = function(includeInstance, msg) {
  var f, obj = {
    relX: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    width: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RubiBlock}
 */
proto.jp.singcolor.RubiBlock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.RubiBlock;
  return proto.jp.singcolor.RubiBlock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RubiBlock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RubiBlock}
 */
proto.jp.singcolor.RubiBlock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRelX(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWidth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RubiBlock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.RubiBlock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RubiBlock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RubiBlock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelX();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 rel_x = 1;
 * @return {number}
 */
proto.jp.singcolor.RubiBlock.prototype.getRelX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.RubiBlock} returns this
 */
proto.jp.singcolor.RubiBlock.prototype.setRelX = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.jp.singcolor.RubiBlock.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RubiBlock} returns this
 */
proto.jp.singcolor.RubiBlock.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 width = 3;
 * @return {number}
 */
proto.jp.singcolor.RubiBlock.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.RubiBlock} returns this
 */
proto.jp.singcolor.RubiBlock.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.Timing.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.Timing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.Timing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Timing.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    data: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.Timing}
 */
proto.jp.singcolor.Timing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.Timing;
  return proto.jp.singcolor.Timing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.Timing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.Timing}
 */
proto.jp.singcolor.Timing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {!proto.jp.singcolor.TimingType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.Timing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.Timing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.Timing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Timing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getData();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 time = 1;
 * @return {number}
 */
proto.jp.singcolor.Timing.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Timing} returns this
 */
proto.jp.singcolor.Timing.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional TimingType type = 2;
 * @return {!proto.jp.singcolor.TimingType}
 */
proto.jp.singcolor.Timing.prototype.getType = function() {
  return /** @type {!proto.jp.singcolor.TimingType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.jp.singcolor.TimingType} value
 * @return {!proto.jp.singcolor.Timing} returns this
 */
proto.jp.singcolor.Timing.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 data = 3;
 * @return {number}
 */
proto.jp.singcolor.Timing.prototype.getData = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Timing} returns this
 */
proto.jp.singcolor.Timing.prototype.setData = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.SongWithCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.SongWithCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.SongWithCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SongWithCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    song: (f = msg.getSong()) && proto.jp.singcolor.Song.toObject(includeInstance, f),
    category: (f = msg.getCategory()) && proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SongWithCategory}
 */
proto.jp.singcolor.SongWithCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.SongWithCategory;
  return proto.jp.singcolor.SongWithCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SongWithCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SongWithCategory}
 */
proto.jp.singcolor.SongWithCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jp.singcolor.Song;
      reader.readMessage(value,proto.jp.singcolor.Song.deserializeBinaryFromReader);
      msg.setSong(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.LiverAndListenerFavoriteSongCategory;
      reader.readMessage(value,proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SongWithCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.SongWithCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SongWithCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SongWithCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSong();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.jp.singcolor.Song.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional Song song = 1;
 * @return {?proto.jp.singcolor.Song}
 */
proto.jp.singcolor.SongWithCategory.prototype.getSong = function() {
  return /** @type{?proto.jp.singcolor.Song} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.Song, 1));
};


/**
 * @param {?proto.jp.singcolor.Song|undefined} value
 * @return {!proto.jp.singcolor.SongWithCategory} returns this
*/
proto.jp.singcolor.SongWithCategory.prototype.setSong = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SongWithCategory} returns this
 */
proto.jp.singcolor.SongWithCategory.prototype.clearSong = function() {
  return this.setSong(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SongWithCategory.prototype.hasSong = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LiverAndListenerFavoriteSongCategory category = 2;
 * @return {?proto.jp.singcolor.LiverAndListenerFavoriteSongCategory}
 */
proto.jp.singcolor.SongWithCategory.prototype.getCategory = function() {
  return /** @type{?proto.jp.singcolor.LiverAndListenerFavoriteSongCategory} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.LiverAndListenerFavoriteSongCategory, 2));
};


/**
 * @param {?proto.jp.singcolor.LiverAndListenerFavoriteSongCategory|undefined} value
 * @return {!proto.jp.singcolor.SongWithCategory} returns this
*/
proto.jp.singcolor.SongWithCategory.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.SongWithCategory} returns this
 */
proto.jp.singcolor.SongWithCategory.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.SongWithCategory.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shouldShowOrder: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory}
 */
proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.LiverAndListenerFavoriteSongCategory;
  return proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory}
 */
proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShouldShowOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShouldShowOrder();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory} returns this
 */
proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory} returns this
 */
proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool should_show_order = 3;
 * @return {boolean}
 */
proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.prototype.getShouldShowOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.LiverAndListenerFavoriteSongCategory} returns this
 */
proto.jp.singcolor.LiverAndListenerFavoriteSongCategory.prototype.setShouldShowOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.jp.singcolor.ChangeFlagType = {
  NORMAL: 0,
  SUB_BLOCK: 1,
  NONE: 255
};

/**
 * @enum {number}
 */
proto.jp.singcolor.TimingType = {
  START_COLOR_CHANGE: 0,
  UPDATE_COLOR_CHANGE: 1,
  TITLE_END: 4,
  DISMISS_BLOCK: 5,
  SHOW_BLOCK: 6,
  START_SUB_COLOR_CHANGE: 7,
  UPDATE_SUB_COLOR_CHANGE: 8,
  STARTS_LOW_COLOR_CHANGE: 12,
  UPDATES_LOW_COLOR_CHANGE: 13,
  APPLAUSE: 23,
  CHORUS_BEGIN: 30,
  CUT_POSTLUDE: 31,
  TWO_CHORUS_END: 33
};

/**
 * @enum {number}
 */
proto.jp.singcolor.LiverAndListenerFavoriteSongsSort = {
  UNSPECIFIED: 0,
  CREATED_AT_ASC: 3,
  SING_COUNT_DESC: 4,
  SING_COUNT_ASC: 5,
  SONG_KANA_ASC: 6,
  SONG_KANA_DESC: 7,
  SINGER_KANA_ASC: 8,
  SINGER_KANA_DESC: 9,
  SONG_POPULARITY_DESC: 10,
  SONG_PUBLISHED_AT_DESC: 11,
  SONG_PUBLISHED_AT_ASC: 12,
  RANDOM: 13,
  CREATED_AT_DESC: 14,
  MANUAL: 15
};

goog.object.extend(exports, proto.jp.singcolor);
